import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import '../../styles/_campaign.scss'
import {getLeadList} from '../../../leads/core/_requests'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {AddLeadsModal} from '../view/AddLeadsModal'
import {LeadList} from './LeadList'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'
export const AudiencePageAnalytics = ({
  leadListCountValue,
  getCampaignData,
}: {
  leadListCountValue: number | undefined
  getCampaignData: () => void
}) => {
  const {formatMessage} = useIntl()
  const [leadList, setLeadList] = useState([])
  const {campaignId} = useParams()
  const [loader, setLoader] = useState<boolean>()
  const [leadListLoader, setleadListLoader] = useState<boolean>(false)
  const [leadListCount, setLeadListCount] = useState<number>()
  const [openLeadsModal, setOpenLeadsModal] = useState<boolean>(false)

  const getListOfList = async () => {
    try {
      setLoader(true)
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await getLeadList(campaignValue)
        if (success) {
          setLeadList(data)
          setLeadListCount(data.length)
          setLoader(false)
        } else {
          setLoader(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        toast.error(formatMessage({id: 'Campaign id not found'}))
      }
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getListOfList()
  }, [])

  useEffect(() => {
    if (leadListCountValue) {
      setLeadListCount(leadListCountValue)
    }
  }, [leadListCountValue])

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <>
          <div className='px-8 pt-8'>
            <div className='w-100 bg-white d-flex justify-content-between'>
              <div className='font-size-20 fw-bold'>{formatMessage({id: 'Lists of Leads'})}</div>
              <GlobalButton
                buttonText={formatMessage({id: 'Add Leads'})}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => setOpenLeadsModal(true)}
              />
            </div>
            <div className='py-4'>
              {leadList &&
                leadList.map((lead: any) => {
                  return (
                    <LeadList
                      key={lead.leadListId}
                      lead={lead}
                      campaignId={campaignId}
                      getListOfList={getListOfList}
                      leadListCount={leadListCount}
                    ></LeadList>
                  )
                })}
            </div>
          </div>
          <AddLeadsModal
            openLeadsModal={openLeadsModal}
            setOpenLeadsModal={setOpenLeadsModal}
            setleadListLoader={setleadListLoader}
            campaignId={campaignId}
            getAllLeadList={getListOfList}
            leadListLoader={leadListLoader}
            getCampaignData={getCampaignData}
          />
        </>
      )}
    </>
  )
}
