export const useTimeUnit = () => {
  const getTimeUnit = (time: number | undefined) => {
    if (time !== undefined) {
      if (time >= 0 && time <= 11) {
        return `${(time === 0 ? 12 : time).toString().padStart(2, '0')}:00 AM`
      } else if (time >= 12 && time <= 23) {
        return `${(time === 12 ? 12 : time - 12).toString().padStart(2, '0')}:00 PM`
      } else {
        return 'Invalid Hour'
      }
    }
  }
  return {getTimeUnit}
}
