import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {Inbox} from './components/inbox'
import {Chat} from './components/chat'

const InboxRoutes = () => {
  return (
    <Routes>
      <Route index element={<Inbox />} />
      <Route path='/:chatId' element={<Chat />} />
    </Routes>
  )
}

export default InboxRoutes
