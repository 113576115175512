import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'
import {Status} from '../../../core/_constants'
import {applyCouponDiscount, handleDownGradeApi, proceedToProRated} from '../core/_requests'
import {toast} from 'react-toastify'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {ButtonTypes} from '../../widgets/core/_constants'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'

export const BillingSummaryModal = ({
  currentPeriodEnd,
  amount,
  currency,
  taxRate,
  active,
  isPlanUpgraded,
  TotalProrationAmount, // setIsUpgraded,
  paymentModalLoading,
}: {
  currentPeriodStart: number
  currentPeriodEnd: number
  amount: number
  currency: string
  taxRate: number
  active?: any
  isPlanUpgraded?: boolean
  TotalProrationAmount?: any
  paymentModalLoading?: boolean
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [couponloading, setCouponLoading] = useState<boolean>(false)
  const [SubTotal, setSubTotal] = useState<any>(0)
  const [tax, setTax] = useState<any>(0)
  const [couponDiscount, setCouponDiscount] = useState<number | null>(null)
  const [initialSubTotal, setInitialSubTotal] = useState<any>(0)
  const [initialTax, setInitialTax] = useState<any>(0)
  const [successText, setSuccessText] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const {
    setPaymentModal,
    setShowBillingSummary,
    showBillingSummary,
    billingData,
    selected,
    priceId,
    setBillingClick,
    proRatedData,
    percentoff,
    setPercentOff,
    invalidCoupon,
    setInvalidCoupon,
    couponCode,
    setCouponCode,
    setScheduleDowngradeData,
    setProRatedData,
    setActive,
    setSelected,
    setIsPlanDowngrade,
    setPriceId,
    taxBeforeCoupon,
    total,
    proRatedLoading,
    setProRatedLoading,
  } = useAuth()
  const {formatMessage} = useIntl()

  const handleDowngrade = async (
    selected: string,
    priceId: string,
    currencyId: string,
    downgrade: boolean
  ) => {
    setLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await handleDownGradeApi(
        selected,
        priceId,
        currencyId === 'inr' ? 'IN' : 'US',
        downgrade,
        couponCode
      )
      if (success) {
        toast.success(data)
        setIsPlanDowngrade(true)
      } else {
        toast.error(
          formatMessage({
            id: "The price specified supports currencies of `usd` which doesn't include the expected currency of `inr`.If any help needed,please contact the support",
          })
        )
      }
    } catch (err: any) {
      console.log(err)
    } finally {
      setLoading(false)
      setShowBillingSummary(false)
    }
  }

  const handleCouponDiscount = async () => {
    setCouponLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await applyCouponDiscount(couponCode)
      if (success) {
        setPercentOff(data?.percent_off)
        setSuccessText(formatMessage({id: 'Coupon code applied!'}))
        setDisabled(true)
        if (proRatedData) {
          proRatedPrice()
        }
      } else {
        setInvalidCoupon(formatMessage({id: 'Coupon code is invalid or expired!'}))
        setSuccessText('')
      }
    } catch (err) {
      console.log(err)
      setSuccessText('')
    } finally {
      setCouponLoading(false)
    }
  }

  async function proRatedPrice() {
    setProRatedLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await proceedToProRated(priceId, currency === 'inr' ? 'IN' : 'US', couponCode)
      if (success) {
        setProRatedData(data)
      }
    } catch (err: any) {
      console.log(err)
    } finally {
      setProRatedLoading(false)
    }
  }

  useEffect(() => {
    let coupon_discount = 0
    let subtotal_after_coupon_discount = 0
    let tax_discount: number = 0
    let tax_after_coupen_discount = 0

    if (percentoff) {
      coupon_discount = parseFloat(((percentoff / 100) * initialSubTotal).toFixed(2))

      subtotal_after_coupon_discount = parseFloat(
        (((100 - percentoff) / 100) * initialSubTotal).toFixed(2)
      )

      tax_discount = parseFloat(((percentoff / 100) * initialTax).toFixed(2))

      tax_after_coupen_discount = parseFloat((((100 - percentoff) / 100) * initialTax).toFixed(2))
      console.log(coupon_discount, tax_discount)

      setTax(tax_after_coupen_discount)
      if (currency === 'inr') {
        setCouponDiscount(coupon_discount + tax_discount)
      } else {
        setCouponDiscount(coupon_discount)
      }
      setSubTotal(subtotal_after_coupon_discount.toFixed(2))
    }
    setPercentOff(null)
    setInvalidCoupon('')
  }, [percentoff])

  useEffect(() => {
    if (proRatedData && isPlanUpgraded) {
      setSubTotal((proRatedData?.prorationSubTotal / 100).toFixed(2))
      setTax(parseFloat((taxRate / 100).toFixed(2)))
      setInitialSubTotal(parseFloat((proRatedData?.prorationSubTotal / 100).toFixed(2)))
      setInitialTax(parseFloat((taxRate / 100).toFixed(2)))
    } else {
      setSubTotal((amount / 100).toFixed(2))
      setTax(parseFloat(((taxRate / 100) * (amount / 100)).toFixed(2)))
      setInitialSubTotal(parseFloat((amount / 100).toFixed(2)))
      setInitialTax(parseFloat(((taxRate / 100) * (amount / 100)).toFixed(2)))
    }
  }, [proRatedData, amount, taxRate, isPlanUpgraded])

  useEffect(() => {
    setPercentOff(null)
    setCouponDiscount(null)
    setInvalidCoupon('')
    setSuccessText('')
    setDisabled(false)
    if (showBillingSummary) {
      if (proRatedData && isPlanUpgraded) {
        setSubTotal(proRatedData?.prorationSubTotal / 100)
        setTax((proRatedData.prorationTax / 100).toFixed(2))
        setCouponCode('')
      } else {
        setSubTotal((amount / 100).toFixed(2))
        setTax(parseFloat(((taxRate / 100) * (amount / 100)).toFixed(2)))
        setCouponCode('')
      }
    }
  }, [showBillingSummary])

  const handleRemoveDiscount = () => {
    if (proRatedData) {
      proRatedPrice()
    }
    setCouponDiscount(null)
    setCouponCode('')
    setSubTotal(initialSubTotal.toFixed(2))
    setTax(initialTax.toFixed(2))
    setSuccessText('')
    setDisabled(false)
  }

  return (
    <>
      <Modal
        show={showBillingSummary}
        onHide={() => {
          if (percentoff) {
            setPercentOff(null)
          }
          setActive(billingData?.planCycle)
          setSelected(billingData?.productName)
          setShowBillingSummary(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='px-3'
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <div className=' d-flex fw-bold'>
            <h2 className='font-size-16'>{formatMessage({id: 'Billing Summary'})}</h2>
          </div>
        </Modal.Header>
        <Modal.Body className='py-3'>
          {!(
            billingData?.status === Status.INCOMPLETE ||
            billingData?.status === Status.PASTDUE ||
            billingData?.status === Status.INCOMPLETE_EXPIRED
          ) && (
            <>
              {billingData?.status !== Status.CANCELED && billingData?.status !== Status.TRIAL && (
                <div className='info-card mb-3'>
                  {isPlanUpgraded ? (
                    <>
                      <div className='fw-bold font-size-20 p-2 text-clre88'>
                        {formatMessage({id: 'Upgrading'})}
                      </div>
                      <div className='p-2 font-size-14 info-card-description text-black-color'>
                        {formatMessage({
                          id: 'You are upgrading the plan. You will be charged immediately the amount based on the pro-rated charges. The plan will start immediately.',
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='fw-bold font-size-20 p-2 text-clre88'>
                        {formatMessage({id: 'Downgrading'})}
                      </div>
                      <div className='px-2 font-size-14 info-card-description text-black'>
                        {formatMessage({
                          id: `The existing subscription  ${billingData?.productName} - ${
                            billingData?.planCycle + 'ly'
                          } will remain active till ${new Date(
                            currentPeriodEnd * 1000
                          ).toDateString()} and the account will be switched to ${selected} - ${
                            active + 'ly'
                          } and charged afterwards`,
                        })}
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}

          <div className='mb-5'>
            <span className='font-weight-600 font-size-16'>
              {formatMessage({id: 'Your Selected Plan:'})}{' '}
            </span>{' '}
            <span className='text-clre88 font-size-16'>{selected}</span>
          </div>
          <div className='d-flex justify-content-between mb-5 font-weight-500'>
            <span className='font-size-16'>
              {billingData?.status === Status.CANCELED
                ? formatMessage({id: 'Current Billing Date'})
                : formatMessage({id: 'Next Billing Date'})}
            </span>

            <span className='font-size-16'>
              {billingData?.status === Status.CANCELED
                ? new Date().toDateString()
                : new Date(currentPeriodEnd * 1000).toDateString()}
            </span>
          </div>
          <div className='d-flex justify-content-between mb-5 font-weight-500'>
            <span className='font-size-16 '> {formatMessage({id: 'Billing Cycle'})} </span>{' '}
            <span className='font-size-16'>
              {(active + 'ly').charAt(0).toUpperCase() + (active + 'ly').slice(1)}
            </span>
          </div>
          <div className='d-flex justify-content-between mb-5 font-weight-500'>
            <span className='font-size-16'>{formatMessage({id: 'Plan Amount'})}</span>{' '}
            <span className='font-size-16'>
              {proRatedLoading ? (
                <div className='w-100 d-flex justify-content-center align-items-center'>
                  <div
                    className={`spinner-border d-center`}
                    style={{width: '1rem', height: '1rem'}}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {proRatedData ? (
                    <span className=''>
                      {currency === 'inr' ? '₹' : '$'}
                      {(proRatedData.planAmount / 100).toFixed(2)}
                    </span>
                  ) : (
                    <>
                      {currency === 'inr' ? '₹' : '$'}
                      {initialSubTotal}
                    </>
                  )}
                </>
              )}
            </span>
          </div>
          {proRatedData && (
            <div className='d-flex justify-content-between mb-5 font-weight-500'>
              <span className='font-size-16'>{formatMessage({id: 'Unused Amount'})}</span>{' '}
              <span className='font-size-16'>
                {currency === 'inr' ? '₹' : '$'}-{(proRatedData.unUsedAmount / 100).toFixed(2)}
              </span>
            </div>
          )}
          <div className='d-flex justify-content-between mb-5 font-weight-500'>
            <span className='font-size-16'>{formatMessage({id: 'SubTotal'})}</span>{' '}
            <span className='font-size-16'>
              {proRatedLoading ? (
                <div className='w-100 d-flex justify-content-center align-items-center'>
                  <div
                    className={`spinner-border d-center`}
                    style={{width: '1rem', height: '1rem'}}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {couponDiscount ? (
                    <span className=''>
                      <span className='strike-through me-3'>
                        {currency === 'inr' ? '₹' : '$'}
                        {proRatedData
                          ? (proRatedData.prorationSubTotal / 100).toFixed(2)
                          : initialSubTotal}
                      </span>
                      {currency === 'inr' ? '₹' : '$'}
                      {proRatedData
                        ? (proRatedData.prorationSubtotalAfterDiscount / 100).toFixed(2)
                        : SubTotal}
                    </span>
                  ) : (
                    <>
                      {currency === 'inr' ? '₹' : '$'}
                      {SubTotal}
                    </>
                  )}
                </>
              )}
            </span>
          </div>

          {currency === 'inr' && (
            <div className='d-flex justify-content-between mb-5 font-weight-500'>
              <span className=' font-size-16 '> {formatMessage({id: 'GST 18%'})}</span>{' '}
              <span className='font-size-16'>
                {proRatedLoading ? (
                  <div className='w-100 d-flex justify-content-center align-items-center'>
                    <div
                      className={`spinner-border d-center`}
                      style={{width: '1rem', height: '1rem'}}
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  </div>
                ) : (
                  <>
                    {couponDiscount ? (
                      <>
                        <span className='strike-through me-3'>
                          {currency === 'inr' ? '₹' : '$'}
                          {proRatedData ? taxBeforeCoupon : initialTax}
                        </span>
                        {currency === 'inr' ? '₹' : '$'}
                        {tax}
                      </>
                    ) : (
                      <>
                        {currency === 'inr' ? '₹' : '$'}
                        {tax}
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
          )}

          <div className='d-flex justify-content-between font-weight-500'>
            <span className='font-size-16 fw-400'>
              {formatMessage({id: 'Coupon Code'})}
              <ToolTipUI
                tooltipText={formatMessage({
                  id: 'Unlock exclusive savings with a coupon code, contact us for personalized discounts!',
                })}
              />
            </span>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-column'>
                <input
                  className='form-check-input-coupon login-input'
                  value={couponCode}
                  disabled={disabled}
                  onChange={(e) => {
                    setCouponCode(e.target.value.toUpperCase())
                  }}
                />
                <span className='fw-bold font-size-12 mt-2 text-red'>
                  {invalidCoupon ? invalidCoupon : ''}
                </span>
                <span
                  className={`fw-bold font-size-12 ${couponDiscount ? 'text-green' : 'text-red'}`}
                >
                  {successText ? successText : ''}
                </span>
              </div>

              <GlobalButton
                buttonText={
                  couponDiscount ? formatMessage({id: 'Remove'}) : formatMessage({id: 'Apply'})
                }
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => {
                  if (couponDiscount) {
                    handleRemoveDiscount()
                  } else {
                    handleCouponDiscount()
                  }
                }}
                isLoading={couponloading}
                isDisable={!couponCode}
              />
            </div>
          </div>
          <hr />
          <div className='d-flex justify-content-between font-weight-500'>
            <span className=' font-size-16 fw-bold'> {formatMessage({id: 'Total'})}</span>{' '}
            <span className='font-size-16'>
              {proRatedLoading ? (
                <div className='w-100 d-flex justify-content-center align-items-center'>
                  <div
                    className={`spinner-border d-center`}
                    style={{width: '1rem', height: '1rem'}}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {couponDiscount ? (
                    <>
                      <span className='strike-through me-3'>
                        {currency === 'inr' ? '₹' : '$'}
                        {currency === 'inr' ? (
                          <>
                            {proRatedData
                              ? total
                              : (parseFloat(initialSubTotal) + parseFloat(initialTax)).toFixed(2)}
                          </>
                        ) : (
                          <>{proRatedData ? total : parseFloat(initialSubTotal).toFixed(2)}</>
                        )}
                      </span>

                      {currency === 'inr' ? (
                        <>
                          {proRatedData
                            ? (proRatedData.prorationTotal / 100).toFixed(2)
                            : (parseFloat(SubTotal) + parseFloat(tax)).toFixed(2)}
                        </>
                      ) : (
                        <>
                          {proRatedData
                            ? (proRatedData.prorationTotal / 100).toFixed(2)
                            : parseFloat(SubTotal).toFixed(2)}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {currency === 'inr' ? '₹' : '$'}
                      {currency === 'inr' ? (
                        <>
                          {proRatedData
                            ? total
                            : (parseFloat(SubTotal) + parseFloat(tax)).toFixed(2)}
                        </>
                      ) : (
                        <>{proRatedData ? total : parseFloat(SubTotal).toFixed(2)}</>
                      )}
                    </>
                  )}
                </>
              )}
            </span>
          </div>
          {billingData?.status === Status.TRIAL && (
            <div className='d-flex justify-content-between font-size-16 fw-bold'>
              {formatMessage({id: 'Warning: Trial will end if you proceed to payment'})}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div>
            <GlobalButton
              buttonText={
                isPlanUpgraded ||
                billingData?.status === Status.CANCELED ||
                billingData?.status === Status.TRIAL ||
                billingData?.status === Status.PASTDUE ||
                billingData?.status === Status.INCOMPLETE ||
                billingData?.status === Status.INCOMPLETE_EXPIRED
                  ? formatMessage({id: 'Proceed to Payment'})
                  : formatMessage({id: 'Change Subscription'})
              }
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                if (
                  !(
                    isPlanUpgraded ||
                    billingData?.status === Status.CANCELED ||
                    billingData?.status === Status.TRIAL ||
                    billingData?.status === Status.INCOMPLETE ||
                    billingData?.status === Status.PASTDUE ||
                    billingData?.status === Status.INCOMPLETE_EXPIRED
                  )
                ) {
                  handleDowngrade(selected, priceId, billingData?.currency, true)
                } else {
                  if (
                    isPlanUpgraded ||
                    billingData?.status === Status.INCOMPLETE ||
                    billingData?.status === Status.PASTDUE
                  ) {
                    setPriceId(priceId)
                    setBillingClick('card')
                  }
                  setPriceId(priceId)
                  setShowBillingSummary(false)
                  setBillingClick('')
                  setPaymentModal(true)
                }
              }}
              isLoading={paymentModalLoading || loading}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
