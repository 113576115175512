import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './modules/auth'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {getLocaleData} from '../_metronic/partials/layout/core/_requests'
import axios from 'axios'
import useAxiosResponse from './modules/widgets/components/General/useAxiosResponse'
import {BillingAccessModal} from './modules/profile/views/BillingAccessModal'
import {BillingModal} from './modules/onboarding/components/BillingModal'
import useGetBillingData from './hooks/useGetBillingData'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!)

const App = () => {
  const {
    showBillingAccessModal,
    setShowBillingAccessModal,
    billingPortalUrl,
    userToken,
    setShowBillingModal,
    showBillingModal,
    setUserToken,
    teamId,
    storeTeamId,
  } = useAuth()
  const [status, setStatus] = useState(true)
  const {getBillingDetailsAPI, onAccessBillingPortal} = useGetBillingData()

  const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'
  const Locale = async () => {
    try {
      const {
        data: {data, success},
      } = await getLocaleData()
      if (success) {
        data.forEach((e: any) => {
          if (e.key === 'locale') {
            if (e.value === 'fr_FR') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'fr'}))
              }
            } else if (e.value === 'de_GN') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'de'}))
              }
            } else if (e.value === 'en_US') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'en'}))
              }
            } else if (e.value === 'es_ES') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'es'}))
              }
            } else if (e.value === 'zh_CN') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'zh'}))
              }
            } else if (e.value === 'hi_HI') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'hi'}))
              }
            } else if (e.value === 'bn_BN') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'bn'}))
              }
            } else if (e.value === 'it_IT') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'it'}))
              }
            } else if (e.value === 'ko_KO') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'ko'}))
              }
            } else if (e.value === 'pt_PT') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'pt'}))
              }
            } else if (e.value === 'ru_RU') {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'ru'}))
              }
            } else {
              if (status) {
                setStatus(false)
                localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: 'ja'}))
              }
            }
          } else if (e.key === 'dateFormat') {
            localStorage.setItem('dateFormat', e.value)
          } else if (e.key === 'timeFormat') {
            localStorage.setItem('timeFormat', e.value)
          } else if (e.key === 'timezone') {
            localStorage.setItem('timeZone', e.value)
          } else if (e.key === 'iana_title') {
            localStorage.setItem('iana_title', e.value)
          }
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  useAxiosResponse(axios)

  useEffect(() => {
    if (userToken) {
      Locale()
    }
  }, [userToken]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateToken = async () => {
      if (localStorage.getItem('kt-auth-react-v')) {
        const token = JSON.parse(localStorage.getItem('kt-auth-react-v')!)
        setUserToken(token)
      }
    }
    updateToken()
  }, [])

  useEffect(() => {
    if (!teamId) {
      storeTeamId(JSON.parse(localStorage.getItem('teamId')!))
    }
  }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Elements stripe={stripePromise}>
                <Outlet />
                <MasterInit />

                <BillingModal
                  setModalShow={setShowBillingModal}
                  modalShow={showBillingModal}
                  upgrade={true}
                  getBillingDetails={getBillingDetailsAPI}
                  onAccessBillingPortal={onAccessBillingPortal}
                />
                <BillingAccessModal
                  showBillingAccessModal={showBillingAccessModal}
                  setShowBillingAccessModal={setShowBillingAccessModal}
                  billingPortalUrl={billingPortalUrl}
                />
              </Elements>
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </GoogleOAuthProvider>
    </Suspense>
  )
}

export {App}
