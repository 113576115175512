import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {VariablesMoreOption} from './VariablesMoreOption'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import OutsideClickHandler from 'react-outside-click-handler'
import Accordion from 'react-bootstrap/Accordion'
import {CharacterLimits} from '../core/_contants'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'
export const CustomiseInputBoxesModal = ({
  openInputBoxModal,
  setOpenInputBoxModal,
  saveMessageLoading,
  primaryMsg,
  setPrimaryMsg,
  secondaryMsg,
  setSecondaryMsg,
  handleUpdateWorkflowStepValue,
  msgErr,
  setMsgErr,
  secondaryMsgErr,
  setSecondaryMsgErr,
  checkVariable,
  workflowActionType,
}: {
  openInputBoxModal: boolean
  setOpenInputBoxModal: Dispatch<SetStateAction<boolean>>
  saveMessageLoading: boolean
  primaryMsg: string
  setPrimaryMsg: Dispatch<SetStateAction<string>>
  secondaryMsg: string
  setSecondaryMsg: Dispatch<SetStateAction<string>>
  handleUpdateWorkflowStepValue: () => void
  msgErr: boolean
  setMsgErr: Dispatch<SetStateAction<boolean>>
  secondaryMsgErr: boolean
  setSecondaryMsgErr: Dispatch<SetStateAction<boolean>>
  checkVariable: (text: string) => boolean
  workflowActionType: string
}) => {
  const {formatMessage} = useIntl()
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const textAreaRefSecondaryMsg = useRef<HTMLTextAreaElement | null>(null)
  // useStates
  const [lastfocussedId, setLastfocussedId] = useState<string>('')
  const [msgTitle, setMsgTitle] = useState<string>('')
  const [alternateMsgTitle, setAlternateMsgTitle] = useState<string>('')
  const [inmailMsg, setInmailMsg] = useState<string>('')
  const [inmailAltMsg, setInmailAltMsg] = useState<string>('')
  const [primaryCharacterCount, setPrimaryCharacterCount] = useState<number>(0)
  const [secondaryCharacterCount, setSecondaryCharacterCount] = useState<number>(0)

  const handleInputFocus = (event: any) => {
    if (event.target.id) {
      setLastfocussedId(event.target.id)
    }
  }

  const appendVariableToPrimaryMsg = (variable: string) => {
    const textarea = textAreaRef.current
    const startPos = textarea!.selectionStart
    const endPos = textarea!.selectionEnd
    const currentText = textarea!.value

    const messageStart = currentText.substring(0, startPos)
    const messageEnd = currentText.substring(endPos, currentText.length)

    const updatedMsg = `${messageStart}${variable}${messageEnd}`

    if (workflowActionType === 'INMAIL') {
      setInmailMsg(updatedMsg)
    } else {
      setPrimaryMsg(updatedMsg)
    }
    setPrimaryCharacterCount(updatedMsg.length)
    setTimeout(() => {
      const newSelectionStart = startPos + variable.length
      const newSelectionEnd = startPos + variable.length
      textarea!.selectionStart = newSelectionStart
      textarea!.selectionEnd = newSelectionEnd
      textarea!.focus()
    }, 0)
  }

  const appendVariableToSecondaryMsg = (variable: string) => {
    const textareaSecondary = textAreaRefSecondaryMsg.current
    const startPos = textareaSecondary!.selectionStart
    const endPos = textareaSecondary!.selectionEnd
    const currentText = textareaSecondary!.value

    const messageStart = currentText.substring(0, startPos)
    const messageEnd = currentText.substring(endPos, currentText.length)

    const updatedMsg = `${messageStart}${variable}${messageEnd}`

    if (workflowActionType === 'INMAIL') {
      setInmailAltMsg(updatedMsg)
    } else {
      setSecondaryMsg(updatedMsg)
    }
    setSecondaryCharacterCount(updatedMsg.length)
    setTimeout(() => {
      const newSelectionStart = startPos + variable.length
      const newSelectionEnd = startPos + variable.length
      textareaSecondary!.selectionStart = newSelectionStart
      textareaSecondary!.selectionEnd = newSelectionEnd
      textareaSecondary!.focus()
    }, 0)
  }
  // useStates
  const [isOpenVariables, setIsOpenVariables] = useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (workflowActionType === 'INMAIL') {
      const priorInmailMsgData = JSON.parse(primaryMsg)
      if (priorInmailMsgData) {
        setMsgTitle(priorInmailMsgData?.title)
        setInmailMsg(priorInmailMsgData?.message)
      } else {
        setMsgTitle('')
        setInmailMsg('')
      }
      if (priorInmailMsgData?.message?.length) {
        setPrimaryCharacterCount(priorInmailMsgData?.message?.length)
      } else {
        setPrimaryCharacterCount(0)
      }
      let priorInmailAltMsgData = JSON.parse(secondaryMsg)
      if (priorInmailAltMsgData) {
        setAlternateMsgTitle(priorInmailAltMsgData?.title)
        setInmailAltMsg(priorInmailAltMsgData?.message)
      } else {
        setAlternateMsgTitle('')
        setInmailAltMsg('')
      }
      if (priorInmailAltMsgData?.message?.length) {
        setSecondaryCharacterCount(priorInmailAltMsgData?.message?.length)
      } else {
        setSecondaryCharacterCount(0)
      }
    } else {
      if (primaryMsg?.length) {
        setPrimaryCharacterCount(primaryMsg.length)
      } else {
        setPrimaryCharacterCount(0)
      }

      if (secondaryMsg?.length) {
        setSecondaryCharacterCount(secondaryMsg.length)
      } else {
        setSecondaryCharacterCount(0)
      }
    }
  }, [workflowActionType, openInputBoxModal])

  useEffect(() => {
    if (workflowActionType === 'INMAIL') {
      const dataPrimary = {
        title: msgTitle,
        message: inmailMsg,
      }
      const jsonPrimaryData = JSON.stringify(dataPrimary)
      setPrimaryMsg(jsonPrimaryData)
      const dataSecondary = {
        title: alternateMsgTitle,
        message: inmailAltMsg,
      }
      const jsonSecondaryData = JSON.stringify(dataSecondary)
      setSecondaryMsg(jsonSecondaryData)
    }
  }, [workflowActionType, msgTitle, alternateMsgTitle, inmailMsg, inmailAltMsg])
  const getCharacterLimit =
    workflowActionType === 'INMAIL'
      ? CharacterLimits.INMAIL_LIMIT
      : workflowActionType === 'MESSAGE'
      ? CharacterLimits.MESSAGE_LIMIT
      : CharacterLimits.SEND_INVITE_LIMIT
  return (
    <>
      <div ref={ref}>
        <Modal
          show={openInputBoxModal}
          onHide={() => {
            setOpenInputBoxModal(false)
            setMsgErr(false)
            setSecondaryMsgErr(false)
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='customize-input-modal'
          container={ref.current}
        >
          <Modal.Header closeButton className='border-bottom-0 py-4 mx-2'>
            <div className='font-size-16 fw-bold text-color'>
              {workflowActionType === 'MESSAGE'
                ? formatMessage({id: 'Input your Linkedin message'})
                : workflowActionType === 'SEND_AN_INVITE'
                ? formatMessage({id: 'Add a personalized note to your request'})
                : workflowActionType === 'INMAIL'
                ? formatMessage({id: 'Input your Inmail Message'})
                : formatMessage({id: 'Input your Message'})}
            </div>
          </Modal.Header>
          <Modal.Body className='py-0'>
            <div>
              <div className='d-flex justify-content-between'>
                <button
                  className='variable-buttons'
                  onClick={() => {
                    if (primaryCharacterCount + ' %%first_name%% '.length <= getCharacterLimit) {
                      appendVariableToPrimaryMsg(' %%first_name%% ')
                    }
                  }}
                >
                  {formatMessage({id: 'First name'})}
                </button>
                <button
                  className='variable-buttons'
                  onClick={() => {
                    if (primaryCharacterCount + ' %%last_name%% '.length <= getCharacterLimit) {
                      appendVariableToPrimaryMsg(' %%last_name%% ')
                    }
                  }}
                >
                  {formatMessage({id: 'Last name'})}
                </button>
                <button
                  className='variable-buttons'
                  onClick={() => {
                    if (primaryCharacterCount + ' %%position%% '.length <= getCharacterLimit) {
                      appendVariableToPrimaryMsg(' %%position%% ')
                    }
                  }}
                >
                  {formatMessage({id: 'Position'})}
                </button>
              </div>
              <div className='d-flex justify-content-between my-3'>
                <button
                  className='variable-buttons'
                  onClick={() => {
                    if (primaryCharacterCount + ' %%company%% '.length <= getCharacterLimit) {
                      appendVariableToPrimaryMsg(' %%company%% ')
                    }
                  }}
                >
                  {formatMessage({id: 'Company'})}
                </button>
                <button
                  className='variable-buttons'
                  onClick={() => {
                    if (primaryCharacterCount + ' %%city%% '.length <= getCharacterLimit) {
                      appendVariableToPrimaryMsg(' %%city%% ')
                    }
                  }}
                >
                  {formatMessage({id: 'City'})}
                </button>
                <OutsideClickHandler onOutsideClick={() => setIsOpenVariables(false)}>
                  <button
                    className='variable-buttons'
                    onClick={() => {
                      if (primaryCharacterCount <= getCharacterLimit) {
                        setIsOpenVariables(!isOpenVariables)
                      }
                    }}
                    disabled={lastfocussedId === 'alternateMsg'}
                  >
                    {formatMessage({id: 'More'})}
                    <img
                      height='26px'
                      width='26px'
                      src={toAbsoluteUrl('/media/icons/duotune/general/downArrow.svg')}
                    />
                  </button>
                  <VariablesMoreOption
                    isOpenVariables={isOpenVariables}
                    appendVariableToPrimaryMsg={appendVariableToPrimaryMsg}
                    primaryCharacterCount={primaryCharacterCount}
                    getCharacterLimit={getCharacterLimit}
                  />
                </OutsideClickHandler>
              </div>
            </div>
            <div>
              {workflowActionType === 'INMAIL' && (
                <div>
                  <label htmlFor='uploadLink' className='form-label fw-normal'>
                    {formatMessage({id: 'Title'})}
                  </label>
                  <ToolTipUI tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.TITLE_INMAIL'})} />
                  <input
                    className='form-control font-size-14 font-weight-400 mb-3'
                    id='title'
                    name='title'
                    placeholder={formatMessage({id: 'Title for message'})}
                    onChange={(e) => setMsgTitle(e.target.value)}
                    value={msgTitle}
                  />
                </div>
              )}
              <label htmlFor='uploadLink' className='form-label fw-normal'>
                {formatMessage({id: 'Message'})}
              </label>
              <ToolTipUI tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.MESSAGE_NODE'})} />
              <textarea
                required
                className={`form-control font-size-14 font-weight-400 ${
                  msgErr ? 'border border-danger' : ''
                }`}
                id='message'
                name='message'
                maxLength={
                  workflowActionType === 'INMAIL'
                    ? CharacterLimits.INMAIL_LIMIT
                    : workflowActionType === 'MESSAGE'
                    ? CharacterLimits.MESSAGE_LIMIT
                    : CharacterLimits.SEND_INVITE_LIMIT
                }
                placeholder={formatMessage({id: 'Message'})}
                onChange={(e) => {
                  if (workflowActionType === 'INMAIL') {
                    setInmailMsg(e.target.value)
                    setPrimaryCharacterCount(e.target.value.length)
                    setMsgErr(false)
                  } else {
                    setPrimaryMsg(e.target.value)
                    setPrimaryCharacterCount(e.target.value.length)
                    setMsgErr(false)
                  }
                }}
                value={workflowActionType === 'INMAIL' ? inmailMsg : primaryMsg}
                ref={textAreaRef}
                onFocus={handleInputFocus}
                rows={4}
              ></textarea>
              <div id='the-count'>
                <span id='current'>{primaryCharacterCount}</span>
                <span id='maximum'>
                  {workflowActionType === 'INMAIL' && `/${CharacterLimits.INMAIL_LIMIT}`}
                  {workflowActionType === 'MESSAGE' && `/${CharacterLimits.MESSAGE_LIMIT}`}
                  {workflowActionType === 'SEND_AN_INVITE' &&
                    `/${CharacterLimits.SEND_INVITE_LIMIT}`}
                </span>
              </div>
              {msgErr && (
                <p className='text-danger'>{formatMessage({id: 'Please enter message.'})}</p>
              )}
            </div>
            <Accordion flush>
              <Accordion.Item eventKey='0'>
                <Accordion.Header
                  className={`${
                    secondaryMsg ? 'accordion-highlight' : ''
                  } input-accordion p-0 mt-4`}
                >
                  <div className='font-size-14 text-black fw-normal'>
                    {formatMessage({id: 'Alternate message (optional)'})}
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: 'Set an alternative message template that will be sent if at least one of variables used in the main message template is missing.',
                      })}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body className='p-0'>
                  <div>
                    <div className='d-flex justify-content-between my-3'>
                      <button
                        className='variable-buttons'
                        onClick={() => {
                          if (
                            secondaryCharacterCount + ' %%first_name%% '.length <=
                            getCharacterLimit
                          ) {
                            appendVariableToSecondaryMsg(' %%first_name%% ')
                          }
                        }}
                      >
                        {formatMessage({id: 'First name'})}
                      </button>
                      <button
                        className='variable-buttons'
                        onClick={() => {
                          if (
                            secondaryCharacterCount + ' %%last_name%% '.length <=
                            getCharacterLimit
                          ) {
                            appendVariableToSecondaryMsg(' %%last_name%% ')
                          }
                        }}
                      >
                        {formatMessage({id: 'Last name'})}
                      </button>
                      <button
                        className='variable-buttons'
                        onClick={() => {
                          if (
                            secondaryCharacterCount + ' %%country%% '.length <=
                            getCharacterLimit
                          ) {
                            appendVariableToSecondaryMsg(' %%country%% ')
                          }
                        }}
                      >
                        {formatMessage({id: 'Country'})}
                      </button>
                    </div>
                    {workflowActionType === 'INMAIL' && (
                      <div>
                        <label htmlFor='uploadLink' className='form-label fw-normal'>
                          {formatMessage({id: 'Title'})}
                        </label>
                        <ToolTipUI
                          tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.TITLE_INMAIL_ALTERNATE'})}
                        />
                        <input
                          className='form-control font-size-14 font-weight-400 mb-3'
                          id='title-alternate'
                          name='title-alternate'
                          placeholder={formatMessage({id: 'Title for alternate message'})}
                          onChange={(e) => setAlternateMsgTitle(e.target.value)}
                          value={alternateMsgTitle}
                        />
                      </div>
                    )}
                    <textarea
                      required
                      className='form-control font-size-14 font-weight-400 fw-bold'
                      id='alternateMsg'
                      maxLength={
                        workflowActionType === 'INMAIL'
                          ? CharacterLimits.INMAIL_LIMIT
                          : workflowActionType === 'MESSAGE'
                          ? CharacterLimits.MESSAGE_LIMIT
                          : CharacterLimits.SEND_INVITE_LIMIT
                      }
                      name='alternateMsg'
                      rows={4}
                      placeholder={formatMessage({id: 'Alternative Message'})}
                      onChange={(e) => {
                        if (workflowActionType === 'INMAIL') {
                          setInmailAltMsg(e.target.value)
                          setSecondaryCharacterCount(e.target.value.length)
                        } else {
                          setSecondaryMsg(e.target.value)
                          setSecondaryCharacterCount(e.target.value.length)
                        }
                      }}
                      value={workflowActionType === 'INMAIL' ? inmailAltMsg : secondaryMsg}
                      ref={textAreaRefSecondaryMsg}
                      onFocus={handleInputFocus}
                    ></textarea>
                    <div id='the-count'>
                      <span id='current'>{secondaryCharacterCount}</span>
                      <span id='maximum'>
                        {workflowActionType === 'INMAIL' && `/${CharacterLimits.INMAIL_LIMIT}`}
                        {workflowActionType === 'MESSAGE' && `/${CharacterLimits.MESSAGE_LIMIT}`}
                        {workflowActionType === 'SEND_AN_INVITE' &&
                          `/${CharacterLimits.SEND_INVITE_LIMIT}`}
                      </span>
                    </div>
                    {secondaryMsgErr && (
                      <p className='text-danger'>
                        {formatMessage({
                          id: 'Please use only these variables First Name,Last Name,Country .',
                        })}
                      </p>
                    )}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Modal.Body>
          <Modal.Footer className='border-top-0 py-4'>
            <div className='w-100 d-flex justify-content-md-end justify-content-around gap-3'>
              <GlobalButton
                buttonText={formatMessage({id: 'Cancel'})}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={() => {
                  setOpenInputBoxModal(false)
                  setMsgErr(false)
                  setSecondaryMsgErr(false)
                }}
              />

              <GlobalButton
                buttonText={formatMessage({id: 'Save'})}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => {
                  handleUpdateWorkflowStepValue()
                }}
                isDisable={msgErr || secondaryMsgErr || saveMessageLoading}
                isLoading={saveMessageLoading}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
