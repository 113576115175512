import {useIntl} from 'react-intl'
import {timezone, workingHour} from '../../core/_models'
import {TimePreferences} from '../createCampaignSteps/TimePreferences'
import {hoursOptions} from '../../core/_constants'
import {useEffect, useState} from 'react'
import {updateWorkingHours} from '../../core/_requests'
import {toast} from 'react-toastify'

export const UpdateTimePreferences = ({
  campaignId,
  workingHour,
  getCampaignData,
}: {
  campaignId: number | undefined
  workingHour: workingHour | undefined
  getCampaignData: () => void
}) => {
  const {formatMessage} = useIntl()
  const [defaultSelectedStartTime, setDefaultSelectedStartTime] = useState<string>(
    hoursOptions[9].id
  )
  const [defaultSelectedEndTime, setDefaultSelectedEndTime] = useState<string>(hoursOptions[18].id)
  const [isWeekendsInclude, setIsWeekendsInclude] = useState<boolean>(false)
  const [timezoneId, setTimezonId] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (workingHour) {
      setTimezonId(workingHour?.timezone?.timezoneId)
      setDefaultSelectedStartTime(convertIntToTimeString(+workingHour?.startTime!))
      setDefaultSelectedEndTime(convertIntToTimeString(+workingHour?.endTime!))
      setIsWeekendsInclude(workingHour?.includeWeekEnds)
    }
  }, [workingHour])

  const convertIntToTimeString = (time: number) => {
    const option = hoursOptions.find((option) => option.id === time.toString())
    if (option) {
      return option.id
    }
    return ''
  }

  return (
    <>
      <div className='ps-md-8 p-8 locale-container mt-0'>
        <div className='font-size-20 fw-bold pb-7'>{formatMessage({id: 'Working hours'})}</div>
        <TimePreferences
          timezoneId={timezoneId}
          setTimezonId={setTimezonId}
          defaultSelectedStartTime={defaultSelectedStartTime}
          setDefaultSelectedStartTime={setDefaultSelectedStartTime}
          defaultSelectedEndTime={defaultSelectedEndTime}
          setDefaultSelectedEndTime={setDefaultSelectedEndTime}
          isWeekendsInclude={isWeekendsInclude}
          setIsWeekendsInclude={setIsWeekendsInclude}
          isCampaignDetails={true}
          workingHourId={workingHour?.workingHourId}
          getCampaignData={getCampaignData}
          enableSaveBtn={false}
        />
      </div>
    </>
  )
}
