import {useEffect} from 'react'
import {useAuth} from '../../../auth'

const useAxiosResponse = (axios: any) => {
  const {setShowBillingModal, logout} = useAuth()

  const pathNames = [
    '/campaigns',
    '/workflow',
    '/leads',
    '/inbox',
    '/activity-log',
    '/activity-control',
    '/settings',
  ]

  useEffect(() => {
    axios.interceptors.response.use(
      (response: any) => {
        if (!!response.data.errors?.length) {
          let showModal = false
          const billingErrors = [
            'Your current plan exceeded, kindly upgrade your billing plan to invite new team member',
            'Your current plan does not have access to this, kindly upgrade your billing plan',
            'Your current plan exceeded, kindly upgrade your billing plan to add new contact to CRM',
            'Your current plan exceeded, kindly upgrade your billing plan to create investor update',
            'Your current plan exceeded, kindly upgrade your billing plan to send more mail',
          ]

          for (let i = 0; i < response.data.errors.length; i++) {
            if (billingErrors.includes(response.data.errors[i])) {
              showModal = true
              break
            }
          }
          if (showModal) {
            setShowBillingModal(true)
          }
          if (
            response.data.errors.includes('jwt expired') ||
            response.data.errors.includes('jwt malformed') ||
            (response.data.errors.includes('Unauthorized') &&
              pathNames.includes(window.location.pathname))
          ) {
            window.location.href = '/'
            logout()
          }
        }
        return response
      },
      (err: any) => Promise.reject(err.message)
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}
export default useAxiosResponse
