import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {Dropdown, Image} from 'react-bootstrap'
import '../styles/leads.scss'
import {filterList} from '../core/_constants'
import {LeadListObject} from '../core/model/lead'
import {campaignObject, userObject} from '../../campaign/core/_models'
import {Roles} from '../../profile/core/_constants'
import {useAuth} from '../../../../app/modules/auth'
import {getUserList} from '../../profile/core/_requests'
import {useLocation, useNavigate} from 'react-router-dom'
import queryString from 'query-string'
import {} from 'react-router-dom'

const LeadFilter = ({
  searchLead,
  setSearchLead,
  campaignId,
  setCampaignId,
  campaignsData,
  leadList,
  leadListId,
  setLeadListId,
  usersData,
  userId,
  setUserId,
  statusFilter,
  setStatusFilter,
  params_campaignId,
  setParams_campaignId,
  params_status,
  setParams_status,
}: {
  searchLead: string
  setSearchLead: React.Dispatch<React.SetStateAction<string>>
  campaignId: number
  setCampaignId: React.Dispatch<React.SetStateAction<number>>
  campaignsData: campaignObject[]
  leadList: LeadListObject[]
  leadListId: number
  setLeadListId: React.Dispatch<React.SetStateAction<number>>
  usersData: userObject[]
  userId: number
  setUserId: React.Dispatch<React.SetStateAction<number>>
  statusFilter: string | undefined
  setStatusFilter: React.Dispatch<React.SetStateAction<string | undefined>>
  params_campaignId: number
  setParams_campaignId: React.Dispatch<React.SetStateAction<number>>
  params_status: string | undefined
  setParams_status: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const campaign_id = Array.isArray(queryParams.campaignId)
    ? parseInt(queryParams.campaignId[0] || '0')
    : parseInt(queryParams.campaignId || '0')

  const [leadFilter, setLeadFilter] = useState(false)
  const {formatMessage} = useIntl()
  const {currentUser, teamLength} = useAuth()
  const [statusLock, setStatusLock] = useState(true)
  const navigate = useNavigate()
  const handleChangeCampaign = (value: number) => {
    setCampaignId(value)
    if (value === 0) {
      setStatusFilter('allLeads')
      setParams_status('allLeads')
      setStatusLock(true)
    } else {
      setStatusLock(false)
    }
  }
  const handleLeadListChange = (value: number) => {
    setLeadListId(value)

    if (value !== 0) {
      setStatusFilter('allLeads')
      setParams_status('allLeads')
      setStatusLock(true)
    } else if (campaignId !== 0) {
      setStatusLock(false)
    }
  }
  const handleChangeUser = (value: number) => {
    setUserId(value)
  }
  const handleFilter = () => {}
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  useEffect(() => {
    setStatusLock(campaign_id === 0 ? true : false)

    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (campaign_id) setStatusLock(false)
    else setStatusLock(true)
  }, [campaign_id])

  return (
    <>
      {windowSize.width > 450 ? (
        <>
          <div className='col-12 row d-flex  ms-0 '>
            <div className='col-lg-5 col-md-5 col-xl-5 col-12 d-flex ps-0'>
              <div>
                <form
                  data-kt-search-element='form'
                  className=' align-items-center d-flex position-relative'
                  autoComplete='off'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-3 position-absolute ms-3'
                  />
                  <input
                    type='text'
                    className='bg-body form-control form-control-flush ps-10 height-36px rounded font-weight-400 text-clrA1'
                    name='search'
                    placeholder={formatMessage({id: 'Search...'})}
                    data-kt-search-element='input'
                    onChange={(e) => setSearchLead(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') e.preventDefault()
                    }}
                  />
                </form>
              </div>
            </div>
            <div className='col-lg-7 col-md-7 col-xl-7 col-12  d-flex justify-content-end mb-4 pe-0 gap-3'>
              {/* <div className='height-36px border bg-white d-flex align-items-center rounded'>
            <Dropdown className='dropdown-container '>
              <Dropdown.Toggle
                className='text-clr88 btn btn-sm btn-flex fw-semibold font-size-12 bg-white'
                id='fc-toogle'
                title='Sort and Filter'
                data-bs-toggle='dropdown'
                data-bs-placement='left'
                data-bs-dismiss='click'
                data-bs-trigger='hover'
              >
                <KTSVG
                  path={
                    true
                      ? '/media/icons/duotune/general/gen031_selected.svg'
                      : '/media/icons/duotune/general/gen031.svg'
                  }
                  className='svg-icon-6 svg-icon-muted me-1'
                />
                {true ? formatMessage({id: 'Filter*'}) : formatMessage({id: 'Filter'})}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {filterList.map((item, index) => {
                  return (
                    <Dropdown.Item href='#/action-1' className='d-flex ' key={index}>
                      <div>
                        <KTSVG path={item.icon} className='svg-icon-3 mx-2' />
                      </div>
                      <div>
                        <p
                          className='text-muted fw-semibold text-muted d-block fs-7'
                          onClick={() => {
                            handleFilter
                          }}
                        >
                          {item.name}
                        </p>
                      </div>
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
              <div className='w-140px'>
                <select
                  className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                  onChange={(e) => {
                    setStatusFilter(e.target.value)
                    setParams_status(e.target.value)
                    const queryParams = queryString.parse(location.search)
                    queryParams.campaignId = campaignId.toString()
                    queryParams.status = e.target.value
                    const newQueryString = queryString.stringify(queryParams)
                    const newUrl = `${location.pathname}?${newQueryString}`
                    window.history.pushState({}, '', newUrl)
                  }}
                  value={statusFilter}
                  disabled={statusLock}
                >
                  <option value='allLeads'>All Leads</option>
                  <option value='awaiting'>Awaiting</option>
                  <option value='failed'>Failed</option>
                  <option value='paused'>Paused</option>
                  <option value='completed'>Completed</option>
                  <option value='inProgress'>In Progress</option>
                </select>
              </div>
              <div className='w-140px'>
                <select
                  className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                  onChange={(e) => {
                    handleLeadListChange(parseInt(e.target.value))

                    const queryParams = queryString.parse(location.search)

                    const newQueryString = queryString.stringify(queryParams)

                    const newUrl = `${location.pathname}`
                    window.history.pushState({}, '', newUrl)
                  }}
                  value={leadListId}
                >
                  {leadList &&
                    leadList?.map(({leadListId, name}: {leadListId: number; name: string}) => (
                      <option value={leadListId} key={leadListId}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>

              <div className='w-140px'>
                <select
                  className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                  onChange={(e) => {
                    handleChangeCampaign(parseInt(e.target.value))
                    setParams_campaignId(+e.target.value)
                    const queryParams = queryString.parse(location.search)
                    queryParams.campaignId = e.target.value
                    if (parseInt(e.target.value) === 0) {
                      delete queryParams.status
                    } else {
                      queryParams.status = params_status!
                    }
                    const newQueryString = queryString.stringify(queryParams)
                    const newUrl = `${location.pathname}?${newQueryString}`
                    window.history.pushState({}, '', newUrl)
                  }}
                  value={campaignId}
                >
                  {campaignsData &&
                    campaignsData?.map(({campaignId, name}: {campaignId: number; name: string}) => (
                      <option value={campaignId} key={campaignId}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
              {(currentUser?.role === Roles.OWNER || currentUser?.role === Roles.ADMIN) &&
                teamLength > 1 && (
                  <div className='w-25  '>
                    <select
                      className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                      onChange={(e) => handleChangeUser(parseInt(e.target.value))}
                      value={userId}
                    >
                      {usersData &&
                        usersData?.map(
                          ({
                            userId,
                            linkedin_name,
                            linkedin_url,
                          }: {
                            userId: number
                            linkedin_name: string
                            linkedin_url: string
                          }) =>
                            !!linkedin_url && (
                              <option value={userId} key={userId}>
                                {linkedin_name}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className='col-12 row d-flex m-2 ms-0 mt-2'
            style={{backgroundColor: 'white', borderRadius: '10px'}}
          >
            <div className='align-self-center col-12 d-flex mt-3 mb-3'>
              <div className='w-100'>
                <form
                  data-kt-search-element='form'
                  className=' align-items-center d-flex position-relative'
                  autoComplete='off'
                >
                  <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-3 position-absolute ms-3'
                  />
                  <input
                    type='text'
                    className='bg-body form-control form-control-flush ps-10 height-36px rounded font-weight-400 text-clrA1 border border-secondary'
                    name='search'
                    placeholder={formatMessage({id: 'Search...'})}
                    data-kt-search-element='input'
                    onChange={(e) => setSearchLead(e.target.value)}
                  />
                </form>
              </div>
            </div>
            <div className='col-12  d-flex justify-content-center flex-column mb-3'>
              <div className='w-100'>
                <select
                  className=' form-select mb-3 height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                  onChange={(e) => handleChangeCampaign(parseInt(e.target.value))}
                  value={campaignId}
                >
                  {campaignsData &&
                    campaignsData?.map(({campaignId, name}: {campaignId: number; name: string}) => (
                      <option value={campaignId} key={campaignId}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>

              <div className='w-100'>
                <select
                  className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                  onChange={(e) => handleLeadListChange(parseInt(e.target.value))}
                  value={leadListId}
                >
                  {leadList &&
                    leadList?.map(({leadListId, name}: {leadListId: number; name: string}) => (
                      <option value={leadListId} key={leadListId}>
                        {name}
                      </option>
                    ))}
                </select>
              </div>
              {(currentUser?.role === Roles.OWNER || currentUser?.role === Roles.ADMIN) &&
                teamLength > 1 && (
                  <div className='w-100'>
                    <select
                      className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer px-3 py-1'
                      onChange={(e) => handleChangeUser(parseInt(e.target.value))}
                      value={userId}
                    >
                      {usersData &&
                        usersData?.map(
                          ({
                            userId,
                            linkedin_name,
                            linkedin_url,
                          }: {
                            userId: number
                            linkedin_name: string
                            linkedin_url: string
                          }) =>
                            !!linkedin_url && (
                              <option value={userId} key={userId}>
                                {linkedin_name}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default LeadFilter
