import {useEffect, useState} from 'react'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {acceptInvite, register} from '../core/_requests'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import SocialLoginButtons from '../components/SocialLoginButtons'
import TextInput from '../../widgets/components/Input/TextInput'
import {useIntl} from 'react-intl'
import {CustomToast} from '../../widgets/components/UI/CustomToast'
import {useNavigate} from 'react-router-dom'
import {Toaster} from '../../widgets/components/General/Toaster'
import {toast} from 'react-toastify'
import {useUtmValidator} from '../../../hooks/useUtmValidator'
import {useFetchCountryAndTimeZone} from '../../../hooks/useFetchCountryAndTimeZone'
import {provisionResources} from '../../onboarding/core/_requests'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export function Registration() {
  const {utmValues, handleUtmEvents} = useUtmValidator()
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setUserToken} = useAuth()
  const [errorsArray, setErrorsArray] = useState<string[]>()
  const [isInvited, setIsInvited] = useState<boolean>(false)
  const {fetchCountryAndTimeZone} = useFetchCountryAndTimeZone()
  const navigate = useNavigate()

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    acceptTerms: false,
  }

  const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(3, formatMessage({id: 'Minimum 3 characters'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'First name is required'})),
    email: Yup.string()
      .email(formatMessage({id: 'Invalid email format'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Email is required'})),
    lastname: Yup.string()
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Last name is required'})),
    password: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 characters'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Password is required'})),
    acceptTerms: Yup.bool(),
  })

  const referral = new URL(window.location.href).searchParams.get('r') || null

  const lsReferral = localStorage.getItem('referral')

  if (!lsReferral?.length) {
    localStorage.setItem('referral', referral || '')
  }

  const localStorageReferral = localStorage.getItem('referral') || ''

  useEffect(() => {
    handleUtmEvents()
  }, [])

  const onSubmit = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: any
  ) => {
    setLoading(true)
    try {
      const locationData = await fetchCountryAndTimeZone()
      const {
        data: {data, success, errors},
      } = await register(
        values.email,
        values.firstname,
        values.lastname,
        values.password,
        utmValues,
        localStorageReferral,
        'en_US',
        'MMM DD, YYYY',
        'hh:mm:ss a',
        locationData?.timeZone || 5.5,
        locationData?.countryId! || 103
      )

      if (success) {
        setSubmitting(false)
        setLoading(false)
        resetForm()
        navigate('/email-verification', {state: {email: values.email}})
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
          setSubmitting(false)
          setLoading(false)
        })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
      setLoading(false)
    }
  }

  const {state} = useLocation() as any

  const handleAcceptInvite = async (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setLoading(true)

    try {
      const locationData = await fetchCountryAndTimeZone()
      const {
        data: {data: token, success, errors, data},
      } = await acceptInvite(
        state.inviteToken,
        values.password,
        'en_US',
        'MMM DD, YYYY',
        'hh:mm:ss a',
        locationData?.timeZone || 5.5,
        locationData?.countryId! || 103
      )
      if (success) {
        await saveAuth(token)
        setUserToken(data.token)
        provisionResources(), navigate('/', {state: {email: values.email}})
      } else {
        setErrorsArray(errors)
        await saveAuth(undefined)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setSubmitting(false)
      setLoading(false)
    }
  }

  return (
    <>
      {' '}
      <Toaster />
      <Formik
        initialValues={initialValues}
        validationSchema={registrationSchema}
        onSubmit={(values, {resetForm, setSubmitting}) => {
          isInvited
            ? handleAcceptInvite(values, setSubmitting)
            : onSubmit(values, setSubmitting, resetForm)
        }}
        validateOnMount
      >
        {(formik) => {
          if (state) {
            initialValues.firstname = state.firstName
            initialValues.lastname = state.lastName
            initialValues.email = state.email
            setIsInvited(true)
          }
          return (
            <Form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework px-3 pe-0 ps-0'
              id='kt_login_signup_form'
            >
              <div className='text-center mb-10'>
                <h1 className='text-black fw-bolder mb-9 font-size-24'>
                  {formatMessage({id: 'Sign Up to DripFunnel'})}
                </h1>
                <SocialLoginButtons localStorageReferral={localStorageReferral} />
              </div>

              <div className='text-center mb-24px fw-bold font-size-14 text-black'>
                {formatMessage({id: 'OR'})}
              </div>

              {errorsArray?.map((error: string) => <CustomToast status={error} />)}

              <div className='row d-flex justify-content-between'>
                <div className='col-6 ps1'>
                  <TextInput
                    fieldType={'text'}
                    label={formatMessage({id: 'First Name'})}
                    fieldName={'firstname'}
                    formik={formik}
                    placeholder={formatMessage({id: 'Enter first name'})}
                    toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.REGISTRATION.FIRST_NAME'})}
                    isStarRequired={true}
                  />
                </div>
                <div className='col-6'>
                  <TextInput
                    fieldType={'text'}
                    label={formatMessage({id: 'Last Name'})}
                    fieldName={'lastname'}
                    formik={formik}
                    placeholder={formatMessage({id: 'Enter last name'})}
                    toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.REGISTRATION.LAST_NAME'})}
                    isStarRequired={true}
                  />
                </div>
              </div>

              {/* begin::Form group */}
              <TextInput
                fieldType={'email'}
                fieldName={'email'}
                formik={formik}
                placeholder={formatMessage({id: 'Enter Email'})}
                label={formatMessage({id: 'Email'})}
                toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.REGISTRATION.EMAIL'})}
                isDisabled={isInvited}
                isStarRequired={true}
              />
              {/* end::Form group */}

              {/* begin::Form group */}
              <TextInput
                fieldType={'text'}
                fieldName={'password'}
                isPassword={true}
                formik={formik}
                placeholder={formatMessage({id: 'Password'})}
                label={formatMessage({id: 'Password'})}
                toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.REGISTRATION.PASSWORD'})}
                isStarRequired={true}
              />
              {/* end::Form group */}

              <TextInput
                fieldName={'acceptTerms'}
                formik={formik}
                linkText1={formatMessage({id: 'Terms of use'})}
                linkText2={formatMessage({id: 'Privacy Policy'})}
                slug1={'https://www.dripfunnel.com/terms-of-use/'}
                slug2={'https://www.dripfunnel.com/privacy-policy/'}
                customText={formatMessage({id: 'I Agree'})}
                placeholder=''
                fieldType={'checkbox'}
                isCheckbox={true}
                isStarRequired={true}
              />

              {/* begin::Form group */}
              {/* begin::Action */}
              <div className='d-flex align-items-center justify-content-between'>
                <div className='text-center fw-bold font-size-14 text-black'>
                  {formatMessage({id: 'Already have an account?'})}{' '}
                  <Link to='/login/' className='link-primary'>
                    {formatMessage({id: 'Sign In'})}
                  </Link>
                </div>
                <GlobalButton
                  buttonText={formatMessage({id: 'Sign Up'})}
                  buttonType={ButtonTypes.PRIMARY}
                  isLoading={loading}
                  isDisable={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                  onButtonClick={formik.handleSubmit}
                />
              </div>
              {/* end::Action */}
              {/* end::Form group */}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
