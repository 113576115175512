import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import OutsideClickHandler from 'react-outside-click-handler'
import {CampaignDropdown} from '../CampaignDropdown'
import {dateConvert} from '../../core/models/_dateConvert'
import {useEffect, useState} from 'react'
import {leadListObject} from '../../core/_models'
import {Link} from 'react-router-dom'
import {ToolTipUI} from '../../../widgets/components/UI/ToolTipUI'
import {crawlStatus} from '../../core/_constants'

export const LeadList = ({
  lead,
  campaignId,
  getListOfList,
  leadListCount,
}: {
  lead: leadListObject
  campaignId: string | undefined
  getListOfList: () => Promise<void>
  leadListCount: number | undefined
}) => {
  const [openLeadsDeleteModal, setOpenLeadsDeleteModal] = useState<number | null>(null)
  const {formatMessage} = useIntl()
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})

  const handleClick = (leadListId: number) => {
    if (openLeadsDeleteModal === null) {
      setOpenLeadsDeleteModal(leadListId)
    } else {
      setOpenLeadsDeleteModal(null)
    }
  }

  const handleOutsideClick = (leadListId: number) => {
    if (openLeadsDeleteModal === leadListId) {
      setOpenLeadsDeleteModal(null)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <>
      {windowSize.width > 450 ? (
        <>
          <div key={lead.leadListId} className='mb-4 Template-Card-Wrapper'>
            <div className='w-100 d-flex flex-column'>
              <div className='d-flex flex-row justify-content-between m-8 align-items-center'>
                <div className='d-flex flex-row'>
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/general/Leads.svg')}
                    alt='No Image'
                    height='55px'
                    width='55px'
                  />
                  <div className='d-flex flex-center ms-3'>
                    <span className='text-dark fw-bold fs-6'>{lead?.name}</span>
                  </div>
                </div>
                <div className='my-3'>
                  <div className='d-flex justify-content-md-start mb-0'>
                    <img
                      className='me-2'
                      src={toAbsoluteUrl('/media/icons/investor/user_with_bg.svg')}
                      height='16px'
                      width='16px'
                    />
                    <span className='fw-bold font-size-14 font-weight-600'>
                      {lead?.leadsCount} / {lead?.expectedLeads} {formatMessage({id: 'Leads'})}
                    </span>
                    <div className='mb-n1'>
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: `${lead?.leadsCount} out of ${lead?.expectedLeads} leads have been successfully loaded.`,
                        })}
                      />
                    </div>
                  </div>
                  <span className='font-size-12 font-weight-400 text-muted'>
                    {formatMessage({id: 'All leads'})}
                  </span>
                </div>
                <div className='my-3'>
                  <div className='d-flex justify-content-md-start mb-0'>
                    <span className='fw-bold font-size-14 font-weight-600'>
                      {formatMessage({id: 'Crawl status'})}
                    </span>
                  </div>
                  <div
                    className={`${
                      lead?.status === crawlStatus.COMPLETED
                        ? 'complete w-70px'
                        : 'bg-warning w-70px'
                    } rounded-pill py-1 d-flex justify-content-center `}
                  >
                    <p className='font-size-10 font-weight-500 m-0'>
                      {lead?.status === crawlStatus.INPROGRESS
                        ? formatMessage({id: 'In Progress'})
                        : formatMessage({
                            id: lead?.status.charAt(0) + lead?.status.slice(1).toLowerCase(),
                          })}
                    </p>
                  </div>
                </div>
                <div className='my-3'>
                  {lead?.linkedInURL ? (
                    <>
                      <Link to={lead?.linkedInURL} className='text-black' target='_blank'>
                        <div className='d-flex justify-content-md-start mb-0'>
                          <img
                            className='me-2'
                            src={toAbsoluteUrl('/media/icons/investor/share_icon.svg')}
                            height='20px'
                            width='20px'
                          />
                          <span className='fw-bold font-size-14 font-weight-600 text-decoration-underline text-color'>
                            {formatMessage({id: 'LinkedIn'})}
                          </span>
                        </div>
                      </Link>
                      <span className='font-size-12 font-weight-400 text-muted'>
                        {formatMessage({id: 'Added from'})}
                      </span>
                    </>
                  ) : (
                    <div className='text-dark font-size-14 fw-bold'>NA</div>
                  )}
                </div>
                <div className='my-3'>
                  <div className='d-flex justify-content-md-start mb-0'>
                    <span className='fw-bold font-size-14 font-weight-600'>
                      {dateConvert(lead?.createdAt)}
                    </span>
                  </div>
                  <span className='font-size-12 font-weight-400 text-muted'>
                    {formatMessage({id: 'Created At'})}
                  </span>
                </div>
                <div className='my-3 cursor-pointer'>
                  <OutsideClickHandler onOutsideClick={() => handleOutsideClick(lead.leadListId)}>
                    <span onClick={() => handleClick(lead.leadListId)}>
                      <img
                        className='ms-2'
                        src={toAbsoluteUrl('/media/icons/investor/threeDots.svg')}
                      />
                    </span>
                    <CampaignDropdown
                      isOpenCampaignOptions={openLeadsDeleteModal === lead.leadListId}
                      campaignId={+campaignId!}
                      leadListId={lead.leadListId}
                      textMessage={formatMessage({
                        id: 'Are you sure you want to delete this leadlist?',
                      })}
                      headingMessage={formatMessage({
                        id: 'Delete Leadlist',
                      })}
                      getCampaignsList={getListOfList}
                      leadListCount={leadListCount}
                      setOpenLeadsDeleteModal={setOpenLeadsDeleteModal}
                    />
                  </OutsideClickHandler>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div key={lead.leadListId} className='mb-4 Template-Card-Wrapper'>
            <div className='w-100 d-flex flex-column'>
              <div className='m-4'>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/duotune/general/Leads.svg')}
                      alt='No Image'
                      height='55px'
                      width='55px'
                    />
                    <div className='d-flex flex-center ms-3'>
                      <span className='text-dark fw-bold fs-6'>{lead?.name}</span>
                    </div>
                  </div>

                  <div className='my-3 cursor-pointer'>
                    <OutsideClickHandler onOutsideClick={() => handleOutsideClick(lead.leadListId)}>
                      <span onClick={() => handleClick(lead.leadListId)}>
                        <img
                          className='ms-2'
                          src={toAbsoluteUrl('/media/icons/investor/threeDots.svg')}
                        />
                      </span>
                      <CampaignDropdown
                        key={lead.leadListId}
                        isOpenCampaignOptions={openLeadsDeleteModal === lead.leadListId}
                        campaignId={+campaignId!}
                        leadListId={lead.leadListId}
                        textMessage={formatMessage({
                          id: 'Are you sure you want to delete this leadlist?',
                        })}
                        headingMessage={formatMessage({
                          id: 'Delete Leadlist',
                        })}
                        getCampaignsList={getListOfList}
                        leadListCount={leadListCount}
                        setOpenLeadsDeleteModal={setOpenLeadsDeleteModal}
                      />
                    </OutsideClickHandler>
                  </div>
                </div>
                <div>
                  <div className='d-flex gap-6'>
                    <div className='my-3'>
                      <div className='d-flex justify-content-md-start mb-0'>
                        <img
                          className='me-2'
                          src={toAbsoluteUrl('/media/icons/investor/user_with_bg.svg')}
                          height='16px'
                          width='16px'
                        />
                        <span className='fw-bold font-size-12 font-weight-600'>
                          {lead?.leadsCount} / {lead?.expectedLeads} {formatMessage({id: 'Leads'})}
                        </span>
                        <div className='mb-n1'>
                          <ToolTipUI
                            tooltipText={formatMessage({
                              id: `${lead?.leadsCount} out of ${lead?.expectedLeads} leads have been successfully loaded.`,
                            })}
                          />
                        </div>
                      </div>
                      <span className='font-size-12 font-weight-400 text-muted'>
                        {formatMessage({id: 'All leads'})}
                      </span>
                    </div>
                    <div className='my-3'>
                      <div
                        className={`${
                          lead?.status === crawlStatus.COMPLETED
                            ? 'complete w-70px'
                            : 'bg-warning w-70px'
                        } rounded-pill py-1 d-flex justify-content-center `}
                      >
                        <p className='font-size-10 font-weight-500 m-0'>
                          {lead?.status === crawlStatus.INPROGRESS
                            ? formatMessage({id: 'In Progress'})
                            : formatMessage({
                                id: lead?.status.charAt(0) + lead?.status.slice(1).toLowerCase(),
                              })}
                        </p>
                      </div>
                      <div className='d-flex justify-content-md-start mb-0'>
                        <span className='font-size-12 font-weight-400 text-muted'>
                          {formatMessage({id: 'Crawl status'})}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex gap-16'>
                    <div>
                      {lead?.linkedInURL ? (
                        <>
                          <Link to={lead?.linkedInURL} className='text-black' target='_blank'>
                            <div className='d-flex justify-content-md-start mb-0'>
                              <img
                                className='me-2'
                                src={toAbsoluteUrl('/media/icons/investor/share_icon.svg')}
                                height='20px'
                                width='20px'
                              />
                              <span className='fw-bold font-size-12 font-weight-600 text-decoration-underline text-color'>
                                {formatMessage({id: 'LinkedIn'})}
                              </span>
                            </div>
                          </Link>
                          <span className='font-size-12 font-weight-400 text-muted'>
                            {formatMessage({id: 'Added from'})}
                          </span>
                        </>
                      ) : (
                        <div className='text-dark font-size-14 fw-bold'>NA</div>
                      )}
                    </div>
                    <div>
                      <div className='d-flex justify-content-md-start mb-0'>
                        <span className='fw-bold font-size-12 font-weight-600'>
                          {dateConvert(lead?.createdAt)}
                        </span>
                      </div>
                      <span className='font-size-12 font-weight-400 text-muted'>
                        {formatMessage({id: 'Created At'})}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
