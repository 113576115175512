import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router'
import {useAuth} from '../../auth'
import {provisionResources} from '../../onboarding/core/_requests'
import plant from '../../../../_metronic/assets/images/svg/onboarding/plant.svg'
import useGetBillingData from '../../../hooks/useGetBillingData'
import {updateBillingAccess} from '../../profile/core/_requests'
import {toast} from 'react-toastify'
import {Spinner} from '../../widgets/components/General/Spinner'
import {Link} from 'react-router-dom'
import logo from '../../../../_metronic/assets/images/svg/drip-funnel.svg'
import '../styles/onboarding.scss'
import {AiOutlineInfoCircle} from 'react-icons/ai'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useGetPlans} from '../../../hooks/useGetPlans'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const OnboardingPage = () => {
  const {formatMessage} = useIntl()
  const {getBillingDetailsAPI} = useGetBillingData()
  const {getAllPlans} = useGetPlans()

  const {
    setShowBillingModal,
    setBillingData,
    setNewTeam,
    selectedPlan,
    setUserToken,
    saveAuth,
    setSelectedPlan,
    active,
    currencyCode,
    setCurrencyCode,
    billingData,
    setSelectedCurrency,
    plans,
    setPlans,
    plansLoading,
  } = useAuth()

  useEffect(() => {
    if (billingData) {
      setCurrencyCode(billingData?.currency.toUpperCase())
      setSelectedCurrency(billingData?.currency.toUpperCase())
    }
  }, [billingData])

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const initialValues = {
    linkedinEmail: '',
    linkedinPassword: '',
  }

  const onUpgradePlan = async (selected: string, price_id: string) => {
    try {
      setLoading(true)
      const [updateBillingResponse] = await Promise.all([
        updateBillingAccess(selected, price_id, currencyCode === 'INR' ? 'IN' : 'USD'),
        provisionResources(),
      ])
      const {
        data: {success, errors},
      } = updateBillingResponse

      if (success) {
        const result: any = await getBillingDetailsAPI!()
        if (result) {
          const tokenObj = {
            token: result.token,
          }
          await saveAuth(tokenObj)
          setUserToken(result.token)
          setBillingData(result.billingDetails)
          toast.success(formatMessage({id: 'Successfully Onboarded'}))
          navigate('/campaigns')
        }
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const continueConnect = async () => {
    try {
      await onUpgradePlan(selectedPlan[0]?.title, selectedPlan[0]?.price_id)
    } catch (err) {
      console.log(err)
    }
  }

  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    getAllPlans()
  }, [currencyCode])

  return (
    <>
      {plansLoading && (!selectedPlan || selectedPlan.length === 0) ? (
        <Spinner placement={true} />
      ) : (
        <>
          <div className='d-flex flex-column justify-content-center vh-100'>
            <div className='text-center mb-4'>
              <img src={logo} alt='logo' width='180' height='50' />
            </div>
            {windowSize.width > 450 ? (
              <div className='d-flex justify-content-center align-items-center  text-black my-5'>
                <div className='company-container container-border d-flex flex-column justify-content-center w-lg-35 px-17'>
                  <div className='font-size-24'>
                    <span className='fw-bold text-white text-dark'>
                      {formatMessage({id: 'Choose plan'})}
                    </span>
                  </div>
                  <div className='mt-8'>
                    <div className='d-flex flex-row justify-content-start'>
                      <div className='col-12'>
                        <div className='d-flex justify-content-start'>
                          <img src={plant} className='plant mb-1' alt='plant' />
                          <div className='d-flex flex-column mb-2'>
                            <span className='font-size-20 fw-bold m-0 mb-2 text-color'>
                              {selectedPlan[0]?.title}
                              {active && (
                                <span className='mx-2 font-size-16 text-color'>
                                  ({active.charAt(0).toUpperCase() + active.slice(1) + 'ly'})
                                </span>
                              )}
                            </span>
                            <span className='font-size-18 fw-semibold fst-italic text-color'>
                              {formatMessage({
                                id: `${selectedPlan[0]?.trial_period} ${selectedPlan[0]?.trial_period_unit} Free trial`,
                              })}
                            </span>
                            <span className='d-flex align-items-center gap-2'>
                              <p className='m-0 text-muted credit-button mt-1 text-white '>
                                {' '}
                                {formatMessage({id: '*No Credit Card Required'})}
                              </p>
                            </span>
                          </div>
                          <div className='fw-bold font-size-14 text-black mt-2'>
                            <Link
                              to=''
                              onClick={() => setShowBillingModal(true)}
                              className='link-primary'
                            >
                              {formatMessage({id: 'Change Plan'})}
                            </Link>
                          </div>
                        </div>
                        <div className='mt-6 pe-8'>
                          {selectedPlan[0]?.features?.map((feature: any, index: number) => (
                            <li className='plan-list text-color' key={index}>
                              {feature.title === 'Daily Activity Limit (Full)' ||
                              feature.title === 'Daily Activity Limit (Limited)' ? (
                                <OverlayTrigger
                                  placement='top'
                                  overlay={
                                    <Tooltip id={`tooltip-${index}`} className='primary-tooltip'>
                                      Daily sending limits:
                                      <br />
                                      Connection requests: 25 (or 50 for premium users)
                                      <br />
                                      Messages: 50 (or 100 for premium users)
                                      <br />
                                      InMails: 10 (or 25 for premium users)
                                      <br />
                                      Profile Views: 150 (or 250 for premium users)
                                      <br />
                                      Skill endorsements: 40 (or 75 for premium users)
                                      <br />
                                      Likes: 40 (or 75 for premium users)
                                      <br />
                                      Profile follows: 40 (or 75 for premium users)
                                    </Tooltip>
                                  }
                                >
                                  {({ref, ...triggerHandler}) => (
                                    <span ref={ref} {...triggerHandler}>
                                      {feature.title}
                                      <AiOutlineInfoCircle
                                        className='ms-1'
                                        style={{fontSize: '24px'}}
                                      />
                                    </span>
                                  )}
                                </OverlayTrigger>
                              ) : (
                                feature.title
                              )}
                            </li>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-row justify-content-end mt-7'>
                      <div>
                        <GlobalButton
                          buttonText={formatMessage({id: 'Continue'})}
                          buttonType={ButtonTypes.PRIMARY}
                          onButtonClick={() => {
                            continueConnect()
                          }}
                          isLoading={loading}
                          isDisable={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className='d-flex justify-content-center align-items-center text-black'>
                  <div className='company-container d-flex flex-column justify-content-center py-10 w-90 px-10'>
                    <div className='font-size-20'>
                      <span className='fw-bold text-white'>
                        {formatMessage({id: 'Choose plan'})}
                      </span>
                    </div>
                    <div className=' mt-8'>
                      <div className='d-flex flex-row justify-content-start'>
                        <div className='col-12'>
                          <div className='d-flex justify-content-start'>
                            <img src={plant} className='plant mt-4' alt='plant' />
                            <div className='d-flex flex-column mb-2'>
                              <h2 className='font-size-16 fw-bold m-0 mb-2 text-white'>
                                {selectedPlan[0]?.title}
                              </h2>
                              <span className='mx-2 font-size-16 text-white'>
                                ({active.charAt(0).toUpperCase() + active.slice(1) + 'ly'})
                              </span>
                              <span className='font-size-14 fw-semibold fst-italic text-white'>
                                {formatMessage({
                                  id: `${selectedPlan[0]?.trial_period} ${selectedPlan[0]?.trial_period_unit} Free trial`,
                                })}
                              </span>
                              <div>
                                <span className='d-flex align-items-center gap-2 text-white'>
                                  <p className='m-0 text-muted credit-button mt-2'>
                                    {' '}
                                    {formatMessage({id: '*No Credit Card Required'})}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div className=' fw-bold font-size-14 text-black mt-2'>
                              <Link
                                to=''
                                onClick={() => setShowBillingModal(true)}
                                className='link-primary'
                              >
                                {formatMessage({id: 'Change Plan'})}
                              </Link>
                            </div>
                          </div>
                          <div className='mt-6 pe-8 text-white'>
                            {selectedPlan[0]?.features?.map((feature: any, index: number) => (
                              <li className='plan-list' key={index}>
                                {feature.title}
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-row justify-content-end'>
                        <div className='mt-7'>
                          <GlobalButton
                            buttonText={formatMessage({id: 'Continue'})}
                            buttonType={ButtonTypes.PRIMARY}
                            onButtonClick={() => {
                              continueConnect()
                            }}
                            isDisable={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}
