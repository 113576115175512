import axios from 'axios'
const API_URL = process.env.REACT_APP_BASE_API_URL
import {serialize} from '../../../core/_util'

export const GET_LEADS_URL = `${API_URL}/leads`
export const DELETE_LEAD_URL = `${API_URL}/leads`
export const EXPORT_LEAD_URL = `${API_URL}/leads/export`
export const GET_CAMPAIGNS_URL = `${API_URL}/campaign`
export const GET_LEAD_LIST_URL = `${API_URL}/leads/lead-list`

export const getAllCampaigns = () => {
  return axios.get(`${GET_CAMPAIGNS_URL}`, {
    validateStatus() {
      return true
    },
  })
}

export const getLeads = ({
  currentPage,
  size,
  searchLead,
  campaignId,
  leadListId,
  userId,
  statusFilter,
}: {
  currentPage: number
  size: number
  searchLead: string
  campaignId: number
  leadListId: number
  userId?: number
  statusFilter: string | undefined
}) => {
  const object = {
    page: currentPage,
    size: size,
    search: searchLead,
    campaignId: campaignId,
    leadListId: leadListId,
    userId: userId,
    statusFilter: statusFilter,
  }
  return axios.get(`${GET_LEADS_URL}?${serialize(object)}`, {
    validateStatus() {
      return true
    },
  })
}

export const deleteLead = ({leadDeleteID}: {leadDeleteID: string}) => {
  return axios.delete(`${DELETE_LEAD_URL}/${leadDeleteID}`, {
    validateStatus() {
      return true
    },
  })
}

export const exportLeads = ({leadListId, campaignId}: {leadListId: number; campaignId: number}) => {
  const object = {
    campaignId: campaignId,
    leadListId: leadListId,
  }
  return axios.get(`${EXPORT_LEAD_URL}?${serialize(object)}`, {
    validateStatus() {
      return true
    },
  })
}
export const getLeadList = (campaignId?: number) => {
  const object = {
    campaignId: campaignId,
  }
  return axios.get(`${GET_LEAD_LIST_URL}?${serialize(object)}`, {
    validateStatus() {
      return true
    },
  })
}
