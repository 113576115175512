import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useEffect} from 'react'
import useGetBillingData from '../../../hooks/useGetBillingData'
import {useAuth} from '../../auth'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const SelectPlanModal = ({
  selectPlanModal,
  setSelectPlanModal,
  setShowBillingModal,
  setBillingData,
  setPriceId,
  runCardDetailsApi,
  runUserDetailsApi,
  modalText,
}: {
  selectPlanModal: boolean
  setSelectPlanModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowBillingModal: React.Dispatch<React.SetStateAction<boolean>>
  setBillingData: React.Dispatch<any>
  setPriceId: React.Dispatch<React.SetStateAction<string>>
  runCardDetailsApi: boolean
  runUserDetailsApi: boolean
  modalText: string
}) => {
  const {formatMessage} = useIntl()
  const {getBillingDetailsAPI} = useGetBillingData()
  const {setScheduleDowngradeData} = useAuth()

  useEffect(() => {
    const getBillingDetails = async () => {
      const result = await getBillingDetailsAPI()
      setBillingData(result?.billingDetails)
      setPriceId(result?.billingDetails?.plan?.id)
      setScheduleDowngradeData(result?.schedule)
    }
    if (runCardDetailsApi || runUserDetailsApi) {
      getBillingDetails()
    }
  }, [runCardDetailsApi, runUserDetailsApi]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div>
        <Modal
          show={selectPlanModal}
          onHide={() => setSelectPlanModal(!selectPlanModal)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({id: 'Upgrade plan'})}</h2>
          </Modal.Header>
          <Modal.Body>
            <div>{modalText}</div>
          </Modal.Body>
          <Modal.Footer>
            <GlobalButton
              buttonText={formatMessage({id: 'Update Subscription'})}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                setSelectPlanModal(!selectPlanModal)
                setShowBillingModal(true)
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
