import axios from 'axios'
import {BILLING_DATA} from '../../profile/core/_requests'
const API_URL = process.env.REACT_APP_BASE_API_URL

const LINKEDIN_CONNECT = `${API_URL}/auth/onboarding/linkedin`

export const GET_FILE_URL = `${API_URL}/file`
const GET_PLAN_URL = `${API_URL}/billing/plans`
const PUT_LINKEDIN_OTP = `${API_URL}/user/setOtp`
const GET_LINKEDIN_MESSAGE = `${API_URL}/user/get/linkedin-message`
const POST_LINKEDIN_MESSAGE = `${API_URL}/user/post/linkedin-message`
const PROCEED_TO_PAYMENT = `${API_URL}/billing/payment`
const GET_CARD_DETAILS = `${API_URL}/billing/card`
const PROVISION_RESOURCES = `${API_URL}/auth/onboarding/provision`
const PRO_RATED_PRICE = `${API_URL}/billing/proration`
const UPDATE_CARD_DETAILS = `${API_URL}/billing/update-card`
const COUPON_DISCOUNT = `${API_URL}/billing/coupon`
const GET_IP_ADDRESS = `https://api.ipify.org/`
//getLocation

//get all plans

const controller = new AbortController()

export const getPlans = (ip?: string, currencyCode?: string) => {
  return axios.get(
    `${GET_PLAN_URL}${
      currencyCode
        ? `?currency_code=${currencyCode}`
        : ip
        ? `?clientIp=${ip}`
        : '?currency_code=INR'
    }`,
    {
      signal: controller.signal,
      validateStatus() {
        return true
      },
    }
  )
}

export const getIpAddress = () => {
  return axios.get(`${GET_IP_ADDRESS}`, {
    headers: {
      Authorization: '',
    },
    validateStatus() {
      return true
    },
  })
}

//CONNECT Linkedin
export const connectLinkedin = (linkedInEmail: string, linkedInPassword: string) => {
  const payload = {
    linkedInEmail,
    linkedInPassword,
  }
  return axios.put(LINKEDIN_CONNECT, payload, {
    validateStatus() {
      return true
    },
  })
}

export const putLinkedInOTP = (linkedinOtp: string) => {
  const payload = {
    otp: linkedinOtp,
  }
  return axios.post(PUT_LINKEDIN_OTP, payload, {
    validateStatus() {
      return true
    },
  })
}
//proceed to payment
export const postProceedToPayment = (priceId: string, currencyId: string, promoCode?: string) => {
  const payload = {
    priceId,
    currencyId,
    promoCode,
  }
  return axios.post(PROCEED_TO_PAYMENT, payload, {
    validateStatus() {
      return true
    },
  })

  //  return axios.post(`${API_URL}/billing/create-setup-intent`, payload, {
  //     validateStatus() {
  //       return true
  //     },
  // })
}

export const changeCard = (paymentToken: string) => {
  const payload = {
    paymentToken,
  }

  return axios.post(`${API_URL}/billing/create-setup-intent`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const confirmCardChange = (setupIntentId: string) => {
  return axios.get(`${API_URL}/billing/confirm-card-change/${setupIntentId}`, {
    validateStatus() {
      return true
    },
  })
}

export const handleDownGradeApi = (
  planName: string,
  priceId: string,
  currencyId: string,
  downgrade?: boolean,
  promoCode?: string
) => {
  const payload = {
    planName,
    priceId,
    currencyId,
    promoCode,
    downgrade,
  }
  return axios.put(BILLING_DATA, payload, {
    validateStatus() {
      return true
    },
  })
}

//proceed to payment if card is already there
export const postProceedToPaymentCard = (
  planName: string,
  priceId: string,
  currencyId: string,
  promoCode?: string
) => {
  const payload = {
    planName,
    priceId,
    currencyId,
    promoCode,
  }
  return axios.put(BILLING_DATA, payload, {
    validateStatus() {
      return true
    },
  })
}

export const getLinkedinMessage = () => {
  return axios.get(GET_LINKEDIN_MESSAGE, {
    validateStatus() {
      return true
    },
  })
}

export const postLinkedinMessage = (message: string) => {
  return axios.post(
    POST_LINKEDIN_MESSAGE,
    {message: message},
    {
      validateStatus() {
        return true
      },
    }
  )
}
//card details
export const cardDetails = () => {
  return axios.get(`${GET_CARD_DETAILS}`, {
    validateStatus() {
      return true
    },
  })
}

export const provisionResources = () => {
  return axios.post(`${PROVISION_RESOURCES}`, {
    validateStatus() {
      return true
    },
  })
}

export const proceedToProRated = (priceId: string, currencyId: string, couponCode?: string) => {
  return axios.get(
    `${PRO_RATED_PRICE}?priceId=${priceId}&currencyId=${currencyId}${
      couponCode ? `&promoCode=${couponCode}` : ''
    }`,
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const updateCard = (
  updateType: string,
  cardToken?: string | undefined,
  name?: string | undefined,
  line1?: string | undefined,
  line2?: string | undefined,
  city?: string | undefined,
  country?: string | undefined,
  state?: string | undefined,
  postal_code?: string | undefined
) => {
  const payload =
    updateType === 'address'
      ? {
          updateType,
          name,
          line1,
          line2,
          city,
          country,
          state,
          postal_code,
        }
      : {
          updateType,
          cardToken,
        }

  return axios.post(UPDATE_CARD_DETAILS, payload, {
    validateStatus(status) {
      return true
    },
  })
}

export const applyCouponDiscount = (coupon: string) => {
  return axios.get(`${COUPON_DISCOUNT}/${coupon}`, {
    validateStatus() {
      return true
    },
  })
}
