import {Activity} from '../core/models/Activity'
import {useIntl} from 'react-intl'
import {RequestLimits, Status} from '../core/_constant'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {DateTime} from 'luxon'
import {useTimeZone} from '../../../hooks/useTimeZone'
import {useEffect} from 'react'

export const ActivityCard = ({
  activity,
  index,
  length,
  isCampaign,
  cardLoader,
  indexNumber,
  ObjectLength,
}: {
  activity: Activity
  index: number
  length: number
  isCampaign: boolean
  cardLoader?: boolean | undefined
  indexNumber?: number | undefined
  ObjectLength?: number | undefined
}) => {
  const {formatMessage} = useIntl()
  const {getDateValue} = useDateFormat()
  const {getTimeZoneValue} = useTimeZone()
  const timeZone = localStorage.getItem('iana_title')

  return (
    <>
      {(() => {
        switch (activity?.type) {
          case RequestLimits.INMAIL:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='inmail'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>
                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/inmail.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Sent an InMail to'})
                              : formatMessage({id: 'Failed to send an InMail'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='inmail'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='inmail'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.VIEW:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='view'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>
                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>
                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/view.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Viewed profile of'})
                              : formatMessage({id: 'Failed to viewed profile of'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='view'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='view'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.MESSAGE:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='message'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/message.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Sent a message to'})
                              : formatMessage({id: 'Failed to sent a message to'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='sent message'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='sent message'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.ENDORSEMENT:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='endorsement'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/endorsement.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'You have endorsed the profile of'})
                              : formatMessage({id: 'Failed to endorse the profile of'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='endorse'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='endorse'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.LIKE:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='like'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>
                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/like.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Liked a post of'})
                              : formatMessage({id: 'Failed to Liked a post of'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='like'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='like'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.FOLLOW:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='follow'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/follow.svg')}
                            alt='follow'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Followed'})
                              : formatMessage({id: 'Failed to Follow'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='follow'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='follow'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.REQUEST:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='request'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/request.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Connection request was sent to'})
                              : formatMessage({id: 'Failed to send request to'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='connect request'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='connection request'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.SEND_AN_INVITE:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                      alt='send_an_invite'
                      className='p-3 z-index-1 bg-white'
                    />

                    <div className='timeline-horizontal-line'></div>
                  </div>

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4 ms-8'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/request.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Connection request was sent to'})
                              : formatMessage({id: 'Failed to send connection request'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='connection request'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='connection request'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.EMAILREQUEST:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <img
                    src={toAbsoluteUrl('/media/icons/activity/email_request.svg')}
                    alt='email_request'
                    className='p-3 z-index-1 bg-white'
                  />

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <p className='fs-6 fw-semibold m-0 pt-3'>
                          &nbsp;{formatMessage({id: 'Email request sent to'})}&nbsp;
                          <span className='text-info'>
                            <a href={`${activity?.lead?.navigationUrl}`} target='blank'>
                              {activity?.lead?.firstName}
                            </a>
                          </span>{' '}
                          {!isCampaign && (
                            <span>
                              &nbsp;{formatMessage({id: 'in'})}&nbsp;
                              <span className='text-info'>
                                <a href={`/campaigns/${activity?.campaign?.campaignId}`}>
                                  {activity?.campaign?.name}
                                </a>
                              </span>{' '}
                              &nbsp;{formatMessage({id: 'campaign'})}&nbsp;
                            </span>
                          )}
                          {activity.createdAt && (
                            <span>
                              {formatMessage({id: 'on'})}&nbsp;
                              <span>
                                {getDateValue(activity?.createdAt?.toString())
                                  ? getDateValue(activity?.createdAt?.toString())
                                  : DateTime.fromISO(
                                      activity?.createdAt?.toString()
                                    ).toLocaleString(DateTime.DATE_MED)}{' '}
                                &nbsp;
                              </span>
                              &nbsp;{formatMessage({id: 'at'})}&nbsp;
                              <span>
                                {activity?.createdAt &&
                                  getTimeZoneValue(activity?.createdAt?.toString())}
                              </span>
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
          case RequestLimits.SEARCH:
            return (
              <div className='d-flex justify-content-start'>
                <div className='d-flex align-items-center me-2 position-relative'>
                  <img
                    src={toAbsoluteUrl('/media/icons/activity/circle.svg')}
                    alt='search'
                    className='p-3 z-index-1 bg-white'
                  />

                  <div
                    className={`timeline-line ${
                      ObjectLength &&
                      ObjectLength - 1 === indexNumber &&
                      length - 1 === index &&
                      'timeline-update-last'
                    }`}
                  ></div>
                </div>

                <div className='card recent-activity mb-4 mt-4'>
                  <div className='card-body p-0 d-flex justify-content-between px-5'>
                    <div className='d-flex justify-content-between'>
                      <div className='overflow-auto d-flex flex-column gap-2'>
                        <div className='d-flex py-2'>
                          <img
                            src={toAbsoluteUrl('/media/icons/activity/request.svg')}
                            alt='inmail'
                            className='pt-3 z-index-1'
                          />
                          <p className='fs-6 fw-semibold m-0 pt-3 ps-2'>
                            &nbsp;
                            {activity?.status === Status.SUCCESS
                              ? formatMessage({id: 'Searched for'})
                              : formatMessage({id: 'Failed to search for'})}
                            &nbsp;
                            <span className='text-blue font-size-14 font-weight-600'>
                              {activity?.lead?.linkedInProfileImageUrl ? (
                                <img
                                  src={activity?.lead?.linkedInProfileImageUrl}
                                  alt='search'
                                  className='bg-white img-border'
                                  width={16}
                                  height={16}
                                />
                              ) : (
                                <img
                                  src={toAbsoluteUrl('/media/icons/activity/user.svg')}
                                  alt='search'
                                  className='bg-white img-border'
                                  width={20}
                                  height={20}
                                />
                              )}
                              &nbsp;
                              <a
                                href={`${activity?.lead?.navigationUrl}`}
                                target='blank'
                                className='text-blue font-size-14 font-weight-600'
                              >
                                {activity?.lead?.firstName}
                              </a>
                            </span>{' '}
                            {!isCampaign && (
                              <span>
                                &nbsp;{formatMessage({id: 'in'})}&nbsp;
                                <span className='text-blue font-size-14 font-weight-600'>
                                  <a
                                    href={`/campaigns/${activity?.campaign?.campaignId}`}
                                    className='text-blue font-size-14 font-weight-600'
                                  >
                                    {activity?.campaign?.name}
                                  </a>
                                  &nbsp;
                                  {formatMessage({id: 'campaign'})}&nbsp;
                                  {activity.createdAt && (
                                    <span>
                                      {DateTime.fromISO(activity?.createdAt?.toString()).toFormat(
                                        'yyyy'
                                      )}
                                    </span>
                                  )}
                                </span>{' '}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='font-size-10 font-weight-400 text-grey pt-2 text-nowrap ms-1'>
                      {activity?.createdAt &&
                        getTimeZoneValue(activity?.createdAt?.toString(), timeZone)}
                    </div>
                  </div>
                </div>
              </div>
            )
        }
      })()}{' '}
    </>
  )
}
