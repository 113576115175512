import {Form, Formik} from 'formik'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import TextInput from '../../widgets/components/Input/TextInput'
import TextArea from '../../widgets/components/Input/TextArea'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const AddNotesModal = ({
  openAddNotesModal,
  setOpenAddNotesModal,
}: {
  openAddNotesModal: boolean
  setOpenAddNotesModal: (value: boolean) => void
}) => {
  const {formatMessage} = useIntl()

  const initialValues = {
    notes: '',
  }

  return (
    <>
      <Modal
        show={openAddNotesModal}
        onHide={() => setOpenAddNotesModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {(formik) => {
            return (
              <Form>
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                  <div className='font-size-16 fw-bold'>{formatMessage({id: 'Add notes'})}</div>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <TextArea
                      fieldName={'notes'}
                      formik={formik}
                      placeholder={formatMessage({id: 'Type your notes here...'})}
                      margin={'m-0 mt-2'}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className='pt-0 border-top-0'>
                  <div className='w-100 d-flex justify-content-end gap-3'>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Cancel'})}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={() => {
                        setOpenAddNotesModal(false)
                      }}
                    />

                    <GlobalButton
                      buttonText={formatMessage({id: 'Save'})}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {}}
                      isDisable={formik.isSubmitting || !formik.isValid}
                    />
                  </div>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
