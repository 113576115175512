import {useElements, Elements, CardElement, AddressElement} from '@stripe/react-stripe-js'
import {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {Status} from '../../../core/_constants'
import {useAuth} from '../../auth'
import {
  changeCard,
  confirmCardChange,
  postProceedToPayment,
  postProceedToPaymentCard,
  updateCard,
} from '../core/_requests'
import {PaymentCheckoutElement} from '../components/PaymentCheckoutElement'
import {CardDetails} from '../../profile/components/CardDetails'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const AddCardModal = ({
  getCardDetails,
  stripe,
  setRunCardDetailsApi,
  setPaymentModalLoading,
  paymentModalLoading,
  getBillingDetails,
}: any) => {
  const elements: any = useElements()
  const [isCardAddressFilled, setIsCardAddressFilled] = useState(false)
  const [isCardFilled, setIsCardFilled] = useState(false)
  const [cardAddress, setCardAddress] = useState<any>()
  const {formatMessage} = useIntl()
  const {
    priceId,
    paymentModal,
    setPaymentModal,
    cardDigits,
    cardBillingDetails,
    selected,
    billingData,
    billingClick,
    couponCode,
    setUpdateCardDetails,
    saveAuth,
    currencyCode,
  } = useAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [paymentClientSecret, setPaymentClientSecret] = useState('')

  const proceedToCardPayment = async (selected: string, currencyId: string) => {
    setLoading(true)

    try {
      const {
        data: {data, success, errors},
      } = await postProceedToPaymentCard(
        selected,
        priceId,
        currencyId === 'inr' ? 'IN' : 'US',
        couponCode
      )
      if (success) {
        const result = await stripe.confirmCardPayment(data?.client_secret, {
          setup_future_usage: 'off_session',
        })
        if (result?.paymentIntent?.status === 'succeeded') {
          toast.success(formatMessage({id: 'Plan Upgraded Successfully'}))
          setPaymentModal(false)
          getBillingDetails()
        }
      }
      getCardDetails()
    } catch (err: any) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const proceedToCardRePayment = async (selected: string, currencyId: string) => {
    setLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await postProceedToPaymentCard(
        selected,
        priceId,
        currencyId === 'inr' ? 'IN' : 'US',
        couponCode
      )
      if (success) {
        const result = await stripe.confirmCardPayment(data?.payment_client_secret, {
          setup_future_usage: 'off_session',
        })
        if (result?.paymentIntent?.status === 'succeeded') {
          toast.success(formatMessage({id: 'Plan Activated Successfully'}))
          setPaymentModal(false)
          getBillingDetails()
        }
      }
      getCardDetails()
    } catch (err: any) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleAddressChange = async () => {
    setLoading(true)
    try {
      const apiParams: [string, ...(string | undefined)[]] = [
        'address',
        undefined,
        cardAddress.name,
        cardAddress.address?.line1,
        cardAddress.address?.line2 || '',
        cardAddress.address?.city,
        cardAddress.address?.country,
        cardAddress.address?.state,
        cardAddress.address?.postal_code,
      ]
      const {
        data: {success, errors},
      } = await updateCard(...apiParams)
      if (success) {
        toast.success(formatMessage({id: 'Card Address is updated successsfully'}))
        setLoading(false)
        setPaymentModal(false)
        setUpdateCardDetails(true)
      } else {
        setLoading(false)
        setPaymentModal(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setLoading(false)
      setPaymentModal(false)
      console.log(err)
    }
  }

  const handleUpdate = async (token: string) => {
    try {
      const {
        data: {success, errors, data},
      } = await changeCard(token)
      if (success) {
        const result = await stripe.confirmCardSetup(data.setupIntentClientSecret)

        if (result.setupIntent?.status === 'succeeded') {
          const {
            data: {data: confirmMessage, success},
          } = await confirmCardChange(data.setupIntentId)
          if (success) {
            setUpdateCardDetails(true)
            toast.success(confirmMessage)
          }
          setLoading(false)
          setPaymentModal(false)
          setUpdateCardDetails(true)
        } else {
          toast.error(result.setupIntent?.last_setup_error?.message)
          return
        }
      } else {
        setLoading(false)
        setPaymentModal(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setLoading(false)
      setPaymentModal(false)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (paymentModal) {
      setPaymentModalLoading(true)
      getPaymentClientSecret()
    }
  }, [paymentModal])

  const getPaymentClientSecret = async () => {
    try {
      const {
        data: {data, success},
      } = await postProceedToPayment(
        priceId,
        billingData?.currency === 'inr' ? 'IN' : 'USD',
        couponCode
      )
      if (success) {
        setPaymentClientSecret(data)
        setPaymentModal(true)
      }
    } catch (error) {
      setPaymentModal(false)
      console.log(error)
    } finally {
      setPaymentModalLoading(false)
    }
  }
  const handleUpdateCard = async () => {
    setLoading(true)
    if (!stripe && !elements) {
      return
    }
    const {token, error} = await stripe.createToken(elements.getElement(CardElement))
    if (error) {
      setLoading(false)
      setPaymentModal(false)
      toast.error(error.message)
    } else {
      handleUpdate(token.id)
    }
  }

  return (
    <Modal
      show={paymentModal}
      onHide={() => setPaymentModal(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='px-3'
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton className='border-bottom-0'>
        <div className=' d-flex fw-bold'>
          {cardDigits.length > 0 ? (
            billingClick === 'card' ? (
              <h2 className='font-size-16 text-white'>{formatMessage({id: 'Enter new card'})}</h2>
            ) : (
              <h2 className='font-size-16 text-white'>
                {formatMessage({id: 'Your Card Details'})}
              </h2>
            )
          ) : paymentClientSecret ? (
            <h2 className='font-size-16'>{formatMessage({id: 'Payment details'})}</h2>
          ) : (
            ''
          )}
        </div>
      </Modal.Header>
      <Modal.Body className='py-3'>
        <>
          {cardDigits ? (
            <>
              {billingData?.status !== Status.TRIAL &&
              billingData?.status !== Status.INCOMPLETE &&
              billingClick === 'card' ? (
                <div className='my-3  '>
                  <div className='border border-1 p-4 rounded'>
                    <CardElement
                      onChange={(event) => {
                        setIsCardFilled(event.complete)
                      }}
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                        hidePostalCode: true,
                      }}
                    />
                  </div>

                  <Modal.Footer>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Update Card'})}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {
                        handleUpdateCard()
                      }}
                      isDisable={!isCardFilled || !stripe || !elements}
                      isLoading={loading}
                    />
                  </Modal.Footer>
                </div>
              ) : billingClick === 'address' ? (
                <>
                  <AddressElement
                    options={{mode: 'billing'}}
                    onChange={(event) => {
                      setIsCardAddressFilled(event.complete)
                      setCardAddress(event.value)
                    }}
                  />
                  <Modal.Footer>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Update Address'})}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {
                        handleAddressChange()
                      }}
                      isDisable={!isCardAddressFilled}
                      isLoading={loading}
                    />
                  </Modal.Footer>
                </>
              ) : (
                <>
                  <CardDetails />
                  <Modal.Footer>
                    <div>
                      <GlobalButton
                        buttonText={formatMessage({id: 'Pay Now'})}
                        buttonType={ButtonTypes.PRIMARY}
                        onButtonClick={() => {
                          if (billingData?.status === Status.ACTIVE) {
                            proceedToCardPayment(selected, billingData?.currency)
                          } else if (cardDigits && billingData?.status === Status.CANCELED) {
                            proceedToCardRePayment(selected, billingData?.currency)
                          }
                        }}
                        isLoading={loading}
                      />
                    </div>
                  </Modal.Footer>
                </>
              )}
            </>
          ) : (
            <>
              {paymentClientSecret ? (
                <Elements stripe={stripe} options={{clientSecret: paymentClientSecret}}>
                  <PaymentCheckoutElement
                    paymentClientSecret={paymentClientSecret}
                    getCardDetails={getCardDetails}
                    setRunCardDetailsApi={setRunCardDetailsApi}
                    getBillingDetails={getBillingDetails}
                  />
                </Elements>
              ) : (
                <div className='w-100 d-flex justify-content-center align-items-center'>
                  <div
                    className={`spinner-border d-center`}
                    style={{width: '3rem', height: '3rem'}}
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      </Modal.Body>
    </Modal>
  )
}
