import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import '../../styles/_campaign.scss'
import {CampaignDetails, CampaignObject, campaignObject} from '../../core/_models'
import {DeleteConfirmationModal} from '../../../widgets/components/DeleteConfirmationModal'
import {campaignStatus} from '../../core/_constants'
import {toast} from 'react-toastify'
import {
  deleteCampaign,
  updateStatusActiveToInactive,
  updateStatusInactiveToActive,
} from '../../core/_requests'
import {useNavigate} from 'react-router-dom'
import {PageHeading} from '../../../template/components/PageHeading'
import {campaignDetailsBreadCrumbs, profileBreadCrumbs} from '../../../profile/core/_constants'
import {ToolTipUI} from '../../../widgets/components/UI/ToolTipUI'
import {useDateFormat} from '../../../../hooks/useDateFormat'
import {DateTime} from 'luxon'
import {useTimeFormat} from '../../../../hooks/useTimeFormat'

export const AnalyticsRow = ({
  data,
  progressPercent,
  details,
  createdAtDate,
  getCampaignData,
}: {
  data: CampaignObject | undefined
  progressPercent: string
  details: CampaignDetails | undefined
  createdAtDate: string
  getCampaignData: () => void
}) => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const {getTimeValue} = useTimeFormat()
  // useStates
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deletingCampaignInside, setDeletingCampaignInside] = useState<boolean>(false)
  const {getDateValue} = useDateFormat()
  const [updateToggle, setUpdateToggle] = useState<boolean>(false)
  const [campaignStatusStr, setCampaignStatusStr] = useState<string | undefined>(data?.status)

  useEffect(() => {
    setCampaignStatusStr(data?.status)
  }, [data?.status])

  const updateActiveToInactive = async (campaignId: number) => {
    try {
      setUpdateToggle(true)
      const {
        data: {success, errors, data},
      } = await updateStatusActiveToInactive(campaignId)
      if (success) {
        toast.success(formatMessage({id: 'Campaign updated successfully'}))
        getCampaignData()
        setUpdateToggle(false)
      } else {
        setCampaignStatusStr(campaignStatus.ACTIVE)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setUpdateToggle(false)
      }
    } catch (err) {
      console.log(err)
      setCampaignStatusStr(campaignStatus.ACTIVE)
      setUpdateToggle(false)
    }
  }

  const updateInactiveToActive = async (campaignId: number) => {
    try {
      setUpdateToggle(true)
      const {
        data: {success, errors, data},
      } = await updateStatusInactiveToActive(campaignId)
      if (success) {
        toast.success(formatMessage({id: 'Campaign updated successfully'}))
        getCampaignData()
        setUpdateToggle(false)
      } else {
        setCampaignStatusStr(campaignStatus.INACTIVE)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setUpdateToggle(false)
      }
    } catch (err) {
      console.log(err)
      setCampaignStatusStr(campaignStatus.INACTIVE)
      setUpdateToggle(false)
    }
  }
  const toggle = () => {
    if (updateToggle) {
      return null
    } else {
      if (data?.status === campaignStatus.INACTIVE) {
        setCampaignStatusStr(campaignStatus.ACTIVE)
        updateInactiveToActive(data?.campaignId)
      } else if (data?.status === campaignStatus.ACTIVE) {
        setCampaignStatusStr(campaignStatus.INACTIVE)
        updateActiveToInactive(data!.campaignId)
      }
    }
  }

  const handleDeleteCampaignInside = async () => {
    if (data?.status === campaignStatus.ACTIVE) {
      toast.error(formatMessage({id: 'Active campaign cannot be deleted'}))
      setOpenDeleteModal(false)
      return
    }
    try {
      setDeletingCampaignInside(true)
      if (data) {
        const {
          data: {success, errors},
        } = await deleteCampaign(data?.campaignId)
        if (success) {
          setDeletingCampaignInside(false)
          setOpenDeleteModal(false)
          navigate(`/`)
          toast.success(formatMessage({id: 'Campaign Deleted successfully'}))
        } else {
          setDeletingCampaignInside(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
    } catch (err) {
      setDeletingCampaignInside(false)
      console.log(err)
    }
  }

  return (
    <>
      <div className='bg-white'>
        <div className='px-8 pt-8'>
          <PageHeading
            title={formatMessage({id: 'Campaign'})}
            description={formatMessage({
              id: 'Check the overview of the campaign, workflow, leads, and performance analytics.',
            })}
            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.TITLE'})}
            breadcrumbs={campaignDetailsBreadCrumbs}
            breadcrumbTitle={data?.name}
          ></PageHeading>
        </div>
        <div className='separator my-4'></div>
        <div className='title d-md-flex justify-content-md-between'>
          <div className='table-responsive w-100 p-0'>
            <table className='table  campaigns-table mb-0'>
              <tbody>
                <tr className='campaign-row bg-white position-relative'>
                  <td className='campaign-first-col align-middle'>
                    <div className='d-flex justify-content-md-start'>
                      <div className='mt-md-1'>
                        <img
                          className='me-3 campaign-img'
                          src={toAbsoluteUrl('/media/campaign/campaign_icon.svg')}
                          height='64px'
                          width='64px'
                        />
                      </div>
                      <div className='text-black font-size-16 font-weight-600 d-flex flex-column justify-content-center'>
                        {' '}
                        <div className='campaign-name text-color'>{data?.name}</div>
                        {data?.user?.linkedin_name && (
                          <div className='d-flex align-items-center justify-content-start'>
                            <img
                              className='me-2'
                              src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                              height='16px'
                              width='16px'
                            />
                            <p className='font-size-14 font-weight-600 m-0 linkedin-text-hover'>
                              <a
                                href={data?.user.linkedin_url}
                                className='linkedin-text'
                                target='_blank'
                              >
                                {data?.user?.linkedin_name}
                              </a>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className='campaign-cols align-middle'>
                    <div className='d-flex justify-content-md-start mb-0'>
                      <div>
                        <img className='me-2' src={toAbsoluteUrl('/media/campaign/filter.svg')} />
                      </div>
                      <div className='fw-bold font-size-14 font-weight-600 new-font-14'>
                        {details?.leadListsCount}
                      </div>
                    </div>
                    <div className='d-flex text-nowrap'>
                      <div className='font-size-12 text-color table-body-fix-text new-font-12'>
                        {formatMessage({id: 'Lists of leads'})}
                      </div>
                      <div className='mt-n1'>
                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: 'GLOBAL.TOOLTIP.CAMPAIGN.LEAD_LIST',
                          })}
                        />
                      </div>
                    </div>
                  </td>
                  <td className='campaign-cols align-middle'>
                    <div className='d-flex justify-content-start '>
                      <div>
                        <img
                          className='me-2'
                          src={toAbsoluteUrl('/media/campaign/acceptance_rate.svg')}
                          height='16px '
                          width='16px'
                        />
                      </div>
                      <div className='fw-bold text-black font-size-14 font-weight-600 new-font-14 text-color'>
                        {details?.acceptanceRate}
                      </div>
                    </div>
                    <div className='d-flex justify-content-start'>
                      <div className='font-size-12 table-body-fix-text text-nowrap new-font-12'>
                        {formatMessage({id: 'Acceptance rate'})}
                      </div>
                      <div className='mt-n1'>
                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: 'GLOBAL.TOOLTIP.CAMPAIGN.ACCEPTANCE_RATE',
                          })}
                        />
                      </div>
                    </div>
                  </td>
                  <td className='campaign-cols align-middle'>
                    <div className='d-flex'>
                      <div>
                        <img
                          className='me-2'
                          src={toAbsoluteUrl('/media/campaign/reply_rate.svg')}
                          height='18px '
                          width='18px'
                        />
                      </div>
                      <div className='fw-bold text-black font-size-14 font-weight-600 new-font-14 text-color'>
                        {details?.replyRate}
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='table-body-fix-text font-size-12 text-nowrap new-font-12'>
                        {formatMessage({id: 'Reply rate'})}
                      </div>
                      <div className='mt-n1'>
                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: 'GLOBAL.TOOLTIP.CAMPAIGN.REPLY_RATE',
                          })}
                        />
                      </div>
                    </div>
                  </td>
                  <td className='campaign-cols align-middle'>
                    <div className='d-flex justify-content-md-start'>
                      <div>
                        <img
                          className='me-2'
                          src={toAbsoluteUrl('/media/campaign/calendar.svg')}
                          height='16px '
                          width='16px'
                        />
                      </div>
                      <div className='fw-bold text-black font-size-14 font-weight-600 text-nowrap new-font-14 text-color'>
                        {getDateValue(data?.createdAt?.toString())
                          ? getDateValue(data?.createdAt?.toString())
                          : data?.createdAt &&
                            DateTime.fromISO(data?.createdAt).toFormat('dd MMM, yyyy')}{' '}
                      </div>
                    </div>
                    <div className='d-flex'>
                      <div className='font-size-12 table-body-fix-text new-font-12'>
                        {formatMessage({id: 'Created At'})}
                      </div>
                      <div className='mt-n1'>
                        <ToolTipUI
                          tooltipText={
                            (getDateValue(data?.createdAt?.toString())
                              ? getDateValue(data?.createdAt?.toString())
                              : data?.createdAt &&
                                DateTime.fromISO(data?.createdAt).toFormat('dd MMM, yyyy')) +
                            ' ' +
                            (getTimeValue(data?.createdAt?.toString())
                              ? getTimeValue(data?.createdAt?.toString())
                              : data?.createdAt &&
                                DateTime.fromISO(data?.createdAt).toFormat('H:m:s'))
                          }
                        />
                      </div>
                    </div>
                  </td>
                  <td className='campaign-cols align-middle'>
                    <div className='d-flex flex-column justify-content-center align-items-center mt-n2'>
                      <div
                        className={`toggle-button ${
                          campaignStatusStr === campaignStatus.ACTIVE
                            ? 'active cursor-pointer'
                            : 'not-active cursor-pointer'
                        }`}
                        onClick={toggle}
                      >
                        <div className='slider'></div>
                      </div>
                      <div className='active-text font-size-12 font-weight-400 table-body-fix-text'>
                        {campaignStatusStr === campaignStatus.ACTIVE
                          ? 'Active'
                          : campaignStatusStr === campaignStatus.INACTIVE
                          ? 'Inactive'
                          : 'Draft'}
                      </div>
                    </div>
                  </td>
                  <td className='campaign-last-col align-middle'>
                    <div
                      className='d-flex justify-content-center mt-n3'
                      onClick={() => {
                        setOpenDeleteModal(!openDeleteModal)
                      }}
                    >
                      <div>
                        <img
                          className='cursor-pointer'
                          src={toAbsoluteUrl('/media/campaign/trash.svg')}
                          height='29px '
                          width='29px'
                        />
                      </div>
                      <DeleteConfirmationModal
                        showDeleteModal={openDeleteModal}
                        setDeleteShowModal={setOpenDeleteModal}
                        headingMessage={formatMessage({id: 'Delete Campaign'})}
                        description={formatMessage({
                          id: 'Are you sure you want to delete this campaign?',
                        })}
                        handleDelete={handleDeleteCampaignInside}
                        deleteLoading={deletingCampaignInside}
                      />
                    </div>
                    <div className='font-size-12 font-weight-400 table-body-fix-text text-center'>
                      {formatMessage({id: 'Remove'})}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
