import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'
import {Status} from '../../../core/_constants'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const CancelConfirmationModal = ({
  showGeneralModal,
  setGeneralShowModal,
  headingMessage,
  description,
  handleConfirm,
  generalLoading,
  buttonText,
  revokeSubscription,
}: {
  showGeneralModal: boolean
  setGeneralShowModal: Dispatch<SetStateAction<boolean>>
  headingMessage: string
  description?: string
  handleConfirm?: any
  generalLoading?: boolean
  buttonText: string
  revokeSubscription?: boolean
}) => {
  const {formatMessage} = useIntl()
  const {billingData} = useAuth()
  return (
    <Modal
      show={showGeneralModal}
      onHide={() => setGeneralShowModal(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <h2>{formatMessage({id: headingMessage})}</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          <div className='font-size-16 mb-2'>
            Your Current Plan:<span className='fw-bold ms-2'>{billingData?.productName}</span>
          </div>
          <div className='font-size-16 mb-2'>
            Your Current Billing Cycle:
            <span className='fw-bold ms-2'>
              {billingData?.planCycle &&
                billingData?.planCycle.charAt(0).toUpperCase() +
                  billingData?.planCycle.slice(1) +
                  'ly'}
            </span>
          </div>
          <div className='font-size-16 mb-2 '>
            {revokeSubscription ? 'Your Next Billing Date:' : 'Your Plan Expiry Date:'}
            <span className='fw-bold ms-2'>
              {isNaN(billingData?.current_period_start)
                ? '-'
                : new Date(billingData?.current_period_end * 1000).toDateString()}
            </span>
          </div>
          <p className='font-size-16'>
            {revokeSubscription || billingData?.status === Status.TRIAL ? (
              ''
            ) : (
              <>
                {formatMessage({id: 'You can still'})}{' '}
                <span className='fw-bold'>{formatMessage({id: 'restore'})}</span>{' '}
                {formatMessage({id: 'your subscription till the'})}{' '}
                <span className='fw-bold'>{formatMessage({id: 'expiry date'})}</span>.
              </>
            )}
          </p>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <GlobalButton
          buttonText={formatMessage({id: 'Cancel'})}
          buttonType={ButtonTypes.SECONDARY}
          onButtonClick={() => {
            setGeneralShowModal(false)
          }}
        />

        <GlobalButton
          buttonText={buttonText}
          buttonType={ButtonTypes.PRIMARY}
          onButtonClick={() => {
            handleConfirm()
          }}
          isLoading={generalLoading}
          isDisable={generalLoading}
        />
      </Modal.Footer>
    </Modal>
  )
}
