import React from 'react'
import {Modal} from 'react-bootstrap'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import TextInput from '../../../widgets/components/Input/TextInput'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {createCampaigns} from '../../core/_requests'
import {CampaignFormValue, newCampaignObject} from '../../core/_models'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'

export const NewCampaignModal = ({
  openCampaignModal,
  setOpenCampaignModal,
  campaignModalLoader,
  setCampaignName,
  setCampaignModalLoader,
  setNewCampaign,
}: {
  openCampaignModal: boolean
  setOpenCampaignModal: (value: boolean) => void
  campaignModalLoader: boolean
  campaignName: string
  setCampaignName: (value: string) => void
  setCampaignModalLoader: React.Dispatch<React.SetStateAction<boolean>>
  setNewCampaign: React.Dispatch<React.SetStateAction<newCampaignObject | undefined>>
}) => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()

  const campaignSchema = Yup.object().shape({
    campaignName: Yup.string()
      .min(3, formatMessage({id: 'Minimum 3 characters'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Campaign name is required'})),
  })

  const createCampaignName = async (values: CampaignFormValue) => {
    try {
      setCampaignModalLoader(true)
      const {
        data: {success, errors, data},
      } = await createCampaigns(values.campaignName)
      if (success) {
        setCampaignModalLoader(false)
        setNewCampaign(data)
        setCampaignName('')
        navigate(`create-flow/${data.campaignId}`)
      } else {
        setCampaignModalLoader(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setCampaignModalLoader(false)
      console.log(err)
    }
  }
  const initialValues = {
    campaignName: '',
  }

  return (
    <>
      <Modal
        show={openCampaignModal}
        onHide={() => setOpenCampaignModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Formik
          initialValues={initialValues}
          onSubmit={createCampaignName}
          validationSchema={campaignSchema}
        >
          {(formik) => {
            return (
              <Form>
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                  <div className='font-size-16 fw-bold'>
                    {formatMessage({id: 'Create new campaign'})}
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <TextInput
                      fieldType={'text'}
                      fieldName={'campaignName'}
                      formik={formik}
                      placeholder={formatMessage({id: 'Campaign name'})}
                      label={formatMessage({id: 'Enter name for campaign'})}
                      toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGN.NAME'})}
                      isStarRequired={true}
                      margin={'mb-0'}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className='pt-0 border-top-0'>
                  <div className='w-100 d-flex justify-content-end gap-3'>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Cancel'})}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={() => {
                        setOpenCampaignModal(false)
                        setCampaignName('')
                      }}
                    />
                    <GlobalButton
                      buttonText={formatMessage({id: 'Create'})}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={formik.handleSubmit}
                      isDisable={formik.isSubmitting || !formik.isValid}
                      isLoading={campaignModalLoader}
                    />
                  </div>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
