import {getBillingAccess, getBillingData} from '../modules/profile/core/_requests'
import {toast} from 'react-toastify'

const useGetBillingData = () => {
  const getBillingDetailsAPI = async () => {
    try {
      const {
        data: {data: values, success, errors},
      } = await getBillingData()
      if (success) {
        return values
      } else {
        errors?.forEach((error: string) => {
          toast.error(error)
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const onAccessBillingPortal = async () => {
    try {
      const {
        data: {data: value, success, errors},
      } = await getBillingAccess()
      if (success) {
        return value
      } else {
        errors.forEach((error: string) => {
          toast.error(error)
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {getBillingDetailsAPI, onAccessBillingPortal}
}
export default useGetBillingData
