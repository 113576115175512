import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import '../styles/index.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers/AssetHelpers'
import TextInput from '../../widgets/components/Input/TextInput'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import {SelectInput} from '../../widgets/components/Input/SelectInput'
import {profileData, updateProfileInfo, deleteUserPermanently} from '../core/_requests'
import {userInitialValues} from '../core/_constants'
import {useAuth} from '../../auth'
import {ChangePasswordModal} from '../../auth/components/ChangePasswordModal'
import {forgotPassword} from '../../auth/core/_requests'
import {toast} from 'react-toastify'
import {Spinner} from '../../widgets/components/General/Spinner'
import {LinkedInModal} from '../../campaign/components/view/LinkedInModal'
import {checkLinkedIn} from '../../campaign/core/_requests'
import {DisconnectModal} from '../../campaign/components/view/DisconnectModal'
import {ReverseCounterModal} from '../../campaign/components/ReverseCounterModal'
import {ConfirmChangePasswordModal} from '../../auth/components/ConfirmChangePasswordModal'
import {DeleteUserConfirmationModal} from './DeleteUserConfirmationModal'
import {useNavigate} from 'react-router-dom'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export function User({
  setImgName,
  imgName,
  getApiLoading,
  countryOptions,
  linkedinEmail,
  runUserDetailsApi,
  serverProvisioned,
  getProfileInfo,
  linkedinName,
  linkedinUrl,
  userUpdatedAt,
}: {
  setImgName: Dispatch<SetStateAction<string | undefined>>
  imgName: string | undefined
  getApiLoading: boolean
  countryOptions: any
  linkedinEmail: string
  runUserDetailsApi: boolean
  serverProvisioned: boolean
  getProfileInfo: () => void
  linkedinName: string
  linkedinUrl: string
  userUpdatedAt: string
}) {
  const {formatMessage} = useIntl()
  const [modelStatus, setModelStatus] = useState<boolean>(false)
  const {currentUser, setCurrentUser, logout} = useAuth()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [changePasswordLoading, setChangePasswordLoading] = useState(false)
  const [deleteUserLoading, setDeleteUserLoading] = useState(false)
  const [, setHasErrors] = useState<boolean | undefined>()
  const [openLinkedInModal, setOpenLinkedInModal] = useState<boolean>(false)
  const [openLinkedIn, setOpenLinkedIn] = useState<boolean>(true)
  const [openDisconnectModal, setOpenDisconnectModal] = useState<boolean>(false)
  const [showCounterModal, setShowCounterModal] = useState<boolean>(false)
  const [buttonLoadingCounter, setButtonLoadingCounter] = useState<boolean>(false)
  const [functionCallBool, setFunctionCallBool] = useState<boolean>(false)
  const [confirmModalShow, setConfirmModalShow] = useState(false)
  const [deleteUserModalShow, setDeleteUserModalShow] = useState(false)
  const specificGMTTime = new Date(userUpdatedAt)
  specificGMTTime.setSeconds(specificGMTTime.getSeconds() + 300)
  const currentGMTTime = new Date()
  const navigate = useNavigate()
  const timeDifferenceInMilliseconds: number = specificGMTTime.getTime() - currentGMTTime.getTime()
  const timeDifferenceInSeconds: number = Math.floor(timeDifferenceInMilliseconds / 1000)
  const openSuitableModal = () => {
    if (!serverProvisioned) {
      setShowCounterModal(true)
    } else {
      checkButton()
    }
  }
  const userSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, formatMessage({id: 'Minimum 3 characters'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'First name is required'})),
    lastName: Yup.string()
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Last name is required'}))
      .nullable(),
    email: Yup.string()
      .email(formatMessage({id: 'Invalid email format'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Email is required'})),
    contact: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 digits'}))
      .max(10, formatMessage({id: 'Maximum 10 digits'}))
      .nullable(),
    country: Yup.string()
      .required(formatMessage({id: 'Country is required'}))
      .nullable(),
  })

  const onSubmit = async (values: any) => {
    try {
      setLoading(true)
      let {
        data: {success, errors},
      } = await updateProfileInfo({...values, countryId: parseInt(values.country)})

      if (success) {
        const {
          data: {success, data, errors},
        } = await profileData()
        if (success) {
          setCurrentUser({
            ...currentUser,
            firstName: data.firstName,
            profileImg: data.profileImage,
            lastName: data.lastName,
          })
          setImgName(data.profileImage)
          userInitialValues.firstName = data.firstName
          userInitialValues.lastName = data.lastName
          userInitialValues.email = data.email
          userInitialValues.contact = data.contact!
          userInitialValues.country = data.countryId!
          userInitialValues.profileImageId = data.profileImageId!
        } else if (errors) {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }

        setLoading(false)
        toast.success(formatMessage({id: 'Profile updated successfully'}))
      } else if (errors) {
        setLoading(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const getForgotedPassword = async () => {
    if (currentUser?.email) {
      try {
        setChangePasswordLoading(true)
        const {
          data: {success},
        } = await forgotPassword(currentUser?.email)
        if (success) {
          setConfirmModalShow(false)
          setShowModal(true)
          setHasErrors(false)
        } else {
          setHasErrors(true)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setChangePasswordLoading(false)
      }
    }
  }
  const deleteUser = async () => {
    try {
      setDeleteUserLoading(true)
      const {
        data: {success},
      } = await deleteUserPermanently()
      if (success) {
        setConfirmModalShow(false)
        logout()
        navigate('/login')
        setDeleteUserLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setDeleteUserLoading(false)
    }
  }

  const handleOpen = () => {
    setModelStatus(true)
  }
  const handleClose = () => {
    setModelStatus(false)
  }
  const checkButton = async () => {
    try {
      const {
        data: {success, errors, data},
      } = await checkLinkedIn()
      if (success) {
        if (!data.isLinkedInCredsAvailable) {
          setOpenLinkedIn(true)
          setOpenLinkedInModal(true)
          getProfileInfo()
        } else {
          setOpenLinkedIn(false)
          getProfileInfo()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (runUserDetailsApi) {
      getProfileInfo()
    }
  }, [runUserDetailsApi])
  useEffect(() => {
    if (serverProvisioned && showCounterModal) {
      setFunctionCallBool(true)
    }
  })
  useEffect(() => {
    if (serverProvisioned && linkedinName) checkButton()
  }, [serverProvisioned])
  return (
    <>
      {getApiLoading && <Spinner />}
      <div className='g-5 g-xxl-8 p-8 settings-container'>
        <div className='col-xl-12 d-md-flex mt-0'>
          {/* file upload  */}
          {/* <div>
            <div
              className={`card position-relative d-flex justify-content-center`}
              onClick={handleOpen}
            >
              <DisplayImage imgName={imgName} width='100%' fit='contain' alt='profile' />
            </div>
          </div> */}

          <div className='w-100'>
            <Formik
              initialValues={userInitialValues}
              validationSchema={userSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                return (
                  <Form>
                    <div className='d-md-flex col-md-12 flex-wrap'>
                      <TextInput
                        fieldType={'text'}
                        label={formatMessage({id: 'First Name'})}
                        fieldName={'firstName'}
                        formik={formik}
                        placeholder=''
                        margin='me-6'
                        toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.FIRST_NAME'})}
                        width={5}
                        isStarRequired={true}
                      />
                      <TextInput
                        fieldType={'text'}
                        label={formatMessage({id: 'Last Name'})}
                        fieldName={'lastName'}
                        formik={formik}
                        placeholder=''
                        margin='me-4'
                        toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.LAST_NAME'})}
                        width={5}
                        isStarRequired={true}
                      />
                      <TextInput
                        fieldType={'email'}
                        fieldName={'email'}
                        formik={formik}
                        placeholder={''}
                        label={formatMessage({id: 'Email Address'})}
                        toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.EMAIL'})}
                        width={5}
                        isStarRequired={true}
                        margin='me-6'
                      />
                      <TextInput
                        fieldType={'number'}
                        fieldName={'contact'}
                        formik={formik}
                        placeholder={''}
                        margin='me-4'
                        label={formatMessage({id: 'Contact Number'})}
                        toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.CONTACT_NUMBER'})}
                        width={5}
                        isStarRequired={false}
                      />
                      <SelectInput
                        label={formatMessage({id: 'User’s Country'})}
                        fieldName={'country'}
                        placeholder={formatMessage({id: 'Select the Country'})}
                        formik={formik}
                        toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.COUNTRY'})}
                        options={countryOptions}
                        width={5}
                        margin={'me-6'}
                      />
                    </div>
                    <div className='linkedin d-flex flex-column col-12 mb-10'>
                      <div className='d-flex'>
                        <img
                          src={toAbsoluteUrl('/media/svg/social-logos/linkedin.svg')}
                          height='25px'
                          width='25px'
                          alt='img_icon'
                          className='rounded-1'
                        />
                        <h3 className='ms-3'>{formatMessage({id: 'LinkedIn credentials'})}</h3>
                      </div>
                      <div className='font-size-14 text-muted fw-semibold my-3'>
                        {formatMessage({
                          id: 'Your LinkedIn credentials are fully encrypted and required to perform automated actions in a background. We do not have direct access and control over your LinkedIn account.',
                        })}
                      </div>
                      {openLinkedIn ? (
                        <div className='d-flex mt-3 text-black'>
                          <div className='d-flex flex-row justify-content-end w-100 me-5'>
                            <GlobalButton
                              buttonText={formatMessage({id: 'Connect LinkedIn Account'})}
                              buttonType={ButtonTypes.PRIMARY}
                              onButtonClick={() => {
                                serverProvisioned ? checkButton() : openSuitableModal()
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex mt-3 text-black'>
                          <div className='d-flex align-items-center col-12'>
                            <div className='col-6'>
                              <div className='d-flex align-items-center'>
                                <img
                                  src={toAbsoluteUrl('/media/svg/social-logos/linkedin.svg')}
                                  height='18px'
                                  width='18px'
                                  alt='img_icon'
                                  className='rounded-1 me-5'
                                />
                                <a href={linkedinUrl} target='_blank'>
                                  {linkedinName}
                                </a>
                              </div>
                              <div className='me-4 text-color'>{linkedinEmail}</div>
                            </div>
                            <div className='d-flex justify-content-end col-4'>
                              <GlobalButton
                                buttonText={formatMessage({id: 'Disconnect'})}
                                buttonType={ButtonTypes.SECONDARY}
                                onButtonClick={() => setOpenDisconnectModal(true)}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {process.env.REACT_APP_DEV_ENV === 'dev' && (
                      <div>
                        <GlobalButton
                          buttonText={formatMessage({id: 'Delete User'})}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => setDeleteUserModalShow(true)}
                          isDisable={deleteUserLoading}
                        />
                      </div>
                    )}
                    <div className='d-md-flex justify-content-end align-items-end button-margin'>
                      <div className='d-grid me-md-3 mt-5 pt-2 mb-4 mb-md-0'>
                        <GlobalButton
                          buttonText={formatMessage({id: 'Change password'})}
                          buttonType={ButtonTypes.SECONDARY}
                          onButtonClick={() => setConfirmModalShow(true)}
                          isDisable={changePasswordLoading}
                        />
                      </div>
                      <GlobalButton
                        buttonText={formatMessage({id: 'Save Changes'})}
                        buttonType={ButtonTypes.PRIMARY}
                        isLoading={loading}
                        isDisable={formik.isSubmitting || !formik.isValid}
                        onButtonClick={formik.handleSubmit}
                      />
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
        <ConfirmChangePasswordModal
          confirmModalShow={confirmModalShow}
          setConfirmModalShow={setConfirmModalShow}
          getForgotedPassword={getForgotedPassword}
          changePasswordLoading={changePasswordLoading}
        />

        <DeleteUserConfirmationModal
          confirmModalShow={deleteUserModalShow}
          setDeleteUserModalShow={setDeleteUserModalShow}
          deleteUser={deleteUser}
          deleteUserLoading={deleteUserLoading}
        />

        <ChangePasswordModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          setShowModal={setShowModal}
        />
        <LinkedInModal
          openLinkedInModal={openLinkedInModal}
          setOpenLinkedIn={setOpenLinkedIn}
          setOpenLinkedInModal={setOpenLinkedInModal}
          getProfileInfo={getProfileInfo}
        ></LinkedInModal>

        <DisconnectModal
          openDisconnectModal={openDisconnectModal}
          setOpenDisconnectModal={setOpenDisconnectModal}
          setOpenLinkedIn={setOpenLinkedIn}
          getProfileInfo={getProfileInfo}
        ></DisconnectModal>

        <ReverseCounterModal
          showCounterModal={showCounterModal}
          setShowCounterModal={setShowCounterModal}
          loadingBool={buttonLoadingCounter}
          timeGap={timeDifferenceInSeconds}
          getProfileInfo={getProfileInfo}
          functionCallBool={functionCallBool}
        />
      </div>
    </>
  )
}
