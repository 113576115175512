import {DateTime} from 'luxon'

export const useTimeZone = () => {
  const getTimeZoneValue = (date: any, timeZone?: any) => {
    if (timeZone) {
      const time_format = localStorage.getItem('timeFormat')
      if (timeZone) {
        const hours = Math.floor(timeZone)
        const minutes = (timeZone - hours) * 60
        const formattedTimezone = `UTC${hours >= 0 ? '+' : '-'}${Math.abs(hours)
          .toString()
          .padStart(2, '0')}:${Math.abs(minutes).toString().padStart(2, '0')}`
        const dateTime = DateTime.fromISO(date)
          .setZone(timeZone)
          .toISO({includeOffset: false, suppressMilliseconds: true})
        if (time_format) {
          const converted_time = DateTime.fromISO(dateTime!).toFormat(time_format)
          return converted_time
        } else {
          const converted_time = DateTime.fromISO(dateTime!).toLocaleString(DateTime.TIME_SIMPLE)
          return converted_time
        }
      } else {
        if (time_format) {
          return DateTime.fromISO(date).toFormat(time_format)
        } else {
          return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)
        }
      }
    } else {
      const time_zone = localStorage.getItem('iana_title')
      const time_format = localStorage.getItem('timeFormat')
      if (time_zone) {
        const dateTime = DateTime.fromISO(date)
          .setZone(time_zone)
          .toISO({includeOffset: false, suppressMilliseconds: true})
        if (time_format) {
          const converted_time = DateTime.fromISO(dateTime!).toFormat(time_format)
          return converted_time
        } else {
          const converted_time = DateTime.fromISO(dateTime!).toLocaleString(DateTime.TIME_SIMPLE)
          return converted_time
        }
      } else {
        if (time_format) {
          return DateTime.fromISO(date).toFormat(time_format)
        } else {
          return DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE)
        }
      }
    }
  }

  return {getTimeZoneValue}
}
