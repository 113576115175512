import {Formik} from 'formik'
import React, {Dispatch, SetStateAction, useState} from 'react'
import {Form, Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import TextInput from '../../widgets/components/Input/TextInput'
import * as Yup from 'yup'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {addUser, editUser} from '../core/_requests'
import {Roles} from '../core/_constants'
import {toast} from 'react-toastify'
import {Spinner} from '../../widgets/components/General/Spinner'
import {ButtonTypes} from '../../widgets/core/_constants'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  roles: '',
}

const UserModal = ({
  title,
  buttonText,
  addModal,
  setAddModal,
  flag,
  userDetails,
  getUserList,
  getPendingUsers,
  getUserApiSpinner,
  billingData,
}: {
  title: string
  buttonText: string
  addModal: boolean
  setAddModal: Dispatch<SetStateAction<boolean>>
  flag: boolean
  userDetails?: any
  getUserList: any
  getPendingUsers?: any
  getUserApiSpinner?: boolean
  billingData: any
}) => {
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState(false)
  const userSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(1, formatMessage({id: 'Minimum 1 character is required'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Full name is required'}))
      .nullable(),
    lastname: Yup.string()
      .min(1, formatMessage({id: 'Minimum 1 character is required'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Last name is required'}))
      .nullable(),
    email: Yup.string()
      .email(formatMessage({id: 'Invalid email format'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Email is required'})),
    roles: Yup.string()
      .required(formatMessage({id: 'Role is required'}))
      .nullable(),
  })

  const onSubmit = async (values: any) => {
    try {
      setLoading(true)
      if (!flag) {
        const payload = {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values.email,
          role: values.roles,
        }
        const {
          data: {success, errors},
        } = await addUser(payload)
        if (success) {
          setLoading(false)
          setAddModal(!addModal)
          getPendingUsers()
          toast.success(formatMessage({id: 'User Invited'}))
        } else {
          setLoading(false)
          setAddModal(!addModal)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        const payload = {
          firstName: values.firstname,
          lastName: values.lastname,
          email: values.email,
          role: values.roles,
        }

        const {
          data: {success, errors},
        } = await editUser(userDetails?.userId, payload)
        if (success) {
          setLoading(false)
          setAddModal(!addModal)
          toast.success(formatMessage({id: 'Role changed successfully'}))
          getUserList()
        } else {
          setLoading(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  if (flag) {
    initialValues.firstname = userDetails?.firstName
    initialValues.lastname = userDetails?.lastName
    initialValues.email = userDetails?.email
    initialValues.roles = userDetails?.role
  } else {
    initialValues.firstname = ''
    initialValues.lastname = ''
    initialValues.email = ''
    initialValues.roles = ''
  }
  return (
    <>
      <div>
        <Modal
          show={addModal}
          onHide={() => setAddModal(!addModal)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          {getUserApiSpinner ? <Spinner placement={true} /> : null}
          <Modal.Header closeButton>
            <h2>{title}</h2>
          </Modal.Header>
          <Formik initialValues={initialValues} validationSchema={userSchema} onSubmit={onSubmit}>
            {(formik) => {
              return (
                <Form>
                  <Modal.Body>
                    <div className='row'>
                      <div className='col-6'>
                        <TextInput
                          fieldType={'text'}
                          fieldName={'firstname'}
                          formik={formik}
                          placeholder={formatMessage({id: 'Enter First Name'})}
                          label={formatMessage({id: 'First Name'})}
                          isTooltipNotRequired={true}
                          isDisabled={flag}
                        />
                      </div>
                      <div className='col-6'>
                        <TextInput
                          fieldType={'text'}
                          fieldName={'lastname'}
                          formik={formik}
                          placeholder={formatMessage({id: 'Enter Last Name'})}
                          label={formatMessage({id: 'Last name'})}
                          isTooltipNotRequired={true}
                          isDisabled={flag}
                        />
                      </div>
                    </div>

                    <TextInput
                      fieldType={'text'}
                      fieldName={'email'}
                      formik={formik}
                      placeholder={formatMessage({id: 'Enter email'})}
                      label={formatMessage({id: 'Email Address'})}
                      isTooltipNotRequired={true}
                      isDisabled={flag}
                    />
                    <div className='col-12 mb-4'>
                      <label className='ps-0 font-size-13'>
                        {formatMessage({id: 'Assign Role'})}
                        <ToolTipUI
                          tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.ASSIGN_ROLE'})}
                        />
                      </label>
                    </div>
                    <div className='row'>
                      <div className='col-6 d-flex'>
                        <div className='col-5'>
                          <TextInput
                            fieldName={'roles'}
                            formik={formik}
                            customText={formatMessage({id: 'Admin'})}
                            placeholder=''
                            fieldType={'radio'}
                            isCheckbox={true}
                            isWidthNotRequired={false}
                            margin='0'
                            isRadio={true}
                            value={Roles.ADMIN}
                          />
                        </div>
                        <div className='col-1'>
                          {' '}
                          <ToolTipUI
                            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.ADMIN'})}
                          />
                        </div>
                      </div>
                      <div className='col-6 d-flex'>
                        <div className='col-5'>
                          <TextInput
                            fieldName={'roles'}
                            formik={formik}
                            customText={formatMessage({id: 'Member'})}
                            placeholder=''
                            fieldType={'radio'}
                            isCheckbox={true}
                            isWidthNotRequired={false}
                            margin='0'
                            isRadio={true}
                            value={Roles.MEMBER}
                          />
                        </div>
                        <div className='col-1'>
                          {' '}
                          <ToolTipUI
                            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.MEMBER'})}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className='d-flex flex-wrap gap-3'>
                      <GlobalButton
                        buttonText={formatMessage({id: 'Discard'})}
                        buttonType={ButtonTypes.SECONDARY}
                        onButtonClick={() => formik.resetForm()}
                      />

                      <GlobalButton
                        buttonText={buttonText}
                        buttonType={ButtonTypes.PRIMARY}
                        isLoading={loading}
                        isDisable={formik.isSubmitting || !formik.isValid}
                        onButtonClick={formik.handleSubmit}
                      />
                    </div>
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        </Modal>
      </div>
    </>
  )
}

export default UserModal
