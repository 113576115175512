import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ButtonTypes} from '../core/_constants'
import {GlobalButton} from './UI/GlobalButton'

export const GeneralConfirmationModal = ({
  showGeneralModal,
  setGeneralShowModal,
  headingMessage,
  description,
  handleConfirm,
  generalLoading,
  buttonText,
}: {
  showGeneralModal: boolean
  setGeneralShowModal: Dispatch<SetStateAction<boolean>>
  headingMessage: string
  description: string
  handleConfirm: any
  generalLoading: boolean
  buttonText: string
}) => {
  const {formatMessage} = useIntl()

  return (
    <Modal
      show={showGeneralModal}
      onHide={() => setGeneralShowModal(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <h2>{formatMessage({id: headingMessage})}</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          {formatMessage({
            id: description,
          })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <GlobalButton
          buttonText={formatMessage({id: 'Cancel'})}
          buttonType={ButtonTypes.SECONDARY}
          onButtonClick={() => {
            setGeneralShowModal(false)
          }}
        />

        <GlobalButton
          buttonText={buttonText}
          buttonType={ButtonTypes.PRIMARY}
          onButtonClick={() => {
            handleConfirm()
          }}
          isLoading={generalLoading}
          isDisable={generalLoading}
        />
      </Modal.Footer>
    </Modal>
  )
}
