import {useIntl} from 'react-intl'
import '../styles/index.scss'
export const Footer = (props: any) => {
  const {formatMessage} = useIntl()
  return (
    <div
      className={`info d-flex font-size-${props.font} mt-auto m-24px ${
        props.font == '10' ? 'w-100' : ''
      }`}
    >
      <div className={`${props.color == 'gray' ? 'text-gray-700' : 'text-white'} me-auto`}>
        &copy; {new Date().getFullYear()} {formatMessage({id: 'DripFunnel'})}
      </div>
      <a
        href='https://www.dripfunnel.com/privacy-policy/'
        target='_blank'
        className={`me-24px ${props.color == 'gray' ? 'text-gray-700' : 'text-white'}`}
      >
        {formatMessage({id: 'Privacy Policy'})}
      </a>
      <a
        href='https://www.dripfunnel.com/terms-of-use/'
        target='_blank'
        className={`me-24px ${props.color == 'gray' ? 'text-gray-700' : 'text-white'}`}
      >
        {formatMessage({id: 'Terms of use'})}
      </a>
      <a
        href='mailto:contact@dripfunnel.com'
        target='_blank'
        className={`${props.color == 'gray' ? 'text-gray-700' : 'text-white'}`}
      >
        {formatMessage({id: 'Support'})}
      </a>
    </div>
  )
}
