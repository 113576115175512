import {useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {verifyRegister} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

export const VerifyRegistration = () => {
  const {registerToken} = useParams()
  const {saveAuth, setUserToken, setNewTeam, logout, auth} = useAuth()
  const navigate = useNavigate()
  const {formatMessage} = useIntl()

  useEffect(() => {
    const verifyRegistration = async (registerToken: any) => {
      try {
        const {
          data: {data: token, success, data, errors},
        } = await verifyRegister(registerToken)
        if (success) {
          await saveAuth(token)
          setUserToken(data.token)
          setNewTeam(false)
          navigate('/onboarding/')
        } else {
          errors.forEach((error: string) => {
            if (error === 'VERIFY_SIGNUP_AGAIN') {
              toast.error(
                formatMessage({
                  id: 'Your invite has expired. Please resend the verification email.',
                })
              )
            } else {
              toast.error(formatMessage({id: error}))
            }
          })
          await saveAuth(undefined)
          navigate('/')
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (registerToken) {
      if (auth?.token) {
        logout()
        document.location.reload()
      } else {
        verifyRegistration(registerToken)
      }
    } else {
      console.log('token is not rendering')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
