import '../styles/onboarding.scss'

export const LoadingPage = () => {
  return (
    <div
      className='w-full d-flex justify-content-center align-content-center'
      style={{height: '84vh'}}
    >
      <span className='indicator-progress loader-container'>
        <span className='spinner-border spinner-border-sm align-middle ms-2 loader-size'></span>
      </span>
    </div>
  )
}
