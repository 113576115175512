import {useIntl} from 'react-intl'
import '../styles/_activity.scss'
// @ts-ignore
import {ActivityCard} from './ActivityCard'
import {useEffect, useRef, useState} from 'react'
import {getAllCampaign, getAllUsersOfTeam, getUserActivityLogs} from '../core/_requests'
import {Activity, ActivityByDate} from '../core/models/Activity'
import {Spinner} from '../../widgets/components/General/Spinner'
import NoDataPage from '../../widgets/components/NoDataPage'
import {toast} from 'react-toastify'
import {Roles, profileBreadCrumbs} from '../../profile/core/_constants'
import {PageHeading} from '../../template/components/PageHeading'
import {useAuth} from '../../../../app/modules/auth'
import {getUserList} from '../../profile/core/_requests'
import {DateTime} from 'luxon'
import {useDateFormat} from '../../../hooks/useDateFormat'

export function ActivityLog() {
  const {formatMessage} = useIntl()
  const {currentUser, teamLength, setTeamLength} = useAuth()
  const [page, setPage] = useState<number>(1)
  const [totalActivityCount, setTotalActivityCount] = useState<number>(0)
  const [recentActivities, setRecentActivities] = useState<Activity[]>([])
  const [userList, setUserList] = useState<any[]>([])
  const [campaignList, setCampaignList] = useState<any[]>([])
  const [selectedUserId, setSelectedUserId] = useState<number>(
    currentUser?.role !== Roles.MEMBER ? 0 : currentUser?.userId ?? 0
  )
  const [selectedCampaignId, setSelectedCampaignId] = useState<number>(0)
  const [pageLength, setPageLength] = useState<number>(10)
  const [loader, setLoader] = useState<boolean>(true)
  const [cardLoader, setCardLoader] = useState<boolean>()
  const {getDateValue} = useDateFormat()
  const [activityByDate, setActivityByDate] = useState<ActivityByDate>({})

  useEffect(() => {
    if ((recentActivities.length < totalActivityCount || page === 1) && pageLength === 10) {
      const getActivityLogs = async () => {
        try {
          if (page === 1) {
            setLoader(true)
          }
          if (page > 1) {
            setCardLoader(true)
          }
          const {
            data: {data: data, success, errors},
          } = await getUserActivityLogs(page, selectedCampaignId, selectedUserId)
          if (success) {
            const {activities, totalActivityCount} = data
            setPageLength(activities.length)
            setTotalActivityCount(totalActivityCount)
            setRecentActivities((prevData: any) => [...prevData, ...activities])
            setLoader(false)
            setCardLoader(false)
          } else {
            setLoader(false)
            setCardLoader(false)
            errors.forEach((error: string) => {
              toast.error(formatMessage({id: error}))
            })
          }
        } catch (error: any) {
          setCardLoader(false)
          setLoader(false)
          console.log(error)
        }
      }
      getActivityLogs()
    }
  }, [page, selectedUserId, selectedCampaignId])

  const containerRef = useRef<any>(null)

  useEffect(() => {
    const container = containerRef.current
    const handleScroll = () => {
      if (
        container &&
        container.scrollTop + container.clientHeight >= container.scrollHeight - 10
      ) {
        setPage((prevPage) => prevPage + 1)
      }
    }
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [recentActivities])

  const getAllUsers = async () => {
    try {
      const {
        data: {data: data, success, errors},
      } = await getAllUsersOfTeam()
      if (success) {
        setUserList([{userId: 0, linkedin_name: 'All Accounts', linkedin_url: 'linkedin_url'}])
        const newData = data.map((user: any) => ({
          userId: user.userId,
          linkedin_name: user.linkedin_name,
          linkedin_url: user.linkedin_url,
        }))
        setUserList((prev: any) => [...prev, ...newData])
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const getAllCampaignList = async () => {
    try {
      const {
        data: {data: data, success, errors},
      } = await getAllCampaign()
      if (success) {
        setCampaignList([{campaignId: 0, campaignName: 'All campaign'}])
        const newData = data.map((campaign: any) => ({
          campaignId: campaign?.campaignId,
          campaignName: campaign?.name,
        }))
        setCampaignList((prev) => [...prev, ...newData])
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllCampaignList()
    if (currentUser && currentUser.role !== Roles.MEMBER) {
      getAllUsers()
    }
  }, [currentUser])

  const getTeamUsers = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      setTeamLength(data.length)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTeamUsers()
  }, [])

  const handleUserList = (e: any) => {
    setRecentActivities([])
    setTotalActivityCount(0)
    setPageLength(10)
    setSelectedUserId(parseInt(e.target.value))
    setPage(1)
  }

  const handleCampaignList = (e: any) => {
    setRecentActivities([])
    setTotalActivityCount(0)
    setPageLength(10)
    setSelectedCampaignId(parseInt(e.target.value))
    setPage(1)
  }

  const groupActivitiesByDate = (activities: Activity[]) => {
    const groupedActivities: ActivityByDate = {}

    activities.forEach((activity: Activity) => {
      const createdAt =
        getDateValue(activity?.createdAt?.toString()) ||
        DateTime.fromISO(activity?.createdAt?.toString()).toLocaleString(DateTime.DATE_MED)

      if (!groupedActivities[createdAt]) {
        groupedActivities[createdAt] = []
      }

      groupedActivities[createdAt].push(activity)
    })

    return groupedActivities
  }

  useEffect(() => {
    setActivityByDate(groupActivitiesByDate(recentActivities))
  }, [recentActivities])

  return (
    <>
      <div>
        <div className='card p-md-8 p-4'>
          <PageHeading
            title={formatMessage({id: 'Activity Log'})}
            description={formatMessage({
              id: 'An activity log tracks user actions and system events in chronological order, offering a detailed history of changes.',
            })}
            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.ACTIVITY.LOG.TITLE'})}
            breadcrumbs={profileBreadCrumbs}
            breadcrumbTitle={formatMessage({id: 'Activity Log'})}
          ></PageHeading>
        </div>
        <div className='settings-profile-container me-0 mt-4'>
          <div className='d-md-flex justify-content-md-end gap-4 mb-4'>
            {(currentUser?.role === Roles.OWNER || currentUser?.role === Roles.ADMIN) &&
              teamLength > 1 && (
                <div className='w-md-25'>
                  <select
                    name=''
                    id=''
                    className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer pb-2'
                    value={selectedUserId}
                    onChange={(e) => handleUserList(e)}
                  >
                    {userList?.map(
                      ({
                        userId,
                        linkedin_name,
                        linkedin_url,
                      }: {
                        userId: number
                        linkedin_name: string
                        linkedin_url: string
                      }) =>
                        !!linkedin_url && (
                          <option value={userId} key={userId}>
                            {linkedin_name}
                          </option>
                        )
                    )}
                  </select>
                </div>
              )}
            <div className='w-md-25'>
              <select
                name=''
                id=''
                className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer py-1'
                value={selectedCampaignId}
                onChange={(e) => handleCampaignList(e)}
              >
                {campaignList?.map(({campaignId, campaignName}: any) => (
                  <option value={campaignId} key={campaignId}>
                    {campaignName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='card row'>
            {loader && <Spinner background={true} />}
            <div className='p-8'>
              {!recentActivities.length ? (
                <div className='no-data-radius color-white'>
                  <NoDataPage
                    noDataText={formatMessage({
                      id: 'There are no activities performed by the platform so far. Please start by creating campaigns to perform automated activity.',
                    })}
                  />
                </div>
              ) : (
                <div className='activity-log-container' ref={containerRef}>
                  {Object.keys(activityByDate).map((date: string, i: number) => (
                    <>
                      <div
                        className={`pill-color w-125px rounded-pill d-flex justify-content-center my-2`}
                      >
                        <p className='font-size-10 font-weight-500 m-0 text-white py-2'>{date}</p>
                      </div>
                      <div className='ms-14'>
                        {activityByDate[date].map((activity: Activity, index: number) => (
                          <ActivityCard
                            key={index}
                            activity={activity}
                            index={index}
                            length={activityByDate[date].length}
                            isCampaign={false}
                            cardLoader={cardLoader}
                            indexNumber={i}
                            ObjectLength={Object.keys(activityByDate).length}
                          />
                        ))}
                      </div>
                    </>
                  ))}
                  {cardLoader && (
                    <div className='w-100 d-md-flex justify-content-md-center d-flex justify-content-start mh-50'>
                      <div className='d-flex align-items-center me-2 position-relative'>
                        <Spinner isActivityLoader={true} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
