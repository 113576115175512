import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLinkedIn} from 'react-linkedin-login-oauth2'
import {useGoogleLogin} from '@react-oauth/google'
import axios from 'axios'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {useUtmValidator} from '../../../hooks/useUtmValidator'
import {useFetchCountryAndTimeZone} from '../../../hooks/useFetchCountryAndTimeZone'

const SocialLoginButtons = ({localStorageReferral}: {localStorageReferral?: string}) => {
  const {handleUtmEvents, utmValues} = useUtmValidator()
  const {state} = useLocation()
  const navigate = useNavigate()
  const {saveAuth, setUserToken, setNewTeam} = useAuth()
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState(false)
  const [, setErrorsArray] = useState<string[]>()
  const {fetchCountryAndTimeZone} = useFetchCountryAndTimeZone()

  useEffect(() => {
    handleUtmEvents()
  }, [])

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true)
      const locationData = await fetchCountryAndTimeZone()

      const {
        data: {data: googleData, errors, success},
      } = await axios.post(`${process.env.REACT_APP_BASE_API_URL}/auth/social-signin`, {
        type: 'GOOGLE',
        token: response.access_token,
        inviteToken: state?.inviteToken || undefined,
        referral: localStorageReferral,
        ...utmValues,
        locale: 'en_US',
        dateFormat: 'MMM DD, YYYY',
        timeFormat: 'hh:mm:ss a',
        timeZone: locationData?.timeZone || 5.5,
        countryId: locationData?.countryId || 103,
      })

      if (success) {
        console.log(locationData?.timeZone)
        setLoading(false)
        await saveAuth(googleData)
        setUserToken(googleData.token)
        console.log(googleData.token)
        if (!googleData.chargebeePlanPresent) {
          setNewTeam(false)
        }
        navigate('/')
      } else {
        setLoading(false)
        setErrorsArray(errors)
        await saveAuth(undefined)
      }
    },
  })

  return (
    <>
      {/* begin::Col */}
      <div className='col-12'>
        <button
          type='button'
          onClick={() => googleLogin()}
          className='btn btn-flex btn-light-primary flex-center text-nowrap py-3 px-5 w-100 h-48px border border-primary'
          disabled={loading}
        >
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-24px google-signin-button me-4'
          />
          {!loading && (
            <span className='indicator-label font-size-14'>
              {formatMessage({id: 'Continue with Google'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {formatMessage({id: 'Please wait...'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Col */}
    </>
  )
}

export default SocialLoginButtons
