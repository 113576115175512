/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import '../../styles/sidebar.scss'
import {toAbsoluteUrl} from '../../../helpers'
const SidebarFooter = () => {
  const {formatMessage} = useIntl()
  const {logout} = useAuth()
  const navigate = useNavigate()

  const handleLogout = async () => {
    navigate('/login')
    logout()
  }
  return (
    <div
      className='app-sidebar-footer d-flex flex-column justify-content-end px-8 mb-10 '
      id='kt_app_sidebar_footer'
    >
      <div className='app-sidebar-logo-default text-center'>
        <div className='text-white me-auto'>
          &copy; {new Date().getFullYear()} {formatMessage({id: 'DripFunnel'})}
        </div>
      </div>
      <div className='app-sidebar-logo-minimize'></div>
    </div>
  )
}

export {SidebarFooter}
