import {AddressElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Modal} from 'react-bootstrap'
import {useAuth} from '../../auth'
import {toast} from 'react-toastify'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const PaymentCheckoutElement = ({
  paymentClientSecret,
  getCardDetails,
  setRunCardDetailsApi,
  getBillingDetails,
}: {
  paymentClientSecret: string
  getCardDetails: () => void
  setRunCardDetailsApi: React.Dispatch<React.SetStateAction<boolean>>
  getBillingDetails: () => void
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const {formatMessage} = useIntl()
  const {setPaymentModal, currencyCode} = useAuth()

  const proceedToCardPayment = async () => {
    setLoading(true)

    try {
      if (!stripe || !elements) return
      const {error: submitError} = await elements.submit()
      if (submitError) {
        console.log(submitError)

        return
      }
      setRunCardDetailsApi(true)

      const {error} = await stripe.confirmPayment({
        elements,
        clientSecret: paymentClientSecret,
        confirmParams: {
          return_url: 'https://app.dripfunnel.com/settings?tabKey=2',
        },
        redirect: 'if_required',
      })
      if (error) {
        toast.error(formatMessage({id: 'Something went wrong with the payment. Please try again'}))
        setPaymentModal(false)
        return
      }
      toast.success(formatMessage({id: 'Payment successfully completed'}))
      setPaymentModal(false)
      getBillingDetails()
      getCardDetails()
    } catch (err: any) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <PaymentElement />
      {currencyCode === 'USD' && <AddressElement options={{mode: 'billing'}} />}
      <Modal.Footer className='px-0 border-top-0'>
        <div>
          <GlobalButton
            buttonText={formatMessage({id: 'Proceed to pay'})}
            buttonType={ButtonTypes.PRIMARY}
            onButtonClick={() => {
              proceedToCardPayment()
            }}
            isLoading={loading}
            isDisable={!stripe || !elements}
          />
        </div>
      </Modal.Footer>
    </>
  )
}
