export enum Limits {
  EMAILREQUEST = 'emailRequest',
  LIKES = 'likes',
  VIEWS = 'views',
  FOLLOWS = 'follows',
  INMAIL = 'inMail',
  ENDORSEMENTS = 'endorsements',
  MESSAGE = 'message',
  REQUEST = 'request',
}

export enum RequestLimits {
  EMAILREQUEST = 'EMAILREQUEST',

  LIKE = 'LIKE_A_POST',
  VIEW = 'VIEW_PROFILE',
  FOLLOW = 'FOLLOW',

  INMAIL = 'INMAIL',

  ENDORSEMENT = 'ENDORSE_SKILLS',
  MESSAGE = 'MESSAGE',

  REQUEST = 'REQUEST',
  SEARCH = 'SEARCH',
  IF_PREMIUM = 'IF_PREMIUM',

  SEND_AN_INVITE = 'SEND_AN_INVITE',
  IF_OPEN_PROFILE = 'IF_OPEN_PROFILE',
  IF_VIEWED_MESSAGE = 'IF_VIEWED_MESSAGE',

  IF_CONNECTED = 'IF_CONNECTED',
  WITHDRAW_INVITE = 'WITHDRAW_INVITE',
  WAIT_TIME = 'WAIT_TIME',
  REPLIED_MESSAGE = 'REPLIED_MESSAGE',
}
export enum Status {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
