import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {DisplayImage} from '../../../../app/modules/widgets/components/General/DisplayImage'
import {GET_FILE_URL} from '../../../../app/modules/profile/core/_requests'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()

  return (
    <div className='app-navbar flex-shrink-0 pe-6'>
      <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        {/* <Search /> */}
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <p className='m-1 fw-bold'>
          <span className='font-size-14'>Hi, </span>

          <span className='font-size-14'>{currentUser?.firstName}</span>
        </p>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('navbar-image cursor-pointer', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {' '}
          <div>
            {currentUser?.profileImg ? (
              <img
                src={`${GET_FILE_URL}/${currentUser?.profileImg}`}
                height='24px'
                width='24px'
                alt='profile'
                style={{objectFit: 'contain'}}
              />
            ) : (
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/userProfile.svg')}
                height='24px'
                width='24px'
                alt='profile'
                style={{objectFit: 'contain'}}
              />
            )}
          </div>
        </div>
        <HeaderUserMenu />
      </div>
      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
