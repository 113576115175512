import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {updateBillingAccess} from '../../profile/core/_requests'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {toast} from 'react-toastify'
import {useAuth} from '../../auth'
import {Toaster} from '../../widgets/components/General/Toaster'
import {useLocation} from 'react-router-dom'
import '../styles/onboarding.scss'
import {proceedToProRated} from '../core/_requests'
import {PlanCycle} from '../core/_constants'
import {Status} from '../../../core/_constants'
import {BillingSummaryModal} from './BillingSummaryModal'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useGetPlans} from '../../../hooks/useGetPlans'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const BillingModal = ({
  modalShow,
  setModalShow,
  upgrade,
  getBillingDetails,
  onAccessBillingPortal,
}: {
  modalShow: boolean
  setModalShow: Dispatch<SetStateAction<boolean>>
  upgrade: boolean
  getBillingDetails?: () => Promise<void>
  onAccessBillingPortal?: () => Promise<void>
}) => {
  const {getAllPlans} = useGetPlans()
  const [loading, setLoading] = useState(false)
  const [featureList, setFeatureList] = useState<any>([])
  const [selectedCard, setSelectedCard] = useState<string>('')
  const [amount, setAmount] = useState(0)
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('inr')
  const [productName, setProductName] = useState('Professional')
  const [isUpgradedPlan, setIsUpgradedPlan] = useState<boolean>(false)
  const [isUpgradedCycle, setIsUpgradedCycle] = useState<boolean>(false)
  const [planUpgradRun, setPlanUpgradRun] = useState<boolean>(false)
  const [cycleUpgradRun, setCycleUpgradRun] = useState<boolean>(false)
  const [isUpgraded, setIsUpgraded] = useState<boolean>(false)
  const [isNotAllowedDownGraded, setIsNotAllowedDowngraded] = useState<boolean>(false)
  const [currentPriceId, setCurrentPriceId] = useState<string>('')
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false)
  const [isProceedButtonClicked, setIsProceedButtonClicked] = useState<boolean>(false)

  const {
    setBillingData,
    setShowBillingModal,
    setShowBillingAccessModal,
    setBillingPortalUrl,
    selectedPlan,
    setSelectedPlan,
    setSelected,
    selected,
    billingData,
    clicked,
    setClickedId,
    priceId,
    setPriceId,
    showBillingModal,
    setShowBillingSummary,
    active,
    setActive,
    proRatedData,
    setProRatedData,
    setInvalidCoupon,
    setCouponCode,
    couponCode,
    setUserToken,
    currencyCode,
    setCurrencyCode,
    selectedCurrency,
    setSelectedCurrency,
    saveAuth,
    setProRatedLoading,
    setTaxBeforeCoupon,
    setTotal,
    plans,
    plansCurrency,
  } = useAuth()

  useEffect(() => {
    if (plans && !billingData) {
      setCurrencyCode(plans[0]?.currency_code)
    } else if (plans && billingData && !isProceedButtonClicked && !showBillingModal) {
      setCurrencyCode(billingData?.currency?.toUpperCase())
    }
  }, [showBillingModal, plans])

  useEffect(() => {
    if (showBillingModal && selectedPlan && selectedPlan.length) {
      setIsContinueClicked(false)
      setActive(selectedPlan[0]?.period_unit)
      setSelected(selectedPlan[0]?.title)
      setIsProceedButtonClicked(false)
    }
  }, [showBillingModal])

  const previousSelectedRef = useRef(selected)
  useEffect(() => {
    previousSelectedRef.current = selected
  }, [selected])

  const previousactiveRef = useRef<any>(active)
  useEffect(() => {
    previousactiveRef.current = active
  }, [active])

  const handleCurrencyChange = (event: any) => {
    setCurrencyCode(event.target.value)
  }

  useEffect(() => {
    if (showBillingModal) {
      setIsUpgraded(false)
      setShowBillingSummary(false)
    }
  }, [showBillingModal])

  const {formatMessage} = useIntl()
  const location = useLocation()

  function checkUpgradeStatus(currentSelected: string) {
    setPlanUpgradRun(true)
    const previousSelected = previousSelectedRef.current
    const planOrder = ['Starter', 'Professional', 'Business']
    const previousIndex = planOrder.indexOf(previousSelected)
    const currentIndex = planOrder.indexOf(currentSelected)

    if (currentIndex > previousIndex) {
      setIsUpgradedPlan(true)
    } else {
      setIsUpgradedPlan(false)
    }
  }

  function checkUpgradeCycleStatus(plancycle: string) {
    setCycleUpgradRun(true)
    const previousSelected = previousactiveRef.current
    const planOrder = ['month', 'quarter', 'year']
    const previousIndex = planOrder.indexOf(previousSelected)
    const currentIndex = planOrder.indexOf(plancycle)
    if (currentIndex > previousIndex) {
      setIsUpgradedCycle(true)
    } else {
      setIsUpgradedCycle(false)
    }
  }

  useEffect(() => {
    if (billingData && showBillingModal) {
      setPriceId(billingData?.plan?.id)
      setCurrentPriceId(billingData?.plan?.id)
      setShowBillingSummary(false)
    }
  }, [showBillingModal])

  useEffect(() => {
    if (billingData?.plan?.id && showBillingModal) {
      setClickedId(billingData?.plan?.id)
      if (
        billingData?.plan?.interval_count === 1 &&
        billingData?.plan?.interval === PlanCycle.MONTHLY
      ) {
        setActive(PlanCycle.MONTHLY)
        setSelected(billingData?.productName)
        setSelectedCard(billingData.plan.product)
      } else if (billingData?.plan?.interval_count === 3) {
        setActive(PlanCycle.QUARTERLY)
        setSelected(billingData?.productName)
        setSelectedCard(billingData.plan.product)
      } else if (
        billingData?.plan?.interval_count === 1 &&
        billingData?.plan?.interval === PlanCycle.YEARLY
      ) {
        setActive(PlanCycle.YEARLY)
        setSelected(billingData?.productName)
        setSelectedCard(billingData.plan.product)
      }
    }
  }, [billingData?.plan?.id, showBillingModal])

  useEffect(() => {
    if (plans) {
      if (active === PlanCycle.YEARLY) {
        const selectedFeaturelist = plans.filter(
          (item: any) => item.period_unit === PlanCycle.YEARLY
        )
        setFeatureList(selectedFeaturelist)
        handlePlanChange(selectedFeaturelist)
      } else if (active === PlanCycle.QUARTERLY) {
        const selectedFeaturelist = plans.filter(
          (item: any) => item.period_unit === PlanCycle.QUARTERLY
        )
        setFeatureList(selectedFeaturelist)
        handlePlanChange(selectedFeaturelist)
      }
      if (active === PlanCycle.MONTHLY) {
        const selectedFeaturelist = plans.filter(
          (item: any) => item.period_unit === PlanCycle.MONTHLY
        )
        setFeatureList(selectedFeaturelist)
        handlePlanChange(selectedFeaturelist)
      }
    }
  }, [active, plans])

  const onUpgradePlan = async (selected: string, price_id: string) => {
    try {
      if (upgrade) {
        setLoading(true)
        const {
          data: {success, errors},
        } = await updateBillingAccess(selected, price_id, currencyCode!)
        if (success) {
          setLoading(false)
          setModalShow(false)
          toast.success(formatMessage({id: 'Successful Update Plan'}))
          const result: any = await getBillingDetails!()
          await saveAuth(result)
          setUserToken(result.token)
          setBillingData(result.billingDetails)
        } else {
          setLoading(false)
          setModalShow(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
          if (errors.includes('Please add a payment method from the billing portal.')) {
            if (onAccessBillingPortal) {
              const urlData: any = await onAccessBillingPortal!()
              setBillingPortalUrl(urlData)
              setShowBillingModal(false)
              setShowBillingAccessModal(true)
            }
          }
        }
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (billingData) {
      setCurrencyCode(billingData.currency.toUpperCase())
      setSelectedCurrency(billingData.currency.toUpperCase())
    }
  }, [billingData])

  useEffect(() => {
    getAllPlans()
  }, [billingData, currencyCode, selectedCurrency])

  useEffect(() => {
    if (showBillingModal) {
      setIsUpgraded(false)
    }
  }, [showBillingModal])

  //This function is checking which plan has period and plantype in it
  const setPropeties = () => {
    const selectedBenfits = featureList?.filter((features: any) => features?.price_id === clicked)
    if (selectedBenfits.length > 0) {
      setSelectedPlan(selectedBenfits)
      setSelected(selectedBenfits[0]?.title)
      setProductName(selectedBenfits.productName)
    }
    if (planUpgradRun && cycleUpgradRun) {
      if (isUpgradedPlan && isUpgradedCycle) {
        setIsUpgraded(true)
        proRatedPrice(priceId, billingData?.currency)
      }
    } else {
      if (planUpgradRun || cycleUpgradRun) {
        if (isUpgradedPlan || isUpgradedCycle) {
          setIsUpgraded(true)
          proRatedPrice(priceId, billingData?.currency)
        }
      }
    }
  }

  async function proRatedPrice(priceId: string, currencyId: string) {
    setProRatedLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await proceedToProRated(priceId, currencyId === 'inr' ? 'IN' : 'US', couponCode)
      if (success) {
        setProRatedData(data)
        setTaxBeforeCoupon(+(data.prorationTax / 100).toFixed(2))
        setTotal(+(data.prorationTotal / 100).toFixed(2))
      } else {
        setProRatedLoading(false)
      }
    } catch (err: any) {
      console.log(err)
    } finally {
      setProRatedLoading(false)
    }
  }

  function handlePlanChange(selectedFeaturelist: any) {
    const selectedCard1 = selectedFeaturelist?.filter(
      (features: any) => features?.title === selected
    )
    if (selectedCard1) {
      setClickedId(selectedCard1[0]?.price_id)
      setPriceId(selectedCard1[0]?.price_id)
      setAmount(selectedCard1[0]?.price)
      setSelectedCurrencyCode(selectedCard1[0]?.currency_code.toLowerCase())
    }
  }

  function getFutureDate(): number {
    const currentDate = new Date()

    if (active === PlanCycle.MONTHLY) {
      currentDate.setDate(currentDate.getDate() + 30)
    } else if (active === PlanCycle.QUARTERLY) {
      currentDate.setDate(currentDate.getDate() + 90)
    } else {
      currentDate.setDate(currentDate.getDate() + 365)
    }

    // Extract year, month, and day
    const futureYear = currentDate.getFullYear()
    const futureMonth = currentDate.getMonth()
    const futureDay = currentDate.getDate()

    // Return a new Date object with the future date only
    return new Date(futureYear, futureMonth, futureDay).getTime() / 1000
  }

  useEffect(() => {
    if (cycleUpgradRun) {
      if (
        isUpgradedPlan &&
        ((billingData.planCycle === PlanCycle.YEARLY &&
          (active === PlanCycle.MONTHLY || active === PlanCycle.QUARTERLY)) ||
          (billingData.planCycle === PlanCycle.QUARTERLY && active === PlanCycle.MONTHLY))
      ) {
        setIsNotAllowedDowngraded(true)
      } else {
        setIsNotAllowedDowngraded(false)
      }
    } else {
      setIsNotAllowedDowngraded(false)
    }
  }, [priceId])

  return (
    <>
      <Toaster />
      {currencyCode && (
        <Modal
          size='xl'
          show={modalShow}
          onHide={() => {
            setIsUpgradedPlan(false)
            setIsUpgradedCycle(false)
            setPlanUpgradRun(false)
            setCycleUpgradRun(false)
            setModalShow(false)
            setIsNotAllowedDowngraded(false)
            setActive(billingData?.planCycle)
            setSelected(billingData?.productName)

            if (!isContinueClicked && location.pathname.includes('/onboarding')) {
              setActive(selectedPlan[0]?.period_unit)
              setSelected(selectedPlan[0]?.title)
            }
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton className='border-bottom-0 px-8 py-4'>
            <div className='fw-bold d-flex flex-column flex-md-row justify-content-between align-items-center w-100'>
              <div className='d-flex flex-row'>
                <h2 className='font-size-16 text-color mb-3 mb-md-0'>
                  {formatMessage({id: 'Upgrade your plan'})}
                </h2>{' '}
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'Upgrade your plan to unlock premium features and elevate your experience with enhanced benefits.',
                  })}
                />
              </div>
              <div className='d-flex flex-column flex-md-row mx-auto align-items-center'>
                <div className='d-flex main-block '>
                  <div
                    className={`${
                      active === PlanCycle.MONTHLY ? 'active-button' : 'inactive-button'
                    } d-flex justify-content-center time-period text-color`}
                    onClick={() => {
                      setActive(PlanCycle.MONTHLY)
                      if (billingData?.status == Status.ACTIVE) {
                        checkUpgradeCycleStatus(PlanCycle.MONTHLY)
                      }
                    }}
                  >
                    Monthly
                  </div>
                  <div
                    className={`${
                      active === PlanCycle.QUARTERLY ? 'active-button' : 'inactive-button'
                    } d-flex justify-content-center time-period text-color`}
                    onClick={() => {
                      setActive(PlanCycle.QUARTERLY)
                      if (billingData?.status == Status.ACTIVE) {
                        checkUpgradeCycleStatus(PlanCycle.QUARTERLY)
                      }
                    }}
                  >
                    <div className='d-flex flex-column'>
                      Quarterly
                      <small className='font-color'>Save up to 15%</small>
                    </div>
                  </div>
                  <div
                    className={`${
                      active === PlanCycle.YEARLY ? 'active-button' : 'inactive-button'
                    } d-flex justify-content-center time-period`}
                    onClick={() => {
                      setActive(PlanCycle.YEARLY)
                      if (billingData?.status == Status.ACTIVE) {
                        checkUpgradeCycleStatus(PlanCycle.YEARLY)
                      }
                    }}
                  >
                    <div className='d-flex flex-column'>
                      Yearly
                      <small className='font-color'>Save up to 30%</small>
                    </div>
                  </div>
                </div>
                <div className='text-right font-size-14 ms-8 mt-3 mt-md-0'>
                  <select
                    id=''
                    className='form-select currency-width border border-secondary text-color'
                    value={currencyCode}
                    onChange={handleCurrencyChange}
                  >
                    <option value='INR'>{formatMessage({id: 'INR'})}</option>
                    <option value='USD'>{formatMessage({id: 'USD'})}</option>
                  </select>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className='py-2'>
            <div className='pb-4'>
              <div className='row mx-4 d-flex justify-content-center ' id='onboarding'>
                {featureList.length > 0 &&
                  featureList.map((featureItem: any, index: number) => (
                    <div key={index} className={'col-md-4 col-12 mb-3 mb-md-0'}>
                      <div
                        className={`card ${
                          featureItem?.title === selected ? 'selected-card' : 'border border-2'
                        }`}
                      >
                        <div className='card-body card-on-zoom'>
                          <div className='card-title'>
                            <div className='d-flex flex-row justify-content-between'>
                              <div className={'price-header text-color'}>{featureItem.title}</div>
                              {featureItem.title === 'Professional' ? (
                                <div className={`ps-2 pt-1 most-popular badge rounded-pill`}>
                                  {formatMessage({id: 'Most Popular'})}
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className='price text-color'>
                              <span className={`align-top price-text me-2`}>
                                {plansCurrency === 'INR' ? '₹' : '$'}
                              </span>
                              <span className='price-text'>
                                {plansCurrency === 'INR' ? (
                                  <span className={`price-text`}>
                                    {featureItem.period_unit === PlanCycle.YEARLY ? (
                                      <span className='price-text'>
                                        {Math.ceil(featureItem.price / 100 / 12)}
                                      </span>
                                    ) : featureItem.period_unit === PlanCycle.QUARTERLY ? (
                                      <span className='price-text'>
                                        {Math.ceil(featureItem.price / 100 / 3)}
                                      </span>
                                    ) : (
                                      <span className='price-text'>
                                        {Math.ceil(featureItem.price / 100)}
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span className={`price-text`}>
                                    {featureItem.period_unit === PlanCycle.YEARLY ? (
                                      <span className='price-text'>
                                        {Math.ceil(featureItem.price / 100 / 12)}
                                      </span>
                                    ) : featureItem.period_unit === PlanCycle.QUARTERLY ? (
                                      <span className='price-text'>
                                        {Math.ceil(featureItem.price / 100 / 3)}
                                      </span>
                                    ) : (
                                      <span className='price-text'>
                                        {Math.ceil(featureItem.price / 100)}
                                      </span>
                                    )}
                                  </span>
                                )}
                              </span>
                              <span className={`after-text font-size-14 fw-bold`}>
                                {' '}
                                {formatMessage({id: '/month'})}
                              </span>
                              <span className='ms-1 font-size-14 fw-bold'>
                                {featureItem.period_unit === PlanCycle.QUARTERLY
                                  ? formatMessage({id: '(billed quarterly)'})
                                  : featureItem.period_unit === PlanCycle.YEARLY
                                  ? formatMessage({id: '(billed yearly)'})
                                  : null}
                              </span>
                            </div>
                          </div>
                          <div className='card-list'>
                            <ul className='feature mt-1 text-color'>
                              {featureItem?.features?.map((feature: any, featureIndex: number) => (
                                <li key={featureIndex} className={`my-2`}>
                                  {feature.title}
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div
                            onClick={() => {
                              setClickedId(featureItem?.price_id)
                              setSelected(featureItem?.title)
                              setSelectedCard(featureItem?.id)
                              setPriceId(featureItem?.price_id)
                              setSelectedCurrencyCode(featureItem?.currency_code.toLowerCase())
                              setAmount(featureItem?.price)
                              if (billingData?.status === Status.ACTIVE) {
                                checkUpgradeStatus(featureItem?.title)
                              }
                            }}
                            className={`col-10 btn trial-btn text-center ${
                              featureItem?.title === selected ? 'selected-plan' : ''
                            }`}
                          >
                            {featureItem?.price_id === currentPriceId &&
                            location.pathname.includes('/settings') &&
                            !(billingData?.status === Status.TRIAL) ? (
                              <span className='mt-3'>
                                {featureItem?.title === selected ? (
                                  <img
                                    alt='selected plan'
                                    src={toAbsoluteUrl(
                                      '/media/icons/duotune/general/selectedPlan.svg'
                                    )}
                                    height={16}
                                    width={16}
                                    className='me-1 mb-1'
                                  />
                                ) : (
                                  <img
                                    alt='selected plan'
                                    src={toAbsoluteUrl(
                                      '/media/icons/duotune/general/selectedPlanOrange.svg'
                                    )}
                                    height={16}
                                    width={16}
                                    className='me-1 mb-1'
                                  />
                                )}
                                {formatMessage({id: 'Current Plan'})}
                              </span>
                            ) : featureItem?.title === selected ? (
                              <span className='mt-3'>
                                <img
                                  alt='selected plan'
                                  src={toAbsoluteUrl(
                                    '/media/icons/duotune/general/selectedPlan.svg'
                                  )}
                                  height={16}
                                  width={16}
                                  className='me-1 mb-1'
                                />
                                {formatMessage({id: 'Selected Plan'})}
                              </span>
                            ) : (
                              <span>{formatMessage({id: 'Select Plan'})}</span>
                            )}
                          </div>
                          <div>
                            {location.pathname.includes('/onboarding') && (
                              <>
                                <div className={`fst-italic text-center my-1 font-size-12 fw-bold`}>
                                  {' '}
                                  {formatMessage({id: '*No Credit card required'})}{' '}
                                </div>
                                <div
                                  className={`text-center free-trial mt-4 fw-bold text-white text-dark`}
                                >
                                  {featureItem?.trial_period}{' '}
                                  {featureItem?.trial_period > 1
                                    ? `${featureItem?.trial_period_unit}`
                                    : featureItem?.trial_period_unit}{' '}
                                  {formatMessage({id: 'free trial'})}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div>
                {isNotAllowedDownGraded && (
                  <div className='fw-bold font-size-16 mt-4 d-flex flex-end mt-2 text-clre88'>
                    {formatMessage({
                      id: 'You cannot upgrade your plan while downgrading the frequency.',
                    })}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-md-end justify-content-around gap-6'>
              <GlobalButton
                buttonText={formatMessage({id: 'Cancel'})}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={() => {
                  setIsUpgradedPlan(false)
                  setIsUpgradedCycle(false)
                  setPlanUpgradRun(false)
                  setCycleUpgradRun(false)
                  setIsNotAllowedDowngraded(false)
                  setModalShow(false)
                  setInvalidCoupon('')
                  setCouponCode('')
                  setActive(billingData?.planCycle)
                  setSelected(billingData?.productName)
                  if (!isContinueClicked) {
                    setActive(selectedPlan[0]?.period_unit)
                    setSelected(selectedPlan[0]?.title)
                  }
                }}
              />

              <GlobalButton
                buttonText={
                  location.pathname.includes('/settings') ||
                  billingData?.status === Status.CANCELED ||
                  billingData?.status === Status.INCOMPLETE_EXPIRED
                    ? formatMessage({id: 'Proceed'})
                    : formatMessage({id: 'Continue'})
                }
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => {
                  if (
                    location.pathname.includes('/settings') ||
                    location.pathname.includes('/campaigns')
                  ) {
                    setPropeties()
                    setIsUpgradedPlan(false)
                    setIsUpgradedCycle(false)
                    setPlanUpgradRun(false)
                    setCycleUpgradRun(false)
                    setShowBillingModal(false)
                    setIsNotAllowedDowngraded(false)
                    setShowBillingSummary(true)
                    setInvalidCoupon('')
                    setCouponCode('')
                    setIsProceedButtonClicked(true)
                  } else {
                    setPropeties()
                    setShowBillingModal(false)
                    setIsContinueClicked(true)
                  }
                }}
                isLoading={loading}
                isDisable={
                  clicked === null ||
                  isNotAllowedDownGraded ||
                  (priceId === currentPriceId &&
                    !(
                      billingData?.status === Status.TRIAL ||
                      billingData?.status === Status.CANCELED ||
                      billingData?.status === Status.INCOMPLETE_EXPIRED
                    ) &&
                    location.pathname.includes('/settings'))
                }
              />
            </div>
          </Modal.Footer>
        </Modal>
      )}

      {isUpgraded ? (
        <BillingSummaryModal
          currentPeriodStart={new Date().getTime() / 1000}
          currentPeriodEnd={getFutureDate()}
          amount={proRatedData?.prorationSubTotal}
          currency={
            billingData && priceId === billingData?.plan?.id
              ? billingData.plan.currency
              : selectedCurrencyCode
          }
          taxRate={proRatedData?.prorationTax}
          TotalProrationAmount={proRatedData?.prorationTotal}
          active={active}
          isPlanUpgraded={true}
        />
      ) : (
        <BillingSummaryModal
          currentPeriodStart={
            billingData && priceId === billingData?.plan?.id
              ? billingData.current_period_start
              : new Date().getTime() / 1000
          }
          currentPeriodEnd={
            billingData && priceId === billingData?.plan?.id
              ? billingData?.current_period_end
              : billingData?.current_period_end
          }
          amount={
            billingData && priceId === billingData?.plan?.id ? billingData.plan.amount : amount
          }
          currency={
            billingData && priceId === billingData?.plan?.id
              ? billingData.plan.currency
              : selectedCurrencyCode
          }
          taxRate={
            billingData &&
            billingData?.default_tax_rates.length > 0 &&
            billingData?.default_tax_rates[0]?.effective_percentage
              ? billingData?.default_tax_rates[0]?.effective_percentage
              : 18
          }
          active={active}
          isPlanUpgraded={false}
        />
      )}
    </>
  )
}
