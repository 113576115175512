import React, {useEffect, useState} from 'react'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import Tabs, {Tab} from 'react-best-tabs'
import {SettingsAnalyticsPage} from './SettingsAnalyticsPage'
import {AudiencePageAnalytics} from './AudiencePageAnalytics'
import TreeDiagram from '../../../template/components/TreeStructure'
import {ActivityLogs} from './ActivityLogs'
import {CampaignObject} from '../../core/_models'
import {UpdateTimePreferences} from './UpdateTimePreferences'

export const AnalyticsTabSection = ({
  campaignData,
  getCampaignData,
}: {
  campaignData: CampaignObject | undefined
  getCampaignData: () => Promise<void>
}) => {
  const {formatMessage} = useIntl()
  const [key, setKey] = useState(1)
  const [activeTab, setActiveTab] = useState('Audience')

  useEffect(() => {
    const tabsContainer = document.getElementsByClassName('setting-tabs-list')[0]
    if (tabsContainer) {
      const tabElements = tabsContainer.getElementsByClassName('rb-tabs-item')
      const selectedTab = Array.from(tabElements).find(
        (element) => element.textContent === activeTab
      )
      if (selectedTab) {
        const rect = selectedTab.getBoundingClientRect()
        tabsContainer.scrollTo({
          left: rect.left - tabsContainer.getBoundingClientRect().left,
          behavior: 'smooth',
        })
      }
    }
  }, [activeTab])

  return (
    <>
      <Tabs
        activeTab={1}
        className='font-size-13'
        ulClassName='text-muted dark-border setting-tabs-list'
        activityClassName='active-tab'
        onClick={(event, tab) => {
          setKey(tab)
          if (tab === 1) {
            setActiveTab('Audience')
          } else if (tab === 2) {
            setActiveTab('Workflow')
          } else if (tab === 3) {
            setActiveTab('Activity Logs')
          } else if (tab === 4) {
            setActiveTab('Working Hours')
          } else if (tab === 5) {
            setActiveTab('Settings')
          }
        }}
      >
        {
          <Tab title={formatMessage({id: 'Audience'})} className={`mr-3 mt-2`}>
            <AudiencePageAnalytics
              leadListCountValue={campaignData?.leadLists.length}
              getCampaignData={getCampaignData}
            />
          </Tab>
        }
        <Tab title={formatMessage({id: 'Workflow'})} className={`mr-3 mt-2`}>
          <TreeDiagram
            workflowSteps={campaignData?.workflow?.workflowSteps}
            userIdValue={campaignData?.userId}
            isCampaign={true}
            showDescription={true}
            workflowName={campaignData?.workflow?.name}
            workflowIdCamp={campaignData?.workflow?.workflowId}
          />
        </Tab>

        <Tab title={formatMessage({id: 'Activity Logs'})} className='mr-3 mt-2 text-nowrap'>
          <ActivityLogs />
        </Tab>
        <Tab title={formatMessage({id: 'Working Hours'})} className='mr-3 mt-2 text-nowrap'>
          <UpdateTimePreferences
            campaignId={campaignData?.campaignId}
            workingHour={campaignData?.workingHour}
            getCampaignData={getCampaignData}
          />
        </Tab>
        <Tab title={formatMessage({id: 'Settings'})} className='mr-3 mt-2'>
          <SettingsAnalyticsPage
            campaignName={campaignData?.name}
            excludeOtherCampaignLeadValue={campaignData?.excludeOtherCampaignLead}
            excludeTeamCampaignLeadValue={campaignData?.excludeTeamCampaignLead}
            getCampaignData={getCampaignData}
            totalLeadsCount={campaignData?.leadLists.length}
            workingHour={campaignData?.workingHour}
            workflowName={campaignData?.workflow.name}
            leadsCount={campaignData?.totalLeadsCount}
            totalExpectedLeads={campaignData?.totalExpectedLeads}
          />
        </Tab>
      </Tabs>
    </>
  )
}
