export const actionType = {
  END: 'END',
  ACTION: 'ACTION',
  WAIT: 'WAIT',
  CONDITION: 'CONDITION',
}

export const workflowActionIdEnum = {
  ENDORSE_SKILLS: 'ENDORSE_SKILLS',
  FOLLOW: 'FOLLOW',
  IF_CONNECTED: 'IF_CONNECTED',
  IF_OPEN_PROFILE: 'IF_OPEN_PROFILE',
  IF_VIEWED_MESSAGE: 'IF_VIEWED_MESSAGE',
  INMAIL: 'INMAIL',
  LIKE_A_POST: 'LIKE_A_POST',
  MESSAGE: 'MESSAGE',
  SEND_AN_INVITE: 'SEND_AN_INVITE',
  VIEW_PROFILE: 'VIEW_PROFILE',
  WITHDRAW_INVITE: 'WITHDRAW_INVITE',
}

export const CharacterLimits = {
  MESSAGE_LIMIT: 4000,
  INMAIL_LIMIT: 1800,
  SEND_INVITE_LIMIT: 280,
}
