/* eslint-disable jsx-a11y/anchor-is-valid */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useAuth} from '../../auth'
import {useStripe, useElements} from '@stripe/react-stripe-js'
import {AddCardModal} from '../../onboarding/views/AddCardModal'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {cancelSubscription} from '../core/_requests'
import useGetBillingData from '../../../hooks/useGetBillingData'
import {cardDetails} from '../../onboarding/core/_requests'
import {Status} from '../../../core/_constants'
import {BillingSummaryModal} from '../../onboarding/components/BillingSummaryModal'
import {PlanCycle} from '../../onboarding/core/_constants'
import {CancelConfirmationModal} from './CancelConfirmationModal'
import {CardDetails} from './CardDetails'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export function Billing({
  billingData,
  selected,
  onAccessBillingPortal,
  runCardDetailsApi,
  setRunCardDetailsApi,
}: {
  billingData: any
  selected: string
  onAccessBillingPortal: any
  runCardDetailsApi: boolean
  setRunCardDetailsApi: Dispatch<SetStateAction<boolean>>
}) {
  const stripe: any = useStripe()
  const elements: any = useElements()
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState<boolean>(false)
  const {setShowBillingModal, setCurrencyCode} = useAuth()
  const [paymentModalLoading, setPaymentModalLoading] = useState(false)
  const {getBillingDetailsAPI} = useGetBillingData()
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const {
    setShowBillingSummary,
    cardDigits,
    setCardDigits,
    cardBillingDetails,
    setCardBillingDetails,
    setExpiryDateYear,
    setExpiryDateMonth,
    setCardBrand,
    updateCardDetails,
    setUpdateCardDetails,
    percentoff,
    setPercentOff,
    saveAuth,
    scheduleDowngradeData,
    setScheduleDowngradeData,
    setActive,
    setSelected,
    setBillingData,
    currencyCode,
    isPlanDowngrade,
    setProRatedData,
  } = useAuth()
  const [modal, setModal] = useState(false)

  const getBillingDetails = async () => {
    try {
      const result = await getBillingDetailsAPI()

      saveAuth(result)
      setBillingData(result?.billingDetails)
      setScheduleDowngradeData(result?.schedule)
    } catch (error) {
      toast.error(error as string)
    }
  }

  async function onCancelSubscription() {
    let action
    if (billingData?.status === Status.ACTIVE && billingData?.cancel_at_period_end) {
      action = 'revoke'
    } else if (billingData?.status === Status.TRIAL && billingData?.cancel_at_period_end) {
      action = 'cancel'
    } else if (billingData?.status === Status.ACTIVE || billingData?.status === Status.TRIAL) {
      action = 'cancel'
    } else if (billingData?.status === Status.CANCELED) {
      action = 'revoke'
    }
    setLoading(true)
    try {
      const {
        data: {success, data},
      } = await cancelSubscription(action, currencyCode!)
      if (success) {
        toast.success(data?.message)
        getBillingDetails()
      }
    } catch (error) {
      toast.error(error as string)
      console.log(error)
    } finally {
      setLoading(false)
      setShowCancelModal(false)
    }
  }

  function getFutureDate(): number {
    const currentDate = new Date()

    if (billingData?.planCycle === PlanCycle.MONTHLY) {
      currentDate.setDate(currentDate.getDate() + 30)
    } else if (billingData?.planCycle === PlanCycle.QUARTERLY) {
      currentDate.setDate(currentDate.getDate() + 90)
    } else {
      currentDate.setDate(currentDate.getDate() + 365)
    }

    // Extract year, month, and day
    const futureYear = currentDate.getFullYear()
    const futureMonth = currentDate.getMonth()
    const futureDay = currentDate.getDate()

    // Return a new Date object with the future date only
    return new Date(futureYear, futureMonth, futureDay).getTime() / 1000
  }

  const getCardDetails = async () => {
    try {
      const {
        data: {data, success},
      } = await cardDetails()
      if (success) {
        console.log(data)

        setCardDigits(data?.card?.last4)
        setCardBillingDetails(data?.billing_details)
        setExpiryDateMonth(data?.card?.exp_month)
        setExpiryDateYear(data?.card?.exp_year)
        setCardBrand(data?.card?.brand)
        setRunCardDetailsApi(false)
        setUpdateCardDetails(false)
      }
    } catch (err: any) {
      toast.error(err.message)
      setRunCardDetailsApi(false)
      setUpdateCardDetails(false)
    } finally {
      setLoading(false)
      setRunCardDetailsApi(false)
      setUpdateCardDetails(false)
    }
  }

  useEffect(() => {
    if (runCardDetailsApi || updateCardDetails) {
      getCardDetails()
    }
  }, [runCardDetailsApi, updateCardDetails])

  useEffect(() => {
    getBillingDetails()
  }, [isPlanDowngrade])

  return (
    <>
      {billingData && Object.keys(billingData).length !== 0 && (
        <div className='card card-custom mx-1 rounded-1 mt-0 row'>
          <div className='card-body'>
            <span className='card-label fs-5 fw-bold '>{formatMessage({id: 'Billing'})}</span>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
              <div className='d-flex flex-column col-6 me-6'>
                <div className='d-flex flex-column col-md-6 w-100'>
                  <div className='row bg-gray-100 mx-0 gap-3 padding-16px mt-5 mb-5 mb-md-0'>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {formatMessage({id: 'Current Plan'})}
                      </p>
                      <div>
                        <p className='mb-0 font-size-13'>
                          {billingData?.productName}
                          <div className='mx-2 badge badge-pill badge-success'>
                            {billingData?.status === 'trialing' && billingData?.cancel_at_period_end
                              ? 'Cancelled'
                              : billingData?.status === 'past_due'
                              ? 'Payment Pending'
                              : billingData?.status === 'expired'
                              ? 'Cancelled'
                              : billingData?.status === 'incomplete'
                              ? 'Payment Pending'
                              : billingData?.status === 'incomplete_expired'
                              ? 'Cancelled'
                              : billingData?.status === 'trialing'
                              ? 'In Trial'
                              : billingData?.status === 'active' &&
                                billingData?.cancel_at_period_end
                              ? 'Active'
                              : billingData?.status === 'active'
                              ? 'Active'
                              : billingData?.status === 'canceled'
                              ? 'Cancelled'
                              : null}
                          </div>

                          {(billingData?.status === 'trialing' ||
                            billingData?.status === 'incomplete' ||
                            billingData?.status === 'past_due') && (
                            <div className='fw-bold font-size-14 text-black'>
                              <Link
                                to=''
                                onClick={() => setShowBillingSummary(true)}
                                className='link-primary'
                              >
                                {formatMessage({id: 'Pay Now'})}
                              </Link>
                            </div>
                          )}
                        </p>
                      </div>
                      {billingData?.status === Status.TRIAL &&
                        !billingData?.cancel_at_period_end && (
                          <div className='ms-1 fw-bold'>
                            {billingData?.current_period_end
                              ? Math.ceil(
                                  (((new Date(billingData?.current_period_end * 1000) as any) -
                                    new Date().getTime()) as any) /
                                    (1000 * 60 * 60 * 24)
                                )
                              : 0}{' '}
                            Days Remaining
                          </div>
                        )}
                    </div>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {' '}
                        {formatMessage({id: 'Next Billing Amount'})}
                      </p>
                      <p className='col-6 mb-0 font-size-13'>
                        {billingData?.status === Status.ACTIVE && billingData?.cancel_at_period_end
                          ? `${billingData?.currency === 'inr' ? '₹' : '$'} 0`
                          : `${billingData?.currency === 'inr' ? '₹' : '$'}${
                              billingData?.plan?.amount_decimal > 0
                                ? (billingData?.plan?.amount_decimal / 100)?.toFixed(2)
                                : billingData?.plan?.amount_decimal?.toFixed(2)
                            }${billingData?.currency === 'inr' ? ' +(18% GST)' : ''}`}
                      </p>
                    </div>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {' '}
                        {formatMessage({id: 'Billing Cycle'})}
                      </p>

                      <p className='col-6 mb-0 font-size-13'>
                        {billingData?.planCycle &&
                          billingData.planCycle.charAt(0).toUpperCase() +
                            billingData.planCycle.slice(1) +
                            'ly'}
                      </p>
                    </div>
                    <div className='d-flex border-bottom border-secondary border-2 p-0 dark-border'>
                      <p className='col-6 mb-0 pb-3 text-gray-700 font-size-13'>
                        {billingData?.status === Status.ACTIVE &&
                        billingData?.cancel_at_period_end ? (
                          <div className='mx-2 badge badge-pill badge-success'>
                            {formatMessage({id: 'Cancellation Date'})}
                          </div>
                        ) : (
                          formatMessage({id: 'Next Billing Date'})
                        )}
                      </p>

                      <p className='col-6 mb-0 font-size-13'>
                        {isNaN(billingData?.current_period_start) ||
                        billingData?.status === Status.CANCELED ||
                        billingData?.status === Status.INCOMPLETE_EXPIRED ||
                        billingData?.status === Status.EXPIRED
                          ? '-'
                          : new Date(billingData?.current_period_end * 1000).toDateString()}
                      </p>
                    </div>
                  </div>
                  {scheduleDowngradeData && (
                    <div className='mt-3 bg-white'>
                      <div className='mt-3 bg-gray-100 p-3 '>{`You have opted to downgrade the plan from ${billingData?.productName} - ${
                        billingData?.planCycle + 'ly'
                      } to ${scheduleDowngradeData?.planName} - ${
                        scheduleDowngradeData?.period_unit + 'ly'
                      } which will be done on ${new Date(
                        scheduleDowngradeData?.planStarts * 1000
                      ).toDateString()}`}</div>
                    </div>
                  )}
                </div>
                <div className='d-flex mt-5 gap-3'>
                  <GlobalButton
                    buttonText={
                      billingData?.status === Status.CANCELED ||
                      billingData?.status === Status.EXPIRED ||
                      billingData?.status === Status.INCOMPLETE_EXPIRED
                        ? formatMessage({id: 'Activate Subscription'})
                        : billingData?.status === Status.ACTIVE && billingData?.cancel_at_period_end
                        ? formatMessage({id: 'Activate Subscription'})
                        : billingData?.status === Status.TRIAL
                        ? formatMessage({id: 'Activate Subscription'})
                        : formatMessage({id: 'Update Subscription'})
                    }
                    buttonType={ButtonTypes.PRIMARY}
                    onButtonClick={() => {
                      setCurrencyCode(billingData?.currency.toUpperCase())
                      setProRatedData(undefined)
                      setShowBillingModal(true)
                      if (percentoff) {
                        setPercentOff(null)
                      }
                    }}
                  />
                  {!(
                    billingData?.status === Status.CANCELED ||
                    billingData?.status === Status.INCOMPLETE ||
                    billingData?.status === Status.INCOMPLETE_EXPIRED ||
                    billingData?.status === Status.PASTDUE
                  ) && (
                    <>
                      <GlobalButton
                        buttonText={
                          billingData?.status === Status.ACTIVE && billingData?.cancel_at_period_end
                            ? formatMessage({id: 'Restore Subscription'})
                            : billingData?.status === Status.ACTIVE ||
                              billingData?.status === Status.TRIAL
                            ? formatMessage({id: 'Cancel Subscription'})
                            : ''
                        }
                        buttonType={ButtonTypes.PRIMARY}
                        onButtonClick={() => setShowCancelModal(true)}
                        isLoading={loading}
                      />
                    </>
                  )}
                </div>
              </div>
              {cardDigits && (
                <>
                  <div className=''>
                    <CardDetails />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <BillingSummaryModal
        currentPeriodStart={billingData?.current_period_start}
        currentPeriodEnd={
          billingData?.status === 'trialing' ? getFutureDate() : billingData?.current_period_end
        }
        amount={billingData?.plan?.amount}
        currency={billingData?.currency}
        taxRate={
          billingData?.default_tax_rates.length > 0
            ? billingData?.default_tax_rates[0].effective_percentage
            : 18
        }
        paymentModalLoading={paymentModalLoading}
      />
      <AddCardModal
        getCardDetails={getCardDetails}
        stripe={stripe}
        setRunCardDetailsApi={setRunCardDetailsApi}
        setPaymentModalLoading={setPaymentModalLoading}
        paymentModalLoading={paymentModalLoading}
        getBillingDetails={getBillingDetails}
      />
      <CancelConfirmationModal
        showGeneralModal={showCancelModal}
        setGeneralShowModal={setShowCancelModal}
        headingMessage={`${formatMessage({id: 'Are you sure, you want to '})}${
          billingData?.status === Status.ACTIVE && billingData?.cancel_at_period_end
            ? formatMessage({id: 'restore'})
            : formatMessage({id: 'cancel'})
        } ${formatMessage({id: ' your plan?'})}`}
        buttonText={formatMessage({id: 'Confirm'})}
        handleConfirm={onCancelSubscription}
        generalLoading={loading}
        revokeSubscription={
          billingData?.status === Status.ACTIVE && billingData?.cancel_at_period_end
        }
      />
    </>
  )
}
