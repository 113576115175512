import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import '../styles/_template.scss'
import {getAllWorkflows, addNewWorkflow} from '../core/_requests'
import {useEffect} from 'react'
import {toast} from 'react-toastify'
import {AddNewWorkflowModal} from '../view/AddNewWorkflowModal'
import {workflow} from '../core/models/_workflowCard'
import {useNavigate} from 'react-router-dom'
import Tabs, {Tab} from 'react-best-tabs'
import {profileBreadCrumbs} from '../../profile/core/_constants'
import {PageHeading} from './PageHeading'
import {templateType} from '../../campaign/core/_constants'
import NoDataPage from '../../widgets/components/NoDataPage'
import {Spinner} from '../../widgets/components/General/Spinner'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'
import {WorkflowList} from './WorkflowList'

export function Template() {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  // useStates
  const [workflowUserData, setWorkflowUserData] = useState<workflow[]>([])
  const [workflowSystemData, setWorkflowSystemData] = useState<workflow[]>([])
  const [openCreateWorkflowModal, setOpenCreateWorkflowModal] = useState<boolean>(false)
  const [workflowName, setWorkflowName] = useState<string>('')
  const [workflowNameErr, setWorkflowNameErr] = useState<boolean>(false)
  const [creatingWorkflow, setCreatingWorkflow] = useState<boolean>(false)
  const [key, setKey] = useState(1)
  const [workflowLoader, setWorkflowLoader] = useState(false)
  const [dynamicWidth, setDynamicWidth] = useState<string>('252px')
  const getAllWorkflowsData = async (type?: string) => {
    try {
      setWorkflowLoader(true)
      const {
        data: {data, success, errors},
      } = await getAllWorkflows(type)

      if (success) {
        if (type === templateType.USER) {
          setWorkflowUserData(data)
        } else if (type === templateType.SYSTEM) {
          setWorkflowSystemData(data)
        }
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setWorkflowLoader(false)
    }
  }

  const handleAddNewWorkflow = async (name: string) => {
    if (!name) {
      setWorkflowNameErr(true)
      return
    }
    try {
      setCreatingWorkflow(true)
      const {
        data: {data, success, errors},
      } = await addNewWorkflow(name)
      if (success) {
        setOpenCreateWorkflowModal(false)
        setCreatingWorkflow(false)
        setWorkflowName('')
        getAllWorkflowsData()
        navigate(`${data.workflowId}`)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setCreatingWorkflow(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getAllWorkflowsData(templateType.USER)
    getAllWorkflowsData(templateType.SYSTEM)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const ref = React.useRef<HTMLDivElement>(null)
  const calculateSystemWidth = () => {
    const containerWidth = ref.current
    if (containerWidth) {
      const width = containerWidth.offsetWidth
      const [width0, width1, width2, width3, width4] = [2, 3, 4, 5, 6].map(
        (factor) => width / factor
      )

      const minWidth = 216
      const maxWidth = 300

      const validWidths = [width0, width1, width2, width3, width4].filter(
        (w) => w >= minWidth && w <= maxWidth
      )
      if (validWidths.length > 0) {
        const bestWidth = Math.min(...validWidths)
        return `${bestWidth}px`
      } else {
        return '252px'
      }
    } else {
      return '252px'
    }
  }
  const handleResize = () => {
    const newDynamicWidth = calculateSystemWidth()
    setDynamicWidth(newDynamicWidth)
  }
  return (
    <>
      <div className='card p-md-8 mb-5 p-4'>
        <div className=' campaign-heading-mobile'>
          <PageHeading
            title={formatMessage({id: 'Workflow'})}
            description={formatMessage({
              id: 'We have provided a few predefined templates for you as well as you can create your own custom template according to your needs.',
            })}
            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.WORKFLOW.TITLE'})}
            breadcrumbs={profileBreadCrumbs}
            breadcrumbTitle={formatMessage({id: 'Workflow'})}
          ></PageHeading>
          <div className='campaign-button-mobile'>
            <GlobalButton
              buttonText={formatMessage({id: 'Create Workflow'})}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => setOpenCreateWorkflowModal(true)}
            />
          </div>
        </div>
      </div>
      {workflowUserData?.length > 0 ? (
        <>
          <Tabs
            activeTab={1}
            className='font-size-14 mt-5'
            ulClassName='text-color dark-border setting-tabs'
            activityClassName='active-primary'
            onClick={(event, tab) => {
              setKey(tab)
              if (tab === 1) {
                getAllWorkflowsData(templateType.USER)
              } else if (tab === 2) {
                getAllWorkflowsData(templateType.SYSTEM)
              }
            }}
          >
            <Tab title={formatMessage({id: 'Custom Workflows'})} className={`mr-3 fw-bolder`}>
              <div className='position-relative' ref={ref}>
                {workflowLoader && <Spinner background={true} />}
                {workflowUserData.length !== 0 ? (
                  <>
                    <div className='gap-3 mt-4'>
                      <div className='row g-5 d-flex justify-content-start align-content-stretch flex-wrap w-100'>
                        {workflowUserData?.map((cardData: workflow) => (
                          <div
                            className='col template-col'
                            key={cardData?.workflowId}
                            style={{maxWidth: dynamicWidth, minWidth: dynamicWidth}}
                          >
                            <WorkflowList
                              btnText={cardData?.name}
                              lastEditedDate={cardData?.updatedAt}
                              creationDate={cardData?.createdAt}
                              workflowId={cardData?.workflowId}
                              userfirstName={cardData?.user?.firstName}
                              userId={cardData?.user?.userId}
                              teamId={cardData?.teamId}
                              getAllWorkflowsData={getAllWorkflowsData}
                              status={cardData?.status}
                              workflowUpdatedBy={cardData.workflowUpdatedBy}
                              selectPage={false}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='py-14 bg-white no-data-radius mt-4'>
                    <NoDataPage
                      noDataText={formatMessage({
                        id: "You haven't created any workflows yet. Please click on Create Workflow to begin.",
                      })}
                    />
                  </div>
                )}
              </div>
              <AddNewWorkflowModal
                openCreateWorkflowModal={openCreateWorkflowModal}
                setOpenCreateWorkflowModal={setOpenCreateWorkflowModal}
                workflowName={workflowName}
                setWorkflowName={setWorkflowName}
                handleAddNewWorkflow={handleAddNewWorkflow}
                workflowNameErr={workflowNameErr}
                setWorkflowNameErr={setWorkflowNameErr}
                creatingWorkflow={creatingWorkflow}
              />
            </Tab>
            {
              <Tab
                title={formatMessage({id: 'System Workflows'})}
                className={`mr-3 fw-bolder`}
                key={key}
              >
                <div ref={ref}>
                  <div className='gap-3 mt-4'>
                    <div className='row g-5 d-flex justify-content-start align-content-stretch flex-wrap w-100'>
                      {workflowSystemData?.map((cardData: workflow) => (
                        <div
                          className='col template-col'
                          key={cardData?.workflowId}
                          style={{maxWidth: dynamicWidth, minWidth: dynamicWidth}}
                        >
                          <WorkflowList
                            btnText={cardData?.name}
                            lastEditedDate={cardData?.updatedAt}
                            creationDate={cardData?.createdAt}
                            workflowId={cardData?.workflowId}
                            userfirstName={cardData?.user?.firstName}
                            userId={cardData?.user?.userId}
                            teamId={cardData?.teamId}
                            getAllWorkflowsData={getAllWorkflowsData}
                            selectPage={false}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
            }
          </Tabs>
        </>
      ) : (
        <>
          <Tabs
            activeTab={1}
            className='font-size-14 mt-5'
            ulClassName='text-color dark-border setting-tabs'
            activityClassName='active-primary'
            onClick={(event, tab) => {
              setKey(tab)
              if (tab === 1) {
                getAllWorkflowsData(templateType.SYSTEM)
              } else if (tab === 2) {
                getAllWorkflowsData(templateType.USER)
              }
            }}
          >
            {
              <Tab
                title={formatMessage({id: 'System Workflows'})}
                className={`mr-3 fw-bolder`}
                key={key}
              >
                <div ref={ref}>
                  <div className='gap-3 mt-4'>
                    <div className='row g-5 d-flex justify-content-start align-content-stretch flex-wrap w-100'>
                      {workflowSystemData?.map((cardData: workflow) => (
                        <div
                          className='col template-col'
                          key={cardData?.workflowId}
                          style={{maxWidth: dynamicWidth, minWidth: dynamicWidth}}
                        >
                          <WorkflowList
                            btnText={cardData?.name}
                            lastEditedDate={cardData?.updatedAt}
                            creationDate={cardData?.createdAt}
                            workflowId={cardData?.workflowId}
                            userfirstName={cardData?.user?.firstName}
                            userId={cardData?.user?.userId}
                            teamId={cardData?.teamId}
                            getAllWorkflowsData={getAllWorkflowsData}
                            selectPage={false}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Tab>
            }
            <Tab title={formatMessage({id: 'Custom Workflows'})} className={`mr-3 fw-bolder`}>
              <div className='position-relative' ref={ref}>
                {workflowLoader && <Spinner background={true} />}
                {workflowUserData.length !== 0 ? (
                  <>
                    <div className='gap-3 mt-4'>
                      <div className='row g-5 d-flex justify-content-start align-content-stretch flex-wrap w-100'>
                        {workflowUserData?.map((cardData: workflow) => (
                          <div
                            className='col template-col'
                            key={cardData?.workflowId}
                            style={{maxWidth: dynamicWidth, minWidth: dynamicWidth}}
                          >
                            <WorkflowList
                              btnText={cardData?.name}
                              lastEditedDate={cardData?.updatedAt}
                              creationDate={cardData?.createdAt}
                              workflowId={cardData?.workflowId}
                              userfirstName={cardData?.user?.firstName}
                              userId={cardData?.user?.userId}
                              teamId={cardData?.teamId}
                              getAllWorkflowsData={getAllWorkflowsData}
                              status={cardData?.status}
                              workflowUpdatedBy={cardData.workflowUpdatedBy}
                              selectPage={false}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='py-14 bg-white no-data-radius mt-4'>
                    <NoDataPage
                      noDataText={formatMessage({
                        id: "You haven't created any workflows yet. Please click on Create Workflow to begin.",
                      })}
                    />
                  </div>
                )}
              </div>
              <AddNewWorkflowModal
                openCreateWorkflowModal={openCreateWorkflowModal}
                setOpenCreateWorkflowModal={setOpenCreateWorkflowModal}
                workflowName={workflowName}
                setWorkflowName={setWorkflowName}
                handleAddNewWorkflow={handleAddNewWorkflow}
                workflowNameErr={workflowNameErr}
                setWorkflowNameErr={setWorkflowNameErr}
                creatingWorkflow={creatingWorkflow}
              />
            </Tab>
          </Tabs>
        </>
      )}
    </>
  )
}
