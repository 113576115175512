import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import '../styles/_campaign.scss'
import {useNavigate} from 'react-router-dom'
import {campaignStatus, crawlStatus, subscriptionError} from '../core/_constants'
import {updateStatusActiveToInactive, updateStatusInactiveToActive} from '../core/_requests'
import {toast} from 'react-toastify'
import OutsideClickHandler from 'react-outside-click-handler'
import {CampaignDropdown} from './CampaignDropdown'
import {DateTime} from 'luxon'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {useAuth} from '../../auth'
import {useTimeFormat} from '../../../hooks/useTimeFormat'
import {User, leadListObject} from '../core/_models'
import {BarLoader} from 'react-spinners'

export const CampaignListTableBody = ({
  key,
  name,
  campaignId,
  progressPercent,
  listOfLeads,
  allLeads,
  acceptanceRate,
  replyRate,
  createdAtDate,
  status,
  getCampaignsList,
  leadlistCount,
  totalLeadsCount,
  checkedValue,
  user,
  leadLists,
  totalExpectedLeads,
}: {
  key: number
  name: string
  campaignId: number
  progressPercent: string
  listOfLeads: string
  allLeads: string
  acceptanceRate: string | undefined
  replyRate: string | undefined
  createdAtDate: string
  status: string
  getCampaignsList: (value?: string) => void
  leadlistCount: number | undefined
  totalLeadsCount: number | undefined
  checkedValue: boolean
  user: User | undefined
  leadLists?: leadListObject[] | undefined
  totalExpectedLeads: number | undefined
}) => {
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const {getDateValue} = useDateFormat()
  const {getTimeValue} = useTimeFormat()

  const [isOpenCampaignOptions, setIsOpenCampaignOptions] = useState<boolean>(false)
  const [updateToggle, setUpdateToggle] = useState<boolean>(false)
  const [campaignStatusString, setCampaignStatusString] = useState<string>(status)
  const [showTooltip, setShowTooltip] = useState(false)
  const {setShowBillingModal} = useAuth()
  const [scrapeCompleted, setScrapeCompleted] = useState<boolean>(true)

  const updateActiveToInactive = async (campaignId: number) => {
    try {
      setUpdateToggle(true)
      const {
        data: {success, errors, data},
      } = await updateStatusActiveToInactive(campaignId)
      if (success) {
        toast.success(formatMessage({id: 'Campaign deactivated successfully'}))
        setUpdateToggle(false)
        if (checkedValue) {
          getCampaignsList('ACTIVE')
        } else {
          getCampaignsList()
        }
      } else {
        setCampaignStatusString(campaignStatus.ACTIVE)
        setUpdateToggle(false)
        errors?.forEach((error: string) => {
          if (error === subscriptionError.CANCELLED_SUBSCRIPTION) {
            setShowBillingModal(true)
          }
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setCampaignStatusString(campaignStatus.ACTIVE)
      if (err === subscriptionError.CANCELLED_SUBSCRIPTION) {
        setShowBillingModal(true)
      }
      setUpdateToggle(false)
      console.log(err)
    }
  }

  const updateInactiveToActive = async (campaignId: number) => {
    try {
      setUpdateToggle(true)
      const {
        data: {success, errors, data},
      } = await updateStatusInactiveToActive(campaignId)
      if (success) {
        toast.success(formatMessage({id: 'Campaign activated successfully'}))
        setUpdateToggle(false)

        if (checkedValue) {
          getCampaignsList('ACTIVE')
        } else {
          getCampaignsList()
        }
      } else {
        setCampaignStatusString(campaignStatus.INACTIVE)
        setUpdateToggle(false)
        errors?.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
          if (error === subscriptionError.CANCELLED_SUBSCRIPTION) {
            setShowBillingModal(true)
          }
        })
      }
    } catch (err) {
      setCampaignStatusString(campaignStatus.INACTIVE)
      if (err === subscriptionError.CANCELLED_SUBSCRIPTION) {
        setShowBillingModal(true)
      }
      setUpdateToggle(false)
      console.log(err)
    }
  }

  const toggle = () => {
    if (updateToggle) {
      return null
    } else {
      if (status === campaignStatus.INACTIVE) {
        setCampaignStatusString(campaignStatus.ACTIVE)
        updateInactiveToActive(campaignId)
      } else if (status === campaignStatus.ACTIVE) {
        setCampaignStatusString(campaignStatus.INACTIVE)
        updateActiveToInactive(campaignId)
      }
    }
  }
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    leadLists?.map(({status}) => {
      if (status === crawlStatus.PENDING || status === crawlStatus.INPROGRESS) {
        setScrapeCompleted(false)
      }
    })
  }, [])

  return (
    <>
      {windowSize.width > 450 ? (
        <tr className='campaign-row bg-white position-relative p-8' key={key}>
          <td className='campaign-first-col align-middle'>
            <div
              className='d-flex justify-content-md-start cursor-pointer'
              onClick={() => navigate(`${campaignId}`)}
            >
              <div className='d-flex align-items-center'>
                <img
                  className='me-3 campaign-img'
                  src={toAbsoluteUrl('/media/campaign/campaign_icon.svg')}
                  height='64px'
                  width='64px'
                />
              </div>
              <div className='text-black font-size-16 font-weight-600 d-flex flex-column justify-content-center'>
                {' '}
                <div className='campaign-name text-color'>{name}</div>
                {user?.linkedin_name && (
                  <div
                    className='
                     d-flex align-items-center justify-content-start'
                  >
                    <img
                      className='me-2 campaign-icon'
                      src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                      height='16px'
                      width='16px'
                    />
                    <p className='font-size-14 font-weight-600 m-0 linkedin-text-hover'>
                      <a href={user.linkedin_url} className='linkedin-text' target='_blank'>
                        {user?.linkedin_name}
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </td>
          <td className='campaign-cols align-middle'>
            <div className='d-flex justify-content-md-start mb-0'>
              <div>
                <img
                  className='me-2 campaign-icon-img'
                  src={toAbsoluteUrl('/media/campaign/filter.svg')}
                />
              </div>
              <div className='fw-bold font-size-14 font-weight-600 new-font-14'>
                {leadlistCount}{' '}
              </div>
            </div>
            <div className='d-flex text-nowrap'>
              <div className='font-size-12 text-black new-font-12 text-color'>
                {formatMessage({id: 'Lists of leads'})}
              </div>
              <div className='mt-n1'>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.LEAD_LIST',
                  })}
                />
              </div>
            </div>
          </td>
          <td className='campaign-cols align-middle position-relative'>
            <div className='d-flex justify-content-md-start'>
              <div>
                <img
                  className='me-1'
                  src={toAbsoluteUrl('/media/campaign/lead.svg')}
                  height='22px '
                  width='22px'
                />
              </div>

              <div className='fw-bold text-black font-size-14 font-weight-600 text-nowrap new-font-14 text-color'>
                {totalLeadsCount} / {totalExpectedLeads} {formatMessage({id: 'Leads'})}
              </div>
            </div>
            <div className='mb-1'>
              {!scrapeCompleted && status === campaignStatus.ACTIVE && (
                <BarLoader color={`var(--orange-color)`} loading={true} width={65} />
              )}
            </div>
            <div className='d-flex'>
              <div className='font-size-12 text-black new-font-12 text-color'>
                {formatMessage({id: 'All leads'})}
              </div>
              <div className='mt-n1'>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.ALL_LEADS',
                  })}
                />
              </div>
            </div>
          </td>
          <td className='campaign-cols align-middle'>
            <div className='d-flex justify-content-start '>
              <div>
                <img
                  className='me-2 campaign-icon'
                  src={toAbsoluteUrl('/media/campaign/acceptance_rate.svg')}
                  height='16px '
                  width='16px'
                />
              </div>
              <div className='fw-bold text-black font-size-14 font-weight-600 new-font-14 text-color'>
                {acceptanceRate}
              </div>
            </div>
            <div className='d-flex justify-content-start'>
              <div className='font-size-12 text-black text-nowrap new-font-12 text-color'>
                {formatMessage({id: 'Acceptance rate'})}
              </div>
              <div className='mt-n1'>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.ACCEPTANCE_RATE',
                  })}
                />
              </div>
            </div>
          </td>
          <td className='campaign-cols align-middle'>
            <div className='d-flex'>
              <div>
                <img
                  className='me-2 campaign-icon'
                  src={toAbsoluteUrl('/media/campaign/reply_rate.svg')}
                  height='18px '
                  width='18px'
                />
              </div>
              <div className='fw-bold text-black font-size-14 font-weight-600 new-font-14 text-color'>
                {replyRate || '0.00'}
              </div>
            </div>
            <div className='d-flex'>
              <div className='text-black font-size-12 text-nowrap new-font-12 text-color'>
                {formatMessage({id: 'Reply rate'})}
              </div>
              <div className='mt-n1'>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.REPLY_RATE',
                  })}
                />
              </div>
            </div>
          </td>
          <td className='campaign-cols align-middle'>
            <div className='d-flex justify-content-md-start'>
              <div>
                <img
                  className='me-2 campaign-icon'
                  src={toAbsoluteUrl('/media/campaign/calendar.svg')}
                  height='16px '
                  width='16px'
                />
              </div>
              <div className='fw-bold text-black font-size-14 font-weight-600 new-font-14 text-color'>
                {getDateValue(createdAtDate?.toString())
                  ? getDateValue(createdAtDate?.toString())
                  : DateTime.fromISO(createdAtDate).toFormat('dd MMM, yyyy')}{' '}
              </div>
            </div>
            <div className='d-flex'>
              <div className='font-size-12 text-black new-font-12 text-color'>
                {formatMessage({id: 'Created At'})}
              </div>
              <div className='mt-n1'>
                <ToolTipUI
                  tooltipText={
                    (getDateValue(createdAtDate?.toString())
                      ? getDateValue(createdAtDate?.toString())
                      : DateTime.fromISO(createdAtDate).toFormat('dd MMM, yyyy')) +
                    ' ' +
                    (getTimeValue(createdAtDate?.toString())
                      ? getTimeValue(createdAtDate?.toString())
                      : DateTime.fromISO(createdAtDate).toFormat('H:m:s'))
                  }
                />
              </div>
            </div>
          </td>
          <td className='campaign-cols align-middle'>
            <div className='d-flex flex-column'>
              <div
                className={`toggle-button ${
                  campaignStatusString === campaignStatus.ACTIVE
                    ? 'active cursor-pointer'
                    : 'not-active cursor-pointer'
                } 
                ${campaignStatusString === campaignStatus.DRAFT ? 'show-tooltip' : 'cursor-pointer'}
                mt-n3 position-relative`}
                onClick={() => toggle()}
                onMouseEnter={() => {
                  if (campaignStatusString === campaignStatus.DRAFT) setShowTooltip(true)
                }}
                onMouseLeave={() => {
                  if (campaignStatusString === campaignStatus.DRAFT) setShowTooltip(false)
                }}
              >
                <div className='slider'></div>
                {showTooltip && (
                  <div className='tooltip-toggle'>
                    {formatMessage({
                      id: 'The campaign is not complete, Please complete the campaign first to activate it.',
                    })}
                  </div>
                )}
              </div>
              <div className='ms-1 active-text font-size-12 font-weight-400 text-black new-font-12 text-color'>
                {campaignStatusString === campaignStatus.ACTIVE
                  ? 'Active'
                  : campaignStatusString === campaignStatus.INACTIVE
                  ? 'Inactive'
                  : 'Draft'}
              </div>
            </div>
          </td>
          <td className='campaign-last-col align-middle cursor-pointer'>
            <OutsideClickHandler onOutsideClick={() => setIsOpenCampaignOptions(false)}>
              <div
                onClick={() => {
                  setIsOpenCampaignOptions(!isOpenCampaignOptions)
                }}
              >
                <img
                  src={toAbsoluteUrl('/media/icons/investor/threeDots.svg')}
                  height='18px'
                  width='18px'
                />
              </div>
              <div className='position-relative'>
                <CampaignDropdown
                  isOpenCampaignOptions={isOpenCampaignOptions}
                  campaignId={campaignId}
                  status={status}
                  getCampaignsList={getCampaignsList}
                  textMessage={formatMessage({
                    id: 'Are you sure you want to delete this campaign?',
                  })}
                  headingMessage={formatMessage({
                    id: 'Delete Campaign',
                  })}
                />
              </div>
            </OutsideClickHandler>
          </td>
        </tr>
      ) : (
        <div key={key} className='card p-6 mt-6'>
          <div className='d-flex justify-content-between align-items-start'>
            <div
              className='d-flex justify-content-start cursor-pointer'
              onClick={() => navigate(`${campaignId}`)}
            >
              <div className='mt-md-1'>
                <img
                  className='me-3'
                  src={toAbsoluteUrl('/media/campaign/campaign_icon.svg')}
                  height='48px'
                  width='48px'
                />
              </div>
              <div className='text-black font-size-16 font-weight-600 d-flex flex-column justify-content-center text-color'>
                {' '}
                <div>{name}</div>
                {user?.linkedin_name && (
                  <div className='d-flex align-items-center'>
                    <img
                      className='me-2'
                      src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                      height='16px'
                      width='16px'
                    />
                    <p className='font-size-14 font-weight-600 m-0 linkedin-text-hover'>
                      <a href={user.linkedin_url} className='linkedin-text' target='_blank'>
                        {user?.linkedin_name}
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <div className='d-flex flex-column align-items-center me-4 mb-n6'>
                <div
                  className={`toggle-button ${
                    campaignStatusString === campaignStatus.ACTIVE
                      ? 'active cursor-pointer'
                      : 'not-active cursor-pointer'
                  } 
                ${campaignStatusString === campaignStatus.DRAFT ? 'show-tooltip' : 'cursor-pointer'}
                `}
                  onClick={() => toggle()}
                  onMouseEnter={() => {
                    if (campaignStatusString === campaignStatus.DRAFT) setShowTooltip(true)
                  }}
                  onMouseLeave={() => {
                    if (campaignStatusString === campaignStatus.DRAFT) setShowTooltip(false)
                  }}
                >
                  <div className='slider'></div>
                  {showTooltip && (
                    <div className='tooltip-toggle'>
                      {formatMessage({
                        id: 'The campaign is not complete, Please complete the campaign first to activate it.',
                      })}
                    </div>
                  )}
                </div>
                <div className='mt-1'>
                  {campaignStatusString === campaignStatus.ACTIVE
                    ? 'Active'
                    : campaignStatusString === campaignStatus.INACTIVE
                    ? 'Inactive'
                    : 'Draft'}
                </div>
              </div>
              <div>
                <OutsideClickHandler onOutsideClick={() => setIsOpenCampaignOptions(false)}>
                  <div
                    onClick={() => {
                      setIsOpenCampaignOptions(!isOpenCampaignOptions)
                    }}
                    className='position-relative'
                  >
                    {' '}
                    <img
                      src={toAbsoluteUrl('/media/icons/investor/threeDots.svg')}
                      height='15px'
                      width='15px'
                    />
                    <CampaignDropdown
                      isOpenCampaignOptions={isOpenCampaignOptions}
                      campaignId={campaignId}
                      status={status}
                      getCampaignsList={getCampaignsList}
                      textMessage={formatMessage({
                        id: 'Are you sure you want to delete this campaign?',
                      })}
                      headingMessage={formatMessage({
                        id: 'Delete Campaign',
                      })}
                    />
                  </div>
                </OutsideClickHandler>
              </div>
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-4 my-3 d-flex flex-column justify-content-center'>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  className='me-2'
                  src={toAbsoluteUrl('/media/campaign/filter.svg')}
                  width='16px'
                />
                <span className='fw-bold text-black font-size-12 font-weight-600 text-color'>
                  {leadlistCount}
                </span>
              </div>
              <div className='text-black d-flex text-nowrap text-color'>
                {formatMessage({id: 'Lists of leads'})}
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.LEAD_LIST',
                  })}
                />
              </div>
            </div>
            <div className='col-3 my-3 d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center'>
                <img
                  className='me-1'
                  src={toAbsoluteUrl('/media/campaign/lead.svg')}
                  width='16px'
                />
                <span className='fw-bold text-black font-size-12 font-weight-600 text-color'>
                  {totalLeadsCount} / {totalExpectedLeads} {formatMessage({id: 'Leads'})}
                </span>
              </div>
              <div className='mb-1'>
                {!scrapeCompleted && status === campaignStatus.ACTIVE && (
                  <BarLoader color={`var(--orange-color)`} loading={true} width={65} />
                )}
              </div>
              <div className='text-black d-flex text-nowrap text-color'>
                {formatMessage({id: 'All leads'})}
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.ALL_LEADS',
                  })}
                />
              </div>
            </div>
            <div className='col-5 my-3 d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center'>
                <img
                  className='me-2'
                  src={toAbsoluteUrl('/media/campaign/acceptance_rate.svg')}
                  width='12px'
                />
                <span className='fw-bold text-black font-size-12 font-weight-600 text-color'>
                  {acceptanceRate}
                </span>
              </div>
              <div className='text-black d-flex text-nowrap text-color'>
                {formatMessage({id: 'Acceptance rate'})}
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.ACCEPTANCE_RATE',
                  })}
                />
              </div>
            </div>
            <div className='col-4 mt-3 d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center'>
                <img
                  className='me-2'
                  src={toAbsoluteUrl('/media/campaign/reply_rate.svg')}
                  width='16px'
                />
                <span className='fw-bold text-black font-size-12 font-weight-600 text-color'>
                  {replyRate || '0.00'}
                </span>
              </div>
              <div className='text-black d-flex text-nowrap text-color'>
                {formatMessage({id: 'Reply rate'})}
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.CAMPAIGN.REPLY_RATE',
                  })}
                />
              </div>
            </div>
            <div className='col-8 mt-3 d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center'>
                <img
                  className='me-2'
                  src={toAbsoluteUrl('/media/campaign/calendar.svg')}
                  width='12px'
                />
                <span className='fw-bold text-black font-size-12 font-weight-600 text-nowrap text-color'>
                  {getDateValue(createdAtDate?.toString())
                    ? getDateValue(createdAtDate?.toString())
                    : DateTime.fromISO(createdAtDate).toFormat('dd MMM, yyyy')}
                </span>
              </div>
              <div className='text-black d-flex text-nowrap text-color'>
                {formatMessage({id: 'Created At'})}
                <ToolTipUI
                  tooltipText={
                    (getDateValue(createdAtDate?.toString())
                      ? getDateValue(createdAtDate?.toString())
                      : DateTime.fromISO(createdAtDate).toFormat('dd MMM, yyyy')) +
                    ' ' +
                    (getTimeValue(createdAtDate?.toString())
                      ? getTimeValue(createdAtDate?.toString())
                      : DateTime.fromISO(createdAtDate).toFormat('H:m:s'))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
