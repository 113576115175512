import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ButtonTypes} from '../../widgets/core/_constants'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
export const ReverseCounterModal = ({
  showCounterModal,
  setShowCounterModal,
  loadingBool,
  timeGap,
  getProfileInfo,
  functionCallBool,
}: {
  showCounterModal: boolean
  setShowCounterModal: Dispatch<SetStateAction<boolean>>
  loadingBool: boolean
  timeGap: any
  getProfileInfo: () => void
  functionCallBool: boolean
}) => {
  const {formatMessage} = useIntl()
  const [time, setTime] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const waitTimeVar = 60
  useEffect(() => {
    if (timeGap < waitTimeVar && timeGap > 0 && showCounterModal) {
      setTime(timeGap)
      setIsActive(true)
    }
  }, [showCounterModal])
  useEffect(() => {
    let interval: any
    if (showCounterModal) {
      if (isActive && time > 0 && time < waitTimeVar) {
        interval = setInterval(() => {
          setTime((prevTime) => prevTime - 1)
        }, 1000)
      }
    }
    return () => {
      clearInterval(interval)
    }
  }, [isActive, time, functionCallBool])

  useEffect(() => {
    let intervalId: any
    if (showCounterModal) {
      intervalId = setInterval(() => {
        if (!functionCallBool) {
          getProfileInfo()
        }
      }, 5000)
    }
    return () => clearInterval(intervalId)
  }, [functionCallBool, showCounterModal])

  useEffect(() => {
    if (showCounterModal && functionCallBool) {
      setShowCounterModal(false)
      toast.success(formatMessage({id: 'Your account has been provisioned successfully'}))
      setIsActive(false)
      setTime(0)
    }
  }, [functionCallBool])

  const resetTimer = () => {
    setShowCounterModal(false)
    setIsActive(false)
  }

  const formatTime = (seconds: any) => {
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return (
      <div
        style={{
          borderRadius: '4px',
          border: '1px solid #145DA3',
          background: '#145DA3',
        }}
      >
        <div className=' px-2 py-1 d-flex'>
          <span className='my-3 mx-2 py-1 px-2 box box-style'>
            {String(minutes).padStart(2, '0').charAt(0)}
          </span>
          <span className='my-3 py-1 px-2 box box-style'>
            {String(minutes).padStart(2, '0').charAt(1)}
          </span>
          <div className='my-3 py-1 ms-2 box-style'>:</div>
          <div className='my-3 mx-2 py-1 px-2 box box-style'>
            {String(remainingSeconds).padStart(2, '0').charAt(0)}
          </div>
          <div className='my-3 py-1 me-2 px-2 box box-style'>
            {String(remainingSeconds).padStart(2, '0').charAt(1)}
          </div>
        </div>
      </div>
    )
  }
  return (
    <Modal
      show={showCounterModal}
      onHide={() => resetTimer()}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-bottom-0 pb-0'>
        <h3>{formatMessage({id: 'Account setup underway'})}</h3>
      </Modal.Header>
      <Modal.Body className='pb-0'>
        {time < waitTimeVar && time > 0 && !functionCallBool && (
          <>
            <h5 className='fw-normal fw-400 font-size-14'>
              {formatMessage({
                id: `Sit back and relax! We're currently setting up your system user account, and it's expected to be ready in less than 2 minutes. Thanks a bunch for your understanding and patience!`,
              })}
            </h5>
            <h5>
              {formatMessage({id: 'Meanwhile, you can explore other features of the platform.'})}
            </h5>
            <div className='timer-container'>
              <div className='timer-display'>
                <div className='timer-value fs-3hx d-flex justify-content-center'>
                  {formatTime(time)}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <img
                src={toAbsoluteUrl('/media/avatars/internal.gif')}
                height='250px'
                width='225px'
              />
            </div>
          </>
        )}
        {(time >= waitTimeVar || time <= 0) && !functionCallBool && (
          <div>
            <div className='d-flex justify-content-center mb-2'>
              <img
                src={toAbsoluteUrl('/media/avatars/internal.gif')}
                height='250px'
                width='225px'
              />
            </div>
            <p>
              {formatMessage({
                id: 'Please reach out to our customer support using the link given below',
              })}
            </p>
            <a href='mailto:support@dripfunnel.com'>{formatMessage({id: 'Contact Us'})}</a>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        className={`border-top-0 z-index-1 ${
          time < waitTimeVar && time > 0 && !functionCallBool ? 'mt-n20' : ''
        }`}
      >
        <GlobalButton
          buttonText={formatMessage({id: 'Ok'})}
          buttonType={ButtonTypes.PRIMARY}
          onButtonClick={() => {
            resetTimer()
          }}
        />
      </Modal.Footer>
    </Modal>
  )
}
