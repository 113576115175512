import React, {useEffect, useState} from 'react'
import '../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import {CampaignListTableHead} from './CampaignListTableHead'
import {NewCampaignModal} from './view/NewCampaignModal'
import {getAllCampaigns, checkLinkedIn, getCampaignAnalytics} from '../core/_requests'
import {toast} from 'react-toastify'
import {LinkedInModal} from './view/LinkedInModal'
import {campaignObject, newCampaignObject} from '../core/_models'
import {useAuth} from '../../auth'
import {PageHeading} from '../../template/components/PageHeading'
import {profileBreadCrumbs} from '../../profile/core/_constants'
import NoDataPage from '../../widgets/components/NoDataPage'
import {profileData} from '../../profile/core/_requests'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {ReverseCounterModal} from './ReverseCounterModal'
import {GeneralInfoModal} from './GeneralInfoModal'
import {Spinner} from '../../widgets/components/General/Spinner'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const AllCampaignList = () => {
  const {formatMessage} = useIntl()
  const [getCampaignsLoader, setGetCampaignsLoader] = useState<boolean>(true)
  const [campaignsData, setCampaignsData] = useState<campaignObject[]>([])
  const [campaignModalLoader, setCampaignModalLoader] = useState<boolean>(false)
  const [campaignsCount, setCampaignsCount] = useState<number>(0)
  const [campaignName, setCampaignName] = useState<string>('')
  const [newCampaign, setNewCampaign] = useState<newCampaignObject>()
  const {selected, currentUser} = useAuth()
  const [checkedValue, setcheckedValue] = useState(false)
  const [serverProvisioned, setServerProvisioned] = useState<boolean>(false)
  const [showCounterModal, setShowCounterModal] = useState<boolean>(false)
  const [buttonLoadingCounter, setButtonLoadingCounter] = useState<boolean>(false)
  const [userUpdatedAt, setuserUpdatedAt] = useState<string>('')
  const [functionCallBool, setFunctionCallBool] = useState<boolean>(false)
  const [isLinkedInEmailPresent, setIsLinkedInEmailPresent] = useState<boolean>(false)
  const [showGeneralInfoModal, setShowGeneralInfoModal] = useState<boolean>(false)
  const [generalInfoLoader, setGeneralInfoLoader] = useState<boolean>(false)
  const [campaignAnalyticsData, setCampaignAnalyticsData] = useState<any>()
  const [isLinkedinCredPresent, setIsLinkedinCredPresent] = useState<boolean>(false)
  const specificGMTTime = new Date(userUpdatedAt)
  specificGMTTime.setSeconds(specificGMTTime.getSeconds() + 60)
  const currentGMTTime = new Date()
  const timeDifferenceInMilliseconds: number = specificGMTTime.getTime() - currentGMTTime.getTime()
  const timeDifferenceInSeconds: number = Math.floor(timeDifferenceInMilliseconds / 1000)

  const openSuitableModal = () => {
    if (!serverProvisioned) {
      setShowCounterModal(true)
    } else {
      if (currentUser?.metadata?.isCampaignLimit === 'false') {
        if (isLinkedinCredPresent) {
          setOpenCampaignModal(true)
        } else {
          setOpenLinkedInModal(true)
        }
      } else {
        if (currentUser?.metadata) {
          if (parseInt(currentUser?.metadata?.campaignLimit, 10) === campaignsData.length) {
            setShowGeneralInfoModal(true)
          } else {
            if (isLinkedinCredPresent) {
              setOpenCampaignModal(true)
            } else {
              setOpenLinkedInModal(true)
            }
          }
        }
      }
    }
  }

  const getUserDetails = async () => {
    try {
      const {
        data: {data, success},
      } = await profileData()
      if (success) {
        setIsLinkedInEmailPresent(!!data.linkedInEmail)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  const getCampaignsList = async (status?: string) => {
    try {
      setGetCampaignsLoader(true)
      const {
        data: {success, errors, data},
      } = await getAllCampaigns(status)
      if (success) {
        setCampaignsData(data)
        setGetCampaignsLoader(false)
      } else {
        setGetCampaignsLoader(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setGetCampaignsLoader(false)
      console.log(err)
    }
  }

  const getAnalytics = async () => {
    try {
      setGetCampaignsLoader(true)
      const {
        data: {success, errors, data},
      } = await getCampaignAnalytics()
      if (success) {
        setCampaignAnalyticsData(data)
        setGetCampaignsLoader(false)
      } else {
        setGetCampaignsLoader(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setGetCampaignsLoader(false)
      console.log(err)
    }
  }

  const checkButton = async () => {
    try {
      if (!isLinkedInEmailPresent) {
        const {
          data: {success, errors, data},
        } = await checkLinkedIn()
        if (success) {
          getUserDetails()
          if (!data.isLinkedInCredsAvailable) {
            setIsLinkedinCredPresent(false)
          } else {
            setIsLinkedinCredPresent(true)
          }
        }
      } else {
        setOpenCampaignModal(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getProfileInfo = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await profileData()
      if (success) {
        setServerProvisioned(data.serverProvisioned)
        if (data.serverProvisioned && showCounterModal) {
          setFunctionCallBool(true)
        }
        setuserUpdatedAt(data?.updatedAt)
      } else if (errors) {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getCampaignsList()
    getProfileInfo()
    getAnalytics()
    checkButton()
  }, [])

  const handleToggleResponseClick = (e: any) => {
    if (e.target.checked) {
      setcheckedValue(true)
      getCampaignsList('ACTIVE')
    } else {
      setcheckedValue(false)
      getCampaignsList()
    }
  }

  const [openCampaignModal, setOpenCampaignModal] = useState<boolean>(false)
  const [openLinkedInModal, setOpenLinkedInModal] = useState<boolean>(false)
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return (
    <>
      {windowSize.width > 450 ? (
        <>
          <div className='bg-white bar mb-5'>
            <div className='title d-md-flex justify-content-md-between p-8 text-color'>
              <PageHeading
                title={formatMessage({id: 'Campaign'})}
                description={formatMessage({
                  id: 'Start creating your own campaigns with pre-defined workflows or by creating your own workflows.',
                })}
                tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.TITLE'})}
                breadcrumbs={profileBreadCrumbs}
                breadcrumbTitle={formatMessage({id: 'Campaign'})}
              ></PageHeading>
              <div className='d-flex align-items-md-end flex-md-column'>
                <div className='d-flex'>
                  <GlobalButton
                    buttonText={formatMessage({id: 'Create Campaign'})}
                    buttonType={ButtonTypes.PRIMARY}
                    onButtonClick={() => openSuitableModal()}
                  />
                </div>
                {(campaignsData.length !== 0 || checkedValue) && (
                  <div className='d-flex align-items-end gap-3 rounded-1 h-40px font-size-12 rounded-start-0 me-5 pe-1'>
                    <input
                      id='activeOnlyCheckbox'
                      className='form-check-input custom-checkbox cursor-pointer'
                      type='checkbox'
                      checked={checkedValue}
                      onChange={(e) => handleToggleResponseClick(e)}
                    />
                    <label className='fw-semibold py-1 text-black text-color'>
                      {formatMessage({id: 'Active only'})}
                    </label>

                    <div className='mb-1'>
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.CAMPAIGN.ACTIVE_FILTER',
                        })}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='border-gray-200'></div>
          <div className='position-relative'>
            {getCampaignsLoader && <Spinner background={true} />}
            {campaignsData.length !== 0 ? (
              <CampaignListTableHead
                campaignsData={campaignsData}
                getCampaignsList={getCampaignsList}
                checkedValue={checkedValue}
                campaignAnalyticsData={campaignAnalyticsData}
              />
            ) : (
              <div className='p-8 bg-white no-data-radius'>
                <NoDataPage
                  noDataText={formatMessage({
                    id: 'No campaigns found. Lets start by creating a new campaign',
                  })}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className='card p-md-8 mb-6 p-4'>
            <div className='campaign-heading-mobile'>
              <PageHeading
                title={formatMessage({id: 'Campaign'})}
                description={formatMessage({
                  id: 'Start creating your own campaigns with pre-defined workflows or by creating your own workflows.',
                })}
                tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.TITLE'})}
                breadcrumbs={profileBreadCrumbs}
                breadcrumbTitle={formatMessage({id: 'Campaign'})}
              ></PageHeading>
              <div className='campaign-button-mobile'>
                <GlobalButton
                  buttonText={formatMessage({id: 'Create Campaign'})}
                  buttonType={ButtonTypes.PRIMARY}
                  onButtonClick={() => openSuitableModal()}
                />
              </div>
              <div className='active-only-mobile d-flex justify-content-end align-items-center text-black gap-3'>
                {(campaignsData.length !== 0 || checkedValue) && (
                  <>
                    <input
                      id='activeOnlyCheckbox'
                      className='form-check-input custom-checkbox'
                      type='checkbox'
                      checked={checkedValue}
                      onChange={(e) => handleToggleResponseClick(e)}
                    />
                    <label className='fw-semibold py-1 text-color'>
                      {formatMessage({id: 'Active only'})}
                    </label>

                    <div className='mb-1'>
                      <ToolTipUI
                        tooltipText={formatMessage({
                          id: 'GLOBAL.TOOLTIP.CAMPAIGN.ACTIVE_FILTER',
                        })}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='position-relative'>
            {getCampaignsLoader && <Spinner background={true} />}
            {campaignsData.length !== 0 ? (
              <CampaignListTableHead
                campaignsData={campaignsData}
                getCampaignsList={getCampaignsList}
                checkedValue={checkedValue}
                campaignAnalyticsData={campaignAnalyticsData}
              />
            ) : (
              <div className='p-8 bg-white no-data-radius'>
                <NoDataPage
                  noDataText={formatMessage({
                    id: 'No campaigns found. Lets start by creating a new campaign',
                  })}
                />
              </div>
            )}
          </div>
        </>
      )}
      <LinkedInModal
        openLinkedInModal={openLinkedInModal}
        setOpenLinkedInModal={setOpenLinkedInModal}
        setOpenLinkedIn={setOpenLinkedInModal}
        getProfileInfo={getProfileInfo}
        checkButton={checkButton}
      ></LinkedInModal>
      <NewCampaignModal
        openCampaignModal={openCampaignModal}
        setOpenCampaignModal={setOpenCampaignModal}
        campaignModalLoader={campaignModalLoader}
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        setCampaignModalLoader={setCampaignModalLoader}
        setNewCampaign={setNewCampaign}
      />
      <ReverseCounterModal
        showCounterModal={showCounterModal}
        setShowCounterModal={setShowCounterModal}
        loadingBool={buttonLoadingCounter}
        timeGap={timeDifferenceInSeconds}
        getProfileInfo={getProfileInfo}
        functionCallBool={functionCallBool}
      />
      <GeneralInfoModal
        showGeneralInfoModal={showGeneralInfoModal}
        setShowGeneralInfoModal={setShowGeneralInfoModal}
        headingMessage={formatMessage({id: 'Upgrade Your Plan'})}
        description={formatMessage({
          id: 'Your current plan (Starter) does not support more than 3 campaigns. Please upgrade your subscription to be to create unlimited campaigns.',
        })}
        generalLoading={generalInfoLoader}
        buttonText={formatMessage({id: 'Upgrade Plan'})}
      />
    </>
  )
}
