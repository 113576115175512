import React, {useEffect, SetStateAction, Dispatch, useState} from 'react'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import Tabs, {Tab} from 'react-best-tabs'
import {ToolTipUI} from '../../../widgets/components/UI/ToolTipUI'
import TextInput from '../../../widgets/components/Input/TextInput'
import TextArea from '../../../widgets/components/Input/TextArea'
import {useCSVReader, formatFileSize} from 'react-papaparse'
import {toast} from 'react-toastify'
import {fetchLeadCountFromUrl} from '../../core/_requests'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {Field} from 'formik'
export const CampaignModalTab = ({
  formik,
  setKey,
  campaignId,
  availableLeads,
  setAvailableLeads,
  setNameConstant,
}: {
  formik?: any
  setKey: (value: number) => void
  campaignId: string | undefined
  availableLeads: number
  setAvailableLeads: Dispatch<SetStateAction<number>>
  setNameConstant: (value: string) => void
}) => {
  const {formatMessage} = useIntl()
  const {CSVReader} = useCSVReader()
  const [validateLoading, setValidateLoading] = useState(false)

  const fetchLeadCount = async (url: string, campaignId: string) => {
    try {
      setValidateLoading(true)
      const {
        data: {success, data},
      } = await fetchLeadCountFromUrl(url, campaignId)

      if (success) {
        if (url.includes('https://www.linkedin.com/search/results/people')) {
          setAvailableLeads(data.numberOfResults)
        } else {
          if (data.numberOfResults >= 250) setAvailableLeads(250)
          else setAvailableLeads(data.numberOfResults)
        }
      } else {
        toast.error(formatMessage({id: 'Could not validate search URL'}))
      }
    } catch (err) {
      toast.error(formatMessage({id: 'Could not validate search URL'}))
      console.log(err)
    } finally {
      setValidateLoading(false)
    }
  }

  useEffect(() => {
    if (
      !!formik.values.linkedInURL &&
      !formik.errors.linkedInURL &&
      campaignId &&
      (formik.values.linkedInURL.startsWith('https://www.linkedin.com/search/results/people/') ||
        formik.values.linkedInURL.startsWith('https://www.linkedin.com/sales/search/people'))
    ) {
      fetchLeadCount(formik.values.linkedInURL, campaignId)
    }
  }, [formik.values.linkedInURL, formik.errors.linkedInURL, campaignId])
  return (
    <>
      <>
        <Tabs
          activeTab={1}
          className='font-size-13'
          ulClassName='text-muted  dark-border setting-tabs'
          activityClassName='bg-primary'
          onClick={(event, tab) => {
            setNameConstant(formik.values.name)
            setKey(tab)
          }}
        >
          <Tab
            title={formatMessage({id: 'Linkedin Search URL'})}
            className={`mr-3 mt-2 fw-bolder text-color`}
          >
            <div>
              <div className='mt-4'>
                <div className='mt-5'>
                  {formatMessage({id: 'Filter profiles in the '})}
                  <span className='text-add-search text-decoration-underline'>
                    <a href='https://www.linkedin.com/search/results/people/' target='_blank'>
                      {formatMessage({id: 'Linkedin search'})}
                    </a>
                  </span>
                  &nbsp;{formatMessage({id: 'and paste the URL below '})}
                  <span className='text-danger'>{formatMessage({id: '*'})}</span>{' '}
                  <ToolTipUI
                    tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.ADD_LEADS.SEARCH_URL'})}
                  />
                </div>
                <TextInput
                  fieldType={'text'}
                  fieldName={'linkedInURL'}
                  formik={formik}
                  isDisabled={validateLoading}
                  placeholder={formatMessage({
                    id: 'https://www.linkedin.com/search/results/people',
                  })}
                  isStarRequired={false}
                  isTooltipNotRequired={true}
                  isMargin={'mb-0'}
                />
                {validateLoading && (
                  <>
                    <span className='fw-semibold'>
                      {formatMessage({id: 'Please wait...'})}&nbsp;
                    </span>
                    <img
                      height={18}
                      src={toAbsoluteUrl('/media/misc/miniloader.gif')}
                      alt='Loader'
                    />
                  </>
                )}
                {!formik.errors.linkedInURL && !!availableLeads && !validateLoading && (
                  <div>
                    <span className='fw-bold'>{formatMessage({id: 'Available Leads:'})}&nbsp;</span>
                    <span className='fw-semibold'>
                      {' '}
                      {formik.values.linkedInURL.startsWith(
                        'https://www.linkedin.com/search/results/people'
                      )
                        ? availableLeads >= 1000
                          ? '1000+'
                          : availableLeads
                        : availableLeads >= 250
                        ? '250+'
                        : availableLeads}
                    </span>
                    <TextInput
                      fieldType={'number'}
                      fieldName={'expectedLeads'}
                      formik={formik}
                      placeholder={formatMessage({
                        id: 'How many leads do you want to load?',
                      })}
                      isStarRequired={false}
                      isTooltipNotRequired={true}
                      isMargin={'mb-0'}
                    />
                  </div>
                )}
              </div>
            </div>
          </Tab>
          <Tab
            title={formatMessage({id: 'Paste profile URLs'})}
            className={`mr-3 mt-2 fw-bolder text-color`}
          >
            <div className='mt-4'>
              <div>
                <div className='mt-5'>
                  {formatMessage({id: 'Insert the LinkedIn profile URLs'})}
                  <span className='text-danger'>{formatMessage({id: '*'})}</span>
                  <ToolTipUI
                    tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.ADD_LEADS.PASTE_PROFILE_URL'})}
                  />
                </div>
                <TextArea
                  fieldName={'profileURLs'}
                  formik={formik}
                  placeholder={'Leads Profile URLs'}
                  margin={'m-0 mt-2'}
                />
              </div>
            </div>
          </Tab>
          <Tab
            title={formatMessage({id: 'Upload CSV file'})}
            className='mr-3 mt-2 fw-bolder text-color'
          >
            <div className='mt-4'>
              <div className='mb-4'>
                {formatMessage({id: 'Take a look at the sample csv file for reference. '})}
                <Link
                  to={'/media/file/sampleFile.csv'}
                  download='Sample-CSV-file'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='blue-color fw-semibold cursor-pointer'>
                    {formatMessage({id: 'Click here'})}
                  </span>
                </Link>
              </div>

              <div>
                <CSVReader
                  onUploadAccepted={(results: any) => {
                    const header = ['profileUrl', 'firstName', 'lastName']
                    const formattedData = []

                    for (let i = 1; i < results.data.length; i++) {
                      const obj: {[key: string]: string | null} = {}
                      for (let j = 0; j < header.length; j++) {
                        let value = results.data[i][j] === '' ? null : results.data[i][j]
                        if (j == 0 && value !== null && value.includes('linkedin.com/sales/lead')) {
                          value = value.replace('/sales/lead/', '/in/')
                          let index = value.indexOf(',NAME_SEARCH')
                          if (index !== -1) {
                            value = value.substring(0, index)
                          }
                        }
                        if (j == 0 && value.includes('?miniProfile')) {
                          value = value.substring(0, value.indexOf('?miniProfile'))
                        }
                        if (value) obj[header[j]] = value
                      }
                      formattedData.push(obj)
                    }
                    formik.setFieldValue('csvFile', formattedData)
                  }}
                  onDragOver={(event: DragEvent) => {
                    event.preventDefault()
                  }}
                  onDragLeave={(event: DragEvent) => {
                    event.preventDefault()
                  }}
                >
                  {({getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove}: any) => (
                    <>
                      <div {...getRootProps()} className='zone'>
                        {acceptedFile ? (
                          <>
                            <div className='file'>
                              <div className='info'>
                                <div className='name'>{acceptedFile.name}</div>
                                <div className='size'>{formatFileSize(acceptedFile.size)}</div>
                              </div>
                              <div className='progress-bar'>
                                <ProgressBar />
                              </div>
                              <div
                                {...getRemoveFileProps({
                                  onClick: () => {
                                    formik.setFieldValue('csvFile', '')
                                  },
                                })}
                                className='remove'
                                onMouseOver={(event: Event) => {
                                  event.preventDefault()
                                }}
                                onMouseOut={(event: Event) => {
                                  event.preventDefault()
                                }}
                              >
                                <Remove />
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className='upload-text' style={{cursor: 'pointer'}}>
                            'Drop CSV file here or click to upload'
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </CSVReader>
              </div>
              <div className='text-danger mt-1'>
                <Field name='csvFile'>
                  {({field, form}: any) => {
                    const errors = form.errors.csvFile || []
                    let firstError = null
                    for (let i = 0; i < errors.length; i++) {
                      if (typeof errors[i] === 'string') {
                        firstError = errors
                        break
                      }
                      if (errors[i]) {
                        firstError = errors[i].firstName || errors[i].profileUrl
                        break
                      }
                    }
                    return <>{firstError && <div>{firstError}</div>}</>
                  }}
                </Field>
              </div>
            </div>
          </Tab>
        </Tabs>
      </>
    </>
  )
}
