import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Form, Table} from 'react-bootstrap'
import '../styles/_activity.scss'
import {PageTitle} from '../../../../../src/_metronic/layout/core/index'
// @ts-ignore
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {RangeSlider} from '../../wizards/components/RangeSlider'
import {getUserActivity, updateUserActivity} from '../core/_requests'
import {Limit, LimitType} from '../core/models/LimitType'
import {ActivityTypeMessage} from './ActivityTypeMessage'
import {Spinner} from '../../widgets/components/General/Spinner'
import {toast} from 'react-toastify'
import {Roles, profileBreadCrumbs} from '../../profile/core/_constants'
import {PageHeading} from '../../template/components/PageHeading'
import {useAuth} from '../../../../app/modules/auth'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {getUserList} from '../../profile/core/_requests'

export function Activity() {
  const {formatMessage} = useIntl()
  const {currentUser, teamLength, setTeamLength} = useAuth()
  const [range, setRange] = useState<number>(0)
  const [activityControl, setActivityControl] = useState<LimitType>()
  const [userActivityLimitId, setUserActivityLimitId] = useState<number>(0)
  const [sliderValues, setSliderValues] = useState<[string, Limit][]>([])
  const [isDragged, setDragged] = useState<boolean>(false)
  const [initialRange, setInitialRange] = useState<number>(0)
  const [userActivityControl, setUserActivityControl] = useState<[string, Limit][]>([])
  const {activityMessage} = ActivityTypeMessage()
  const [loader, setLoader] = useState<boolean>(false)
  const [isShieldOn, setIsShieldOn] = useState(false)
  const [userList, setUserList] = useState<[]>()
  const [selectedTeamMember, setSelectedTeamMember] = useState<number | undefined>(
    currentUser?.userId || undefined
  )
  const [showTooltip, setShowTooltip] = useState(false)
  const rangeArr = [
    {rangeId: 5, range: 5},
    {rangeId: 10, range: 10},
  ]
  const order = ['views', 'message', 'follows', 'likes', 'endorsements', 'request', 'inMail']

  const handleOnChangeRange = (rangeValue: number) => {
    setRange(rangeValue)
    setDragged(true)
  }

  useEffect(() => {
    if (isDragged) {
      const body: Record<string, string | number> = {}
      body['userActivityLimitId'] = userActivityLimitId
      body['range'] = range.toString()
      updateUserLimit(body)
    }
  }, [isDragged])

  const updateUserLimit = async (body: Record<string, string | number | boolean>) => {
    try {
      const {
        data: {success, errors},
      } = await updateUserActivity(body, selectedTeamMember)
      if (success) {
        getActivity()
        setDragged(false)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getActivity = async () => {
    try {
      setLoader(true)
      const {
        data: {data: data, success, errors},
      } = await getUserActivity(selectedTeamMember)
      if (success) {
        setLoader(false)
        const {range, userActivityLimitId, activityShieldEnabled, ...restData} = data
        setRange(+range)
        setSliderValues(
          (Object.entries(restData) as [string, Limit][]).sort((a, b) => {
            const orderA = order.indexOf(a[0])
            const orderB = order.indexOf(b[0])
            return orderA - orderB
          })
        )
        setInitialRange(range)
        setUserActivityLimitId(userActivityLimitId)
        setIsShieldOn(activityShieldEnabled)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err: any) {
      setLoader(false)
      console.log(err)
    }
  }

  useEffect(() => {
    getActivity()
  }, [])

  const handleToggle = async () => {
    if (currentUser?.metadata?.profileShield === 'true') {
      if (isShieldOn) {
        setIsShieldOn(false)
        const body: Record<string, number | boolean> = {}
        body['userActivityLimitId'] = userActivityLimitId
        body['activityShieldEnabled'] = false
        updateUserLimit(body)
      } else {
        setIsShieldOn(true)
        const body: Record<string, number | boolean | string> = {}
        body['userActivityLimitId'] = userActivityLimitId
        body['activityShieldEnabled'] = true
        body['range'] = '5'
        updateUserLimit(body)
      }
    } else if (currentUser?.metadata?.profileShield === 'false') {
      return
    }
  }

  const getUsersList = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      if (success) {
        setUserList(data)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getTeamUsers = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      setTeamLength(data.length)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (currentUser?.role !== Roles.MEMBER) {
      getUsersList()
      getTeamUsers()
    }
  }, [])

  useEffect(() => {
    if (
      (currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
      teamLength > 1
    ) {
      getActivity()
    }
  }, [selectedTeamMember])
  return (
    <>
      <div className='card p-md-8 p-4'>
        <PageHeading
          title={formatMessage({id: 'Activity Control'})}
          description={formatMessage({
            id: 'You can set your particular action to be performed how many times per day by the slider against each.',
          })}
          tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.ACTIVITY.CONTROL.TITLE'})}
          breadcrumbs={profileBreadCrumbs}
          breadcrumbTitle={formatMessage({id: 'Activity Control'})}
        ></PageHeading>
      </div>
      <div className='d-md-flex flex-md-row d-flex flex-column pt-8 acivity-main-block'>
        <div className='activity-control settings-profile-container'>
          <div className='card row ms-0'>
            <div className='card-body'>
              <div className='d-md-flex justify-content-between'>
                <div className='d-flex'>
                  <h1 className='activity-heading text-dark font-size-20 font-weight-600 mb-4'>
                    {formatMessage({id: 'Daily Activity Limits'})}
                  </h1>
                  <div className='mt-1'>
                    <ToolTipUI
                      tooltipText={formatMessage({
                        id: 'GLOBAL.TOOLTIP.ACTIVITY_LIMITS',
                      })}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-between gap-8'>
                  {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                    teamLength > 1 && (
                      <div className='w-md-50'>
                        <label className='form-label font-size-14 font-weight-600'>
                          {formatMessage({id: 'Account'})}
                        </label>
                        <select
                          name=''
                          id=''
                          className=' form-select height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer pb-2 team-dropdown'
                          value={selectedTeamMember}
                          onChange={(e) => setSelectedTeamMember(parseInt(e.target.value))}
                          defaultValue={currentUser?.userId}
                        >
                          {userList?.map(
                            ({userId, linkedin_name, linkedin_url}: any) =>
                              !!linkedin_url && (
                                <option value={userId} key={userId}>
                                  {linkedin_name}
                                </option>
                              )
                          )}
                        </select>
                      </div>
                    )}
                  <div className=''>
                    <div className='d-flex'>
                      {' '}
                      <label className='form-label font-size-14 font-weight-600 '>
                        {formatMessage({id: 'Range:'})}
                      </label>
                      <div>
                        {' '}
                        <ToolTipUI
                          tooltipText={formatMessage({
                            id: 'GLOBAL.TOOLTIP.ACTIVITY_CONTROL_RANGE',
                          })}
                        />
                      </div>
                    </div>

                    <select
                      name=''
                      id=''
                      className=' form-select mb-3 height-36px d-flex align-items-center font-weight-400 font-size-12 cursor-pointer pb-2 range-dropdown'
                      onChange={(e) => handleOnChangeRange(parseInt(e.target.value))}
                      value={range}
                      disabled={isShieldOn}
                    >
                      {rangeArr &&
                        rangeArr
                          .filter(({rangeId}) => !isShieldOn || rangeId !== 10)
                          .map(({rangeId, range}: {rangeId: number; range: number}) => (
                            <option value={rangeId} key={rangeId}>
                              {range}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='position-relative'>
                {loader && <Spinner />}
                <div className='table table-responsive h-100 pt-8'>
                  <div className='position-absolute top-0 start-0'>
                    {isShieldOn && (
                      <span className='badge bg-primary'>
                        {formatMessage({
                          id: 'You cannot manually edit your activity limits since you have enabled the Profile Shield.',
                        })}
                      </span>
                    )}
                  </div>
                  <Table className={`w-100`}>
                    <tbody>
                      {sliderValues &&
                        sliderValues.map(
                          (
                            [activityKey, activityValue]: [
                              activityKey: string,
                              activityValue: Limit,
                            ],
                            index: number
                          ) => (
                            <tr className='table-tbody-row' key={activityKey}>
                              <td>{activityMessage(activityKey)}</td>
                              <td className='w-75 pt-5'>
                                <RangeSlider
                                  initialValue={[
                                    activityValue.userLimit - range,
                                    activityValue.userLimit,
                                  ]}
                                  difference={range}
                                  perDayLimit={
                                    currentUser?.metadata?.activityLimit === 'true'
                                      ? activityValue.limited
                                      : currentUser?.metadata?.activityLimit === 'false'
                                      ? activityValue.full
                                      : 0
                                  }
                                  currentSlider={sliderValues[index]}
                                  userActivityLimitId={userActivityLimitId}
                                  initialRange={initialRange}
                                  setInitialRange={setInitialRange}
                                  fullLimit={activityValue.full}
                                  selectedTeamMember={selectedTeamMember}
                                  currentUser={currentUser}
                                  teamLength={teamLength}
                                  isShieldOn={isShieldOn}
                                />
                              </td>
                              <td>{activityValue.full}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='profile-shield ms-8 settings-profile-container'>
          <div className='card row'>
            <div className='card-body'>
              <div>
                <div>
                  {' '}
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/linkedin-shield.svg')} />
                </div>
                <div className='mt-5'>
                  <div className='d-flex justify-content-between'>
                    <h1 className='activity-heading fw-bold fs-4 text-dark mb-4'>
                      {formatMessage({id: 'LinkedIn Profile Shield'})}
                    </h1>{' '}
                    <Form.Check
                      type='switch'
                      id='custom-switch'
                      checked={isShieldOn}
                      onChange={handleToggle}
                      className={`${
                        currentUser?.metadata?.profileShield === 'true'
                          ? 'shield-toggle-btn-change'
                          : 'shield-toggle-btn-off'
                      }`}
                      onMouseOver={() => {
                        if (currentUser?.metadata?.profileShield === 'false') {
                          setShowTooltip(true)
                        }
                      }}
                      onMouseLeave={() => {
                        if (currentUser?.metadata?.profileShield === 'false') {
                          setShowTooltip(false)
                        }
                      }}
                    />
                    {showTooltip && (
                      <div className='shield-tooltip-toggle'>
                        {formatMessage({
                          id: 'Please upgrade your plan to be able to enjoy the benefits of this feature',
                        })}
                      </div>
                    )}
                  </div>
                  <div>
                    <p>
                      {' '}
                      {formatMessage({
                        id: 'Advanced safety feature that ensures gradual growth of your LinkedIn Performance and may adjust your daily connecting and messaging limits in order to avoid your account from being flagged by Linkedin for excessive activity.',
                      })}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
