import {useIntl} from 'react-intl'
import {createWorkingHours, getAllTimezones, updateWorkingHours} from '../../core/_requests'
import {ChangeEvent, useEffect, useState} from 'react'
import {getLocation} from '../../../auth/core/_requests'
import {hoursOptions} from '../../core/_constants'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {PageHeading} from '../../../template/components/PageHeading'
import {campaignDetailsBreadCrumbs} from '../../../profile/core/_constants'
import '../../../campaign/styles/_campaign.scss'
import {Form, Formik} from 'formik'
import {SelectInput} from '../../../widgets/components/Input/SelectInput'
import {TimeZoneType} from '../../../profile/core/_models'
import TextInput from '../../../widgets/components/Input/TextInput'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {WorkingHourValue} from '../../core/_models'
import * as Yup from 'yup'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'

export const TimePreferences = ({
  timezoneId,
  setTimezonId,
  defaultSelectedStartTime,
  setDefaultSelectedStartTime,
  defaultSelectedEndTime,
  setDefaultSelectedEndTime,
  isWeekendsInclude,
  setIsWeekendsInclude,
  isCampaignDetails,
  workingHourId,
  prevStep,
  submitStep,
  setIsApiCalled,
  campaignName,
  getCampaignData,
  enableSaveBtn,
}: {
  timezoneId: number | undefined
  setTimezonId: React.Dispatch<React.SetStateAction<number | undefined>>
  defaultSelectedStartTime: string
  setDefaultSelectedStartTime: React.Dispatch<React.SetStateAction<string>>
  defaultSelectedEndTime: string
  setDefaultSelectedEndTime: React.Dispatch<React.SetStateAction<string>>
  isWeekendsInclude: boolean
  setIsWeekendsInclude: React.Dispatch<React.SetStateAction<boolean>>
  isCampaignDetails: boolean
  workingHourId?: number | undefined
  prevStep?: () => void
  submitStep?: () => void
  setIsApiCalled?: React.Dispatch<React.SetStateAction<boolean>> | undefined
  campaignName?: string | undefined
  getCampaignData?: () => void
  enableSaveBtn: boolean
}) => {
  const {formatMessage} = useIntl()
  const [timeZoneOptions, setTimezoneOptions] = useState<[]>([])
  const [userTimeZone, setUserTimeZone] = useState<number>(0)
  const [loader, setLoader] = useState<boolean>(false)
  const {campaignId} = useParams()

  const handleSelectChangeStartTime = (event: ChangeEvent<HTMLSelectElement>) => {
    setDefaultSelectedStartTime(event.target.value)
  }
  const handleSelectChangeEndTime = (event: ChangeEvent<HTMLSelectElement>) => {
    setDefaultSelectedEndTime(event.target.value)
  }

  const handleSelectTimeZone = (event: ChangeEvent<HTMLSelectElement>) => {
    setTimezonId(+event.target.value)
  }

  const getAllTimeZones = async () => {
    try {
      setLoader(true)
      const {
        data: {data},
      } = await getAllTimezones()
      const timeZoneData = data.map((timeZone: TimeZoneType) => {
        return {
          id: timeZone.timezoneId,
          name: timeZone.title,
          value: timeZone.timezoneId,
        }
      })
      setTimezoneOptions(timeZoneData)
      if (timezoneId) {
        setTimezonId(timezoneId)
      } else {
        setTimezonId(
          data?.filter((obj: {gmtDiff: number}) => obj.gmtDiff === userTimeZone)[0]?.timezoneId
        )
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
      console.log(err)
    }
  }

  const getTimeZone = async () => {
    try {
      setLoader(true)
      const {
        data: {
          data: {offset},
        },
      } = await getLocation()
      setUserTimeZone(offset)
      getAllTimeZones()
    } catch (err) {
      setLoader(false)
      console.log(err)
    }
  }

  const initialValues = {
    timezone: '',
    startTime: '',
    endTime: '',
    weekend: false,
  }

  const updateWorkingHour = async (values: WorkingHourValue) => {
    try {
      const {
        data: {success, errors, data},
      } = await updateWorkingHours(
        workingHourId!,
        +values.startTime,
        +values.endTime,
        values.weekend,
        +values.timezone
      )
      if (success) {
        toast.success(formatMessage({id: 'Time preferences updated successfully'}))
        if (getCampaignData) getCampaignData()
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const createWorkingHour = async (values: WorkingHourValue) => {
    try {
      if (campaignId) {
        const campaignIdAsNumber = parseInt(campaignId, 10)
        const {
          data: {data, success, errors},
        } = await createWorkingHours(
          values.startTime,
          values.endTime,
          values.weekend,
          +values.timezone,
          campaignIdAsNumber
        )
        if (success) {
          if (setIsApiCalled) {
            setIsApiCalled(true)
          }
          if (submitStep) {
            submitStep()
          }
          toast.success(formatMessage({id: 'Campaign working hours set successfully'}))
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    getTimeZone()
  }, [userTimeZone])

  const timezoneDefaultValue = () => {
    const result: {id?: number; gmtDiff?: number}[] = timezoneId
      ? timeZoneOptions?.filter((obj: any) => obj?.id === timezoneId)
      : timeZoneOptions?.filter((obj: any) => obj?.gmtDiff === userTimeZone)
    return result[0]?.id?.toString()
  }

  useEffect(() => {
    timezoneDefaultValue()
  }, [userTimeZone])

  const timePreferenceValidationSchema = Yup.object().shape({
    timezone: Yup.string().required('Time Zone is required'),
    startTime: Yup.string()
      .required('Start Time is required')
      .test({
        test: function (startTime, {parent}) {
          const startHour = startTime ? parseInt(startTime, 10) : 0
          const endHour = parseInt(parent.endTime, 10)
          if (startHour > endHour) {
            return this.createError({
              message: 'The Start Time should not exceed the End Time',
            })
          }
          return (
            endHour - startHour >= 4 ||
            this.createError({
              message: 'Start Time must be at least 4 hours before End Time',
            })
          )
        },
      }),
    endTime: Yup.string()
      .required('End Time is required')
      .when('startTime', (startTime, schema) => {
        return schema.test({
          test: function (endTime: string) {
            const startHour = parseInt(startTime, 10)
            const endHour = parseInt(endTime, 10)
            if (startHour > endHour) {
              return this.createError({
                message: 'The End Time should not be earlier than the Start Time.',
              })
            }
            return (
              endHour - startHour >= 4 ||
              this.createError({message: 'End Time must be at least 4 hours after Start Time'})
            )
          },
        })
      }),
    weekend: Yup.boolean(),
  })

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <>
          {!isCampaignDetails && (
            <PageHeading
              title={formatMessage({id: 'Time preference setting'})}
              description={formatMessage({
                id: 'Please select your desired timezone and time duration for your campaign to run',
              })}
              tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.TIME'})}
              breadcrumbs={campaignDetailsBreadCrumbs}
              breadcrumbTitle={formatMessage({id: 'Time preference setting'})}
              campaignName={campaignName}
            ></PageHeading>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={isCampaignDetails ? updateWorkingHour : createWorkingHour}
            validationSchema={timePreferenceValidationSchema}
            validateOnMount
          >
            {(formik) => {
              if (timezoneId) {
                formik.initialValues.timezone = timezoneDefaultValue() || ''
                formik.initialValues.startTime = defaultSelectedStartTime
                formik.initialValues.endTime = defaultSelectedEndTime
                formik.initialValues.weekend = isWeekendsInclude
              }
              return (
                <>
                  <Form>
                    <div className={`${!isCampaignDetails ? 'mt-md-10' : ''} w-100`}>
                      <div className=' w-100'>
                        <div>
                          <div className='col-md-8'>
                            <div className='row'>
                              <SelectInput
                                label={formatMessage({id: 'Time Zone'})}
                                fieldName='timezone'
                                placeholder={formatMessage({id: 'Select the Time Zone'})}
                                formik={formik}
                                isStarRequired={true}
                                options={timeZoneOptions}
                                width={10}
                                isTooltipNotRequired={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='col-md-8'>
                            <div className='row'>
                              <SelectInput
                                label={formatMessage({id: 'Start Time'})}
                                fieldName='startTime'
                                placeholder={formatMessage({id: 'Select start time...'})}
                                formik={formik}
                                isStarRequired={true}
                                options={hoursOptions}
                                width={5}
                                isTooltipNotRequired={true}
                              />
                              <SelectInput
                                label={formatMessage({id: 'End Time'})}
                                fieldName='endTime'
                                placeholder={formatMessage({id: 'Select end time...'})}
                                formik={formik}
                                isStarRequired={true}
                                options={hoursOptions}
                                margin='me-6'
                                width={5}
                                isTooltipNotRequired={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className=' mb-6'>
                          <div className='col-lg-8'>
                            <div className='row'>
                              <div className='col-lg-6 fv-row d-flex gap-2 text-white'>
                                <TextInput
                                  fieldName={'weekend'}
                                  formik={formik}
                                  customText={formatMessage({
                                    id: 'Weekends',
                                  })}
                                  placeholder=''
                                  fieldType={'checkbox'}
                                  isCheckbox={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-md-flex button-margin justify-content-end'>
                      <div className='d-flex gap-6 justify-content-end'>
                        {!isCampaignDetails && (
                          <>
                            <GlobalButton
                              buttonText={formatMessage({id: 'Back'})}
                              buttonType={ButtonTypes.SECONDARY}
                              onButtonClick={prevStep ? prevStep : () => {}}
                            />
                          </>
                        )}
                        <GlobalButton
                          buttonText={
                            !isCampaignDetails
                              ? formatMessage({id: 'Save & Proceed'})
                              : formatMessage({id: 'Save'})
                          }
                          buttonType={ButtonTypes.PRIMARY}
                          onButtonClick={formik.handleSubmit}
                          isDisable={
                            formik.isSubmitting ||
                            !formik.isValid ||
                            (enableSaveBtn ? false : !formik.dirty)
                          }
                        />
                      </div>
                    </div>
                  </Form>
                </>
              )
            }}
          </Formik>
        </>
      )}
    </>
  )
}
