import '../../styles/_campaign.scss'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {ToolTipUI} from '../../../widgets/components/UI/ToolTipUI'
import {useIntl} from 'react-intl'
import {CampaignDetails} from '../../core/_models'

import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../auth'

export const AnalyticsDataCards = ({
  details,
  campaignId,
}: {
  details: CampaignDetails | undefined
  campaignId: string | undefined
}) => {
  const navigate = useNavigate()
  const {setAnalyticsClick} = useAuth()
  let campaignValue: number
  const cardDetails = [
    {
      id: '0',
      smallHeading: 'All leads',
      dataNumber: details?.leadsCount,
      showImg: true,
      img: 'lead',
      onclick: () => handleCardClick(campaignValue, 'allLeads'),
    },
    {
      id: '1',
      smallHeading: 'In progress',
      dataNumber: details?.inProgress,
      img: 'in_progress',
      onclick: () => handleCardClick(campaignValue, 'inProgress'),
    },
    {
      id: '2',
      smallHeading: 'Completed',
      dataNumber: details?.completed,
      img: 'completed',
      onclick: () => handleCardClick(campaignValue, 'completed'),
    },
    {
      id: '3',
      smallHeading: 'Awaiting',
      dataNumber: details?.awaiting,
      img: 'awaiting',
      onclick: () => handleCardClick(campaignValue, 'awaiting'),
    },
    {
      id: '4',
      smallHeading: 'Paused',
      dataNumber: details?.isPaused,
      img: 'paused',
      onclick: () => handleCardClick(campaignValue, 'paused'),
    },
    {
      id: '5',
      smallHeading: 'Failed',
      dataNumber: details?.failed,
      img: 'failed',
      onclick: () => handleCardClick(campaignValue, 'failed'),
    },
  ]

  const handleCardClick = (campaignValue: number, status: string) => {
    setAnalyticsClick(true)
    navigate(`/leads?campaignId=${campaignValue}&status=${status}`)
  }
  if (campaignId) {
    campaignValue = parseInt(campaignId, 10)
  }

  const {formatMessage} = useIntl()
  return (
    <>
      <div className='row bg-white px-8 pb-8 m-0 bottom-bar'>
        {cardDetails.map((data) => (
          <div className='col-md-2 col-sm-4 col-6 ps-0 pt-3' key={data.id} onClick={data.onclick}>
            <div className='border border-solid border-gray-200 custom-small-cards d-flex flex-column justify-content-center align-items-center p-6'>
              <div className='d-flex justify-content-start'>
                <img
                  className='me-4'
                  src={toAbsoluteUrl(`/media/campaign/${data.img}.svg`)}
                  alt='icon-1'
                  height='23px'
                  width='23px'
                />
                <p className='fw-bold fs-4 me-4'>{data?.dataNumber}</p>
              </div>
              <div className='d-flex'>
                <div className='text-grey-color text-decoration-underline cursor-pointer font-size-14 font-weight-500 text-nowrap'>
                  {data.smallHeading}
                </div>
                <div>
                  <ToolTipUI
                    tooltipText={formatMessage({
                      id: `GLOBAL.TOOLTIP.CAMPAIGN.CARD_SELECT.${data.smallHeading}`,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
