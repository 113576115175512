import {FC, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import '../../../styles/sidebar.scss'
type Props = {
  to: string
  title: string
  icon?: string
  activeIcon?: string
  hoverIcon: string
  fontIcon?: string
  hasBullet?: boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  activeIcon,
  hoverIcon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', {active: isActive})}
        to={to}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        <div className='d-flex'>
          {icon && activeIcon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <>
                {isHovered ? (
                  <KTSVG path={hoverIcon} className='svg-icon-2' />
                ) : isActive ? (
                  <KTSVG path={activeIcon} className='svg-icon-2' />
                ) : (
                  <KTSVG path={icon} className='svg-icon-2' />
                )}
              </>
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span
            className={clsx(
              `menu-title ${isHovered ? 'orange-color' : isActive ? 'blue-color' : 'white-color'}`
            )}
          >
            {title}
          </span>
        </div>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
