import React from 'react'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {useIntl} from 'react-intl'
import {useEffect, useRef, useState} from 'react'
import {AddLeadsPage} from './createCampaignSteps/AddLeadsPage'
import {SelectTimePreferences} from './createCampaignSteps/SelectTimePreferences'
import {SelectWorkflowPage} from './createCampaignSteps/SelectWorkflowPage'
import {CampaignSettingsPage} from './createCampaignSteps/CampaignSettingsPage'
import '../styles/_campaign.scss'
import {useAuth} from '../../../../../src/app/modules/auth/core/Auth'
import {getUserList} from '../../profile/core/_requests'
import {AddAccountsPage} from './createCampaignSteps/AddAccountsPage'
import {Roles} from '../../profile/core/_constants'

export const CreateCampaignFlowPage = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [campaignName, setCampaignName] = useState<string>()
  const stepper = useRef<StepperComponent | null>()
  const {formatMessage} = useIntl()
  const {stepperIndex, setStepperIndex, currentUser, setTeamLength, teamLength} = useAuth()
  // useStates
  const [, setsteps] = useState<any>(stepper.current?.currentStepIndex)
  const [saveBtnWorkflow, setSaveBtnWorkflow] = useState<boolean>(true)
  const [isApiCalled, setIsApiCalled] = useState<boolean>(false)
  const [memberId, setMemberId] = useState<number>()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    if (stepperIndex) {
      stepper.current?.goto(stepperIndex)
    }
    setStepperIndex(null)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
    setsteps(stepper.current?.currentStepIndex)
  }

  const submitStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goNext()
    setsteps(stepper.current?.currentStepIndex)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }
    loadStepper()
  }, [stepperRef])

  const getTeamUsers = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      setTeamLength(data.length)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTeamUsers()
  }, [])

  useEffect(() => {
    if (currentUser) {
      setMemberId(currentUser.userId)
    }
  }, [])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const shouldApplyMsN8 = windowWidth >= 767

  return (
    <>
      <div
        ref={stepperRef}
        className='stepper stepper-pills stepper-column d-flex flex-column flex-row-fluid font-size-13'
        id='kt_create_account_stepper'
      >
        <div className='card gap-5 w-100 p-4 rounded-3'>
          <div className='d-flex flex-row w-100 px-4 mt-1 justify-content-between'>
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper d-flex flex-md-row flex-column'>
                <div
                  className={`stepper-icon w-40px h-40px ms-md-0 ${shouldApplyMsN8 ? 'ms-n8' : ''}`}
                >
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>{formatMessage({id: '1'})}</span>
                </div>
                <div className='stepper-label text-left'>
                  <h3 className='stepper-title font-size-13 text-nowrap'>
                    {formatMessage({id: 'Step 1'})}
                  </h3>

                  <div className='stepper-desc fw-semibold font-size-12'>
                    {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                    teamLength > 1
                      ? formatMessage({id: 'Select Account'})
                      : formatMessage({id: 'Add Leads'})}
                  </div>
                </div>
              </div>
              <div className='stepper-line'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper me-4 d-flex flex-md-row flex-column align-items-md-center align-items-start'>
                <div className='stepper-icon me-md-3 me-0 w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>{formatMessage({id: '2'})}</span>
                </div>
                <div className='stepper-label text-left'>
                  <h3 className='font-size-13 stepper-title'>{formatMessage({id: 'Step 2'})}</h3>
                  <div className='stepper-desc fw-semibold font-size-12'>
                    {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                    teamLength > 1
                      ? formatMessage({id: 'Add Leads'})
                      : formatMessage({id: 'Select a workflow'})}
                  </div>
                </div>
              </div>
              <div className='stepper-line'></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper me-4 d-flex flex-md-row flex-column align-items-md-center align-items-start'>
                <div className='stepper-icon me-md-3 me-0 w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>{formatMessage({id: '3'})}</span>
                </div>
                <div className='stepper-label text-left'>
                  <h3 className='font-size-13 stepper-title'>{formatMessage({id: 'Step 3'})}</h3>
                  <div className='stepper-desc fw-semibold font-size-12'>
                    {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                    teamLength > 1
                      ? formatMessage({id: 'Select a workflow'})
                      : formatMessage({id: 'Select time preferences'})}
                  </div>
                </div>
              </div>
              <div className='stepper-line '></div>
            </div>
            <div className='stepper-item' data-kt-stepper-element='nav'>
              <div className='stepper-wrapper d-flex flex-md-row flex-column align-items-md-center align-items-start'>
                <div className='stepper-icon me-md-3 me-0 w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>{formatMessage({id: '4'})}</span>
                </div>
                <div className='stepper-label text-left'>
                  <h3 className='font-size-13 stepper-title'>{formatMessage({id: 'Step 4'})}</h3>
                  <div className='stepper-desc fw-semibold font-size-12'>
                    {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                    teamLength > 1
                      ? formatMessage({id: 'Select time preferences'})
                      : formatMessage({id: 'Settings'})}
                  </div>
                </div>
              </div>
              <div className='stepper-line'></div>
            </div>

            <div className='stepper-item' data-kt-stepper-element='nav'>
              {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                teamLength > 1 && (
                  <div>
                    <div className='stepper-wrapper d-flex flex-md-row flex-column align-items-md-center align-items-start'>
                      <div className='stepper-icon me-md-3 me-0 w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{formatMessage({id: '5'})}</span>
                      </div>
                      <div className='stepper-label text-left'>
                        <h3 className='font-size-13 stepper-title'>
                          {formatMessage({id: 'Step 5'})}
                        </h3>

                        <div className='stepper-desc fw-semibold font-size-12'>
                          {formatMessage({id: 'Settings'})}
                        </div>
                      </div>
                    </div>
                    <div className='stepper-line'></div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className='gap-5 w-100 rounded-3 mt-5'>
          <div className='d-flex flex-row-fluid bg-body rounded'>
            <div className='w-100'>
              {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                teamLength > 1 && (
                  <div data-kt-stepper-element='content' className='current'>
                    <AddAccountsPage
                      submitStep={submitStep}
                      memberId={memberId}
                      setMemberId={setMemberId}
                    />
                  </div>
                )}

              <div
                className={
                  !(
                    (currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                    teamLength > 1
                  )
                    ? 'current'
                    : ''
                }
                data-kt-stepper-element='content'
              >
                <AddLeadsPage
                  submitStep={submitStep}
                  setCampaignName={setCampaignName}
                  campaignName={campaignName}
                  teamLength={teamLength}
                  currentUser={currentUser}
                  prevStep={prevStep}
                />
              </div>
              <div data-kt-stepper-element='content'>
                <SelectWorkflowPage
                  setSaveBtnWorkflow={setSaveBtnWorkflow}
                  saveBtnWorkflow={saveBtnWorkflow}
                  prevStep={prevStep}
                  submitStep={submitStep}
                  campaignName={campaignName}
                />
              </div>
              <div data-kt-stepper-element='content'>
                <SelectTimePreferences
                  prevStep={prevStep}
                  submitStep={submitStep}
                  setIsApiCalled={setIsApiCalled}
                  campaignName={campaignName}
                />
              </div>

              <div data-kt-stepper-element='content'>
                <CampaignSettingsPage
                  prevStep={prevStep}
                  submitStep={submitStep}
                  isApiCalled={isApiCalled}
                  setIsApiCalled={setIsApiCalled}
                  campaignName={campaignName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
