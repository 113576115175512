import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useThemeMode} from '../../../../_metronic/partials'
import {useIntl} from 'react-intl'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

const Error404: FC = () => {
  const {mode} = useThemeMode()
  const navigate = useNavigate()
  const {formatMessage} = useIntl()
  return (
    <>
      {mode === 'dark' ? (
        <img src={toAbsoluteUrl('/media/illustrations/error/illustration404Dark.svg')} alt='' />
      ) : (
        <img src={toAbsoluteUrl('/media/illustrations/error/illustration404.svg')} alt='' />
      )}
      <div className={`my-5 ${mode === 'dark' ? 'errorHeadingDark' : 'errorHeading'}`}>
        {formatMessage({id: '404 Error - Page Not Found'})}
      </div>
      <div className={`my-5   ${mode === 'dark' ? 'errorDetailsDark' : 'errorDetails'}`}>
        {formatMessage({
          id: 'The page you are looking for could not be found. Please check the URL or try searching for the page using the search bar. We apologize for any inconvenience this may have caused.',
        })}
      </div>
      <div className='mb-4 d-flex justify-content-center'>
        <GlobalButton
          buttonText={formatMessage({id: 'Go to Dashboard'})}
          buttonType={ButtonTypes.PRIMARY}
          onButtonClick={() => {
            navigate('/')
          }}
        />
      </div>
    </>
  )
}

export {Error404}
