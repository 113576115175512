import {useAuth} from '../modules/auth'
import {getPlans} from '../modules/onboarding/core/_requests'

export const useGetPlans = () => {
  const {
    billingData,
    currencyCode,
    setCurrencyCode,
    setPlansCurrency,
    setPlans,
    getPlansApiLoading,
    setSelectedPlan,
  } = useAuth()
  const getAllPlans = async () => {
    getPlansApiLoading(true)
    try {
      const response = await fetch(`https://api.ipify.org/`)
      const ip: any = await response.text()
      const {
        data: {data, success, errors},
      } = await getPlans(ip, currencyCode)

      if (success) {
        const defaultPlan = data.filter((plan: any) => {
          if (plan?.currency_code === 'INR') {
            return plan?.default === 'true'
          } else if (plan?.currency_code === 'USD') {
            return plan?.default === 'true'
          }
        })
        setPlans(data)
        setPlansCurrency(data[0]?.currency_code)
        getPlansApiLoading(false)
        setSelectedPlan(defaultPlan)
      }
    } catch (err) {
      const {
        data: {data, success, errors},
      } = await getPlans(undefined, currencyCode)
      if (success) {
        setCurrencyCode(data[0]?.currency_code)
        const defaultPlan = data.filter((plan: any) => {
          if (plan.currency_code === 'INR') {
            return plan?.default === 'true'
          } else if (plan.currency_code === 'USD') {
            return plan?.default === 'true'
          }
        })
        setPlans(data)
        setPlansCurrency(data[0]?.currency_code)
        setSelectedPlan(defaultPlan)
      }
      console.log(err)
      getPlansApiLoading(false)
    } finally {
      getPlansApiLoading(false)
    }
  }

  return {getAllPlans}
}
