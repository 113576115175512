import {particularActionFlow, workflowActionFlowType} from './models/_workflowActionFlow'
import {workflowStepArr} from './models/_treeStructure'

function findParentStepId(
  data: workflowStepArr[] | null,
  targetStepId: number | null,
  parent_action_set: Set<string> = new Set()
): Set<string> {
  function findStepRecursive(currentStep: workflowStepArr[] | null, target: number | null) {
    const parentFoundStep = currentStep?.find(
      (obj: {positiveStepId: number | null; negativeStepId: number | null}) =>
        obj.positiveStepId === target || obj.negativeStepId === target
    )

    if (parentFoundStep) {
      const workflowActionId = parentFoundStep?.workflowAction?.workflowActionId
      if (workflowActionId !== 'WAIT_TIME') {
        if (parentFoundStep?.workflowAction?.isBinary) {
          parent_action_set.add(
            workflowActionId + (parentFoundStep?.positiveStepId === target ? '+' : '-')
          )
        } else {
          if (workflowActionId) parent_action_set.add(workflowActionId)
        }
      }
      findStepRecursive(data, parentFoundStep?.workflowStepId)
    }
  }

  findStepRecursive(data, targetStepId)
  return parent_action_set
}

function allowedWorkflowActions(
  workflowActionIds: string[],
  workflowStepId: number | null,
  actionFlowData: workflowActionFlowType,
  action_set: Set<string>
): boolean[] {
  const NOT_ALLOWED_FIRSTSTEP = new Set(actionFlowData?.NOT_ALLOWED_FIRSTSTEP || [])
  return workflowActionIds.map((workflowActionId) => {
    if (!workflowStepId) {
      return !NOT_ALLOWED_FIRSTSTEP.has(workflowActionId)
    } else {
      const desiredWorkflowAction = actionFlowData?.workflowActionFlow?.find(
        (workflowAction: particularActionFlow) =>
          workflowAction.workflowActionId === workflowActionId
      )
      if (desiredWorkflowAction?.OnlyAllowedAction) {
        if (action_set.has(desiredWorkflowAction?.OnlyAllowedAction)) {
          for (const action of action_set) {
            if (desiredWorkflowAction?.NotAllowedActions.includes(action)) {
              return false
            } else {
              return true
            }
          }
          return true
        } else {
          return false
        }
      } else if (desiredWorkflowAction?.canBeAllowedActions) {
        let allowed = false
        for (const action of action_set) {
          if (desiredWorkflowAction.canBeAllowedActions.includes(action)) {
            allowed = true
            break
          }
        }
        if (allowed) {
          return true
        }
      }
      let notAllowed = false
      for (const action of action_set) {
        if (desiredWorkflowAction?.NotAllowedActions.includes(action)) {
          notAllowed = true
          break
        }
      }
      if (notAllowed) {
        return false
      }

      return true
    }
  })
}

export const getEnableDisableList = (
  workflowStepId: number | null,
  actionFlow: workflowActionFlowType,
  data: workflowStepArr[] | null,
  actionIDs: string[]
): boolean[] => {
  const action_set =
    workflowStepId !== 0
      ? findParentStepId(data, workflowStepId, new Set<string>())
      : new Set<string>()
  return allowedWorkflowActions(actionIDs, workflowStepId, actionFlow, action_set)
}
