import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {DeleteConfirmationModal} from '../../widgets/components/DeleteConfirmationModal'
import {deleteCampaign, deleteLeadList} from '../core/_requests'
import '../styles/_campaign.scss'
import {campaignStatus} from '../core/_constants'

export const CampaignDropdown = ({
  isOpenCampaignOptions,
  campaignId,
  status,
  getCampaignsList,
  leadListId,
  textMessage,
  headingMessage,
  leadListCount,
  setOpenLeadsDeleteModal,
}: {
  isOpenCampaignOptions: boolean
  campaignId: number | undefined
  status?: string
  getCampaignsList: () => void
  leadListId?: number | undefined
  textMessage: string
  headingMessage: string
  leadListCount?: number
  setOpenLeadsDeleteModal?: any
}) => {
  const {formatMessage} = useIntl()
  const [openDeleteCampaignModal, setOpenDeleteCampaignModal] = useState<boolean>(false)
  const [deletingCampaign, setDeletingCampaign] = useState<boolean>(false)
  const [deletingLeadList, setDeletingLeadList] = useState<boolean>(false)

  const handleDeleteCampaign = async () => {
    if (status === campaignStatus.ACTIVE) {
      toast.error(formatMessage({id: 'Active campaign cannot be deleted'}))
      setOpenDeleteCampaignModal(false)
      return
    }
    try {
      setDeletingCampaign(true)
      if (campaignId) {
        const {
          data: {success, errors},
        } = await deleteCampaign(campaignId)
        if (success) {
          setDeletingCampaign(false)
          setOpenDeleteCampaignModal(false)
          getCampaignsList()
          toast.success(formatMessage({id: 'Campaign Deleted successfully'}))
        } else {
          setDeletingCampaign(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
    } catch (err) {
      setDeletingCampaign(false)
      console.log(err)
    }
  }

  const handleDeleteLeadList = async () => {
    if (leadListId) {
      if (leadListCount === 1) {
        toast.error(
          formatMessage({
            id: 'There should be at least one lead list in a campaign, If you wish to delete it, Please add another lead list first',
          })
        )
        setOpenDeleteCampaignModal(false)
        return
      }
      try {
        setDeletingLeadList(true)
        if (campaignId) {
          const {
            data: {success, errors},
          } = await deleteLeadList(campaignId, leadListId)
          if (success) {
            setOpenDeleteCampaignModal(false)
            getCampaignsList()
            toast.success(formatMessage({id: 'Lead list Deleted successfully'}))
            setOpenLeadsDeleteModal(null)
            setDeletingLeadList(false)
          } else {
            errors.forEach((error: string) => {
              toast.error(formatMessage({id: error}))
            })
            setDeletingLeadList(false)
          }
        }
      } catch (error: any) {
        console.log(error)
        setDeletingLeadList(false)
      }
    }
  }

  return (
    <>
      {isOpenCampaignOptions ? (
        <div
          className={`menu menu-sub menu-sub-dropdown data-room-option-dropdown show top-1 ${
            leadListId ? 'leadList-dropdown' : 'campaign-dropdown'
          }`}
        >
          <div className='option-padding' onClick={() => setOpenDeleteCampaignModal(true)}>
            <p className='mb-0 text-dark'>{formatMessage({id: 'Delete'})}</p>
          </div>

          <div className='separator border-gray-200'></div>
        </div>
      ) : null}
      <DeleteConfirmationModal
        showDeleteModal={openDeleteCampaignModal}
        setDeleteShowModal={setOpenDeleteCampaignModal}
        headingMessage={headingMessage}
        description={textMessage}
        handleDelete={leadListId ? handleDeleteLeadList : handleDeleteCampaign}
        deleteLoading={leadListId ? deletingLeadList : deletingCampaign}
        id={leadListId}
      />
    </>
  )
}
