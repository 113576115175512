import React from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
import LeadsHome from './views/LeadsHome'

const LeadsRoutes = () => {
  return (
    <>
      <Routes>
        <Route index element={<LeadsHome />} />
      </Routes>
    </>
  )
}
export default LeadsRoutes
