import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {ButtonTypes} from '../../core/_constants'

export const GlobalButton = ({
  buttonText,
  buttonType,
  isLoading,
  isDisable,
  onButtonClick,
}: {
  buttonText: string
  buttonType: string
  isLoading?: boolean
  isDisable?: boolean
  onButtonClick: () => void
}) => {
  const {formatMessage} = useIntl()
  return (
    <>
      <button
        type='button'
        disabled={isDisable || isLoading}
        onClick={onButtonClick}
        className={`${isLoading && 'opacity-50'} ${
          buttonType === ButtonTypes.PRIMARY
            ? 'primary_button'
            : buttonType === ButtonTypes.SECONDARY
            ? 'secondary_button'
            : buttonType === ButtonTypes.TERITARY
            ? 'teritary_button'
            : 'button_link'
        } d-flex align-items-center justify-content-center custom-button-property`}
      >
        {isLoading ? (
          <span className={`indicator-label font-size-13 p-0 opacity-50`}>
            {formatMessage({id: 'Please wait...'})}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <span className={`indicator-label font-size-14 text-nowrap`}>{buttonText}</span>
        )}
      </button>
    </>
  )
}
