import {Form, Formik} from 'formik'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import TextInput from '../../widgets/components/Input/TextInput'
import TextArea from '../../widgets/components/Input/TextArea'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const AddContactInformationModal = ({
  openAddContactInfoModal,
  setOpenAddContactInfoModal,
}: {
  openAddContactInfoModal: boolean
  setOpenAddContactInfoModal: (value: boolean) => void
}) => {
  const {formatMessage} = useIntl()

  const initialValues = {
    notes: '',
  }

  return (
    <>
      <Modal
        show={openAddContactInfoModal}
        onHide={() => setOpenAddContactInfoModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {(formik) => {
            return (
              <Form>
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                  <div className='font-size-16 font-weight-600 text-color'>
                    {formatMessage({id: 'Contact information'})}
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <TextInput
                      fieldType={'text'}
                      label={formatMessage({id: 'Enter email address'})}
                      fieldName={'email'}
                      formik={formik}
                      placeholder={formatMessage({id: 'Email address'})}
                      isTooltipNotRequired={true}
                    />
                    <TextInput
                      fieldType={'text'}
                      label={formatMessage({id: 'Enter phone number'})}
                      fieldName={'phoneNumber'}
                      formik={formik}
                      placeholder={formatMessage({id: 'Phone number'})}
                      isTooltipNotRequired={true}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className='pt-0 border-top-0'>
                  <div className='w-100 d-flex justify-content-end gap-3'>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Cancel'})}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={() => {
                        setOpenAddContactInfoModal(false)
                      }}
                    />

                    <GlobalButton
                      buttonText={formatMessage({id: 'Save'})}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => {}}
                      isDisable={formik.isSubmitting || !formik.isValid}
                    />
                  </div>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
