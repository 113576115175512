import {useState} from 'react'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import {forgotPassword} from '../core/_requests'
import TextInput from '../../widgets/components/Input/TextInput'
import {useIntl} from 'react-intl'
import {FormikProps} from '../core/_models'
import {Link} from 'react-router-dom'
import {CustomToast} from '../../widgets/components/UI/CustomToast'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

const initialValues = {
  email: '',
}

export function ForgotPassword() {
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState(false)
  const [errorsArray, setErrorsArray] = useState<string[]>()

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({id: 'Invalid email format'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Email is required'})),
  })

  const onSubmit = async (values: any, {setStatus, setSubmitting}: FormikProps) => {
    setLoading(true)

    try {
      const {
        data: {success, errors},
      } = await forgotPassword(values.email)
      if (success) {
        setStatus(
          formatMessage({
            id: 'Password reset link has been emailed to you. Please check your inbox including your spam folder',
          })
        )
        setErrorsArray([])
        setLoading(false)
      } else {
        setStatus('')
        setErrorsArray(errors)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgotPasswordSchema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {(formik) => (
        <Form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          id='kt_login_password_reset_form'
        >
          <div className='mb-24px'>
            {/* begin::Title */}
            <h1 className='text-center text-black fw-bolder mb-17px font-size-24'>
              {formatMessage({id: 'Forgot Password?'})}
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='fw-bold font-size-14 text-black'>
              {formatMessage({
                id: 'Enter the email that you used when register to recover your password. You will receive a password reset link.',
              })}
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Title */}
          {errorsArray?.map((error: string) => <CustomToast status={error} />)}
          {formik.status && <CustomToast status={formik.status} />}

          <TextInput
            fieldType={'email'}
            fieldName={'email'}
            formik={formik}
            placeholder={formatMessage({id: 'Enter email'})}
            label={formatMessage({id: 'Email'})}
            toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.FORGOT_PASSWORD.EMAIL'})}
            isStarRequired={true}
          />

          {/* begin::Form group */}

          <div className='d-flex justify-content-between align-items-center'>
            <div className='text-center fw-bold font-size-14'>
              <Link to='/login' className=' link-primary'>
                {formatMessage({id: 'Back to Login'})}
              </Link>
            </div>
            <GlobalButton
              buttonText={formatMessage({id: 'Submit'})}
              buttonType={ButtonTypes.PRIMARY}
              isLoading={loading}
              isDisable={formik.isSubmitting || !formik.isValid}
              onButtonClick={formik.handleSubmit}
            />
          </div>
          {/* end::Form group */}
        </Form>
      )}
    </Formik>
  )
}
