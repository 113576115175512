import axios from 'axios'
import {ProfileType} from '../core/_models'

export const API_URL = process.env.REACT_APP_BASE_API_URL
const GET_PROFILE_DATA = `${API_URL}/user`
const UPDATE_PROFILE_DATA = `${API_URL}/user`
const CREATE_LOCALE = `${API_URL}/userMeta`
export const GET_FILE_URL = `${API_URL}/file`
const GET_REFERRAL_DATA = `${API_URL}/referral/code`
const UPDATE_PAYPAL_EMAIL = `${API_URL}/referral/paypal`
const TOTAL_REFERRAL_EARNING = `${API_URL}/referral/earnings`
const USERS_DATA = `${API_URL}/team`
const TRANSFER_OWNERSHIP = `${API_URL}/team/transfer-ownership/users`
const AUTH_INVITE = `${API_URL}/auth/invite`
export const BILLING_DATA = `${API_URL}/billing`
const BILLING_PORTAL = `${API_URL}/billing/access`
const PENDING_USERS = `${API_URL}/team/pending-invite`
const COUNTRY_URL = `${API_URL}/country`
const TIME_ZONE_URL = `${API_URL}/timezones`
const TRANSACTION_URL = `${API_URL}/billing/transactions`
const CANCEL_SUBSCRIPTION = `${API_URL}/billing/subscription/cancel`
const DELETE_USER_PERMANENTLY = `${API_URL}/user/delete`
const DELETE_TEAM_MEMBER = `${API_URL}/team/remove-user`

export const profileData = () => {
  return axios.get(`${GET_PROFILE_DATA}`, {
    validateStatus() {
      return true
    },
  })
}

export const createLocale = (key: string, value: string) => {
  return axios.post(
    CREATE_LOCALE,
    {
      key,
      value,
    },
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const getLocale = () => {
  return axios.get(`${API_URL}/userMeta`, {
    validateStatus() {
      return true
    },
  })
}

export const updateProfileInfo = (profileData: ProfileType) => {
  return axios.put(UPDATE_PROFILE_DATA, profileData, {
    validateStatus() {
      return true
    },
  })
}

export const getReferralData = () => {
  return axios.get(GET_REFERRAL_DATA, {
    validateStatus() {
      return true
    },
  })
}

export const putPaypalReferralEmail = (data: {paypalEmail: string | undefined}) => {
  return axios.put(
    UPDATE_PAYPAL_EMAIL,
    {paypalEmail: data.paypalEmail},
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const getTotalReferralEarning = () => {
  return axios.get(TOTAL_REFERRAL_EARNING, {
    validateStatus() {
      return true
    },
  })
}

export const getUserList = () => {
  return axios.get(`${USERS_DATA}/users`, {
    validateStatus() {
      return true
    },
  })
}

export const updateTransferOwnership = (userId: number | undefined) => {
  return axios.post(`${TRANSFER_OWNERSHIP}/${userId}`, {
    validateStatus() {
      return true
    },
  })
}

export const addUser = (data: any) => {
  return axios.post(AUTH_INVITE, data, {
    validateStatus() {
      return true
    },
  })
}

export const deleteTeamMember = (userId: number) => {
  return axios.delete(`${DELETE_TEAM_MEMBER}/${userId}`, {
    validateStatus() {
      return true
    },
  })
}

export const getUser = (userId: number) => {
  return axios.get(`${USERS_DATA}/users/${userId}`, {
    validateStatus() {
      return true
    },
  })
}

export const editUser = (userId: number | undefined, data: any) => {
  return axios.put(`${USERS_DATA}/users/${userId}`, data, {
    validateStatus() {
      return true
    },
  })
}

export const getBillingData = () => {
  return axios.get(`${BILLING_DATA}`, {
    timeout: 10000,
    validateStatus() {
      return true
    },
  })
}
export const getBillingAccess = () => {
  return axios.get(`${BILLING_PORTAL}`, {
    validateStatus() {
      return true
    },
  })
}

export const getTransactionData = () => {
  return axios.get(`${TRANSACTION_URL}`, {
    validateStatus() {
      return true
    },
  })
}

export const updateBillingAccess = (
  planName: string | undefined,
  priceId: string | null,
  currencyId: string
) => {
  return axios.put(
    `${BILLING_DATA}`,
    {planName, priceId, currencyId},
    {
      validateStatus() {
        return true
      },
    }
  )
}
export const getPendingUsers = () => {
  return axios.get(`${PENDING_USERS}`, {
    validateStatus() {
      return true
    },
  })
}

export const deletePendingInvite = (inviteId: number) => {
  return axios.delete(`${PENDING_USERS}/delete/${inviteId}`, {
    validateStatus() {
      return true
    },
  })
}

// getCountry
export const getCountry = () => {
  return axios.get(COUNTRY_URL)
}

//get timezone
export const getTimeZone = () => {
  return axios.get(TIME_ZONE_URL)
}

export const cancelSubscription = (action: string | undefined, currencyId: string) => {
  return axios.delete(`${CANCEL_SUBSCRIPTION}?cancel_type=${action}&currencyId=${currencyId}`, {
    validateStatus() {
      return true
    },
  })
}

// delete user permanently
export const deleteUserPermanently = () => {
  return axios.delete(DELETE_USER_PERMANENTLY, {
    validateStatus() {
      return true
    },
  })
}
