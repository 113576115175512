/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import {Link, useLocation} from 'react-router-dom'
import {Form, Formik} from 'formik'
import {login, resendVerification} from '../core/_requests'
import {useAuth} from '../core/Auth'
import SocialLoginButtons from '../components/SocialLoginButtons'
import TextInput from '../../widgets/components/Input/TextInput'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {CustomToast} from '../../widgets/components/UI/CustomToast'
import {FormikProps} from '../core/_models'
import {Toaster} from '../../widgets/components/General/Toaster'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState(false)
  const [emailDisabled, setEmailDisabled] = useState<boolean>(false)
  const {saveAuth, setUserToken, setNewTeam} = useAuth()
  const navigate = useNavigate()
  const [errorsArray, setErrorsArray] = useState<string[]>()
  const [showModal, setShowModal] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const [mailValue, setMailValue] = useState<string>()
  const [resendEmailDisabled, setResendEmailDisabled] = useState<boolean>(false)

  const initialValues = {
    email: '',
    password: '',
  }

  const initialEmailValues = {
    email: '',
  }
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({id: 'Invalid email format'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Email is required'})),
    password: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 characters'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Password is required'})),
  })

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(formatMessage({id: 'Invalid email format'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Email is required'})),
  })

  const onSubmit = async (values: any, {setSubmitting}: FormikProps) => {
    setLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await login(values.email, values.password)
      if (success) {
        await saveAuth(data)
        setUserToken(data.token)
        if (!data.chargebeePlanPresent) {
          setNewTeam(false)
        }
        navigate('/', {state: {email: values.email}})
      } else {
        if (errors[0].toString() === 'User is not verified yet') {
          setShowModal(true)
        }
        setErrorsArray(errors)
        await saveAuth(undefined)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
      setLoading(false)
    }
  }

  const handleOnSubmit = async (values: {email: string}) => {
    setEmailLoading(true)
    try {
      const {
        data: {success, errors},
      } = await resendVerification(values)
      if (success) {
        toast.success(formatMessage({id: 'Please check your email for verification link'}))
        setEmailLoading(false)
        setShowModal(false)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error) {
      console.log(error)
      setEmailLoading(false)
    } finally {
      setEmailLoading(false)
    }
  }

  const {state} = useLocation() as any
  return (
    <>
      <Toaster />
      <Formik
        validationSchema={loginSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnMount
      >
        {(formik) => {
          if (state) {
            initialValues.email = state.email
            setEmailDisabled(true)
          }
          setMailValue(formik?.values?.email)
          return (
            <>
              <Form className='form w-100 px-sm-3' id='kt_login_signin_form'>
                {/* begin::Heading */}
                <div className='text-center mb-10'>
                  <h1 className='text-black fw-bolder mb-29px'>
                    {formatMessage({id: 'Sign in to DripFunnel'})}
                  </h1>
                  <SocialLoginButtons />
                </div>
                {/* begin::Heading */}
                <div className='text-center mb-7 fw-bold font-size-14 text-black'>
                  {formatMessage({id: 'OR'})}
                </div>
                {errorsArray?.map((error: string) => <CustomToast status={error} />)}
                {/* begin::Form group */}
                <TextInput
                  isDisabled={emailDisabled}
                  fieldName={'email'}
                  formik={formik}
                  fieldType={'text'}
                  placeholder={formatMessage({id: 'Enter Email'})}
                  label={formatMessage({id: 'Email'})}
                  toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.LOGIN.EMAIL'})}
                  isStarRequired={true}
                />
                {/* end::Form group */}

                {/* begin::Form group */}
                <TextInput
                  fieldName={'password'}
                  formik={formik}
                  fieldType={'text'}
                  isPassword={true}
                  placeholder={formatMessage({id: 'Password'})}
                  isForgetPassword={true}
                  label={formatMessage({id: 'Password'})}
                  toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.LOGIN.PASSWORD'})}
                  isStarRequired={true}
                />
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='text-center fw-bold font-size-14 text-black'>
                    {formatMessage({id: "Don't have an account yet?"})}{' '}
                    <Link to='/registration/' className='link-primary'>
                      {formatMessage({id: 'Sign Up'})}
                    </Link>
                  </div>
                  <GlobalButton
                    buttonText={formatMessage({id: 'Sign In'})}
                    buttonType={ButtonTypes.PRIMARY}
                    isLoading={loading}
                    isDisable={formik.isSubmitting || !formik.isValid}
                    onButtonClick={formik.handleSubmit}
                  />
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <div className='mb-n5'>
            <h2>{formatMessage({id: 'Account not verified'})}</h2>
            <p className='mt-2'>
              {formatMessage({
                id: 'Your account is not yet verified. Please check your email for the verification link. If you have not received it yet, we can resend it to you.',
              })}
            </p>
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialEmailValues}
          validationSchema={EmailSchema}
          onSubmit={handleOnSubmit}
        >
          {(formik) => {
            if (mailValue) {
              initialEmailValues.email = mailValue
              setResendEmailDisabled(true)
            }
            return (
              <Form>
                <Modal.Body>
                  <div>
                    <TextInput
                      isDisabled={resendEmailDisabled}
                      fieldName={'email'}
                      formik={formik}
                      fieldType={'text'}
                      placeholder={formatMessage({id: 'Enter Email'})}
                      label={formatMessage({id: 'Email'})}
                      toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.LOGIN.EMAIL'})}
                      isStarRequired={true}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer className='p-0'>
                  <div className='d-flex justify-content-between gap-3 my-10 me-10'>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Cancel'})}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={() => {
                        setShowModal(false)
                        setLoading(false)
                      }}
                    />
                    <GlobalButton
                      buttonText={formatMessage({id: 'Resend email'})}
                      buttonType={ButtonTypes.PRIMARY}
                      isLoading={emailLoading}
                      isDisable={formik.isSubmitting || !formik.isValid}
                      onButtonClick={formik.handleSubmit}
                    />
                  </div>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
