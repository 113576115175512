import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {ResponseType} from '../../../core/_models'

const API_URL = process.env.REACT_APP_BASE_API_URL

export const LOGIN_URL = `${API_URL}/auth/signin`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const VERIFY_REGISTER_URL = `${API_URL}/auth/verify-register`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset`
export const FORGOT_PASSWORD = `${API_URL}/auth/forgot`
export const SOCIAL_LOGIN_VARIFICATION = `${API_URL}/auth/social-signin`
export const INVITE_USER = `${API_URL}/auth/invite`
export const ACCEPT_INVITATION = `${API_URL}/auth/accept-invite`
export const CURRENCIES = `${API_URL}/currency`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify-token`
export const VERIFY_TOKEN = `${API_URL}/auth/verify-token`
export const VERIFY_INVITE = `${API_URL}/auth/verify-invite`
export const ACCEPT_INVITE = `${API_URL}/auth/accept-invite`

const VERIFY_RESET_LINK = `${API_URL}/auth/reset/verify`
const RESEND_VERIFICATION = `${API_URL}/auth/resend-verification`
const RESEND_INVITE = `${API_URL}/auth/resend-invite`
// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
const IPSTACK_URL = `${API_URL}/location`

// Server should return AuthModel
//signin
export function login(email: string, password: string) {
  return axios.post<ResponseType<AuthModel>>(
    LOGIN_URL,
    {
      email,
      password,
    },
    {
      validateStatus() {
        return true
      },
    }
  )
}

//signup
export function verifyRegister(token: string) {
  return axios.post(
    VERIFY_REGISTER_URL,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },

      validateStatus() {
        return true
      },
    }
  )
}

// verify-sigup
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  utmValues: any,
  referral: string,
  locale: string,
  dateFormat: string,
  timeFormat: string,
  timeZone: number,
  countryId: number
) {
  return axios.post(
    REGISTER_URL,
    {
      email,
      firstName: firstname,
      lastName: lastname,
      password,
      ...utmValues,
      referral,
      locale,
      dateFormat,
      timeFormat,
      timeZone,
      countryId,
    },
    {
      validateStatus() {
        return true
      },
    }
  )
}

//reset password
export function resetPassword(token: string, password: string) {
  return axios.patch(
    RESET_PASSWORD_URL,
    {
      token,
      password,
    },
    {
      validateStatus() {
        return true
      },
    }
  )
}

//forgot password
export function forgotPassword(email: string) {
  return axios.post(
    FORGOT_PASSWORD,
    {email},
    {
      validateStatus() {
        return true
      },
    }
  )
}

//social login varification
export function socialLoginVerification(type: string, token: string) {
  return axios.post(SOCIAL_LOGIN_VARIFICATION, {type, token})
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

export function getUserByToken(token: string) {
  return axios.post<ResponseType<UserModel>>(GET_USER_BY_ACCESSTOKEN_URL, {
    token,
  })
}

export function verifyToken(token: string) {
  localStorage.setItem('kt-auth-react-v', JSON.stringify({token}))
  return axios.post(VERIFY_TOKEN, {token})
}

export function verifyInvite(inviteToken: string) {
  return axios.post(
    VERIFY_INVITE,
    {inviteToken},
    {
      validateStatus() {
        return true
      },
    }
  )
}

export function acceptInvite(
  inviteToken: string,
  password: string,
  locale: string,
  dateFormat: string,
  timeFormat: string,
  timeZone: number,
  countryId: number
) {
  return axios.post(ACCEPT_INVITE, {
    inviteToken,
    password,
    locale,
    dateFormat,
    timeFormat,
    timeZone,
    countryId,
  })
}

export const getTokenStatus = (token: string) => {
  return axios.post(VERIFY_RESET_LINK, {token})
}

export const resendVerification = (payload: {email: string}) => {
  return axios.post(RESEND_VERIFICATION, payload, {
    validateStatus() {
      return true
    },
  })
}

export const resendInvite = (email: string) => {
  return axios.post(
    RESEND_INVITE,
    {email},
    {
      validateStatus() {
        return true
      },
    }
  )
}

//getLocation
export const getLocation = () => {
  return axios.get(IPSTACK_URL, {
    validateStatus() {
      return true
    },
  })
}
