import axios from 'axios'
import {serialize} from '../../../core/_util'
const API_URL = process.env.REACT_APP_BASE_API_URL
// API declarations
export const GET_WORKFLOW_ACTIONS = `${API_URL}/workflow/actions`
export const GET_ALL_WORKFLOWS = `${API_URL}/workflow`
export const DELETE_WORKFLOW = `${API_URL}/workflow`
export const CREATE_WORKFLOW_STEP = `${API_URL}/workflow/step`
export const UPDATE_WORKFLOW_STEP_VALUE = `${API_URL}/workflow/value`
export const DELETE_WORKFLOW_STEP = `${API_URL}/workflow/step`
export const GET_ALLOWED_WORKFLOWACTIONS = `${API_URL}/workflow/actions/allowed`

export const getWorkflowActions = () => {
  return axios.get(`${GET_WORKFLOW_ACTIONS}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const getAllWorkflows = (type?: string) => {
  const queryParams = {
    type,
  }
  return axios.get(`${GET_ALL_WORKFLOWS}?${serialize(queryParams)}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const addNewWorkflow = (name: string) => {
  const payload = {
    name,
  }

  return axios.post(GET_ALL_WORKFLOWS, payload, {
    validateStatus(status) {
      return true
    },
  })
}

export const deleteWorkflow = (workflowId: number) => {
  return axios.delete(`${DELETE_WORKFLOW}/${workflowId}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const getSingleWorkflow = (workflowId: number) => {
  return axios.get(`${GET_ALL_WORKFLOWS}/${workflowId}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const createWorkflowStep = (
  workflowId: number,
  workflowStepId: number | null,
  workflowActionId: string | undefined,
  isPositive: boolean | null
) => {
  if (workflowStepId) {
    const payload = {
      workflowId,
      workflowStepId,
      workflowActionId,
      isPositive,
    }
    return axios.post(CREATE_WORKFLOW_STEP, payload, {
      validateStatus(status) {
        return true
      },
    })
  } else {
    const payload = {
      workflowId,
      workflowActionId,
    }

    return axios.post(CREATE_WORKFLOW_STEP, payload, {
      validateStatus(status) {
        return true
      },
    })
  }
}

export const updateWorkflowStep = (
  primaryValue: string,
  secondaryValue: string,
  workflowStepId: number | null
) => {
  const payload = {
    primaryValue,
    secondaryValue,
  }
  return axios.put(`${UPDATE_WORKFLOW_STEP_VALUE}/${workflowStepId}`, payload, {
    validateStatus(status) {
      return true
    },
  })
}

export const deleteWorkflowStep = (workflowStepId: number | null) => {
  return axios.delete(`${DELETE_WORKFLOW_STEP}/${workflowStepId}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const getAllowedWorkflowActions = () => {
  return axios.get(`${GET_ALLOWED_WORKFLOWACTIONS}`, {
    validateStatus(status) {
      return true
    },
  })
}
