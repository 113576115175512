import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {ButtonTypes} from '../core/_constants'
import {GlobalButton} from './UI/GlobalButton'

export const DeleteConfirmationModal = ({
  showDeleteModal,
  setDeleteShowModal,
  headingMessage,
  description,
  handleDelete,
  deleteLoading,
  id,
  formik,
  companyFolderContentId,
  activeContentId,
}: {
  showDeleteModal: boolean
  setDeleteShowModal: Dispatch<SetStateAction<boolean>>
  headingMessage: string
  description: string
  handleDelete?: any
  deleteLoading?: boolean
  id?: any
  formik?: any
  companyFolderContentId?: any
  activeContentId?: any
}) => {
  const {formatMessage} = useIntl()
  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={ref}>
        <Modal
          show={showDeleteModal}
          onHide={() => setDeleteShowModal(false)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          container={ref.current}
        >
          <Modal.Header closeButton className='border-bottom-0 pb-3'>
            <h2 className='text-color'>{formatMessage({id: headingMessage})}</h2>
          </Modal.Header>
          <Modal.Body className='py-1 font-size-14 text-color'>
            <p>
              {formatMessage({
                id: description,
              })}
            </p>
          </Modal.Body>
          <Modal.Footer className='pt-0 border-top-0'>
            <div className='w-100 d-flex justify-content-end gap-3'>
              <GlobalButton
                buttonText={formatMessage({id: 'Cancel'})}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={() => setDeleteShowModal(false)}
              />

              {headingMessage === 'Remove Image' && (
                <GlobalButton
                  buttonText={formatMessage({id: 'Remove Image'})}
                  buttonType={ButtonTypes.PRIMARY}
                  onButtonClick={() => handleDelete(formik)}
                  isLoading={deleteLoading}
                  isDisable={deleteLoading}
                />
              )}

              {headingMessage !== 'Remove Image' && (
                <GlobalButton
                  buttonText={formatMessage({id: 'Confirm Delete'})}
                  buttonType={ButtonTypes.PRIMARY}
                  onButtonClick={() => {
                    if (activeContentId === companyFolderContentId) {
                      handleDelete(id)
                    } else {
                      handleDelete(id)
                    }
                  }}
                  isLoading={deleteLoading}
                  isDisable={deleteLoading}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}
