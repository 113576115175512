import React, {useEffect, useState} from 'react'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import {FilterForLeads} from '../createCampaignSteps/FilterForLeads'
import {useParams} from 'react-router'
import {updateCampaignDetails} from '../../core/_requests'
import {toast} from 'react-toastify'
import {CampaignSettings} from '../createCampaignSteps/CampaignSetting'
import {workingHour} from '../../core/_models'

export const SettingsAnalyticsPage = ({
  campaignName,
  excludeOtherCampaignLeadValue,
  excludeTeamCampaignLeadValue,
  getCampaignData,
  totalLeadsCount,
  workingHour,
  workflowName,
  leadsCount,
  totalExpectedLeads,
}: {
  campaignName: string | undefined
  excludeOtherCampaignLeadValue: boolean | undefined
  excludeTeamCampaignLeadValue: boolean | undefined
  getCampaignData: () => Promise<void>
  totalLeadsCount: number | undefined
  workingHour: workingHour | undefined
  workflowName: string | undefined
  leadsCount?: number
  totalExpectedLeads?: number
}) => {
  const {formatMessage} = useIntl()
  const [excludeTeamCampaignLead, setExcludeTeamCampaignLead] = useState<boolean | undefined>()
  const [excludeOtherCampaignLead, setExcludeOtherCampaignLead] = useState<boolean | undefined>()
  const [name, setName] = useState<string>()

  useEffect(() => {
    if (excludeTeamCampaignLeadValue) {
      setExcludeTeamCampaignLead(excludeTeamCampaignLeadValue)
    }
    if (excludeOtherCampaignLeadValue) {
      setExcludeOtherCampaignLead(excludeOtherCampaignLeadValue)
    }
  }, [excludeTeamCampaignLeadValue, excludeOtherCampaignLeadValue])

  useEffect(() => {
    setName(campaignName)
  }, [campaignName])

  return (
    <>
      <div className='p-8'>
        <div className='font-size-20 fw-bold pb-7'>{formatMessage({id: 'Settings'})}</div>
        <CampaignSettings
          campaignName={campaignName}
          totalLeadsCount={totalLeadsCount}
          excludeOtherCampaignLead={excludeOtherCampaignLead}
          excludeTeamCampaignLead={excludeTeamCampaignLead}
          workingHour={workingHour}
          workflowName={workflowName}
          isCampaignDetails={true}
          name={name}
          getCampaignData={getCampaignData}
          leadsCount={leadsCount}
          totalExpectedLeads={totalExpectedLeads}
        />
      </div>
    </>
  )
}
