import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import TextInput from '../../widgets/components/Input/TextInput'
import {SelectInput} from '../../widgets/components/Input/SelectInput'
import {updateWorkflowStep} from '../core/_requests'
import {toast} from 'react-toastify'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const AddWaitTimeModal = ({
  openTimeModal,
  setOpenTimeModal,
  delayTimeLoader,
  setdelayTimeLoader,
  workflowStepId,
  primaryValue,
  secondaryValue,
  getSingleWorkflowData,
  workflowId,
}: {
  openTimeModal: boolean
  setOpenTimeModal: Dispatch<SetStateAction<boolean>>
  delayTimeLoader: boolean
  setdelayTimeLoader: Dispatch<SetStateAction<boolean>>
  workflowStepId: number | null
  primaryValue: string
  secondaryValue: string
  getSingleWorkflowData: (value: number) => void
  workflowId: number
}) => {
  const {formatMessage} = useIntl()
  const initialValues = {
    delayTime: '',
    timeUnit: '',
  }
  const workflowSchema = Yup.object().shape({
    delayTime: Yup.string()
      .max(2, formatMessage({id: 'The field may not be greater than two characters'}))
      .required('Delay time is required'),
    timeUnit: Yup.string().required('Time unit is required'),
  })

  const handleUpdateDelayTimeValue = async (delayTime: string, unitTime: string) => {
    const delayString = delayTime.toString()
    try {
      setdelayTimeLoader(true)
      const {
        data: {success, errors},
      } = await updateWorkflowStep(delayString, unitTime, workflowStepId)
      if (success) {
        setOpenTimeModal(false)
        setdelayTimeLoader(false)
        toast.success(formatMessage({id: 'Time gap updated successfully'}))
        getSingleWorkflowData(workflowId)
      } else {
        setOpenTimeModal(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setdelayTimeLoader(false)
      }
    } catch (err) {
      setdelayTimeLoader(false)
      console.log(err)
    }
  }
  const unitOptions = [
    {id: '1', name: 'days'},
    {id: '2', name: 'hours'},
  ]
  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <>
      <div ref={ref}>
        <Modal
          size={'sm'}
          show={openTimeModal}
          onHide={() => {
            setOpenTimeModal(false)
          }}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          container={ref.current}
        >
          <Modal.Header closeButton className='border-bottom-0'>
            <div className='font-size-16 fw-bold'>
              {formatMessage({id: 'Delay before the next action:'})}
            </div>
          </Modal.Header>
          <Formik
            initialValues={initialValues}
            validationSchema={workflowSchema}
            onSubmit={(values, formikHelpers) => {
              handleUpdateDelayTimeValue(values.delayTime, values.timeUnit)
            }}
            validateOnMount
          >
            {(formik) => {
              if (primaryValue && secondaryValue) {
                formik.initialValues.delayTime = primaryValue
                formik.initialValues.timeUnit = secondaryValue
              }
              return (
                <Form>
                  <Modal.Body className='pt-0 pb-2 px-12'>
                    <div className='d-flex justify-content-center'>
                      <TextInput
                        fieldType={'number'}
                        fieldName={'delayTime'}
                        formik={formik}
                        placeholder={formatMessage({id: 'Enter time'})}
                        label={''}
                        isStarRequired={false}
                        isTooltipNotRequired={true}
                        width={4}
                        margin={'me-8'}
                        isMargin='mb-0'
                      />
                      <div className='select-time-unit'>
                        <SelectInput
                          label={''}
                          isStarRequired={true}
                          fieldName={'timeUnit'}
                          placeholder={formatMessage({id: 'Select time Unit'})}
                          formik={formik}
                          options={unitOptions}
                          width={12}
                          heightCustom={true}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className='border-top-0'>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Cancel'})}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={() => {
                        setOpenTimeModal(false)
                      }}
                    />
                    <GlobalButton
                      buttonText={formatMessage({id: 'Apply'})}
                      buttonType={ButtonTypes.PRIMARY}
                      isLoading={delayTimeLoader}
                      isDisable={formik.isSubmitting || !formik.isValid || delayTimeLoader}
                      onButtonClick={formik.handleSubmit}
                    />
                  </Modal.Footer>
                </Form>
              )
            }}
          </Formik>
        </Modal>
      </div>
    </>
  )
}
