import {useIntl} from 'react-intl'
import {Limits} from '../core/_constant'

export function ActivityTypeMessage() {
  const {formatMessage} = useIntl()

  const activityMessage = (activityKey: string) => {
    if (activityKey === Limits.EMAILREQUEST) {
      return formatMessage({id: 'Email Request'})
    } else if (activityKey === Limits.VIEWS) {
      return formatMessage({id: 'Profile Views'})
    } else if (activityKey === Limits.LIKES) {
      return formatMessage({id: 'Post Likes'})
    } else if (activityKey === Limits.FOLLOWS) {
      return formatMessage({id: 'Follows'})
    } else if (activityKey === Limits.INMAIL) {
      return formatMessage({id: 'InMail'})
    } else if (activityKey === Limits.ENDORSEMENTS) {
      return formatMessage({id: 'Endorsements'})
    } else if (activityKey === Limits.MESSAGE) {
      return formatMessage({id: 'Messages'})
    } else if (activityKey === Limits.REQUEST) {
      return formatMessage({id: 'Connection Request'})
    } else {
      return ''
    }
  }

  return {activityMessage}
}
