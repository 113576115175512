import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {PlanCycle} from '../../onboarding/core/_constants'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: Partial<UserModel> | undefined
  setCurrentUser: Dispatch<SetStateAction<Partial<UserModel> | undefined>>
  logout: () => void
  userToken: string | undefined
  setUserToken: Dispatch<SetStateAction<string | undefined>>
  investorId: number | undefined
  storeInvestorId: Dispatch<SetStateAction<number | undefined>>
  newTeam: boolean | undefined
  setNewTeam: Dispatch<SetStateAction<boolean | undefined>>
  showBillingModal: boolean
  setShowBillingModal: Dispatch<SetStateAction<boolean>>
  currentState: string
  setCurrentState: Dispatch<SetStateAction<string>>
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
  currencyBill: string
  setCurrencyBill: Dispatch<SetStateAction<string>>
  billingData: any
  setBillingData: Dispatch<SetStateAction<any>>
  setRole: Dispatch<SetStateAction<string | undefined>>
  role: string | undefined
  showBillingAccessModal: boolean
  setShowBillingAccessModal: Dispatch<SetStateAction<boolean>>
  billingPortalUrl: string | undefined
  setBillingPortalUrl: Dispatch<SetStateAction<string | undefined>>
  teamId: number | undefined
  storeTeamId: Dispatch<SetStateAction<number | undefined>>
  selectedPlan: any
  setSelectedPlan: Dispatch<SetStateAction<any>>
  stepperIndex: number | undefined | null
  setStepperIndex: Dispatch<SetStateAction<number | undefined | null>>
  paymentModal: boolean
  setPaymentModal: Dispatch<SetStateAction<boolean>>
  clicked: string
  setClickedId: Dispatch<SetStateAction<string>>
  priceId: string
  setPriceId: Dispatch<SetStateAction<string>>
  showBillingSummary: boolean
  setShowBillingSummary: Dispatch<SetStateAction<boolean>>
  cardDigits: string
  setCardDigits: Dispatch<SetStateAction<string>>
  cardBillingDetails: any
  setCardBillingDetails: Dispatch<SetStateAction<Object>>
  expiryDateMonth: string
  setExpiryDateMonth: Dispatch<SetStateAction<string>>
  expiryDateYear: string
  setExpiryDateYear: Dispatch<SetStateAction<string>>
  cardBrand: string
  setCardBrand: Dispatch<SetStateAction<string>>
  active: PlanCycle
  setActive: Dispatch<SetStateAction<PlanCycle>>
  billingClick: string
  setBillingClick: Dispatch<SetStateAction<string>>
  updateCardDetails: boolean
  setUpdateCardDetails: Dispatch<SetStateAction<boolean>>
  proRatedData: any
  setProRatedData: Dispatch<SetStateAction<any>>
  percentoff: number | null
  setPercentOff: Dispatch<SetStateAction<any>>
  invalidCoupon: string
  setInvalidCoupon: Dispatch<SetStateAction<any>>
  couponCode: string
  setCouponCode: Dispatch<SetStateAction<any>>
  teamLength: number
  setTeamLength: Dispatch<SetStateAction<number>>
  selectedCurrency: string | undefined
  setSelectedCurrency: Dispatch<SetStateAction<string | undefined>>
  currencyCode: string | undefined
  setCurrencyCode: Dispatch<SetStateAction<string | undefined>>
  scheduleDowngradeData: any
  setScheduleDowngradeData: Dispatch<SetStateAction<any>>
  proRatedLoading: boolean
  setProRatedLoading: Dispatch<SetStateAction<boolean>>
  workflowActionValue: string
  setworkflowActionValue: Dispatch<SetStateAction<string>>
  isPlanDowngrade: boolean
  setIsPlanDowngrade: Dispatch<SetStateAction<boolean>>
  plans: any
  setPlans: Dispatch<SetStateAction<any>>
  taxBeforeCoupon: number | undefined
  setTaxBeforeCoupon: Dispatch<SetStateAction<number | undefined>>
  total: number | undefined
  setTotal: Dispatch<SetStateAction<number | undefined>>
  plansLoading: boolean
  getPlansApiLoading: Dispatch<SetStateAction<boolean>>
  plansCurrency: string | undefined
  setPlansCurrency: Dispatch<SetStateAction<string | undefined>>
  analyticsClick: boolean
  setAnalyticsClick: Dispatch<SetStateAction<boolean>>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  data: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  userToken: undefined,
  setUserToken: () => {},
  investorId: undefined,
  storeInvestorId: () => {},
  newTeam: undefined,
  setNewTeam: () => {},
  showBillingModal: false,
  setShowBillingModal: () => {},
  currentState: 'Monthly',
  setCurrentState: () => {},
  selected: 'Business',
  setSelected: () => {},
  currencyBill: 'USD',
  setCurrencyBill: () => {},
  billingData: undefined,
  setBillingData: () => {},
  role: undefined,
  setRole: () => {},
  showBillingAccessModal: false,
  setShowBillingAccessModal: () => {},
  billingPortalUrl: undefined,
  setBillingPortalUrl: () => {},
  teamId: undefined,
  storeTeamId: () => {},
  selectedPlan: [],
  setSelectedPlan: () => {},
  stepperIndex: null,
  setStepperIndex: () => {},
  paymentModal: false,
  setPaymentModal: () => {},
  clicked: '',
  setClickedId: () => {},
  priceId: '',
  setPriceId: () => {},
  showBillingSummary: false,
  setShowBillingSummary: () => false,
  cardDigits: '',
  setCardDigits: () => {},
  cardBillingDetails: undefined,
  setCardBillingDetails: () => {},
  expiryDateMonth: '',
  setExpiryDateMonth: () => {},
  expiryDateYear: '',
  setExpiryDateYear: () => {},
  cardBrand: '',
  setCardBrand: () => {},
  active: PlanCycle.YEARLY,
  setActive: () => {},
  billingClick: '',
  setBillingClick: () => {},
  updateCardDetails: false,
  setUpdateCardDetails: () => false,
  proRatedData: undefined,
  setProRatedData: () => {},
  percentoff: null,
  setPercentOff: () => {},
  invalidCoupon: '',
  setInvalidCoupon: () => {},
  couponCode: '',
  setCouponCode: () => {},
  teamLength: 0,
  setTeamLength: () => {},
  selectedCurrency: undefined,
  setSelectedCurrency: () => {},
  currencyCode: undefined,
  setCurrencyCode: () => {},
  scheduleDowngradeData: undefined,
  setScheduleDowngradeData: () => {},
  proRatedLoading: false,
  setProRatedLoading: () => {},
  workflowActionValue: '',
  setworkflowActionValue: () => {},
  isPlanDowngrade: false,
  setIsPlanDowngrade: () => {},
  plans: [],
  setPlans: () => {},
  taxBeforeCoupon: undefined,
  setTaxBeforeCoupon: () => {},
  total: undefined,
  setTotal: () => {},
  plansLoading: false,
  getPlansApiLoading: () => {},
  plansCurrency: undefined,
  setPlansCurrency: () => {},
  analyticsClick: false,
  setAnalyticsClick: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<Partial<UserModel> | undefined>()
  const [userToken, setUserToken] = useState<string | undefined>()
  const [investorId, storeInvestorId] = useState<number | undefined>()
  const [newTeam, setNewTeam] = useState<boolean | undefined>()
  const [showBillingModal, setShowBillingModal] = useState<boolean>(false)
  const [currentState, setCurrentState] = useState<string>('Monthly')
  const [selected, setSelected] = useState('Business')
  const [currencyBill, setCurrencyBill] = useState('USD')
  const [billingData, setBillingData] = useState<any>()
  const [role, setRole] = useState<any>()
  const [showBillingAccessModal, setShowBillingAccessModal] = useState(false)
  const [billingPortalUrl, setBillingPortalUrl] = useState<string | undefined>()
  const [teamId, storeTeamId] = useState<any>()
  const [selectedPlan, setSelectedPlan] = useState<any>([])
  const [stepperIndex, setStepperIndex] = useState<number | undefined | null>()
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [clicked, setClickedId] = useState<string>('')
  const [priceId, setPriceId] = useState<string>('')
  const [showBillingSummary, setShowBillingSummary] = useState<boolean>(false)
  const [cardDigits, setCardDigits] = useState<string>('')
  const [expiryDateMonth, setExpiryDateMonth] = useState<string>('')
  const [expiryDateYear, setExpiryDateYear] = useState<string>('')
  const [cardBillingDetails, setCardBillingDetails] = useState<any>()
  const [cardBrand, setCardBrand] = useState<string>('')
  const [active, setActive] = useState(PlanCycle.MONTHLY)
  const [billingClick, setBillingClick] = useState<string>('')
  const [updateCardDetails, setUpdateCardDetails] = useState<boolean>(false)
  const [proRatedData, setProRatedData] = useState<any>()
  const [percentoff, setPercentOff] = useState<number | null>(null)
  const [invalidCoupon, setInvalidCoupon] = useState<string>('')
  const [couponCode, setCouponCode] = useState<string>('')
  const [teamLength, setTeamLength] = useState<number>(0)
  const [selectedCurrency, setSelectedCurrency] = useState<string | undefined>()
  const [currencyCode, setCurrencyCode] = useState<string | undefined>()
  const [scheduleDowngradeData, setScheduleDowngradeData] = useState<any>()
  const [proRatedLoading, setProRatedLoading] = useState<boolean>(false)
  const [workflowActionValue, setworkflowActionValue] = useState<string>('')
  const [isPlanDowngrade, setIsPlanDowngrade] = useState(false)
  const [plans, setPlans] = useState<any>([])
  const [taxBeforeCoupon, setTaxBeforeCoupon] = useState<number | undefined>()
  const [total, setTotal] = useState<number | undefined>()
  const [plansLoading, getPlansApiLoading] = useState<boolean>(false)
  const [plansCurrency, setPlansCurrency] = useState<string | undefined>()
  const [analyticsClick, setAnalyticsClick] = useState<boolean>(false)
  const saveAuth = async (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth({token: auth.token})

      const {data} = await getUserByToken(auth.token)
      if (data) {
        setCurrentUser(data.data)
      }
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    await saveAuth(undefined)
    setCurrentUser(undefined)
    setUserToken(undefined)
    storeTeamId(undefined)
    storeInvestorId(undefined)
    setShowBillingModal(false)
    localStorage.removeItem('teamId')
    setNewTeam(undefined)
    setCurrentState('')
    setSelected('')
    setCurrencyBill('')
    // setBillingData(undefined)
    setRole(undefined)
    setShowBillingAccessModal(false)
    setBillingPortalUrl(undefined)
    setSelectedPlan(undefined)
    setStepperIndex(undefined)
    setPaymentModal(false)
    setClickedId('')
    setPriceId('')
    setShowBillingSummary(false)
    setCardDigits('')
    setExpiryDateMonth('')
    setExpiryDateYear('')
    setCardBillingDetails(undefined)
    setCardBrand('')
    setActive(PlanCycle.MONTHLY)
    setBillingClick('')
    setUpdateCardDetails(false)
    setProRatedData(undefined)
    setPercentOff(null)
    setInvalidCoupon('')
    setCouponCode('')
    setTeamLength(0)
    // setSelectedCurrency(undefined)
    // setCurrencyCode(undefined)
    setScheduleDowngradeData(undefined)
    setProRatedLoading(false)
    setworkflowActionValue('')
    setIsPlanDowngrade(false)
    setAuth(undefined)
    setPlans([])
    setTaxBeforeCoupon(undefined)
    setTotal(undefined)
    getPlansApiLoading(false)
    setPlansCurrency(undefined)
    setAnalyticsClick(false)
  }
  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        setUserToken,
        userToken,
        investorId,
        storeInvestorId,
        newTeam,
        setNewTeam,
        showBillingModal,
        setShowBillingModal,
        currentState,
        setCurrentState,
        selected,
        setSelected,
        currencyBill,
        setCurrencyBill,
        billingData,
        setBillingData,
        setRole,
        role,
        showBillingAccessModal,
        setShowBillingAccessModal,
        billingPortalUrl,
        setBillingPortalUrl,
        teamId,
        storeTeamId,
        selectedPlan,
        setSelectedPlan,
        stepperIndex,
        setStepperIndex,
        paymentModal,
        setPaymentModal,
        clicked,
        setClickedId,
        priceId,
        setPriceId,
        showBillingSummary,
        setShowBillingSummary,
        cardDigits,
        setCardDigits,
        cardBillingDetails,
        setCardBillingDetails,
        expiryDateMonth,
        setExpiryDateMonth,
        expiryDateYear,
        setExpiryDateYear,
        cardBrand,
        setCardBrand,
        active,
        setActive,
        billingClick,
        setBillingClick,
        updateCardDetails,
        setUpdateCardDetails,
        proRatedData,
        setProRatedData,
        percentoff,
        setPercentOff,
        invalidCoupon,
        setInvalidCoupon,
        couponCode,
        setCouponCode,
        teamLength,
        setTeamLength,
        selectedCurrency,
        setSelectedCurrency,
        currencyCode,
        setCurrencyCode,
        scheduleDowngradeData,
        setScheduleDowngradeData,
        proRatedLoading,
        setProRatedLoading,
        workflowActionValue,
        setworkflowActionValue,
        isPlanDowngrade,
        setIsPlanDowngrade,
        plans,
        setPlans,
        taxBeforeCoupon,
        setTaxBeforeCoupon,
        total,
        setTotal,
        plansLoading,
        getPlansApiLoading,
        plansCurrency,
        setPlansCurrency,
        analyticsClick,
        setAnalyticsClick,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data.data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
