import axios from 'axios'
import {Limit} from './models/LimitType'
import {serialize} from '../../../core/_util'

const API_URL = process.env.REACT_APP_BASE_API_URL

const ACTIVITY_LOG = `${API_URL}/activity`

export const getUserActivityLogs = (page: number, campaignId?: number, userId?: number) => {
  const object = {
    page,
    size: 10,
    userId,
    campaignId,
  }
  return axios.get(`${ACTIVITY_LOG}?${serialize(object)}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const getUserActivity = (userId?: number) => {
  const object = {
    userId,
  }
  return axios.get(`${ACTIVITY_LOG}/activity-limit?${serialize(object)}`, {
    validateStatus(status) {
      return true
    },
  })
}

export const updateUserActivity = (
  body: Record<string, number | Limit | string | boolean>,
  selectedTeamMember: number | undefined
) => {
  const object = {
    userId: selectedTeamMember,
  }
  return axios.put(
    `${ACTIVITY_LOG}/edit-activity-limit?${serialize(object)}`,
    {...body},
    {
      validateStatus(status) {
        return true
      },
    }
  )
}

export const getAllUsersOfTeam = () => {
  return axios.get(`${API_URL}/team/users`, {
    validateStatus(status) {
      return true
    },
  })
}

export const getAllCampaign = () => {
  return axios.get(`${API_URL}/campaign`, {
    validateStatus(status) {
      return true
    },
  })
}
