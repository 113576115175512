import React, {useState, useEffect} from 'react'
import '../styles/_template.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {DateTime} from 'luxon'
import {deleteWorkflow} from '../core/_requests'
import {DeleteConfirmationModal} from '../../widgets/components/DeleteConfirmationModal'
import {toast} from 'react-toastify'
import {templateType} from '../../campaign/core/_constants'
import {user} from '../core/models/_workflowCard'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import {useTimeFormat} from '../../../hooks/useTimeFormat'

export const WorkflowList = ({
  btnText,
  lastEditedDate,
  creationDate,
  workflowId,
  userfirstName,
  userId,
  teamId,
  getAllWorkflowsData,
  status,
  workflowUpdatedBy,
  selectPage,
  selectedCardId,
}: {
  btnText: string
  lastEditedDate?: string
  creationDate: string
  workflowId: number
  userfirstName: string
  userId: number
  teamId: number
  getAllWorkflowsData?: (value: string) => void
  status?: string
  workflowUpdatedBy?: user | undefined
  selectPage: boolean
  selectedCardId?: number
}) => {
  const navigate = useNavigate()
  const {formatMessage} = useIntl()
  const {getDateValue} = useDateFormat()
  const {getTimeValue} = useTimeFormat()
  // useStates
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [formattedStatus, setFormattedStatus] = useState('')

  const handleDeleteWorkflow = async (workflowId: number) => {
    try {
      setDeleteLoading(true)
      const {
        data: {success, errors},
      } = await deleteWorkflow(workflowId)
      if (success) {
        setDeleteLoading(false)
        setOpenDeleteModal(false)
        if (getAllWorkflowsData) {
          getAllWorkflowsData(templateType.USER)
        }
        toast.success(formatMessage({id: 'Workflow Deleted successfully'}))
      } else {
        setDeleteLoading(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setDeleteLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (status) {
      const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
      setFormattedStatus(formattedStatus)
    }
  }, [status])

  const timeDifference = () => {
    if (lastEditedDate) {
      const creationDateTime = DateTime.fromISO(creationDate).toFormat('dd MMM, yyyy')
      const updatedDateTime = DateTime.fromISO(lastEditedDate).toFormat('dd MMM, yyyy')
      const creationMinute = DateTime.fromISO(creationDate).toFormat('H:m:s')
      const updationMinute = DateTime.fromISO(lastEditedDate).toFormat('H:m:s')

      const diffInMinutes = DateTime.fromFormat(updationMinute, 'H:m:s').diff(
        DateTime.fromFormat(creationMinute, 'H:m:s'),
        'minutes'
      ).minutes
      return (
        creationDateTime !== updatedDateTime ||
        (creationDateTime === updatedDateTime && diffInMinutes > 2)
      )
    }
  }
  return (
    <>
      <div>
        <div className={`${selectPage ? 'workflow-Card-Wrapper' : 'Template-Card-Wrapper'}`}>
          <div className={`${selectedCardId === workflowId ? 'selected-card-workflow' : ''} card`}>
            <div className='image-border p-5'>
              <div
                className={`d-flex ${
                  timeDifference() ? 'justify-content-between' : 'justify-content-end'
                }`}
              >
                {userId && teamId && timeDifference() && !selectPage && (
                  <div className='font-size-12 view-text d-flex'>
                    <span className='me-1'>{formatMessage({id: 'Last edited: '})}</span>
                    <div className='d-flex'>
                      <div>{workflowUpdatedBy?.firstName}</div>
                      <OverlayTrigger
                        placement='top'
                        overlay={
                          <Tooltip>
                            <span className='text-nowrap view-text'>
                              {lastEditedDate &&
                                (getDateValue(lastEditedDate?.toString())
                                  ? getDateValue(lastEditedDate?.toString())
                                  : DateTime.fromISO(lastEditedDate).toFormat('dd MMM, yyyy')) +
                                  ' ' +
                                  (getTimeValue(lastEditedDate?.toString())
                                    ? getTimeValue(lastEditedDate?.toString())
                                    : DateTime.fromISO(lastEditedDate).toFormat('H:m:s'))}
                            </span>
                          </Tooltip>
                        }
                      >
                        <img
                          className='cursor-pointer'
                          src={toAbsoluteUrl('/media/illustrations/misc/clock.svg')}
                          alt='clock'
                          height='18px'
                          width='18px'
                        />
                      </OverlayTrigger>
                    </div>
                  </div>
                )}
                {!selectPage && userId && teamId && (
                  <div onClick={() => setOpenDeleteModal(true)}>
                    <img
                      className='delete-icon cursor-pointer'
                      src={toAbsoluteUrl('/media/icons/investor-updates/deleteIcon.svg')}
                      alt='delete'
                      height='18px'
                      width='18px'
                    />
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/illustrations/misc/workflowImg.svg')}
                  className={`img-fluid`}
                  alt='Card'
                />
              </div>
            </div>
            <div className='p-5'>
              <div
                className={`${
                  selectPage
                    ? 'pb-5 d-flex flex-column align-items-start justify-content-start'
                    : 'pb-5 d-flex flex-column'
                }`}
              >
                <h5 className='card-title m-0 font-size-16 pb-3 font-weight-600'>{btnText}</h5>
                <div
                  className={`${
                    selectPage
                      ? 'text-black font-weight-600 saved-text-select'
                      : 'font-size-12 text-black font-weight-400'
                  }`}
                >
                  {!selectPage ? (
                    userId && teamId ? (
                      <>
                        <div className='d-flex flex-wrap'>
                          <div className='me-1 text-theme-color'>
                            {formatMessage({id: 'Created by: '})}
                          </div>
                          <div className='text-theme-color'>
                            {userfirstName}
                            <OverlayTrigger
                              placement='top'
                              overlay={
                                <Tooltip>
                                  <span className='text-nowrap view-text'>
                                    {(getDateValue(creationDate?.toString())
                                      ? getDateValue(creationDate?.toString())
                                      : DateTime.fromISO(creationDate).toFormat('dd MMM, yyyy')) +
                                      ' ' +
                                      (getTimeValue(creationDate?.toString())
                                        ? getTimeValue(creationDate?.toString())
                                        : DateTime.fromISO(creationDate).toFormat('H:m:s'))}
                                  </span>
                                </Tooltip>
                              }
                            >
                              <img
                                className=' cursor-pointer'
                                src={toAbsoluteUrl('/media/illustrations/misc/clock.svg')}
                                alt='clock'
                                height='18px'
                                width='18px'
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <span className='text-color'>{formatMessage({id: 'Saved on: '})} </span>
                        <span className='text-color'>
                          {getDateValue(creationDate?.toString())
                            ? getDateValue(creationDate?.toString())
                            : DateTime.fromISO(creationDate).toFormat('dd MMM, yyyy')}
                        </span>
                      </>
                    )
                  ) : (
                    <>
                      {formatMessage({id: 'Saved on: '})}{' '}
                      <span>
                        {getDateValue(creationDate?.toString())
                          ? getDateValue(creationDate?.toString())
                          : DateTime.fromISO(creationDate).toFormat('dd MMM, yyyy')}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className={`workflow-edit-mobile ${status ? 'workflow-status' : ''}`}>
                {status && (
                  <div
                    className={`${
                      status === 'COMPLETE' ? 'complete' : 'incomplete'
                    } d-flex align-self-center rounded-pill px-4 py-2 mb-3`}
                  >
                    <div className='font-size-10 font-weight-500 m-0'>{formattedStatus}</div>
                  </div>
                )}
                {!selectPage && (
                  <div>
                    <button className='btn p-3 px-4 text-center view-btn'>
                      <div
                        className='d-flex justify-space-between cursor-pointer'
                        onClick={() => navigate(`${workflowId}`)}
                      >
                        {userId && teamId ? (
                          <img
                            src={toAbsoluteUrl('/media/icons/duotune/general/edit.svg')}
                            alt='delete'
                            height='16px'
                            width='16px'
                            className='me-2'
                          />
                        ) : (
                          <img
                            className='me-3'
                            width='18px'
                            height='18px'
                            src={toAbsoluteUrl('/media/icons/duotune/general/view.svg')}
                          />
                        )}
                        <div className='orange-color'>
                          {userId && teamId
                            ? formatMessage({id: 'Edit'})
                            : formatMessage({id: 'View'})}
                        </div>
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DeleteConfirmationModal
          showDeleteModal={openDeleteModal}
          setDeleteShowModal={setOpenDeleteModal}
          headingMessage={formatMessage({id: 'Delete Workflow'})}
          description={formatMessage({
            id: 'Are you sure you want to delete this workflow permanently?',
          })}
          handleDelete={handleDeleteWorkflow}
          deleteLoading={deleteLoading}
          id={workflowId}
        />
      </div>
    </>
  )
}
