import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {timeZoneOptions} from '../../../profile/core/_constants'
import {Form, Formik} from 'formik'
import '../../styles/_campaign.scss'
import {hoursOptions} from '../../core/_constants'
import {createWorkingHours, getAllTimezones} from '../../core/_requests'
import {timeZone} from '../../core/models/_timeZone'
import {ChangeEvent} from 'react'
import {useParams} from 'react-router-dom'
import {getLocation} from '../../../auth/core/_requests'
import {toast} from 'react-toastify'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {TimePreferences} from './TimePreferences'

export const SelectTimePreferences = ({
  prevStep,
  submitStep,
  setIsApiCalled,
  campaignName,
}: {
  prevStep: any
  submitStep: () => void
  setIsApiCalled: React.Dispatch<React.SetStateAction<boolean>>
  campaignName: string | undefined
}) => {
  const {formatMessage} = useIntl()
  // useStates
  const [defaultSelectedStartTime, setDefaultSelectedStartTime] = useState<string>(
    hoursOptions[9].id
  )
  const [defaultSelectedEndTime, setDefaultSelectedEndTime] = useState<string>(hoursOptions[18].id)
  const [isWeekendsInclude, setIsWeekendsInclude] = useState<boolean>(false)
  const [timezoneId, setTimezonId] = useState<number | undefined>(undefined)

  return (
    <>
      <div className='locale-container mt-0 p-8'>
        <TimePreferences
          timezoneId={timezoneId}
          setTimezonId={setTimezonId}
          defaultSelectedStartTime={defaultSelectedStartTime}
          setDefaultSelectedStartTime={setDefaultSelectedStartTime}
          defaultSelectedEndTime={defaultSelectedEndTime}
          setDefaultSelectedEndTime={setDefaultSelectedEndTime}
          isWeekendsInclude={isWeekendsInclude}
          setIsWeekendsInclude={setIsWeekendsInclude}
          isCampaignDetails={false}
          prevStep={prevStep}
          submitStep={submitStep}
          setIsApiCalled={setIsApiCalled}
          campaignName={campaignName}
          enableSaveBtn={true}
        />
      </div>
    </>
  )
}
