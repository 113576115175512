import React from 'react'

export const Spinner = ({
  placement,
  isActivityLoader,
  background,
  isInlineLoader = false,
}: {
  placement?: boolean
  isActivityLoader?: boolean
  background?: boolean
  isInlineLoader?: boolean
}) => {
  return (
    <>
      {isInlineLoader ? (
        <div
          className={`spinner-border d-center`}
          style={{width: '1rem', height: '1rem'}}
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      ) : (
        <div
          className={`spinner-wrapper ${background ? 'background-white' : ''} ${
            isActivityLoader ? 'activity-spinner' : ''
          }`}
        >
          <div
            className={`spinner-border ${placement ? 'd-center' : ''}`}
            style={{width: '4rem', height: '4rem'}}
            role='status'
          >
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
    </>
  )
}
