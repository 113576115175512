import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import LeadTable from '../components/LeadTable'
import LeadFilter from '../components/LeadFilter'
import {getAllCampaigns, getLeadList, getLeads} from '../core/_requests'
import {LeadDataObject, LeadListObject} from '../core/model/lead'
import {campaignObject, userObject} from '../../campaign/core/_models'
import {Roles, profileBreadCrumbs} from '../../profile/core/_constants'
import {PageHeading} from '../../template/components/PageHeading'
import {getUserList} from '../../profile/core/_requests'

import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import {query} from 'express'
import {useAuth} from '../../auth'
const LeadsHome = () => {
  const {formatMessage} = useIntl()
  const {currentUser} = useAuth()
  let debounceTimeout: NodeJS.Timeout
  const [getLeadsLoader, setGetLeadsLoader] = useState(true)
  const [searchLead, setSearchLead] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [campaignId, setCampaignId] = useState(0)

  const [leadsCount, setLeadsCount] = useState(0)

  const [statusFilter, setStatusFilter] = useState<string | undefined>('')

  const [campaignsData, setCampaignsData] = useState<campaignObject[]>([
    {
      campaignId: 0,
      name: 'All Campaigns',
      status: '',
      createdAt: '',
      excludeOtherCampaignLead: false,
      excludeTeamCampaignLead: false,
    },
  ])
  const [leadData, setLeadData] = useState<LeadDataObject[]>([])
  const [leadList, setLeadList] = useState<LeadListObject[]>([
    {leadListId: 0, name: 'All Lead List'},
  ])
  const [usersData, setUsersData] = useState<userObject[]>([
    {
      userId: 0,
      linkedin_name: 'All Accounts',
      linkedin_url: 'linkedin_url',
    },
  ])
  const [leadListId, setLeadListId] = useState<number>(0)
  const [userId, setUserId] = useState<number>(0)
  const [flag, setFlag] = useState<boolean>(true)
  const size = 10
  const didMount = useRef(false)
  const {setTeamLength, analyticsClick, setAnalyticsClick} = useAuth()

  const location = useLocation()

  let queryParams = queryString.parse(location.search)
  const [params_campaignId, setParams_campaignId] = useState(
    Array.isArray(queryParams.campaignId)
      ? parseInt(queryParams.campaignId[0] || '0')
      : parseInt(queryParams.campaignId || '0')
  )
  const [params_status, setParams_status] = useState<string | undefined>(
    typeof queryParams.status === 'string' ? queryParams.status : undefined
  )

  const handleGetLeads = async () => {
    try {
      setGetLeadsLoader(true)
      let campaignIdData = campaignId
      let statusFilterData = campaignId !== 0 ? statusFilter : undefined
      if (analyticsClick) {
        campaignIdData = Array.isArray(queryParams.campaignId)
          ? parseInt(queryParams.campaignId[0] || '0')
          : parseInt(queryParams.campaignId || '0')
        statusFilterData = typeof queryParams.status === 'string' ? queryParams.status : undefined
        setAnalyticsClick(false)
      }
      const promises = [
        getLeads({
          currentPage,
          size,
          searchLead,
          campaignId: campaignIdData,
          leadListId,
          userId,
          statusFilter: statusFilterData,
        }),
        getAllCampaigns(),
        getLeadList(),
      ]
      if (currentUser?.role !== Roles.MEMBER) {
        promises.push(getUserList())
      }
      const [leadsResponse, campaignsResponse, leadListResponse, usersResponse] =
        await Promise.all(promises)

      const leadsData = leadsResponse.data

      const campaignsData = campaignsResponse.data
      const leadListData = leadListResponse.data
      const usersListData = usersResponse ? usersResponse.data : undefined

      if (leadsData.success) {
        setLeadsCount(leadsData.data.totalLeadsCount)
        setLeadData(leadsData.data.leads)
      }

      if (campaignsData.success) {
        if (flag) setCampaignsData([{name: 'All Campaign', campaignId: 0}, ...campaignsData.data])
      }
      if (leadListData.success) {
        if (flag) setLeadList([{name: 'All Lead List', leadListId: 0}, ...leadListData.data])
      }
      if (usersListData && usersListData.success) {
        if (flag) {
          setTeamLength(usersListData.data.length)
          setUsersData((prev) => [...prev, ...usersListData.data])
        }
      }
      setGetLeadsLoader(false)
      setFlag(false)
    } catch (err) {
      setGetLeadsLoader(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (didMount.current) {
      setCurrentPage(1)

      clearTimeout(debounceTimeout)
      debounceTimeout = setTimeout(() => {
        setFlag(false)
        handleGetLeads()
      }, 2000)
    } else {
      didMount.current = true
    }
    return () => {
      clearTimeout(debounceTimeout)
    }
  }, [searchLead])

  useEffect(() => {
    if (params_campaignId && params_status) {
      setCampaignId(+params_campaignId)
      setStatusFilter(params_status)
    } else {
      setCampaignId(campaignId), setStatusFilter(statusFilter)
    }

    if (didMount.current) {
      handleGetLeads()
    } else {
      didMount.current = true
    }
  }, [currentPage, campaignId, leadListId, userId, statusFilter])
  return (
    <>
      <div className='card p-md-8 mb-4 p-4'>
        <PageHeading
          title={formatMessage({id: 'Leads'})}
          description={formatMessage({
            id: 'Leads represent the individuals with whom users connect and network.',
          })}
          tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.LEADS.TITLE'})}
          breadcrumbs={profileBreadCrumbs}
          breadcrumbTitle={formatMessage({id: 'Leads'})}
        ></PageHeading>
      </div>
      <LeadFilter
        searchLead={searchLead}
        setSearchLead={setSearchLead}
        campaignId={campaignId}
        setCampaignId={setCampaignId}
        campaignsData={campaignsData}
        leadList={leadList}
        leadListId={leadListId}
        setLeadListId={setLeadListId}
        usersData={usersData}
        userId={userId}
        setUserId={setUserId}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        params_campaignId={params_campaignId}
        setParams_campaignId={setParams_campaignId}
        params_status={params_status}
        setParams_status={setParams_status}
      />
      <LeadTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLeadData={setLeadData}
        leadData={leadData}
        handleGetLeads={handleGetLeads}
        getLeadsLoader={getLeadsLoader}
        leadsCount={leadsCount}
        leadListId={leadListId}
        campaignId={campaignId}
      />
    </>
  )
}
export default LeadsHome
