import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Dropdown} from 'react-bootstrap'
import '../styles/leads.scss'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {DeleteConfirmationModal} from '../../widgets/components/DeleteConfirmationModal'
import {GeneralConfirmationModal} from '../../widgets/components/GeneralConfirmationModal'
import NoDataPage from '../../widgets/components/NoDataPage'
import {deleteLead, exportLeads} from '../core/_requests'
import {Spinner} from '../../widgets/components/General/Spinner'
import {toast} from 'react-toastify'
import {CustomPagination} from '../../widgets/components/General/CustomPagination'
import {LeadDataObject} from '../core/model/lead'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {useTimeFormat} from '../../../hooks/useTimeFormat'
import {DateTime} from 'luxon'
const LeadTable = ({
  currentPage,
  setCurrentPage,
  setLeadData,
  leadData,
  handleGetLeads,
  getLeadsLoader,
  leadsCount,
  leadListId,
  campaignId,
}: {
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  setLeadData: React.Dispatch<React.SetStateAction<LeadDataObject[]>>
  leadData: LeadDataObject[]
  handleGetLeads: () => {}
  getLeadsLoader: boolean
  leadsCount: number
  leadListId: number
  campaignId: number
}) => {
  const [totalPages, setTotalPages] = useState<number>(1)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [leadDeleteID, setLeadDeleteID] = useState('')
  const [showDeleteModal, setDeleteShowModal] = useState(false)
  const [showGeneralModal, setGeneralShowModal] = useState(false)
  const [generalLoading, setGeneralLoading] = useState(false)
  const {formatMessage} = useIntl()
  const {getDateValue} = useDateFormat()
  const {getTimeValue} = useTimeFormat()
  const activityTypeMap: {[key: string]: string} = {
    END: 'Workflow End',
    ENDORSE_SKILLS: 'Endorse Skills',
    FOLLOW: 'Followed',
    IF_CONNECTED: 'If Connected',
    IF_OPEN_PROFILE: 'If Open Profile',
    IF_PREMIUM: 'If Premium',
    IF_VIEWED_MESSAGE: 'If Message Viewed',
    INMAIL: 'Inmail Sent',
    LIKE_A_POST: 'Liked Post',
    MESSAGE: 'Message Sent',
    SEND_AN_INVITE: 'Invite Sent',
    VIEW_PROFILE: 'Profile Viewed',
    WITHDRAW_INVITE: 'Invite Withdrawn',
  }
  const onExportLeads = async () => {
    try {
      setGeneralLoading(true)
      const {
        data: {data: values, success, errors},
      } = await exportLeads({leadListId: leadListId, campaignId: campaignId})
      if (success) {
        toast.success(formatMessage({id: 'Downloading Leads '}))
        const url = window.URL.createObjectURL(new Blob([values]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'leads.csv')
        document.body.appendChild(link)
        link.click()
        link.remove()
        setGeneralShowModal(false)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setGeneralLoading(false)
    }
  }

  const handleDeleteLead = async () => {
    try {
      setDeleteLoading(true)
      const {
        data: {success, errors, data},
      } = await deleteLead({leadDeleteID})
      if (success) {
        setDeleteLoading(false)
        toast.success(formatMessage({id: 'Lead deleted successfully'}))
        setDeleteShowModal(false)
        handleGetLeads()
      } else {
        setDeleteLoading(false)
      }
    } catch (err) {
      setDeleteLoading(false)
      console.log(err)
    }
  }
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    const pages = leadsCount ? Math.ceil(leadsCount / 10) : 1
    setTotalPages(pages)
  }, [leadsCount])

  const getActivityTypeString = (type: string) => {
    return activityTypeMap[type] || type
  }
  return (
    <>
      {windowSize.width > 450 ? (
        <div className={`card `}>
          {getLeadsLoader && <Spinner background={true} />}
          <div className='card-header border-0 p-8'>
            <h3 className='card-title align-items-start flex-column mt-n4'>
              <span className='text-muted fw-semibold fs-14'>
                {formatMessage({id: 'Total Leads:'})} {leadsCount}
              </span>
            </h3>
            {leadData.length > 0 && (
              <div
                className='card-toolbar my-0'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to export the leads'
              >
                <a
                  href='#'
                  className='btn btn-sm create-campaign-btn '
                  onClick={() => setGeneralShowModal(true)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/upload.svg' className='svg-icon-2' />
                  {formatMessage({id: 'Export Leads'})}
                </a>
              </div>
            )}
          </div>

          <div className='card-body pt-0'>
            {leadData.length > 0 ? (
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className=''>{formatMessage({id: 'Name'})}</th>
                      <th className=''>{formatMessage({id: 'Location'})}</th>
                      <th className=''>{formatMessage({id: 'Company'})}</th>
                      <th className='text-nowrap'>{formatMessage({id: 'Last Activity'})}</th>
                      <th className=''> {formatMessage({id: 'Action'})}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadData &&
                      leadData.map((item: LeadDataObject) => {
                        return (
                          <tr key={item.leadId}>
                            <td className='maxWidth250'>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-45px me-5'>
                                  <img
                                    src={
                                      item.linkedInProfileImageUrl &&
                                      item.linkedInProfileImageUrl.startsWith('https')
                                        ? item.linkedInProfileImageUrl
                                        : toAbsoluteUrl('/media/icons/duotune/general/user1.svg')
                                    }
                                    alt='No Image'
                                  />
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a
                                    href={item?.navigationUrl}
                                    target='_blank'
                                    className='text-dark fw-bold text-hover-primary fs-6'
                                  >
                                    {item?.firstName || item?.lastName ? (
                                      <>
                                        {item?.firstName} {item?.lastName}
                                      </>
                                    ) : (
                                      <>{item?.navigationUrl}</>
                                    )}
                                  </a>
                                  <span className='text-muted fw-semibold d-block fs-7'>
                                    {item?.headLine}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <span className='text-dark fw-bold d-block fs-6'>{item?.city}</span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7 maxWidth200'>
                                {item?.country && item?.country.length > 25
                                  ? `${item.country.substring(0, 25)}...`
                                  : item?.country}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold d-block fs-6'>
                                {item?.currentCompany}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7 maxWidth200'>
                                {item?.position && item?.position.length > 25
                                  ? `${item.position.substring(0, 25)}...`
                                  : item?.position}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-bold d-block fs-6'>
                                {getActivityTypeString(item.userActivities[0]?.type)}
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7 maxWidth200'>
                                {item.userActivities[0]
                                  ? getDateValue(item.userActivities[0]?.createdAt?.toString())
                                    ? getDateValue(item.userActivities[0]?.createdAt?.toString())
                                    : DateTime.fromISO(
                                        item.userActivities[0]?.createdAt?.toString()
                                      ).toLocaleString(DateTime.DATE_MED)
                                  : ''}
                              </span>
                            </td>
                            <td>
                              <div className='dropdown-container'>
                                <Dropdown>
                                  <Dropdown.Toggle className='bi bi-three-dots-vertical p-1 '></Dropdown.Toggle>
                                  <Dropdown.Menu className='m-2'>
                                    <Dropdown.Item
                                      className='text-danger'
                                      onClick={() => {
                                        setDeleteShowModal(true)
                                        setLeadDeleteID(item.leadId.toString())
                                      }}
                                    >
                                      <p className='mb-0'>{formatMessage({id: 'Remove'})}</p>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
                <div className='d-flex justify-content-end'>
                  <CustomPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            ) : (
              <NoDataPage
                noDataText={formatMessage({
                  id: 'There are no leads added to the platform so far. Please start creating campaigns to populate the leads.',
                })}
              />
            )}
          </div>
        </div>
      ) : (
        <div className={`card `}>
          {getLeadsLoader && <Spinner background={true} />}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Leads</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>
                {formatMessage({id: 'Total Leads:'})} {leadsCount}
              </span>
            </h3>
            {leadData.length > 0 && (
              <div
                className='card-toolbar my-0'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to export the leads'
              >
                <a
                  href='#'
                  className='btn btn-sm create-campaign-btn '
                  onClick={() => setGeneralShowModal(true)}
                >
                  <KTSVG path='/media/icons/duotune/arrows/upload.svg' className='svg-icon-2' />
                  {formatMessage({id: 'Export Leads'})}
                </a>
              </div>
            )}
          </div>

          <div className='m-3'>
            {leadData.length > 0 ? (
              <>
                {leadData &&
                  leadData.map((item: LeadDataObject) => {
                    return (
                      <div key={item.leadId} className='mb-4 Template-Card-Wrapper'>
                        <div className='w-100 d-flex flex-column'>
                          <div className='d-flex flex-row justify-content-between mt-3 mx-3'>
                            <div className='d-flex flex-row'>
                              <img
                                src={
                                  item.linkedInProfileImageUrl &&
                                  item.linkedInProfileImageUrl.startsWith('https')
                                    ? item.linkedInProfileImageUrl
                                    : toAbsoluteUrl('/media/icons/duotune/general/user1.svg')
                                }
                                alt='No Image'
                                height='30px'
                                style={{borderRadius: '24px'}}
                              />
                              <div className='mt-2 ms-1'>
                                <a
                                  href={item?.navigationUrl}
                                  target='_blank'
                                  className='text-dark fw-bold text-hover-primary fs-6'
                                >
                                  {item?.firstName} {item?.lastName}
                                </a>
                              </div>
                            </div>
                            <div className='me-2 d-flex flex-column justify-content-end align-items-end'>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                Location
                              </span>
                              <span className='text-dark fw-bold fs-7'>{item?.city}</span>
                            </div>
                          </div>
                          <div className='d-flex flex-row justify-content-between mt-4 ms-3 mb-3'>
                            <div>
                              <span>
                                <a href='#' className='text-dark fw-bold fs-7'>
                                  {item?.currentCompany}
                                </a>
                              </span>
                              <span className='text-muted fw-semibold text-muted d-block fs-7 maxWidth200'>
                                {item?.position && item?.position.length > 25
                                  ? `${item.position.substring(0, 25)}...`
                                  : item?.position}
                              </span>
                            </div>
                            <div className='dropdown-container me-5'>
                              <Dropdown>
                                <Dropdown.Toggle className='bi bi-three-dots-vertical p-1 '></Dropdown.Toggle>
                                <Dropdown.Menu className='m-2'>
                                  <Dropdown.Item
                                    className='text-danger'
                                    onClick={() => {
                                      setDeleteShowModal(true)
                                      setLeadDeleteID(item.leadId.toString())
                                    }}
                                  >
                                    <p className='mb-0'>{formatMessage({id: 'Remove'})}</p>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </>
            ) : (
              <NoDataPage
                noDataText={formatMessage({id: 'Oops!! The screen is currently empty.'})}
              />
            )}
          </div>
        </div>
      )}
      <DeleteConfirmationModal
        showDeleteModal={showDeleteModal}
        setDeleteShowModal={setDeleteShowModal}
        headingMessage={'Delete lead'}
        description={'Are you sure you want to delete this lead'}
        handleDelete={handleDeleteLead}
        deleteLoading={deleteLoading}
      />
      <GeneralConfirmationModal
        showGeneralModal={showGeneralModal}
        setGeneralShowModal={setGeneralShowModal}
        headingMessage={'Export  CSV'}
        description={'Confirm to download the csv file'}
        handleConfirm={onExportLeads}
        generalLoading={generalLoading}
        buttonText={'Confirm'}
      />
    </>
  )
}

export default LeadTable
