import React from 'react'
import {useIntl} from 'react-intl'
import lock from '../../../../_metronic/assets/images/svg/profile/lock.svg'
import visa from '../../../../_metronic/assets/images/svg/profile/visa.svg'
import mastercard from '../../../../_metronic/assets/images/svg/profile/mastercard.svg'
import americanExpress from '../../../../_metronic/assets/images/svg/profile/americanExpress.svg'
import defaultCard from '../../../../_metronic/assets/images/svg/profile/defaultcard.svg'
import {useAuth} from '../../auth'
import {Link} from 'react-router-dom'

export const CardDetails = () => {
  const {formatMessage} = useIntl()
  const {
    setShowBillingSummary,
    cardDigits,
    cardBillingDetails,
    expiryDateYear,
    expiryDateMonth,
    cardBrand,
    setPaymentModal,
    setBillingClick,
  } = useAuth()

  console.log(cardBillingDetails)

  return (
    <>
      <div className=' d-flex flex-column'>
        <h4 className='card-label fs-5'>{formatMessage({id: 'Payment Method'})}</h4>
        <div className='d-flex justify-content-between'>
          <span className='payment-method me-5'>Card details</span>
          <span className='color-grey'>
            All transactions are secure and encrypted by{' '}
            <span className='link-primary'>
              <a href='https://stripe.com/' target='_target'>
                Stripe
              </a>
            </span>
            <img className='ms-2 mb-1' src={lock} alt='lock-icon' />
          </span>
        </div>

        <div className='d-flex justify-content-between align-items-center bg-gray-100 padding-16px h-25 mt-4'>
          <div className='d-flex flex-row'>
            <div className='me-3'>
              {(cardBrand === 'visa' && <img src={visa} alt='visa-card' />) ||
                (cardBrand === 'mastercard' && <img src={mastercard} alt='master-card' />) ||
                (cardBrand === 'amex' && <img src={americanExpress} alt='american-express' />) || (
                  <img src={defaultCard} alt='default-card' />
                )}
            </div>

            <div className='color-grey'>**** **** **** {cardDigits}</div>
          </div>
          <div className='dot'></div>
          <div className=' card-digits color-grey d-flex align-items-center'>
            <div>
              <span className='me-2'>Expiry</span>
              <span className='text-nowrap'>{expiryDateMonth + '/' + expiryDateYear}</span>
            </div>
          </div>
          <div className=' fw-bold font-size-14 text-black'>
            <Link
              to=''
              onClick={() => {
                setPaymentModal(true)
                setBillingClick('card')
              }}
              className='link-primary'
            >
              {formatMessage({id: 'Change Card'})}
            </Link>
          </div>
        </div>
      </div>
      {cardBillingDetails?.name && cardBillingDetails?.address.city && (
        <>
          <h4 className='card-label fs-5 mt-5'>{formatMessage({id: 'Billing Details'})}</h4>
          <div className=' bg-gray-100 mx-0  padding-16px mt-2'>
            <div className='d-flex flex-row justify-content-between'>
              <div className='fw-bold mb-2 font-size-14'>{cardBillingDetails?.name}</div>
              <div className='fw-bold font-size-14 text-black'>
                <Link
                  to=''
                  onClick={() => {
                    setPaymentModal(true)
                    setBillingClick('address')
                  }}
                  className='link-primary'
                >
                  {formatMessage({id: 'Change Address'})}
                </Link>
              </div>
            </div>
            <div className='color-grey address-billing-column'>
              <div>{cardBillingDetails?.address?.line1}</div>
              <div>{cardBillingDetails?.address?.line2}</div>
              <div>{cardBillingDetails?.address?.city}</div>
              <div>
                {cardBillingDetails?.address?.state} {cardBillingDetails?.address?.postal_code}
              </div>
              <div>{cardBillingDetails?.address?.country}</div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
