import React, {useEffect, useState} from 'react'
import ReactSlider from 'react-slider'
import {getUserActivity, updateUserActivity} from '../../activity/core/_requests'
import {Limit} from '../../activity/core/models/LimitType'

export function RangeSlider({
  initialValue,
  difference,
  perDayLimit,
  currentSlider,
  userActivityLimitId,
  initialRange,
  setInitialRange,
  fullLimit,
  selectedTeamMember,
  currentUser,
  teamLength,
  isShieldOn,
}: {
  initialValue: number[]
  difference: number
  perDayLimit: number
  currentSlider: [string, Limit]
  userActivityLimitId: number
  initialRange: number
  setInitialRange: React.Dispatch<React.SetStateAction<number>>
  fullLimit: number
  selectedTeamMember: number | undefined
  currentUser: any
  teamLength: number
  isShieldOn?: boolean
}) {
  const [value, setValue] = useState<number[]>([10, 15])
  const [initialApiCall, setInitialApiCall] = useState<boolean>(false)

  const handleSliderChange = (newValue: number[], index: number) => {
    if (newValue[0] + difference <= perDayLimit && newValue[1] <= perDayLimit) {
      if (index === 0) {
        newValue[1] = newValue[0] + difference
      } else {
        newValue[0] = newValue[1] - difference
      }
      setInitialApiCall(true)
      setValue([...newValue])
    }
  }
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (currentSlider && initialApiCall) {
      const body: Record<string, number | Limit> = {}
      body['userActivityLimitId'] = userActivityLimitId
      body[currentSlider[0]] = value[1]
      currentSlider[1].userLimit = value[1]
      updateUserLimit(body)
    }
  }, [value[1], initialApiCall])

  const updateUserLimit = async (body: Record<string, number | Limit>) => {
    try {
      const {
        data: {success},
      } = await updateUserActivity(body, selectedTeamMember)
      if (success) {
        setInitialApiCall(false)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleApiCall = (
    value: number[],
    userActivityLimitId: number,
    currentSlider: [string, Limit]
  ) => {
    const body: Record<string, number | Limit> = {}
    body['userActivityLimitId'] = userActivityLimitId
    body[currentSlider[0]] = value[1]
    currentSlider[1].userLimit = value[1]
    updateUserLimit(body)
  }
  useEffect(() => {
    const handleDifferenceChange = () => {
      if (
        value[0] + difference <= perDayLimit &&
        value[1] <= perDayLimit &&
        initialRange &&
        +initialRange !== difference &&
        value[1] <= difference
      ) {
        setInitialRange(difference)
        let newValue = [...value]
        if (newValue[1] - difference < 1) {
          newValue[0] = 1
          newValue[1] = 1 + difference
        } else {
          newValue[0] = newValue[1] - difference
        }
        setValue(newValue)
        handleApiCall(newValue, userActivityLimitId, currentSlider)
      }
    }
    handleDifferenceChange()
  }, [difference])

  return (
    <>
      <ReactSlider
        className={`horizontal-slider ${isShieldOn ? 'pe-none' : ''}`}
        trackClassName='example-track'
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        renderThumb={(props, state) => (
          <div {...props} className='custom-thumb-inner'>
            {state.valueNow}
          </div>
        )}
        pearling={true}
        min={1}
        max={fullLimit}
        value={value}
        onAfterChange={handleSliderChange}
        minDistance={difference}
        disabled={isShieldOn}
      />

      <div className={`custom-slider-bar ${isShieldOn ? 'opacity-75' : ''}`}>
        <div
          className='custom-slider-fill'
          style={{
            width: `${
              value[0] < 0
                ? ((value[1] - 1) / (fullLimit - 0)) * 100
                : ((value[1] - value[0]) / (fullLimit - 0)) * 100
            }%`,
            left: `${Math.max(0, (value[0] / (fullLimit - 0)) * 100)}%`,
          }}
        ></div>
        <div
          className='custom-slider-limit'
          style={{
            width: `${(perDayLimit / fullLimit) * 100}%`,
          }}
        ></div>
      </div>
    </>
  )
}
