import React from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Spinner} from '../../widgets/components/General/Spinner'
import NoDataPage from '../../widgets/components/NoDataPage'

export const Transaction = ({
  transactionData,
  transactionLoading,
}: {
  transactionData: any
  transactionLoading: boolean
}) => {
  const {formatMessage} = useIntl()
  return (
    <>
      {transactionLoading && <Spinner />}
      <div className='card p-8'>
        {Array.isArray(transactionData) && (
          <div className='border border-secondary rounded-3 table-responsive'>
            <table className='table mb-0'>
              <thead className='table-secondary font-size-14 fw-semibold text-black rounded-3 align-middle text-nowrap'>
                <tr>
                  <th className='p-4 table-border-top-left'>
                    {formatMessage({id: 'Transaction ID'})}
                  </th>
                  <th>{formatMessage({id: 'Date'})}</th>
                  <th>{formatMessage({id: 'Payment method'})}</th>
                  <th>{formatMessage({id: 'Amount'})}</th>
                  <th className='table-border-top-right pe-3'>{formatMessage({id: 'Receipt'})}</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {transactionData.map(
                    ({
                      transactionId,
                      transactionDate,
                      cardType,
                      cardNumber,
                      currencyCode,
                      amount,
                      receiptPdfLink,
                    }: {
                      transactionId: string
                      transactionDate: number
                      cardType: string
                      cardNumber: string
                      currencyCode: string
                      amount: number
                      receiptPdfLink: string
                    }) => (
                      <tr
                        className='border-top border-secondary align-middle text-nowrap'
                        key={transactionId}
                      >
                        <td className='p-4'>{transactionId.slice(-8).toUpperCase()}</td>
                        <td>{new Date(transactionDate * 1000).toDateString()}</td>
                        <td>
                          <img
                            src={toAbsoluteUrl('/media/icons/duotune/general/visa.svg')}
                            alt='card'
                            width='20'
                            height='20'
                            className='me-1'
                          />
                          {cardType.charAt(0).toUpperCase() + cardType.slice(1)}{' '}
                          {formatMessage({id: 'ending in'})} {cardNumber}
                        </td>
                        <td>
                          {currencyCode === 'inr' ? '₹' : '$'} {amount}
                        </td>
                        <td>
                          <a href={receiptPdfLink} target='blank'>
                            <img
                              src={toAbsoluteUrl('/media/icons/duotune/general/download.svg')}
                              alt='receipt'
                              width='20'
                              height='20'
                            />
                          </a>
                        </td>
                      </tr>
                    )
                  )}
                </>
              </tbody>
              <tfoot className='table-secondary border-top border-secondary'>
                <tr>
                  <td className='table-border-bottom-left'></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='table-border-bottom-right'></td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {!Array.isArray(transactionData) && (
          <div>
            <NoDataPage
              noDataText={formatMessage({
                id: 'No Transactions Found.',
              })}
            />
          </div>
        )}
      </div>
    </>
  )
}
