import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const NoDataPage = ({
  width,
  mainfileActivity,
  noDataText,
}: {
  width?: any
  mainfileActivity?: boolean
  noDataText: string
}) => {
  const {formatMessage} = useIntl()

  return (
    <div
      className={`pt-5 ${
        mainfileActivity ? '' : 'no-data-page-share-drawer'
      } w-100 bg-white d-flex align-items-center justify-content-center h-100`}
    >
      <div className={`d-flex flex-column gap-5 align-items-center ${width ? width : 'width'}`}>
        <div>
          <img
            src={toAbsoluteUrl('/media/icons/investor-updates/NoDataNewImg.svg')}
            alt='no-data-img'
            height={206}
            width={310}
          />
        </div>
        <div className='fw-bold font-size-14 text-center' style={{order: 1}}>
          <p>{noDataText}</p>
        </div>
      </div>
    </div>
  )
}

export default NoDataPage
