/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import '../styles/_template.scss'
import {CustomiseInputBoxesModal} from './CustomiseInputBoxesModal'
import {toast} from 'react-toastify'
import {updateWorkflowStep, deleteWorkflowStep} from '../core/_requests'
import {DeleteConfirmationModal} from '../../widgets/components/DeleteConfirmationModal'

const TreeNodeDropdown = ({
  isOpenTreeNode,
  hasMessage,
  showDescription,
  setOpenInputBoxModal,
  setShowDeleteStepModal,
}: {
  isOpenTreeNode: boolean
  hasMessage: boolean
  showDescription?: boolean
  setOpenInputBoxModal: (value: boolean) => void
  setShowDeleteStepModal: (value: boolean) => void
}) => {
  const {formatMessage} = useIntl()

  return (
    <>
      {isOpenTreeNode ? (
        <div className='menu menu-sub menu-sub-dropdown data-room-option-dropdown show tree-node-dropdown'>
          {hasMessage ? (
            <>
              <div
                className='option-padding'
                onClick={() => {
                  setOpenInputBoxModal(true)
                }}
              >
                <p className='mb-0 text-dark'>{formatMessage({id: 'Customise'})}</p>
              </div>

              <div className='separator border-gray-200'></div>
            </>
          ) : null}
          {!showDescription && (
            <div className='option-padding' onClick={() => setShowDeleteStepModal(true)}>
              <p className='mb-0 text-dark'>{formatMessage({id: 'Remove'})}</p>
            </div>
          )}
        </div>
      ) : null}
    </>
  )
}

export {TreeNodeDropdown}
