import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import '../styles/_inbox.scss'
import {isSameDay} from 'date-fns'
import {divide} from 'lodash'
import {DateTime} from 'luxon'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {Picker} from 'emoji-mart'
import EmojiPicker from 'emoji-picker-react'
import {AddNotesModal} from '../view/AddNotesModal'
import {AddContactInformationModal} from '../view/AddContactInformationModal'
import {useNavigate} from 'react-router'

export const Inbox = () => {
  const {formatMessage} = useIntl()
  const {getDateValue} = useDateFormat()
  const [message, setMessage] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [openAddNotesModal, setOpenAddNotesModal] = useState<boolean>(false)
  const [openAddContactInfoModal, setOpenAddContactInfoModal] = useState<boolean>(false)
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})

  const arr = [
    {
      id: 0,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 1,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 2,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 3,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 4,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 5,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 6,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 7,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 8,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 9,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 10,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 11,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 12,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 13,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
    {
      id: 14,
      senderName: 'Erik Johnson',
      user: 'Jim Badger',
      chat: 'Hi Jim, How are you.',
    },
  ]

  const chatMessages = [
    {
      id: 1,
      content: 'Hi',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:30:00.000Z',
    },
    {
      id: 2,
      content: 'Hello',
      sender: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:31:00.000Z',
    },
    {
      id: 3,
      content: 'How are you?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:32:00.000Z',
    },
    {
      id: 4,
      content: 'What happened?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 5,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 6,
      content: 'What are you doing?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 7,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 8,
      content: 'What are you doing?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 9,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 10,
      content: 'What are you doing?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-07T10:33:00.000Z',
    },
    {
      id: 11,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-07T10:33:00.000Z',
    },
  ]

  const userInfo = {
    id: 1,
    location: 'India',
    email: 'mikejones@gmail.com',
    phoneNumber: '8012983476',
  }

  const statusOptions = [
    {id: 1, status: 'Interested'},
    {id: 2, status: 'Uninterested'},
    {id: 3, status: 'Available'},
    {id: 4, status: 'Unavailable'},
  ]

  const handleInputChange = (event: any) => {
    setMessage(event.target.value)
  }

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      // sendMessage(message);
      setMessage('')
    }
  }

  const handleEmojiSelect = (emoji: any) => {
    setMessage(message + emoji.native)
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  const navigate = useNavigate()

  const handleProfile = (id: string) => {
    navigate(`/inbox/${id}`)
  }

  return (
    <>
      {windowSize.width > 450 ? (
        <>
          <div className='d-md-flex flex-md-row d-flex flex-column w-100'>
            <div className='settings-profile-container w-75'>
              <div className='card row ms-0'>
                <div className='card-body m-0 p-0'>
                  <div className='d-flex justify-content-between p-8 border-bottom-inbox'>
                    <div className='mt-2'>
                      {' '}
                      <h1 className='activity-heading text-dark font-size-20 font-weight-600'>
                        {formatMessage({id: 'Inbox'})}
                      </h1>{' '}
                    </div>
                    <div className='d-flex gap-8 mt-2'>
                      <div className='d-flex gap-1'>
                        <div>
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/inbox/all_messages.svg')}
                          />
                        </div>
                        <div className='mt-1'>
                          <p>
                            {formatMessage({
                              id: 'All messages',
                            })}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className='d-flex gap-1'>
                          <div>
                            <img alt='Logo' src={toAbsoluteUrl('/media/icons/inbox/unread.svg')} />
                          </div>
                          <div className='mt-1'>
                            <p>
                              {formatMessage({
                                id: 'Unread',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-100 d-flex border-bottom-inbox'>
                    <div className='profile d-flex flex-column justify-content-center align-items-center'>
                      <input
                        type='text'
                        className='bg-body form-control form-control-flush height-36px rounded font-weight-400 text-clrA1 input-background search-box'
                        name='search'
                        placeholder={formatMessage({id: 'Search'})}
                      />
                    </div>

                    <div className='d-flex my-5 mx-10'>
                      <img src={toAbsoluteUrl('/media/logos/profile.svg')} alt='like' />
                      <h1 className='font-size-16 font-weight-600 text-black mt-4 ps-2 text-color'>
                        {formatMessage({id: 'Mike Jones'})}
                      </h1>
                    </div>
                  </div>
                  <div className='w-100 d-flex'>
                    <div className='chat'>
                      {arr.map((ar: any) => (
                        <div className='border-bottom-inbox'>
                          <div className='d-flex mt-2 justify-content-end me-2'>
                            {' '}
                            <img
                              className='me-2'
                              src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                              height='16px'
                              width='16px'
                            />{' '}
                            <h1 className='font-size-12 font-weight-400 text-black ps-2 text-color'>
                              {formatMessage({id: 'Mike Jones'})}
                            </h1>
                          </div>
                          <div className='d-flex justify-content-center mt-3 mb-8'>
                            <div>
                              <img
                                className='me-2'
                                src={toAbsoluteUrl('/media/logos/profile.svg')}
                                height='48px'
                                width='48px'
                              />{' '}
                            </div>
                            <div className='d-flex flex-column'>
                              <h1 className='font-size-14 font-weight-600 text-black ps-2 text-color'>
                                {formatMessage({id: 'Mike Jones'})}
                              </h1>
                              <p className='font-size-14 font-weight-400 ps-2'>
                                <span className='text-primary-color'>
                                  {formatMessage({id: 'You:'})}&nbsp;
                                </span>
                                <span>{ar.chat}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='chat-container'>
                      <div className=''>
                        {chatMessages.map((message, index: number) => {
                          let currentDate = null

                          if (index === 0) {
                            currentDate =
                              getDateValue(message.timestamp) ||
                              DateTime.fromISO(message.timestamp).toLocaleString(DateTime.DATE_MED)
                          }

                          const messageDate =
                            getDateValue(message.timestamp) ||
                            DateTime.fromISO(message.timestamp).toLocaleString(DateTime.DATE_MED)

                          const shouldDisplayDate = currentDate !== messageDate
                          currentDate = messageDate

                          return (
                            <>
                              {' '}
                              {shouldDisplayDate ||
                                (index === 0 && (
                                  <div className='d-flex justify-content-center mt-4 font-size-12 font-weight-400 text-grey-color'>
                                    {getDateValue(message.timestamp) ||
                                      DateTime.fromISO(message.timestamp).toLocaleString(
                                        DateTime.DATE_MED
                                      )}
                                  </div>
                                ))}
                              <div
                                key={message.id}
                                className={`message d-flex flex-column mt-15 ${
                                  message?.sender?.id === 5
                                    ? 'sender align-items-end me-10'
                                    : 'receiver ms-10 align-items-start'
                                }`}
                              >
                                <img
                                  src={toAbsoluteUrl('/media/logos/profile.svg')}
                                  alt='profile'
                                  width={24}
                                  height={24}
                                  className='mb-3'
                                />
                                <div
                                  className={`${
                                    message?.sender?.id === 5
                                      ? 'sender-message-content'
                                      : 'receiver-message-content'
                                  } bg-color text-white p-5 message-content`}
                                >
                                  {message?.content}
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>

                      <div
                        className={`chat-input mt-10 d-flex justify-content-center ${
                          showEmojiPicker ? 'mb-5' : 'mb-10'
                        }`}
                      >
                        <input
                          type='text'
                          value={message}
                          onChange={handleInputChange}
                          placeholder='Type a message'
                          className='h-100 w-75  form-control rounded-0 rounded-start  input-background border-0'
                        />
                        <button
                          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          className='h-100 border-0  input-background'
                        >
                          <img
                            src={toAbsoluteUrl('/media/icons/inbox/emoji.svg')}
                            alt='emoji'
                            width={16}
                            height={16}
                          />
                        </button>

                        <button
                          onClick={handleSendMessage}
                          className='h-100 border-0  input-background rounded-end pb-1 pe-5'
                        >
                          <img
                            src={toAbsoluteUrl('/media/icons/inbox/send.svg')}
                            alt='send'
                            width={16}
                            height={16}
                          />
                        </button>
                      </div>
                      {showEmojiPicker && (
                        <div className='d-flex justify-content-center mt-2'>
                          <EmojiPicker width={570} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='ms-8 settings-profile-container w-25'>
              <div className='card row'>
                <div className='card-body'>
                  <div className='d-flex justify-content-center mt-20'>
                    <img
                      className='me-2'
                      src={toAbsoluteUrl('/media/logos/profile.svg')}
                      height='94px'
                      width='94px'
                    />{' '}
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-center mt-4'>
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                        height='16px'
                        width='16px'
                      />{' '}
                      <h1 className='font-size-18 font-weight-600 text-black ps-2 text-color'>
                        {formatMessage({id: 'Mike Jones'})}
                      </h1>
                    </div>
                    <div>
                      <p className='font-size-14 font-weight-400 text-black ps-2 text-center text-color'>
                        {formatMessage({id: 'Content creator, Marketing Strategy'})}
                      </p>
                    </div>
                  </div>
                  <div className='px-2'>
                    <div>
                      <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                        {formatMessage({id: 'Location:'})}
                      </p>
                      <p className='font-size-14 font-weight-400 text-color'>{userInfo.location}</p>
                    </div>
                    <div>
                      <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                        {formatMessage({id: 'Status:'})}
                      </p>
                      <select
                        name=''
                        id=''
                        className='form-select mb-3 height-36px d-flex align-items-center font-weight-400 font-size-14 cursor-pointer py-2 status-dropdown text-color'
                      >
                        {statusOptions.map(({id, status}: any) => (
                          <option value={id} key={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='mt-5'>
                      <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                        {formatMessage({id: 'Active campaign:'})}
                      </p>
                      <p className='font-size-14 font-weight-400 text-color'>
                        {formatMessage({id: 'Lead Gen: India L2, 1-10'})}
                      </p>
                    </div>
                    <div>
                      <div className='input-background p-5 rounded'>
                        <div className='d-flex justify-content-between'>
                          <p className='font-size-14 font-weight-600 text-black '>
                            {formatMessage({id: 'Contact information'})}
                          </p>
                          <img
                            className=''
                            src={toAbsoluteUrl('/media/icons/inbox/plus.svg')}
                            height='16px'
                            width='16px'
                            onClick={() => setOpenAddContactInfoModal(true)}
                          />{' '}
                        </div>
                        <div>
                          <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                            {formatMessage({id: 'Email:'})}
                          </p>
                          <p className='font-size-14 font-weight-400 text-black'>
                            {userInfo.email}
                          </p>
                        </div>
                        <div>
                          <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                            {formatMessage({id: 'Phone number:'})}
                          </p>
                          <p className='font-size-14 font-weight-400 text-black'>
                            {userInfo.phoneNumber}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='mt-8'>
                      <div className='input-background p-5 rounded'>
                        <div className='d-flex justify-content-between'>
                          <p className='font-size-14 font-weight-600 text-black'>
                            {formatMessage({id: 'Notes'})}
                          </p>
                          <img
                            className=''
                            src={toAbsoluteUrl('/media/icons/inbox/plus.svg')}
                            height='16px'
                            width='16px'
                            onClick={() => setOpenAddNotesModal(true)}
                          />{' '}
                        </div>
                        <p className='font-size-14 font-weight-400 text-center text-grey-color'>
                          {formatMessage({id: 'The user does not have any notes add one'})}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='d-md-flex flex-md-row d-flex flex-column w-100'>
            <div className='settings-profile-container'>
              <div className='card row ms-0'>
                <div className='card-body m-0 p-0'>
                  <div className='d-flex justify-content-between px-4 py-2 border-bottom-inbox'>
                    <div className='mt-2'>
                      {' '}
                      <h1 className='activity-heading text-dark font-size-20 font-weight-600'>
                        {formatMessage({id: 'Inbox'})}
                      </h1>{' '}
                    </div>
                    <div className='d-flex gap-8 mt-2'>
                      <div className='d-flex gap-1'>
                        <div>
                          <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/icons/inbox/all_messages.svg')}
                          />
                        </div>
                        <div className='mt-1'>
                          <p>
                            {formatMessage({
                              id: 'All messages',
                            })}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className='d-flex gap-1'>
                          <div>
                            <img alt='Logo' src={toAbsoluteUrl('/media/icons/inbox/unread.svg')} />
                          </div>
                          <div className='mt-1'>
                            <p>
                              {formatMessage({
                                id: 'Unread',
                              })}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-100'>
                    <div className='d-flex justify-content-center pt-4 px-4'>
                      <input
                        type='text'
                        className='bg-body form-control form-control-flush height-36px rounded font-weight-400 text-clrA1 input-background search-box w-100'
                        name='search'
                        placeholder={formatMessage({id: 'Search'})}
                      />
                    </div>
                  </div>
                  <div className='w-100 d-flex pb-4'>
                    <div className='w-100 h-100 chat'>
                      {arr.map((ar: any) => (
                        <div className='border-bottom-inbox'>
                          <div className='d-flex mt-4 mx-4'>
                            {' '}
                            <img
                              className='me-2'
                              src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                              height='16px'
                              width='16px'
                            />{' '}
                            <h1 className='font-size-12 font-weight-400 text-black ps-2'>
                              {formatMessage({id: 'Mike Jones'})}
                            </h1>
                          </div>
                          <div className='d-flex mt-3 mx-4' onClick={() => handleProfile(ar.id)}>
                            <div className='m-0 p-0 mb-4'>
                              <img
                                className='me-2'
                                src={toAbsoluteUrl('/media/logos/profile.svg')}
                                height='48px'
                                width='48px'
                              />{' '}
                            </div>
                            <div className='d-flex flex-column'>
                              <h1 className='font-size-14 font-weight-600 text-black ps-2'>
                                {formatMessage({id: 'Mike Jones'})}
                              </h1>
                              <p className='font-size-14 font-weight-400 ps-2'>
                                <span className='text-primary-color'>
                                  {formatMessage({id: 'You:'})}&nbsp;
                                </span>
                                <span>{ar.chat}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <AddContactInformationModal
        openAddContactInfoModal={openAddContactInfoModal}
        setOpenAddContactInfoModal={setOpenAddContactInfoModal}
      ></AddContactInformationModal>
      <AddNotesModal
        openAddNotesModal={openAddNotesModal}
        setOpenAddNotesModal={setOpenAddNotesModal}
      ></AddNotesModal>
    </>
  )
}
