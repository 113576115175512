import {useEffect, useRef, useState} from 'react'
import {getUserActivityLogs} from '../../../activity/core/_requests'
import {useParams} from 'react-router-dom'
import NoDataPage from '../../../widgets/components/NoDataPage'
import {ActivityCard} from '../../../activity/components/ActivityCard'
import {Activity, ActivityByDate} from '../../../activity/core/models/Activity'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useDateFormat} from '../../../../hooks/useDateFormat'
import {DateTime} from 'luxon'

export const ActivityLogs = () => {
  const [page, setPage] = useState(1)
  const {campaignId} = useParams()
  const [allActivities, setAllActivities] = useState<Activity[]>([])
  const containerRef = useRef<any>(null)
  const [totalActivityCount, setTotalActivityCount] = useState<number>(0)
  const [pageLength, setPageLength] = useState<number>(10)
  const [loader, setLoader] = useState<boolean>(false)
  const {formatMessage} = useIntl()
  const [cardLoader, setCardLoader] = useState<boolean>()
  const {getDateValue} = useDateFormat()
  const [activityByDate, setActivityByDate] = useState<ActivityByDate>({})

  useEffect(() => {
    if ((allActivities.length < totalActivityCount || page === 1) && pageLength === 10) {
      const getActivityLogs = async () => {
        try {
          if (page === 1) {
            setLoader(true)
          }
          if (page > 1) {
            setCardLoader(true)
          }
          if (campaignId) {
            const campaignValue = parseInt(campaignId, 10)
            const {
              data: {data: data, success, errors},
            } = await getUserActivityLogs(page, campaignValue)
            if (success) {
              const {activities, totalActivityCount} = data
              setPageLength(activities.length)
              setAllActivities((prevData: any) => [...prevData, ...activities])
              setTotalActivityCount(totalActivityCount)
              setLoader(false)
              setCardLoader(false)
            } else {
              setLoader(false)
              setCardLoader(false)
              errors.forEach((error: string) => {
                toast.error(formatMessage({id: error}))
              })
            }
          } else {
            toast.error(formatMessage({id: 'Campaign id not found'}))
          }
        } catch (error: any) {
          setLoader(false)
          setCardLoader(false)
          console.log(error)
        }
      }
      getActivityLogs()
    }
  }, [page])

  useEffect(() => {
    const container = containerRef.current
    const handleScroll = () => {
      if (
        container &&
        container.scrollTop + container.clientHeight >= container.scrollHeight - 10
      ) {
        setPage((prevPage) => prevPage + 1)
      }
    }
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [allActivities])

  const groupActivitiesByDate = (activities: Activity[]) => {
    const groupedActivities: ActivityByDate = {}

    activities.forEach((activity: Activity) => {
      const createdAt =
        getDateValue(activity?.createdAt?.toString()) ||
        DateTime.fromISO(activity?.createdAt?.toString()).toLocaleString(DateTime.DATE_MED)

      if (!groupedActivities[createdAt]) {
        groupedActivities[createdAt] = []
      }

      groupedActivities[createdAt].push(activity)
    })

    return groupedActivities
  }

  useEffect(() => {
    setActivityByDate(groupActivitiesByDate(allActivities))
  }, [allActivities])

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <div className='settings-profile-container p-8 me-0'>
          <div className='font-size-20 fw-bold pb-7'>{formatMessage({id: 'Activity logs'})}</div>

          <div className='card row'>
            <div className='card-body p-0 m-0'>
              {!allActivities.length ? (
                <NoDataPage
                  noDataText={formatMessage({
                    id: 'Looks like there is no activity performed yet by this campaign. Please make sure the campaign is active and revisit after some time.',
                  })}
                />
              ) : (
                <div className='activity-log-container' ref={containerRef}>
                  {Object.keys(activityByDate).map((date: string, i: number) => (
                    <>
                      <div
                        className={`pill-color w-125px rounded-pill d-flex justify-content-center my-2`}
                      >
                        <p className='font-size-10 font-weight-500 m-0 text-white py-2'>{date}</p>
                      </div>
                      <div className='ms-14'>
                        {activityByDate[date].map((activity: Activity, index: number) => (
                          <ActivityCard
                            key={index}
                            activity={activity}
                            index={index}
                            length={activityByDate[date].length}
                            isCampaign={false}
                            cardLoader={cardLoader}
                            indexNumber={i}
                            ObjectLength={Object.keys(activityByDate).length}
                          />
                        ))}
                      </div>
                    </>
                  ))}
                  {cardLoader && (
                    <div className='w-100 d-md-flex justify-content-md-center d-flex justify-content-start mh-50'>
                      <div className='d-flex align-items-center me-2 position-relative'>
                        <Spinner isActivityLoader={true} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
