import {Routes, Route} from 'react-router-dom'
import {Template} from './components/Template'
import TreeDiagram from './components/TreeStructure'

const CampaignRoutes = () => {
  return (
    <Routes>
      <Route index element={<Template />} />
      <Route path=':workflowId' element={<TreeDiagram />} />
    </Routes>
  )
}

export default CampaignRoutes
