import axios from 'axios'

export const API_URL = process.env.REACT_APP_BASE_API_URL
const GET_NOTIFICATIONS = `${API_URL}/notifications`
const GET_LOCALE = `${API_URL}/userMeta`
export const getNotifications = () => {
  return axios.get(`${GET_NOTIFICATIONS}`, {
    validateStatus() {
      return true
    },
  })
}

export const getAllNotifications = () => {
  return axios.get(`${GET_NOTIFICATIONS}/?all=true`, {
    validateStatus() {
      return true
    },
  })
}

export const getLocaleData = () => {
  return axios.get(`${GET_LOCALE}`)
}
