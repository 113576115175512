import React from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const DeleteUserConfirmationModal = ({
  confirmModalShow,
  setDeleteUserModalShow,
  deleteUser,
  deleteUserLoading,
}: {
  confirmModalShow: boolean
  setDeleteUserModalShow: (show: boolean) => void
  deleteUser: any
  deleteUserLoading: boolean
}) => {
  const {formatMessage} = useIntl()
  return (
    <Modal
      show={confirmModalShow}
      onHide={() => setDeleteUserModalShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton className='border-bottom-0'>
        <h2 className='text-color'>{formatMessage({id: 'Delete User'})}</h2>
      </Modal.Header>
      <Modal.Body className='py-1 font-size-14 text-color'>
        <p>{formatMessage({id: 'All data related to user will be deleted permanently'})}</p>
      </Modal.Body>
      <Modal.Footer className='pt-0 border-top-0'>
        <div className='w-100 d-flex justify-content-end gap-3'>
          <GlobalButton
            buttonText={formatMessage({id: 'Cancel'})}
            buttonType={ButtonTypes.SECONDARY}
            onButtonClick={() => {
              setDeleteUserModalShow(false)
            }}
          />
          <GlobalButton
            buttonText={formatMessage({id: 'Confirm'})}
            buttonType={ButtonTypes.PRIMARY}
            onButtonClick={deleteUser}
            isLoading={deleteUserLoading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}
