import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import TextInput from '../../widgets/components/Input/TextInput'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const AddNewWorkflowModal = ({
  openCreateWorkflowModal,
  setOpenCreateWorkflowModal,
  workflowName,
  setWorkflowName,
  handleAddNewWorkflow,
  workflowNameErr,
  setWorkflowNameErr,
  creatingWorkflow,
}: {
  openCreateWorkflowModal: boolean
  setOpenCreateWorkflowModal: Dispatch<SetStateAction<boolean>>
  workflowName: string
  setWorkflowName: Dispatch<SetStateAction<string>>
  handleAddNewWorkflow: (value: string) => void
  workflowNameErr: boolean
  setWorkflowNameErr: Dispatch<SetStateAction<boolean>>
  creatingWorkflow: boolean
}) => {
  const {formatMessage} = useIntl()
  const initialValues = {
    workflowName: '',
  }
  const workflowSchema = Yup.object().shape({
    workflowName: Yup.string()
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Workflow name is required'})),
  })

  return (
    <>
      <Modal
        show={openCreateWorkflowModal}
        onHide={() => {
          setOpenCreateWorkflowModal(false)
          setWorkflowNameErr(false)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <div className='font-size-16 fw-bold'>
            {formatMessage({id: 'Create new custom workflow'})}
          </div>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={workflowSchema}
          onSubmit={(values, formikHelpers) => {
            handleAddNewWorkflow(values.workflowName)
          }}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <Modal.Body className='pt-0 pb-0'>
                <TextInput
                  fieldType={'text'}
                  fieldName={'workflowName'}
                  formik={formik}
                  placeholder={formatMessage({id: 'Enter workflow name'})}
                  label={formatMessage({id: 'Enter workflow name*'})}
                  isStarRequired={false}
                  toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.CREATE.WORKFLOW'})}
                />
              </Modal.Body>
              <Modal.Footer className='pt-0 border-top-0'>
                <GlobalButton
                  buttonText={formatMessage({id: 'Cancel'})}
                  buttonType={ButtonTypes.SECONDARY}
                  onButtonClick={() => {
                    setOpenCreateWorkflowModal(false)
                    setWorkflowName('')
                    setWorkflowNameErr(false)
                  }}
                />
                <GlobalButton
                  buttonText={formatMessage({id: 'Add Workflow'})}
                  buttonType={ButtonTypes.PRIMARY}
                  isLoading={creatingWorkflow}
                  isDisable={formik.isSubmitting || !formik.isValid || creatingWorkflow}
                  onButtonClick={formik.handleSubmit}
                />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}
