import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {CampaignListTableBody} from './CampaignListTableBody'
import '../styles/_campaign.scss'
import {Analytics, User, campaignObject} from '../core/_models'
import {BillingSummaryModal} from '../../onboarding/components/BillingSummaryModal'
import {useAuth} from '../../auth'
import {AddCardModal} from '../../onboarding/views/AddCardModal'
import {cardDetails} from '../../onboarding/core/_requests'
import {toast} from 'react-toastify'

export const CampaignListTableHead = ({
  campaignsData,
  getCampaignsList,
  checkedValue,
  campaignAnalyticsData,
}: {
  campaignsData: campaignObject[]
  getCampaignsList: (value?: string) => void
  checkedValue: boolean
  campaignAnalyticsData: Analytics[]
}) => {
  const {billingData} = useAuth()
  const [campaignList, setCampaignList] = useState<campaignObject[]>([])

  const getCardDetails = async () => {
    try {
      const {
        data: {data, success},
      } = await cardDetails()
    } catch (err: any) {
      toast.error(err.message)
    }
  }
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const campaignObject: any = {}
    campaignAnalyticsData?.forEach((item: Analytics) => {
      campaignObject[item.campaignId] = item
    })
    const finalList = campaignsData?.map((item: campaignObject) => {
      const campaignValue = campaignObject[item.campaignId]
      return {
        ...item,
        ...(campaignValue || {}),
      }
    })
    setCampaignList(finalList)
  }, [campaignsData, campaignAnalyticsData])

  return (
    <>
      {windowSize.width > 450 ? (
        <div className='table-responsive spinner'>
          <table className='table campaigns-table'>
            <tbody>
              {campaignList.map((item: campaignObject) => (
                <>
                  <CampaignListTableBody
                    key={item.campaignId}
                    name={item.name}
                    campaignId={item.campaignId}
                    progressPercent={'40'}
                    listOfLeads={'1'}
                    allLeads={'55'}
                    acceptanceRate={item.acceptanceRate}
                    replyRate={item.replyRate}
                    createdAtDate={item.createdAt}
                    status={item.status}
                    getCampaignsList={getCampaignsList}
                    leadlistCount={item.leadlistCount}
                    totalLeadsCount={item.totalLeadsCount}
                    checkedValue={checkedValue}
                    user={item.user}
                    leadLists={item.leadLists}
                    totalExpectedLeads={item.totalExpectedLeads}
                  />
                  <div className='mt-5'></div>
                </>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          {campaignList.map((item: campaignObject) => (
            <>
              <CampaignListTableBody
                key={item.campaignId}
                name={item.name}
                campaignId={item.campaignId}
                progressPercent={'40'}
                listOfLeads={'1'}
                allLeads={'55'}
                acceptanceRate={item.acceptanceRate}
                replyRate={item.replyRate}
                createdAtDate={item.createdAt}
                status={item.status}
                getCampaignsList={getCampaignsList}
                leadlistCount={item.leadlistCount}
                totalLeadsCount={item.totalLeadsCount}
                checkedValue={checkedValue}
                user={item.user}
                leadLists={item.leadLists}
                totalExpectedLeads={item.totalExpectedLeads}
              />
            </>
          ))}
        </div>
      )}
      <BillingSummaryModal
        currentPeriodStart={billingData?.current_period_start}
        currentPeriodEnd={billingData?.current_period_end}
        amount={billingData?.plan?.amount}
        currency={billingData?.currency}
        taxRate={
          billingData?.default_tax_rates.length > 0
            ? billingData?.default_tax_rates[0].effective_percentage
            : 18
        }
      />
      <AddCardModal
        getCardDetails={getCardDetails}
        billingStatus={billingData?.status}
        currencyId={billingData?.currency}
      />
    </>
  )
}
