import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import {useTimeUnit} from '../../../../hooks/useTimeUnit'
import TextInput from '../../../widgets/components/Input/TextInput'
import {Form, Formik} from 'formik'
import {useNavigate, useParams} from 'react-router-dom'
import {updateCampaignDetails} from '../../core/_requests'
import {toast} from 'react-toastify'
import {workingHour} from '../../core/_models'
import {ButtonTypes} from '../../../widgets/core/_constants'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'

export const CampaignSettings = ({
  campaignName,
  totalLeadsCount,
  excludeOtherCampaignLead,
  excludeTeamCampaignLead,
  workingHour,
  workflowName,
  isCampaignDetails,
  getCampaignData,
  prevStep,
  submitStep,
  leadsCount,
  totalExpectedLeads,
}: {
  campaignName: string | undefined
  totalLeadsCount: number | undefined
  excludeOtherCampaignLead: boolean | undefined
  excludeTeamCampaignLead: boolean | undefined
  workingHour: workingHour | undefined
  workflowName: string | undefined
  isCampaignDetails: boolean | undefined
  name: string | undefined
  getCampaignData: () => Promise<void>
  prevStep?: any
  submitStep?: any
  leadsCount: number | undefined
  totalExpectedLeads: number | undefined
}) => {
  const {formatMessage} = useIntl()
  const {getTimeUnit} = useTimeUnit()
  const {campaignId} = useParams()
  const navigate = useNavigate()
  const updateCampaignData = async (values: any) => {
    try {
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await updateCampaignDetails(
          campaignValue,
          values.excludeOtherCampaign,
          values.excludeTeamCampaign,
          values.name
        )
        if (success) {
          getCampaignData()
          toast.success(formatMessage({id: 'Campaign details updated successfully'}))
          if (!isCampaignDetails) navigate('/campaigns')
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        toast.error(formatMessage({id: 'Campaign id not found'}))
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const initialValues = {
    name: '' || campaignName,
    excludeOtherCampaign: true,
    excludeTeamCampaign: true,
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={updateCampaignData}>
        {(formik) => {
          if (campaignName) {
            formik.initialValues.name = campaignName
            formik.initialValues.excludeOtherCampaign = excludeOtherCampaignLead ?? false
            formik.initialValues.excludeTeamCampaign = excludeTeamCampaignLead ?? false
          }
          return (
            <Form>
              <div className='row p-8 m-0 setting-page campaigns-setting-table mb-8 pb-0'>
                <div className='col-md-6'>
                  <table>
                    <tbody className='align-top'>
                      <tr className={`${isCampaignDetails ? 'align-middle' : ''}`}>
                        <td>{formatMessage({id: 'Campaign name'})}</td>
                        <td>-</td>
                        <td>
                          {!isCampaignDetails ? (
                            campaignName
                          ) : (
                            <TextInput
                              fieldType={'name'}
                              fieldName={'name'}
                              formik={formik}
                              placeholder={formatMessage({id: 'Campaign name'})}
                              isStarRequired={false}
                              margin='mb-0'
                              isTooltipNotRequired={true}
                            />
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>{formatMessage({id: 'Lead list count'})}</td>
                        <td>-</td>
                        <td>{totalLeadsCount}</td>
                      </tr>
                      <tr>
                        <td>{formatMessage({id: 'All leads'})}</td>
                        <td>-</td>
                        <td>
                          {leadsCount} / {totalExpectedLeads}
                        </td>
                      </tr>
                      <tr>
                        <td>{formatMessage({id: 'Workflow'})}</td>
                        <td>-</td>
                        <td>
                          {workflowName}
                          {formik.dirty}
                        </td>
                      </tr>
                      <tr>
                        <td>{formatMessage({id: 'Time Zone'})}</td>
                        <td>-</td>
                        <td>{workingHour?.timezone?.title}</td>
                      </tr>
                      <tr>
                        <td>{formatMessage({id: 'Start time'})}</td>
                        <td>-</td>
                        <td> {getTimeUnit(workingHour?.startTime)}</td>
                      </tr>
                      <tr>
                        <td>{formatMessage({id: 'End time'})}</td>
                        <td>-</td>
                        <td> {getTimeUnit(workingHour?.endTime)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='col-md-6'>
                  <div className='mt-0 fs-6 flex-row mb-md-4 mb-8 '>
                    <div className='ms-md-8'>
                      <div className=' fw-bold mb-1'>
                        {formatMessage({id: 'Do not add a lead to the campaign if:'})}
                      </div>
                      <div className='d-flex justify-content-start gap-5 mt-8'>
                        <div>
                          <TextInput
                            fieldName={'excludeOtherCampaign'}
                            formik={formik}
                            customText={formatMessage({
                              id: 'Same leads found in other campaigns',
                            })}
                            diffColorText={formatMessage({id: ' (recommended)'})}
                            placeholder=''
                            fieldType={'checkbox'}
                            isCheckbox={true}
                          />
                        </div>
                      </div>
                      <TextInput
                        fieldName={'excludeTeamCampaign'}
                        formik={formik}
                        customText={formatMessage({
                          id: 'Same leads found in campaigns of your team members',
                        })}
                        diffColorText={formatMessage({id: ' (recommended)'})}
                        placeholder=''
                        fieldType={'checkbox'}
                        isCheckbox={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-md-flex justify-content-end button-margin'>
                <div className='d-flex gap-6 justify-content-end w-100'>
                  {!isCampaignDetails && (
                    <>
                      <GlobalButton
                        buttonText={formatMessage({id: 'Back'})}
                        buttonType={ButtonTypes.SECONDARY}
                        onButtonClick={prevStep}
                      />
                    </>
                  )}
                  <GlobalButton
                    buttonText={
                      !isCampaignDetails
                        ? formatMessage({id: 'Save & Launch'})
                        : formatMessage({id: 'Save'})
                    }
                    buttonType={ButtonTypes.PRIMARY}
                    onButtonClick={formik.handleSubmit}
                    isDisable={
                      formik.isSubmitting ||
                      !formik.isValid ||
                      (isCampaignDetails ? !formik.dirty : false)
                    }
                  />
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
