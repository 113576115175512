/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {AuthPage, useAuth, getAuth} from '../modules/auth'
import {App} from '../App'
import {LinkedInCallback} from 'react-linkedin-login-oauth2'

import {VerifyInvite} from '../modules/auth/components/VerifyInvite'
import {AuthLayout} from '../modules/auth/AuthLayout'
import {ResetPassword} from '../modules/auth/views/ResetPassword'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {VerifyRegistration} from '../modules/auth/views/VerifyRegistration'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const {userToken, setUserToken} = useAuth()

  const token = getAuth()

  if (token) {
    setUserToken(token.token)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route element={<AuthLayout />}>
            <Route path='/reset-password/:token' element={<ResetPassword />} />
          </Route>
          {userToken ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route path='/invite/:inviteToken' element={<VerifyInvite />} />
              <Route path='/registration/:registerToken' element={<VerifyRegistration />} />
            </>
          ) : (
            <>
              <Route index element={<Navigate to='/login' />} />
              <Route path='/linkedin' element={<LinkedInCallback />} />
              <Route path='/invite/:inviteToken' element={<VerifyInvite />} />
              <Route path='/registration/:registerToken' element={<VerifyRegistration />} />
              <Route path='/*' element={<AuthPage />} />
              <Route path='*' element={<ErrorsPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
