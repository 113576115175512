import {FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

import {useAuth} from '../modules/auth'
import {ToolbarWrapper} from '../../_metronic/layout/components/toolbar'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Activity} from '../modules/activity/components/Activity'
import {ActivityLog} from '../modules/activity/components/ActivityLog'
import {OnboardingPage} from '../modules/onboarding/views/OnboardingPage'
import LeadsRoutes from '../modules/leads/LeadsRoutes'
import CampaignRoutes from '../modules/campaign/CampaignRoutes'
import TemplateRoutes from '../modules/template/TemplateRoutes'
import {LoadingPage} from '../modules/onboarding/components/LoadingPage'
import InboxRoutes from '../modules/inbox/InboxRoutes'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()

  return (
    <>
      {currentUser ? (
        <Routes>
          {/* Redirect to Dashboard after success login/registartion */}
          {currentUser?.plan ? (
            <Route element={<MasterLayout />}>
              <Route index element={<Navigate to='/campaigns' />} />
              <Route path='campaigns/*' element={<CampaignRoutes />} />
              <Route path='workflow/*' element={<TemplateRoutes />} />
              <Route
                path='settings'
                element={
                  <SuspensedView>
                    <ToolbarWrapper />
                  </SuspensedView>
                }
              />
              <Route path='activity-control' element={<Activity />} />
              <Route path='activity-log' element={<ActivityLog />} />
              <Route path='activity' element={<Activity />} />
              <Route path='leads/*' element={<LeadsRoutes />} />
              <Route path='inbox/*' element={<InboxRoutes />} />
              <Route path='*' element={<ErrorsPage />} />
            </Route>
          ) : (
            <>
              <Route index element={<Navigate to='/onboarding/' />} />
              <Route path='/onboarding' element={<OnboardingPage />} />
              <Route element={<MasterLayout />}>
                <Route path='/campaigns' element={<LoadingPage />} />
              </Route>

              <Route path='*' element={<ErrorsPage />} />
            </>
          )}
        </Routes>
      ) : null}
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
