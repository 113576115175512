/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'

import {Form, Formik} from 'formik'
import {getTokenStatus, resetPassword} from '../core/_requests'

import TextInput from '../../widgets/components/Input/TextInput'
import {useIntl} from 'react-intl'
import {CustomToast} from '../../widgets/components/UI/CustomToast'
import {FormikProps} from '../core/_models'
import {useLocation} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'
import {useAuth} from '../core/Auth'

const initialValues = {
  password: '',
  repeatPassword: '',
}

export function ResetPassword() {
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState(false)
  const [errorsArray, setErrorsArray] = useState<string[]>()
  const [resetPasswordError, setResetPasswordError] = useState<boolean | undefined>()
  const navigate = useNavigate()
  const {logout} = useAuth()
  let {pathname} = useLocation()
  const token = pathname.split('/')[2]
  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 characters'}))
      .max(50, formatMessage({id: 'Maximum 50 characters'}))
      .required(formatMessage({id: 'Password is required'})),
    repeatPassword: Yup.string()
      .required(formatMessage({id: 'Repeat New Password is required'}))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          formatMessage({id: "New Password and Repeat New Password didn't match"})
        ),
      }),
  })
  const onSubmit = async (values: any, {setStatus, setSubmitting}: FormikProps) => {
    setLoading(true)
    try {
      const {
        data: {success, errors},
      } = await resetPassword(token, values.password)
      if (success) {
        toast.success(formatMessage({id: 'Password changed successfully'}))
        setLoading(false)
        logout()
        navigate('/login')
      } else if (errors) {
        setErrorsArray(errors)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)

      setStatus('something went wrong')
      setSubmitting(false)
      setLoading(false)
    }
  }

  const handleResetPassword = async () => {
    try {
      const {
        data: {
          data: {valid},
        },
      } = await getTokenStatus(token)
      setResetPasswordError(!valid)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleResetPassword()
  }, [token, resetPasswordError]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {resetPasswordError === undefined ? null : !resetPasswordError ? (
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form className='form w-100 px-3' id='kt_login_signin_form'>
              <div className='mb-11'>
                <h1 className='text-center text-dark fw-bolder mb-3'>
                  {formatMessage({id: 'Reset Password'})}
                </h1>
                <div className='fw-bold font-size-14 text-black'>
                  {formatMessage({
                    id: 'Enter your new password to regain access to your account. Choose a secure password to protect your information.',
                  })}
                </div>
              </div>

              {errorsArray?.map((error: string, index) => (
                <CustomToast key={index} status={error} />
              ))}
              {formik.status && <CustomToast status={formik.status} />}

              <TextInput
                fieldName={'password'}
                formik={formik}
                fieldType={'password'}
                placeholder={''}
                label={formatMessage({id: 'Enter new password'})}
                toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.RESET_PASSWORD.NEW_PASSWORD'})}
                isStarRequired={true}
              />

              <TextInput
                fieldName={'repeatPassword'}
                formik={formik}
                fieldType={'password'}
                placeholder={''}
                label={formatMessage({id: 'Repeat new password'})}
                toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.RESET_PASSWORD.RETYPE_PASSWORD'})}
                isStarRequired={true}
              />
              <div className='d-flex justify-content-end'>
                <GlobalButton
                  buttonText={formatMessage({id: 'Update Password Now'})}
                  buttonType={ButtonTypes.PRIMARY}
                  isLoading={loading}
                  isDisable={formik.isSubmitting || !formik.isValid}
                  onButtonClick={formik.handleSubmit}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div>
          {formatMessage({
            id: 'Sorry, this password reset link has expired. Please submit a new request to reset your password.',
          })}
        </div>
      )}
    </>
  )
}
