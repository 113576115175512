import {useState} from 'react'
import {useCookies} from 'react-cookie'

export const useUtmValidator = () => {
  const [cookies] = useCookies(['utm_source', 'utm_campaign', 'utm_medium'])
  const [utmValues, setUtmValues] = useState<any>({})

  function handleUtmEvents() {
    const utm_source = cookies.utm_source
    const utm_medium = cookies.utm_medium
    const utm_campaign = cookies.utm_campaign

    if (utm_source != '' || utm_medium != '' || utm_campaign != '') {
      let data = {
        utm_source,
        utm_medium,
        utm_campaign,
      }

      if (
        data.utm_source &&
        data.utm_source.firstTouch === null &&
        data.utm_source.lastTouch === null
      ) {
        delete data.utm_source
      }
      if (
        data.utm_medium &&
        data.utm_medium.firstTouch === null &&
        data.utm_medium.lastTouch === null
      ) {
        delete data.utm_medium
      }

      if (
        data.utm_campaign &&
        data.utm_campaign.firstTouch === null &&
        data.utm_campaign.lastTouch === null
      ) {
        delete data.utm_campaign
      }

      setUtmValues(data)
    }
  }

  return {utmValues, handleUtmEvents}
}
