import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import '../../styles/_campaign.scss'
import {toast} from 'react-toastify'
import {getUserList} from '../../../profile/core/_requests'
import {userType} from '../../core/models/_userListType'
import {useParams} from 'react-router-dom'
import {updateCampaignDetail} from '../../core/_requests'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'

export const AddAccountsPage = ({
  submitStep,
  memberId,
  setMemberId,
}: {
  submitStep: () => void
  memberId: number | undefined
  setMemberId: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const {formatMessage} = useIntl()

  // useStates
  const [userList, setUserList] = useState<userType[]>([])
  const {campaignId} = useParams()
  const getUsersList = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      if (success) {
        setUserList(data)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getUsersList()
  }, [])

  const updateCampaignData = async () => {
    try {
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await updateCampaignDetail(campaignValue, +memberId!)
        if (success) {
          submitStep()
          toast.success(formatMessage({id: 'Account selected successfully'}))
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        toast.error(formatMessage({id: 'Campaign id not found'}))
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const handleSelectChangeMemberId = (value: any) => {
    setMemberId(value.toString())
  }

  return (
    <>
      <div className='row w-100 m-0 m-8'>
        <div className='card-body p-0'>
          <div className='row d-flex mb-8'>
            <div className='col-md-6 col-12 p-0 ps-2'>
              <h4 className='card-label font-size-20 font-weight-600'>
                {formatMessage({id: 'Select Your Linkedin Account'})}
              </h4>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <table className='account-table w-100'>
                {userList &&
                  userList.map((user: any) => (
                    <tbody key={user.userId}>
                      <tr>
                        <div className='mb-8 Template-Card-Wrapper'>
                          <div className='w-100 d-flex flex-column'>
                            <div className='d-flex flex-row justify-content-between m-8'>
                              <td className=''>
                                {' '}
                                <div className='d-flex flex-column'>
                                  <div className='d-flex justify-content-md-start mb-0 form-check form-check-inline pt-4 m-0'>
                                    <input
                                      className='form-check-input radio-button me-3'
                                      type='radio'
                                      name='inlineRadioOptions'
                                      id={`inlineRadio_${user?.userId}`}
                                      value={user.userId}
                                      onChange={(e) => handleSelectChangeMemberId(e.target.value)}
                                      defaultChecked={memberId === user?.userId}
                                      disabled={!user.linkedin_url}
                                    />
                                    <span className='text-dark fw-bold fs-6'>
                                      {user.firstName} {user.lastName}
                                    </span>
                                  </div>

                                  <div className='font-size-12 font-weight-400 text-muted ms-10 pt-2'>
                                    {formatMessage({id: 'Name'})}
                                  </div>
                                </div>
                              </td>
                              <td className=''>
                                {' '}
                                <div className='my-3'>
                                  <div className='d-flex justify-content-md-start mb-0'>
                                    <img
                                      className='me-2'
                                      src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                                      height='16px'
                                      width='16px'
                                    />

                                    {user.linkedin_url ? (
                                      <span className='fw-bold font-size-14 font-weight-600 linkedin-text'>
                                        <a href={`${user.linkedin_url}`} target='_blank'>
                                          {user.linkedin_name}
                                        </a>
                                      </span>
                                    ) : (
                                      <span className='fw-bold font-size-14 font-weight-600 text-danger text-decoration-underline'>
                                        {formatMessage({id: 'Linkedin account is not linked'})}
                                      </span>
                                    )}
                                  </div>
                                  <div className='font-size-12 font-weight-400 text-muted pt-2'>
                                    {formatMessage({id: 'Linkedin account'})}
                                  </div>
                                </div>
                              </td>
                              <td className=''>
                                {' '}
                                <div className='my-3'>
                                  <div className='d-flex justify-content-md-start mb-0'>
                                    <span className='fw-bold font-size-14 font-weight-600'>
                                      {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
                                    </span>
                                  </div>

                                  <div className='font-size-12 font-weight-400 text-muted pt-2'>
                                    {formatMessage({id: 'Role'})}
                                  </div>
                                </div>
                              </td>
                              <td className=''>
                                {' '}
                                <div className='my-3'>
                                  <div className='d-flex justify-content-md-start mb-0'>
                                    <span className='fw-bold font-size-14 font-weight-600'>
                                      {user.email}
                                    </span>
                                  </div>
                                  <div className='font-size-12 font-weight-400 text-muted pt-2'>
                                    {formatMessage({id: 'Email ID'})}
                                  </div>
                                </div>
                              </td>
                            </div>
                          </div>
                        </div>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>
          </div>
          <div className='d-flex flex-stack pt-5'>
            <div className='d-flex gap-6 justify-content-end w-100'>
              <GlobalButton
                buttonText={formatMessage({id: 'Next'})}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={() => updateCampaignData()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
