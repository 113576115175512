import React, {useEffect, useRef, useState} from 'react'
import '../../styles/_campaign.scss'
import {AnalyticsRow} from './AnalyticsRow'
import {AnalyticsDataCards} from './AnalyticsDataCards'
import {AnalyticsTabSection} from './AnalyticsTabSection'
import {fetchCampaignDetails, getCampaignDetails} from '../../core/_requests'
import {useParams} from 'react-router'
import {CampaignDetails, CampaignObject} from '../../core/_models'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../../../src/app/modules/auth/core/Auth'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {getUserList} from '../../../profile/core/_requests'
import {Roles} from '../../../profile/core/_constants'

export const CampaignAnalyticsSection = () => {
  const [campaignData, setCampaignData] = useState<CampaignObject>()
  const [campaignDetails, setCampaignDetails] = useState<CampaignDetails>()
  const navigate = useNavigate()
  const {setStepperIndex, currentUser, teamLength, setTeamLength} = useAuth()
  const {campaignId} = useParams()
  const [loader, setLoader] = useState<boolean>(true)
  const {formatMessage} = useIntl()

  const getCampaignData = async () => {
    try {
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await getCampaignDetails(campaignValue)
        if (success) {
          if (
            (currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
            teamLength > 1
          ) {
            if (data.userId === null) {
              setStepperIndex(1)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            } else if (data.leadLists.length === 0) {
              setStepperIndex(2)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            } else if (data.workflow === null) {
              setStepperIndex(3)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            } else if (data.workingHour === null) {
              setStepperIndex(4)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            }
          } else {
            if (data.leadLists?.length === 0) {
              setStepperIndex(1)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            } else if (data.workflow === null) {
              setStepperIndex(2)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            } else if (data.workingHour === null) {
              setStepperIndex(3)
              navigate(`/campaigns/create-flow/${campaignValue}`)
            }
          }
          setCampaignData(data)
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        toast.error(formatMessage({id: 'Campaign id not found'}))
      }
      setLoader(false)
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getTeamUsers()
  }, [])

  const getTeamUsers = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      setTeamLength(data.length)
      getCampaignData()
      getsCampaignDetails()
    } catch (err) {
      console.log(err)
    }
  }

  const getsCampaignDetails = async () => {
    try {
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, data, errors},
        } = await fetchCampaignDetails(campaignValue)
        if (success) {
          setCampaignDetails(data)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getsCampaignDetails()
  }, [])

  return (
    <>
      {loader ? (
        <div className='position-relative h-100vh'>
          <Spinner background={true} />
        </div>
      ) : (
        <>
          {' '}
          <div className='table-responsive bar mb-4'>
            <AnalyticsRow
              data={campaignData}
              progressPercent='25'
              details={campaignDetails}
              createdAtDate='Sep 26, 2023'
              getCampaignData={getCampaignData}
            />
            <AnalyticsDataCards details={campaignDetails} campaignId={campaignId} />
          </div>
          <div className='bg-white bar'>
            <AnalyticsTabSection campaignData={campaignData} getCampaignData={getCampaignData} />
          </div>
        </>
      )}
    </>
  )
}
