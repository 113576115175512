import {PageLink} from '../../template/core/models/_heading'

export const hoursOptions = [
  {
    id: '0',
    name: '12:00 AM',
    value: '0',
  },
  {
    id: '1',
    name: '01:00 AM',
    value: '1',
  },
  {
    id: '2',
    name: '02:00 AM',
    value: '2',
  },
  {
    id: '3',
    name: '03:00 AM',
    value: '3',
  },
  {
    id: '4',
    name: '04:00 AM',
    value: '4',
  },
  {
    id: '5',
    name: '05:00 AM',
    value: '5',
  },
  {
    id: '6',
    name: '06:00 AM',
    value: '6',
  },
  {
    id: '7',
    name: '07:00 AM',
    value: '7',
  },
  {
    id: '8',
    name: '08:00 AM',
    value: '8',
  },
  {
    id: '9',
    name: '09:00 AM',
    value: '9',
  },
  {
    id: '10',
    name: '10:00 AM',
    value: '10',
  },
  {
    id: '11',
    name: '11:00 AM',
    value: '11',
  },
  {
    id: '12',
    name: '12:00 PM',
    value: '12',
  },
  {
    id: '13',
    name: '01:00 PM',
    value: '13',
  },
  {
    id: '14',
    name: '02:00 PM',
    value: '14',
  },
  {
    id: '15',
    name: '03:00 PM',
    value: '15',
  },
  {
    id: '16',
    name: '04:00 PM',
    value: '16',
  },
  {
    id: '17',
    name: '05:00 PM',
    value: '17',
  },
  {
    id: '18',
    name: '06:00 PM',
    value: '18',
  },
  {
    id: '19',
    name: '07:00 PM',
    value: '19',
  },
  {
    id: '20',
    name: '08:00 PM',
    value: '20',
  },
  {
    id: '21',
    name: '09:00 PM',
    value: '21',
  },
  {
    id: '22',
    name: '10:00 PM',
    value: '22',
  },
  {
    id: '23',
    name: '11:00 PM',
    value: '23',
  },
]

export const templateType = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
}

export const templateStatus = {
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
}

export const campaignStatus = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  INACTIVE: 'INACTIVE',
}

export const campaignBreadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Campaign',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const subscriptionError = {
  CANCELLED_SUBSCRIPTION: 'Subscription is cancelled. Please renew your subscription',
}

export enum crawlStatus {
  PENDING = 'PENDING',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
}
