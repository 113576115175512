import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {AllCampaignList} from './components/AllCampaignList'
import {CreateCampaignFlowPage} from './components/CreateCampaignFlowPage'
import {CampaignAnalyticsSection} from './components/singleCampaignPage/CampaignAnalyticsSection'

const CampaignRoutes = () => {
  return (
    <Routes>
      <Route index element={<AllCampaignList />} />
      <Route path='create-flow/:campaignId' element={<CreateCampaignFlowPage />} />
      <Route path='/:campaignId' element={<CampaignAnalyticsSection />} />
    </Routes>
  )
}

export default CampaignRoutes
