import React, {useRef, useState} from 'react'
import {Modal, Overlay, Tooltip} from 'react-bootstrap'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import {CampaignModalTab} from '../createCampaignSteps/CampaignModalTab'
import * as Yup from 'yup'
import {Form, Formik} from 'formik'
import {AddLeads, CreateLeadList} from '../../core/_requests'
import {toast} from 'react-toastify'
import TextInput from '../../../widgets/components/Input/TextInput'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'

export const AddLeadsModal = ({
  openLeadsModal,
  setOpenLeadsModal,
  setleadListLoader,
  campaignId,
  getAllLeadList,
  leadListLoader,
  getCampaignData,
}: {
  openLeadsModal: boolean
  setOpenLeadsModal: (value: boolean) => void
  setleadListLoader: React.Dispatch<React.SetStateAction<boolean>>
  campaignId: string | undefined
  getAllLeadList: () => Promise<void>
  leadListLoader: boolean
  getCampaignData?: () => void
}) => {
  const [nameConstant, setNameConstant] = useState('')
  const {formatMessage} = useIntl()
  const initialValues = {
    name: nameConstant,
    linkedInURL: '',
  }
  const profileUrlsInitialValues = {
    name: nameConstant,
    profileURLs: '',
  }
  const csvFileInitialValues = {
    name: nameConstant,
    csvFile: '',
  }
  const [availableLeads, setAvailableLeads] = useState(0)
  const [showTooltip, setShowTooltip] = useState(false)
  const targetRef = useRef(null)

  const handleTooltipShow = () => setShowTooltip(true)
  const handleTooltipHide = () => setShowTooltip(false)

  const handleProfileUrls = async (formik: any) => {
    const inputText = formik.values.profileURLs
    const parts = inputText.split(/[\n, ]+/)
    const newFilteredUrls = parts
      .filter((item: string) => item.startsWith('https://www.linkedin.com/in/'))
      .map((profileUrl: string) => ({
        profileUrl: profileUrl.includes('?miniProfile')
          ? profileUrl.substring(0, profileUrl.indexOf('?miniProfile'))
          : profileUrl,
        firstName: null,
        lastName: null,
      }))
    try {
      setLoading(true)
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await AddLeads(formik.values.name, newFilteredUrls, campaignValue)
        if (success) {
          setLoading(false)
          getAllLeadList()
          toast.success(formatMessage({id: 'Lead List Created successfully'}))
          setOpenLeadsModal(false)
          setNameConstant('')
        } else {
          setLoading(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
          setOpenLeadsModal(false)
        }
      } else {
        setLoading(false)
        setOpenLeadsModal(false)
      }
    } catch (err) {
      setLoading(false)
      setOpenLeadsModal(false)
      console.log(err)
    }
  }
  const handleCreateLeadList = async (formik: any) => {
    try {
      setleadListLoader(true)
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await CreateLeadList(
          formik.values.name,
          formik.values.linkedInURL,
          formik.values.expectedLeads,
          campaignValue
        )
        if (success) {
          setleadListLoader(false)
          getAllLeadList()
          if (getCampaignData) {
            getCampaignData()
          }
          toast.success(formatMessage({id: 'Lead List Created successfully'}))
          setOpenLeadsModal(false)
          setNameConstant('')
        } else {
          setleadListLoader(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        setleadListLoader(false)
      }
    } catch (err) {
      setleadListLoader(false)
      console.log(err)
    }
  }

  const handleUploadCsv = async (formik: any) => {
    try {
      setLoading(true)
      if (campaignId) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await AddLeads(formik.values.name, formik.values.csvFile, campaignValue)
        if (success) {
          setLoading(false)
          getAllLeadList()
          toast.success(formatMessage({id: 'Lead List Created successfully'}))
          setOpenLeadsModal(false)
          setNameConstant('')
        } else {
          setLoading(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const addLeadsValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    expectedLeads: Yup.number()
      .required('Please enter a value')
      .min(1, 'Minimum 1 lead is required')
      .max(availableLeads, `Maximum ${availableLeads} leads can be added.`),
    linkedInURL: Yup.string()
      .matches(
        /^(https:\/\/www\.linkedin\.com\/search\/results\/people\/(.*)?|https:\/\/www\.linkedin\.com\/sales\/lists\/people\/(.*)?|https:\/\/www\.linkedin\.com\/sales\/search\/people\?(.*)?)$/,
        'Please enter valid url format'
      )
      .max(8000, 'LinkedIn URL must be under 8000 characters')
      .required('LinkedIn URL is required'),
  })

  const profileUrlsValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    profileURLs: Yup.string()
      .matches(/^https:\/\/www\.linkedin\.com\/in\/.*/, 'Please enter valid url format')
      .required('LinkedIn Profile URL is required'),
  })

  const uploadCsvValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    csvFile: Yup.array()
      .of(
        Yup.object().shape({
          profileUrl: Yup.string()
            .trim()
            .matches(/^https:\/\/www\.linkedin\.com\/in\/.*/, 'Invalid Profile URL format')
            .required('Profile URL is required'),
          firstName: Yup.string()
            .trim()
            .nullable()
            .matches(/^\S*$/, 'First name cannot contain spaces'),
          lastName: Yup.string().trim().nullable(),
        })
      )
      .required('CSV File is required')
      .test('is-not-empty', 'CSV File cannot be empty', function (value) {
        return value && value.length > 0
          ? true
          : this.createError({message: 'CSV File cannot be empty'})
      }),
  })

  const [key, setKey] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const formikInitialValues =
    key === 1 ? initialValues : key === 2 ? profileUrlsInitialValues : csvFileInitialValues
  const formikValidationSchema =
    key === 1
      ? addLeadsValidationSchema
      : key === 2
      ? profileUrlsValidationSchema
      : uploadCsvValidationSchema
  return (
    <>
      <Modal
        show={openLeadsModal}
        onHide={() => {
          setOpenLeadsModal(false)
          setAvailableLeads(0)
          setNameConstant('')
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Formik
          initialValues={formikInitialValues}
          onSubmit={() => {}}
          validationSchema={formikValidationSchema}
          enableReinitialize={true}
          validateOnMount
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <Modal.Header closeButton className='border-bottom-0 pb-0'>
                    <div className='font-size-16 fw-bold'>
                      {formatMessage({id: 'Add Lead list'})}
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <TextInput
                        fieldType={'text'}
                        label={formatMessage({id: 'Enter lead list name'})}
                        fieldName={'name'}
                        formik={formik}
                        placeholder={formatMessage({id: 'Enter name'})}
                        toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.ADD_LEADS.NAME'})}
                        isStarRequired={true}
                      />
                    </div>
                    <div>
                      <div className='fw-bold mt-4 font-size-14'>
                        {formatMessage({id: 'How would you like to add leads?'})}
                        <span
                          ref={targetRef}
                          className='ms-2 fw-bold cursor-pointer text-add-search'
                          onMouseOver={handleTooltipShow}
                          onMouseOut={handleTooltipHide}
                        >
                          {formatMessage({id: 'Need Help?'})}
                        </span>

                        <Overlay show={showTooltip} target={targetRef.current} placement='top'>
                          {(props) => (
                            <Tooltip id='overlay-example' {...props}>
                              <div className='d-flex flex-column align-items-start '>
                                <div className='mb-1 '>
                                  {formatMessage({id: '1. Login to LinkedIn via web.'})}
                                </div>
                                <div className='mb-1'>
                                  {formatMessage({
                                    id: '2. Click on search and type for keyword to whom',
                                  })}
                                  <br />
                                  <span className='ms-2'>
                                    {formatMessage({
                                      id: 'you need to connect and do prospecting.Ex-',
                                    })}
                                  </span>
                                  <br />
                                  <span className=''>
                                    {formatMessage({
                                      id: 'startup sales head, VP marketing,B2B sales',
                                    })}
                                  </span>
                                  <br />
                                  <span className='ms-5 d-flex flex-row justify-content-start'>
                                    {formatMessage({id: 'lead etc.'})}
                                  </span>
                                </div>
                                <div className='mb-1'>
                                  {formatMessage({
                                    id: '3. Click on people and select other filter based',
                                  })}
                                  <br />
                                  <span className='ms-4 d-flex flex-row justify-content-start'>
                                    {formatMessage({id: 'on your choice.'})}
                                  </span>
                                </div>
                                <div className='mb-1'>
                                  {formatMessage({
                                    id: '4. Copy URL from browser and paste to DripFunnel.',
                                  })}
                                </div>
                              </div>
                            </Tooltip>
                          )}
                        </Overlay>
                      </div>
                      <CampaignModalTab
                        formik={formik}
                        setKey={setKey}
                        campaignId={campaignId}
                        availableLeads={availableLeads}
                        setAvailableLeads={setAvailableLeads}
                        setNameConstant={setNameConstant}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className='pt-0 border-top-0'>
                    <div className='w-100 d-flex justify-content-md-end justify-content-around gap-3'>
                      <GlobalButton
                        buttonText={formatMessage({id: 'Cancel'})}
                        buttonType={ButtonTypes.SECONDARY}
                        onButtonClick={() => {
                          setOpenLeadsModal(false)
                          setAvailableLeads(0)
                          setNameConstant('')
                        }}
                      />
                      {key === 1 ? (
                        <GlobalButton
                          buttonText={formatMessage({id: 'Create lead list'})}
                          buttonType={ButtonTypes.PRIMARY}
                          onButtonClick={() => handleCreateLeadList(formik)}
                          isDisable={formik.isSubmitting || !formik.isValid || leadListLoader}
                          isLoading={leadListLoader}
                        />
                      ) : key === 2 ? (
                        <GlobalButton
                          buttonText={formatMessage({id: 'Create lead list'})}
                          buttonType={ButtonTypes.PRIMARY}
                          onButtonClick={() => handleProfileUrls(formik)}
                          isDisable={formik.isSubmitting || !formik.isValid}
                          isLoading={loading}
                        />
                      ) : (
                        <GlobalButton
                          buttonText={formatMessage({id: 'Create lead list'})}
                          buttonType={ButtonTypes.PRIMARY}
                          onButtonClick={() => handleUploadCsv(formik)}
                          isDisable={formik.isSubmitting || !formik.isValid}
                          isLoading={loading}
                        />
                      )}
                    </div>
                  </Modal.Footer>
                </Form>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}
