import {Modal} from 'react-bootstrap'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import React, {useEffect, useState} from 'react'
import {
  connectLinkedin,
  getLinkedinMessage,
  postLinkedinMessage,
  putLinkedInOTP,
} from '../../../onboarding/core/_requests'
import {toast} from 'react-toastify'
import TextInput from '../../../widgets/components/Input/TextInput'
import {Form, Formik} from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'
import {profileData, updateProfileInfo} from '../../../profile/core/_requests'
import {cancelLinkedInOnboarding} from '../../core/_requests'

export const LinkedInModal = ({
  openLinkedInModal,
  setOpenLinkedInModal,
  setOpenLinkedIn,
  getProfileInfo,
  checkButton,
}: {
  openLinkedInModal: boolean
  setOpenLinkedInModal: (value: boolean) => void
  setOpenLinkedIn: (value: boolean) => void
  getProfileInfo: () => void
  checkButton?: () => void
}) => {
  const {formatMessage} = useIntl()

  const [openOtpModal, setOpenOtpModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [otpModalLoading, setOtpModalLoading] = useState(false)
  const [apiCall, setApiCall] = useState(false)
  const [abandonBtnLoading, setAbandonBtnLoading] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [challengeType, setChallengeType] = useState<'APP' | 'EMAIL' | 'MOBILE'>()
  const [isLinkedinAuth, setIsLinkedinAuth] = useState(false)
  const [response, setResponse] = useState<string>('')
  const [isPhoneModal, setIsPhoneModal] = useState(false)
  const [showAbandonBtn, setShowAbandonBtn] = useState(false)

  const linkedinConnect = async (values: any) => {
    try {
      setEmail(values.linkedinEmail)
      setPassword(values.linkedinPassword)
      setResponse('')
      setLoading(true)
      setShowAbandonBtn(true)

      const {
        data: {data, success, errors},
      } = await connectLinkedin(values.linkedinEmail, values.linkedinPassword)
      if (success) {
        setApiCall(true)
      } else {
        setLoading(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    } finally {
      setAbandonBtnLoading(false)
    }
  }

  const submitOTP = async (values: any) => {
    try {
      if (values != 1) {
        setOtpModalLoading(true)
        setShowAbandonBtn(true)
      } else {
        setOpenOtpModal(false)
        setIsLinkedinAuth(false)
      }
      const {
        data: {data, success, errors},
      } = await putLinkedInOTP(values.otp || values)
      if (!success) {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getMessage = async () => {
    try {
      const {
        data: {data, success},
      } = await getLinkedinMessage()
      if (success) {
        if (data.linkedInChallenge) {
          setOpenOtpModal(true)
          setChallengeType(data.linkedInChallenge)
          await postLinkedinMessage(data.linkedInError)
          setIsLinkedinAuth(false)
        }
        if (data.linkedInError == 'Success') {
          await postLinkedinMessage(data.linkedInError)
          toast.success(
            formatMessage({
              id: 'LinkedIn account connected successfully.',
            })
          )
          const {
            data: {success, data: profile, errors},
          } = await profileData()
          if (profile.contact == null) {
            setIsPhoneModal(true)
          } else {
            setOpenLinkedIn(false)
            setOpenLinkedInModal(false)
          }
          setOtpModalLoading(false)
          setLoading(false)
          setApiCall(false)
          setEmail('')
          setPassword('')
          getProfileInfo()
          if (checkButton) {
            checkButton()
          }
        } else if (data.linkedInError === 'Invalid Username or Password') {
          setLoading(false)
          setApiCall(false)
          setResponse(data.linkedInError)
          await postLinkedinMessage(data.linkedInError)
        } else if (data.linkedInError.includes('We could not connect you')) {
          await postLinkedinMessage(data.linkedInError)
          setLoading(false)
          setEmail('')
          setApiCall(false)
          setPassword('')
          setResponse(data.linkedInError)
          await responseCount()
        } else if (data.linkedInError.includes('In app verification is failed. Please try again')) {
          setLoading(false)
          setEmail('')
          setApiCall(false)
          setPassword('')
          setResponse(data.linkedInError)
          setOpenOtpModal(false)
          await postLinkedinMessage(data.linkedInError)
          await responseCount()
        } else if (data.linkedInError.includes('authenticator app') && data.linkedInOtp == null) {
          setOpenOtpModal(true)
          setIsLinkedinAuth(true)
        } else if (data.linkedInError.includes('authenticator app') && data.linkedInOtp !== null) {
          setOpenOtpModal(false)
          setIsLinkedinAuth(false)
        } else {
          setOpenOtpModal(true)
          setIsLinkedinAuth(false)
          setOtpModalLoading(false)
          setLoading(false)
          setResponse('')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleAbandon = async () => {
    setAbandonBtnLoading(true)
    try {
      const {
        data: {data},
      } = await cancelLinkedInOnboarding()

      if (data) {
        toast.success(formatMessage({id: 'Onboarding Cancelled Successfully'}))
        setAbandonBtnLoading(false)
        setEmail('')
        setPassword('')
      }
    } catch (err) {
    } finally {
      setLoading(false)
      setAbandonBtnLoading(false)
      setApiCall(false)
      setShowAbandonBtn(false)
    }
  }

  useEffect(() => {
    if (apiCall) {
      const intervalId = setInterval(async () => {
        await getMessage()
      }, 5000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [apiCall])

  const responseCount = async () => {
    setTimeout(async () => {
      setResponse('')
    }, 5000)
  }

  return (
    <>
      <Modal show={openLinkedInModal} aria-labelledby='contained-modal-title-vcenter' centered>
        {!isPhoneModal ? (
          <Modal.Body className='mx-10'>
            {!openOtpModal ? (
              <>
                <div className='d-flex flex-center '>
                  <div className='text-center'>
                    <>
                      {!loading ? (
                        <UsernamePasswordModal
                          email={email}
                          password={password}
                          response={response}
                          linkedinConnect={linkedinConnect}
                          setLoading={setLoading}
                          setOpenLinkedInModal={setOpenLinkedInModal}
                        />
                      ) : (
                        <AnimatingModal
                          abandonBtnLoading={abandonBtnLoading}
                          showAbandonBtn={showAbandonBtn}
                          handleAbandon={handleAbandon}
                        />
                      )}
                    </>
                  </div>
                </div>
              </>
            ) : (
              <>
                {isLinkedinAuth ? (
                  <LinkedInAuthenticatorModal submitOTP={submitOTP} />
                ) : (
                  <OTPModal
                    abandonBtnLoading={abandonBtnLoading}
                    showAbandonBtn={showAbandonBtn}
                    handleAbandon={handleAbandon}
                    otpModalLoading={otpModalLoading}
                    response={response}
                    setOpenOtpModal={setOpenOtpModal}
                    setOtpModalLoading={setOtpModalLoading}
                    submitOTP={submitOTP}
                    challengeType={challengeType}
                  />
                )}
              </>
            )}
          </Modal.Body>
        ) : (
          <ProfileContactModal
            setOpenLinkedIn={setOpenLinkedIn}
            setOpenLinkedInModal={setOpenLinkedInModal}
          />
        )}
      </Modal>
    </>
  )
}

const ProfileContactModal = ({
  setOpenLinkedIn,
  setOpenLinkedInModal,
}: {
  setOpenLinkedIn: (val: boolean) => void
  setOpenLinkedInModal: (val: boolean) => void
}) => {
  const {formatMessage} = useIntl()

  const [isContactButtonLoading, setIsContactButtonLoading] = useState(false)

  const phoneInitialValues = {
    contact: '',
  }

  const phoneSchema = Yup.object().shape({
    contact: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 digits'}))
      .max(12, formatMessage({id: 'Maximum 12 digits'})),
  })

  const updateContactDetails = async (value: any) => {
    setIsContactButtonLoading(true)
    try {
      let {
        data: {success, data, errors},
      } = await profileData()
      if (success) {
        data.contact = value.contact
        let {
          data: {success, errors},
        } = await updateProfileInfo({...data})
        if (success) {
          toast.success(formatMessage({id: 'Profile updated successfully'}))
          setOpenLinkedIn(false)
          setOpenLinkedInModal(false)
        }
      }
    } catch (error) {
      toast.error(formatMessage({id: 'Something went wrong'}))
    } finally {
      setIsContactButtonLoading(false)
    }
  }

  return (
    <Modal.Body className='mx-10'>
      <div className='d-flex flex-center '>
        <div className='text-center'>
          <div className='w-100'>
            <img
              src={toAbsoluteUrl('/media/logos/linkedInLogo.svg')}
              height='40px'
              width='100px'
              alt='img_icon'
              className='rounded-1 '
            />
            <br />
            <div className='mt-4'>
              <span className='font-size-14 fw-400'>
                {formatMessage({
                  id: 'Thank you for connecting your LinkedIn account. Please add your contact number to increase your profile completeness.',
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-100'>
        <div className='col-12 mt-10'>
          <Formik
            initialValues={phoneInitialValues}
            validationSchema={phoneSchema}
            onSubmit={updateContactDetails}
            validateOnMount
            enableReinitialize
          >
            {(formik) => {
              return (
                <Form>
                  <TextInput
                    fieldType={'text'}
                    fieldName={'contact'}
                    formik={formik}
                    placeholder={formatMessage({id: 'Contact Number'})}
                    margin='me-4'
                    label={formatMessage({id: 'Contact Number'})}
                    toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.USER.CONTACT_NUMBER'})}
                    width={12}
                    isStarRequired={false}
                  />
                  <div className='d-flex'>
                    <div className='me-3 col-12 mb-4'>
                      <button
                        className='btn d-flex align-items-center justify-content-center custom-button-property w-100 linkedin-login'
                        disabled={!formik.isValid || formik.isSubmitting}
                      >
                        {isContactButtonLoading ? (
                          <span className={`indicator-label font-size-13 p-0 opacity-50`}>
                            {formatMessage({id: 'Please wait...'})}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          <span>
                            {formatMessage({id: 'Save'})}
                            {formik.isValid}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </Modal.Body>
  )
}

const ModalHeader = ({
  setLoading,
  setOpenLinkedInModal,
}: {
  setLoading: (val: boolean) => void
  setOpenLinkedInModal: (val: boolean) => void
}) => {
  const {formatMessage} = useIntl()
  return (
    <>
      <div className='w-100'>
        <img
          src={toAbsoluteUrl('/media/logos/linkedInLogo.svg')}
          height='40px'
          width='100px'
          alt='img_icon'
          className='rounded-1 '
        />
        <span
          style={{position: 'absolute', right: '0%', fontSize: 'x-large'}}
          className='cursor-pointer'
          onClick={() => {
            setOpenLinkedInModal(false)
            setLoading(false)
          }}
        >
          x
        </span>
      </div>
      <br />
      <span className='font-size-14 fw-400'>
        {formatMessage({id: 'Sign in with your LinkedIn credentials'})}
      </span>
    </>
  )
}

const UsernamePasswordModal = ({
  email,
  password,
  response,
  linkedinConnect,
  setLoading,
  setOpenLinkedInModal,
}: {
  email: string
  password: string
  response: string
  linkedinConnect: (values: any) => void
  setLoading: (val: boolean) => void
  setOpenLinkedInModal: (val: boolean) => void
}) => {
  const {formatMessage} = useIntl()

  const initialValues = {
    linkedinEmail: '' || email,
    linkedinPassword: '' || password,
  }

  const linkedinSchema = Yup.object().shape({
    linkedinEmail: Yup.string()
      .min(5, formatMessage({id: 'Minimum 5 characters'}))
      .max(500, formatMessage({id: 'Maximum 500 characters'}))
      .required(formatMessage({id: 'LinkedIn User is required'})),

    linkedinPassword: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 characters'}))
      .max(256, formatMessage({id: 'Maximum 256 characters'}))
      .required(formatMessage({id: 'LinkedIn Password is required'})),
  })

  return (
    <>
      <ModalHeader setLoading={setLoading} setOpenLinkedInModal={setOpenLinkedInModal} />
      <div className='col-12 mt-10'>
        <div className='font-size-14 mb-24px text-color '>
          {formatMessage({
            id: 'We require access to your LinkedIn to run campaigns from your account. Rest assured, our access is secure, encrypted, and you retain the option to disconnect at any time.',
          })}
        </div>
        <Formik
          initialValues={initialValues || {linkedinEmail: email, linkedinPassword: password}}
          validationSchema={linkedinSchema}
          onSubmit={linkedinConnect}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            return (
              <Form>
                <TextInput
                  fieldType={'text'}
                  fieldName={'linkedinEmail'}
                  formik={formik}
                  placeholder={formatMessage({id: 'LinkedIn Email or Phone'})}
                  label={formatMessage({id: 'LinkedIn Email or Phone'})}
                  toolTipText={formatMessage({id: 'GLOBAL.TOOLTIP.LINKEDIN.EMAIL'})}
                  isStarRequired={true}
                />
                <TextInput
                  fieldType={'text'}
                  fieldName={'linkedinPassword'}
                  formik={formik}
                  isPassword={true}
                  placeholder={formatMessage({id: 'LinkedIn Password'})}
                  label={formatMessage({id: 'LinkedIn Password'})}
                  toolTipText={formatMessage({
                    id: 'GLOBAL.TOOLTIP.LINKEDIN.PASSWORD',
                  })}
                  isStarRequired={true}
                />
                <div className='mb-2'>
                  <span style={{color: 'red'}}>{response}</span>
                </div>
                <div className='d-flex'>
                  <div className='me-3 col-12 mb-4'>
                    <button
                      className='btn d-flex align-items-center justify-content-center custom-button-property w-100 linkedin-login'
                      disabled={!formik.isValid}
                    >
                      <span>{formatMessage({id: 'Sign In'})}</span>
                    </button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

const AnimatingModal = ({
  showAbandonBtn,
  abandonBtnLoading,
  handleAbandon,
}: {
  showAbandonBtn: boolean
  abandonBtnLoading: boolean
  handleAbandon: () => void
}) => {
  const {formatMessage} = useIntl()
  return (
    <>
      <span className='fw-bold'>{formatMessage({id: 'It can take few minutes'})}</span>
      <br />
      <div className='mb-5 font-size-14'>
        <span className='text-clre88'>
          *
          {formatMessage({
            id: ` Please don't close the browser or refresh the page`,
          })}
        </span>
      </div>

      <div>
        <div className='d-flex justify-content-center'>
          <img src={toAbsoluteUrl('/media/avatars/internal.gif')} height='250px' width='225px' />
        </div>
      </div>
      {showAbandonBtn && (
        <>
          <div className='mb-3 text-center'>Trouble signing in?</div>
          <div className='d-flex justify-content-center'>
            <GlobalButton
              buttonText={formatMessage({id: 'Try Another Account'})}
              buttonType={ButtonTypes.SECONDARY}
              isLoading={abandonBtnLoading}
              isDisable={abandonBtnLoading}
              onButtonClick={handleAbandon}
            />
          </div>
        </>
      )}
    </>
  )
}

const LinkedInAuthenticatorModal = ({submitOTP}: {submitOTP: (values: any) => void}) => {
  const {formatMessage} = useIntl()

  return (
    <>
      <div className='d-flex flex-column flex-start mt-5 mx-10'>
        <img
          src={toAbsoluteUrl('/media/logos/linkedIn_logo.svg')}
          height='32px'
          width='32px'
          alt='img_icon'
          className='mb-2'
        />
        <span className='fw-bold font-size-24'>
          {formatMessage({id: 'Check your LinkedIn App'})}
        </span>
      </div>
      <div className='d-flex flex-column flex-center mx-10 mt-5'>
        <span>
          {formatMessage({
            id: 'We sent a notification to your signed in devices. Open your LinkedIn app and tap Yes to confirm your sign-in attempt.',
          })}
        </span>
        <br />
        <div className='mb-4 text-decoration-underline'>
          <span className='fw-bold d-flex justify-content-center font-size-14'>
            {formatMessage({id: 'OR'})}
          </span>
        </div>
        <div className='d-flex justify-content-center'>
          <span
            className='fw-bold text-decoration-underline font-size-14 cursor-pointer'
            style={{color: '#00519F'}}
            onClick={() => {
              submitOTP(1)
            }}
          >
            {formatMessage({id: 'Verify with SMS'})}
          </span>
        </div>
      </div>
    </>
  )
}

const OTPModal = ({
  otpModalLoading,
  abandonBtnLoading,
  showAbandonBtn,
  response,
  challengeType,
  submitOTP,
  setOpenOtpModal,
  setOtpModalLoading,
  handleAbandon,
}: {
  otpModalLoading: boolean
  abandonBtnLoading: boolean
  showAbandonBtn: boolean
  response: string
  challengeType?: 'APP' | 'EMAIL' | 'MOBILE'
  submitOTP: (values: any) => void
  setOpenOtpModal: (val: boolean) => void
  setOtpModalLoading: (val: boolean) => void
  handleAbandon: () => void
}) => {
  const {formatMessage} = useIntl()

  const initialOtpValues = {
    otp: '',
  }

  const OtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(6, formatMessage({id: 'Minimum 6 characters'}))
      .max(6, formatMessage({id: 'Maximum 6 characters'}))
      .required(formatMessage({id: 'Otp is required'})),
  })

  return (
    <>
      <div className='d-flex flex-center'>
        <div className='font-size-20 text-center'>
          {!otpModalLoading && !response && challengeType ? (
            <>
              <span className='fw-bold'>{formatMessage({id: 'Enter your OTP'})}</span>
              <br />
              <span className='font-size-14'>
                {formatMessage({
                  id: 'Please enter the OTP which you received on your ',
                })}
                {challengeType}
              </span>

              <div className='col-12 mt-10'>
                <Formik
                  initialValues={initialOtpValues}
                  validationSchema={OtpSchema}
                  onSubmit={submitOTP}
                  validateOnMount
                >
                  {(formik) => {
                    return (
                      <Form>
                        <TextInput
                          fieldType={'text'}
                          fieldName={'otp'}
                          formik={formik}
                          placeholder={formatMessage({id: 'Enter Your OTP'})}
                          label={formatMessage({id: 'Enter Your OTP '})}
                          toolTipText={formatMessage({
                            id: 'GLOBAL.TOOLTIP.LINKEDIN.EMAIL',
                          })}
                          isStarRequired={true}
                        />

                        <div className='d-flex justify-content-end'>
                          <div className='me-3'>
                            <GlobalButton
                              buttonText={formatMessage({id: 'Cancel'})}
                              buttonType={ButtonTypes.SECONDARY}
                              onButtonClick={() => {
                                setOpenOtpModal(false)
                                setOtpModalLoading(false)
                              }}
                              isDisable={otpModalLoading}
                            />
                          </div>
                          <GlobalButton
                            buttonText={formatMessage({id: 'Enter'})}
                            buttonType={ButtonTypes.PRIMARY}
                            onButtonClick={formik.handleSubmit}
                            isDisable={formik.isSubmitting || !formik.isValid || otpModalLoading}
                            isLoading={otpModalLoading}
                          />
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </>
          ) : (
            <>
              {!!response ? (
                <>
                  <div className='my-5'>
                    <span className='fw-bold font-size-20'>{formatMessage({id: 'Warning'})}</span>
                  </div>
                  <div>
                    <div className='d-flex justify-content-center mt-5'>
                      <span className='fw-bold font-size-16'>{response}</span>
                    </div>
                  </div>
                </>
              ) : (
                <AnimatingModal
                  abandonBtnLoading={abandonBtnLoading}
                  handleAbandon={handleAbandon}
                  showAbandonBtn={showAbandonBtn}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
