import {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'

export const BillingAccessModal = ({
  showBillingAccessModal,
  setShowBillingAccessModal,
  billingPortalUrl,
}: {
  showBillingAccessModal: boolean
  setShowBillingAccessModal: Dispatch<SetStateAction<boolean>>
  billingPortalUrl: string | undefined
}) => {
  return (
    <>
      <Modal
        show={showBillingAccessModal}
        onHide={() => setShowBillingAccessModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <iframe
            title='Manage Subscription'
            src={billingPortalUrl}
            width='100%'
            height={600}
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  )
}
