import {getLocation} from '../modules/auth/core/_requests'
import {CountryModel} from '../modules/profile/core/_models'
import {getCountry} from '../modules/profile/core/_requests'

export const useFetchCountryAndTimeZone = () => {
  const fetchCountryAndTimeZone = async () => {
    try {
      const [
        {
          data: {data: locationDetails},
        },
        {
          data: {data: countries},
        },
      ] = await Promise.all([getLocation(), getCountry()])

      const country = countries.find(
        (country: CountryModel) => country.country_code === locationDetails.countryCode
      )
      if (country) {
        return {countryId: country.countryId, timeZone: locationDetails.offset}
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {fetchCountryAndTimeZone}
}
