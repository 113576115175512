import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useState} from 'react'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {DateTime} from 'luxon'
import EmojiPicker from 'emoji-picker-react'
import {AddContactInformationModal} from '../view/AddContactInformationModal'
import {AddNotesModal} from '../view/AddNotesModal'
import {useNavigate} from 'react-router'

export const Chat = () => {
  const {formatMessage} = useIntl()
  const {getDateValue} = useDateFormat()
  const [message, setMessage] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [isClicked, setIsClicked] = useState<boolean>(false)
  const [openAddNotesModal, setOpenAddNotesModal] = useState<boolean>(false)
  const [openAddContactInfoModal, setOpenAddContactInfoModal] = useState<boolean>(false)
  const navigate = useNavigate()

  const chatMessages = [
    {
      id: 1,
      content: 'Hi',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:30:00.000Z',
    },
    {
      id: 2,
      content: 'Hello',
      sender: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:31:00.000Z',
    },
    {
      id: 3,
      content: 'How are you?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:32:00.000Z',
    },
    {
      id: 4,
      content: 'What happened?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 5,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 6,
      content: 'What are you doing?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 7,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 8,
      content: 'What are you doing?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 9,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-06T10:33:00.000Z',
    },
    {
      id: 10,
      content: 'What are you doing?',
      sender: {
        id: 5, // Sender's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 201, // Receiver's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-07T10:33:00.000Z',
    },
    {
      id: 11,
      content: 'nothing',
      sender: {
        id: 201, // Sender's ID
        name: 'ReceiverName',
        avatar: '/media/logos/profile.svg',
      },
      receiver: {
        id: 5, // Receiver's ID
        name: 'SenderName',
        avatar: '/media/logos/profile.svg',
      },
      timestamp: '2023-12-07T10:33:00.000Z',
    },
  ]

  const handleInputChange = (event: any) => {
    setMessage(event.target.value)
  }

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      // sendMessage(message);
      setMessage('')
    }
  }

  const statusOptions = [
    {id: 1, status: 'Interested'},
    {id: 2, status: 'Uninterested'},
    {id: 3, status: 'Available'},
    {id: 4, status: 'Unavailable'},
  ]

  return (
    <>
      <div className='d-md-flex flex-md-row d-flex flex-column w-100'>
        <div className='settings-profile-container'>
          <div className='card row ms-0'>
            <div className='card-body m-0 p-0'>
              <div className='d-flex justify-content-between px-4 py-2 border-bottom-inbox'>
                <div className='mt-2'>
                  {' '}
                  <h1 className='activity-heading text-dark font-size-20 font-weight-600'>
                    {formatMessage({id: 'Inbox'})}
                  </h1>{' '}
                </div>
                <div className='d-flex gap-8 mt-2'>
                  <div className='d-flex gap-1'>
                    <div>
                      <img alt='Logo' src={toAbsoluteUrl('/media/icons/inbox/all_messages.svg')} />
                    </div>
                    <div className='mt-1'>
                      <p>
                        {formatMessage({
                          id: 'All messages',
                        })}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className='d-flex gap-1'>
                      <div>
                        <img alt='Logo' src={toAbsoluteUrl('/media/icons/inbox/unread.svg')} />
                      </div>
                      <div className='mt-1'>
                        <p>
                          {formatMessage({
                            id: 'Unread',
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isClicked && (
                <>
                  {' '}
                  <div className='d-flex py-4 justify-content-between border-bottom-inbox'>
                    <div className='d-flex ms-4'>
                      <img
                        src={toAbsoluteUrl('/media/icons/inbox/backArrow.svg')}
                        alt='like'
                        className='me-4'
                        onClick={() => navigate('/inbox')}
                      />

                      <img src={toAbsoluteUrl('/media/logos/profile.svg')} alt='like' />
                      <h1 className='font-size-16 font-weight-600 text-black mt-4 ps-4'>
                        {formatMessage({id: 'Mike Jones'})}
                      </h1>
                    </div>
                    <div className='mt-7 me-4' onClick={() => setIsClicked(true)}>
                      <img src={toAbsoluteUrl('/media/icons/inbox/rightArrow.svg')} alt='like' />
                    </div>
                  </div>
                  <div className='chat-container'>
                    <div className=''>
                      {chatMessages.map((message, index: number) => {
                        let currentDate = null

                        if (index === 0) {
                          currentDate =
                            getDateValue(message.timestamp) ||
                            DateTime.fromISO(message.timestamp).toLocaleString(DateTime.DATE_MED)
                        }

                        const messageDate =
                          getDateValue(message.timestamp) ||
                          DateTime.fromISO(message.timestamp).toLocaleString(DateTime.DATE_MED)

                        const shouldDisplayDate = currentDate !== messageDate
                        currentDate = messageDate

                        return (
                          <>
                            {' '}
                            {shouldDisplayDate ||
                              (index === 0 && (
                                <div className='d-flex justify-content-center font-size-12 font-weight-400 text-grey-color mt-4'>
                                  {getDateValue(message.timestamp) ||
                                    DateTime.fromISO(message.timestamp).toLocaleString(
                                      DateTime.DATE_MED
                                    )}
                                </div>
                              ))}
                            <div
                              key={message.id}
                              className={`message d-flex flex-column mt-4 ${
                                message?.sender?.id === 5
                                  ? 'sender align-items-end me-4'
                                  : 'receiver ms-4 align-items-start'
                              }`}
                            >
                              <img
                                src={toAbsoluteUrl('/media/logos/profile.svg')}
                                alt='profile'
                                width={24}
                                height={24}
                                className='mb-3'
                              />
                              <div
                                className={`${
                                  message?.sender?.id === 5
                                    ? 'sender-message-content'
                                    : 'receiver-message-content'
                                } bg-color text-white p-5 message-content`}
                              >
                                {message?.content}
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>

                    <div className={`chat-input mt-4 d-flex justify-content-center mb-4`}>
                      <input
                        type='text'
                        value={message}
                        onChange={handleInputChange}
                        placeholder='Type a message'
                        className='h-100 w-75  form-control rounded-0 rounded-start  input-background border-0'
                      />
                      <button
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        className='h-100 border-0  input-background'
                      >
                        <img
                          src={toAbsoluteUrl('/media/icons/inbox/emoji.svg')}
                          alt='emoji'
                          width={16}
                          height={16}
                        />
                      </button>

                      <button
                        onClick={handleSendMessage}
                        className='h-100 border-0  input-background rounded-end pb-1 pe-5'
                      >
                        <img
                          src={toAbsoluteUrl('/media/icons/inbox/send.svg')}
                          alt='send'
                          width={16}
                          height={16}
                        />
                      </button>
                    </div>
                    {showEmojiPicker && (
                      <div className='d-flex justify-content-center mt-2'>
                        <EmojiPicker width={570} />
                      </div>
                    )}
                  </div>
                </>
              )}

              {isClicked && (
                <>
                  <div className='mt-4 d-flex ms-4'>
                    <div onClick={() => setIsClicked(false)}>
                      <img
                        className='me-2'
                        src={toAbsoluteUrl('/media/icons/inbox/leftArrow.svg')}
                        height='24px'
                        width='24px'
                      />{' '}
                    </div>
                    <div className='d-flex justify-content-center mt-4 col-10'>
                      <img
                        className='me-2'
                        src={toAbsoluteUrl('/media/logos/profile.svg')}
                        height='94px'
                        width='94px'
                      />{' '}
                    </div>
                  </div>

                  <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-center'>
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/icons/investor/linkedin.svg')}
                        height='16px'
                        width='16px'
                      />{' '}
                      <h1 className='font-size-18 font-weight-600 text-black ps-2'>
                        {formatMessage({id: 'Mike Jones'})}
                      </h1>
                    </div>
                    <div>
                      <p className='font-size-14 font-weight-400 text-black ps-2 text-center'>
                        {formatMessage({id: 'Content creator, Marketing Strategy'})}
                      </p>
                    </div>
                  </div>
                  <div className='px-4'>
                    <div>
                      <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                        {formatMessage({id: 'Location:'})}
                      </p>
                      <p className='font-size-14 font-weight-400 text-color'>
                        {formatMessage({id: 'India'})}
                      </p>
                    </div>
                    <div>
                      <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                        {formatMessage({id: 'Status:'})}
                      </p>
                      <select
                        name=''
                        id=''
                        className=' form-select mb-3 height-36px d-flex align-items-center font-weight-400 font-size-14 cursor-pointer py-2 text-black'
                      >
                        {statusOptions.map(({id, status}: any) => (
                          <option value={id} key={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='mt-4'>
                      <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                        {formatMessage({id: 'Active campaign:'})}
                      </p>
                      <p className='font-size-14 font-weight-400 text-black'>
                        {formatMessage({id: 'Lead Gen: India L2, 1-10'})}
                      </p>
                    </div>
                    <div>
                      <div className='input-background p-5 rounded'>
                        <div className='d-flex justify-content-between'>
                          <p className='font-size-14 font-weight-600 text-black '>
                            {formatMessage({id: 'Contact information'})}
                          </p>
                          <img
                            className=''
                            src={toAbsoluteUrl('/media/icons/inbox/plus.svg')}
                            height='16px'
                            width='16px'
                            onClick={() => setOpenAddContactInfoModal(true)}
                          />{' '}
                        </div>
                        <div>
                          <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                            {formatMessage({id: 'Email:'})}
                          </p>
                          <p className='font-size-14 font-weight-400 text-black'>
                            {formatMessage({id: 'mikejones@gmail.com'})}
                          </p>
                        </div>
                        <div>
                          <p className='font-size-14 font-weight-400 m-0 text-grey-color'>
                            {formatMessage({id: 'Phone number:'})}
                          </p>
                          <p className='font-size-14 font-weight-400 text-black'>
                            {formatMessage({id: '8012983476'})}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='my-4'>
                      <div className='input-background p-5 rounded'>
                        <div className='d-flex justify-content-between'>
                          <p className='font-size-14 font-weight-600 text-black'>
                            {formatMessage({id: 'Notes'})}
                          </p>
                          <img
                            className=''
                            src={toAbsoluteUrl('/media/icons/inbox/plus.svg')}
                            height='16px'
                            width='16px'
                            onClick={() => setOpenAddNotesModal(true)}
                          />{' '}
                        </div>
                        <p className='font-size-14 font-weight-400 text-center text-grey-color'>
                          {formatMessage({id: 'The user does not have any notes add one'})}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddContactInformationModal
        openAddContactInfoModal={openAddContactInfoModal}
        setOpenAddContactInfoModal={setOpenAddContactInfoModal}
      ></AddContactInformationModal>
      <AddNotesModal
        openAddNotesModal={openAddNotesModal}
        setOpenAddNotesModal={setOpenAddNotesModal}
      ></AddNotesModal>
    </>
  )
}
