import React, {Dispatch, SetStateAction} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const GeneralInfoModal = ({
  showGeneralInfoModal,
  setShowGeneralInfoModal,
  headingMessage,
  description,
  generalLoading,
  buttonText,
}: {
  showGeneralInfoModal: boolean
  setShowGeneralInfoModal: Dispatch<SetStateAction<boolean>>
  headingMessage: string
  description: string
  generalLoading: boolean
  buttonText: string
}) => {
  const {formatMessage} = useIntl()
  const {setShowBillingModal} = useAuth()

  return (
    <>
      <Modal
        show={showGeneralInfoModal}
        onHide={() => setShowGeneralInfoModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <h2>{formatMessage({id: headingMessage})}</h2>
        </Modal.Header>
        <Modal.Body>
          <p>
            {formatMessage({
              id: description,
            })}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <GlobalButton
            buttonText={formatMessage({id: 'Cancel'})}
            buttonType={ButtonTypes.SECONDARY}
            onButtonClick={() => {
              setShowGeneralInfoModal(false)
            }}
          />

          <GlobalButton
            buttonText={buttonText}
            buttonType={ButtonTypes.PRIMARY}
            onButtonClick={() => {
              setShowGeneralInfoModal(false)
              setShowBillingModal(true)
            }}
            isLoading={generalLoading}
            isDisable={generalLoading}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
