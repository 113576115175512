import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useState} from 'react'
import {disconnectLinkedInAccount} from '../../core/_requests'
import {toast} from 'react-toastify'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'

export const DisconnectModal = ({
  openDisconnectModal,
  setOpenDisconnectModal,
  setOpenLinkedIn,
  getProfileInfo,
}: {
  openDisconnectModal: boolean
  setOpenDisconnectModal: React.Dispatch<React.SetStateAction<boolean>>
  setOpenLinkedIn: React.Dispatch<React.SetStateAction<boolean>>
  getProfileInfo: () => void
}) => {
  const {formatMessage} = useIntl()
  const [loading, setLoading] = useState<boolean>(false)

  const handleDisconnect = async () => {
    try {
      setLoading(true)
      const {
        data: {success, errors, data},
      } = await disconnectLinkedInAccount()
      if (success) {
        setOpenLinkedIn(true)
        getProfileInfo()
        toast.success(formatMessage({id: 'Your LinkedIn account is disconnected.'}))
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setOpenDisconnectModal(false)
      setLoading(false)
    }
  }

  return (
    <>
      {' '}
      <Modal
        show={openDisconnectModal}
        onHide={() => setOpenDisconnectModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton className='border-bottom-0'>
          <h2 className='text-color'>{formatMessage({id: 'Disconnect'})}</h2>
        </Modal.Header>
        <Modal.Body className='py-1 font-size-14 text-color'>
          <p>
            {formatMessage({
              id: 'CAUTION! Disconnecting your LinkedIn account is a destructive action. It will halt all your running campaigns, and you will need to re-connect your LinkedIn account to resume. Are you sure you wish to proceed?',
            })}
          </p>
        </Modal.Body>
        <Modal.Footer className='pt-0 border-top-0'>
          <GlobalButton
            buttonText={formatMessage({id: 'Cancel'})}
            buttonType={ButtonTypes.SECONDARY}
            onButtonClick={() => {
              setOpenDisconnectModal(false)
            }}
          />

          <GlobalButton
            buttonText={formatMessage({id: 'Disconnect'})}
            buttonType={ButtonTypes.PRIMARY}
            onButtonClick={handleDisconnect}
            isLoading={loading}
            isDisable={loading}
          />
        </Modal.Footer>
      </Modal>
    </>
  )
}
