import React, {useState} from 'react'
import {Modal, Dropdown} from 'react-bootstrap'
import {Spinner} from '../../widgets/components/General/Spinner'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  updateTransferOwnership,
  getUser,
  deletePendingInvite,
  deleteTeamMember,
} from '../core/_requests'
import {useAuth} from '../../auth'
import {toast} from 'react-toastify'
import UserModal from './UserModal'
import {DateTime} from 'luxon'
import {RolesName} from '../core/_constants'
import {useDateFormat} from '../../../hooks/useDateFormat'
import {Roles} from '../core/_constants'
import {resendInvite} from '../../auth/core/_requests'
import {useTimeFormat} from '../../../hooks/useTimeFormat'
import {SelectPlanModal} from './SelectPlanModal'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export const Team = ({
  userList,
  pendingUserList,
  getUserList,
  getPendingUsers,
  billingData,
  runCardDetailsApi,
  runUserDetailsApi,
  setRunUserDetailsApi,
}: {
  userList: any
  pendingUserList: any
  getUserList: any
  getPendingUsers: any
  billingData: any
  runCardDetailsApi?: boolean
  runUserDetailsApi?: boolean
  setRunUserDetailsApi?: any
}) => {
  const {formatMessage} = useIntl()
  const [showKebabMenu, setShowKebabMenu] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isTransferOwnershipLoading, setIsTransferOwnershipLoading] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setDeleteShowModal] = useState(false)
  const [addModal, setAddModal] = useState(false)
  const [userId, setUserId] = useState<any>()
  const [editUserModal, setEditUserModal] = useState(false)
  const [showUserId, setShowUserId] = useState<any>()
  const [userDetails, setUserDetails] = useState<any>()
  const [flag, setFlag] = useState(false)
  const [transferOwnershipRadio, setTransferOwnershipRadio] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deletePendingLoading, setDeletePendingLoading] = useState(false)
  const [getUserApiSpinner, setUserApiSpinner] = useState(false)
  const [showDeletePendingInvites, setShowPendingInvites] = useState(false)
  const [selectedInviteId, setSelectedInviteId] = useState<any>()
  const [selectPlanModal, setSelectPlanModal] = useState<boolean>(false)
  const {getDateValue} = useDateFormat()
  const {getTimeValue} = useTimeFormat()
  const {setUserToken, saveAuth, currentUser, setBillingData, setShowBillingModal, setPriceId} =
    useAuth()

  const UpdateTransferOwnership = async () => {
    try {
      setIsTransferOwnershipLoading(true)
      const {
        data: {data, success, errors},
      } = await updateTransferOwnership(userId)
      if (success) {
        await saveAuth(data)
        setUserToken(data.token)
        getUserList()
        toast.success(formatMessage({id: 'Transfer Ownership updated'}))
        setTimeout(() => {
          const tabsContainer = document.getElementsByClassName('setting-tabs-list')[0]
          tabsContainer.querySelector('li')?.click()
        }, 50)
        setRunUserDetailsApi(true)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsTransferOwnershipLoading(false)
      setShowModal(!showModal)
    }
  }

  const DeleteUser = async () => {
    try {
      setDeleteLoading(true)
      const {
        data: {success, errors},
      } = await deleteTeamMember(showUserId)
      if (success) {
        toast.success(formatMessage({id: 'Team Member Deleted'}))
        getUserList()
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setDeleteLoading(false)
      setDeleteShowModal(!showDeleteModal)
    }
  }

  const deleteInvite = async (inviteId: number) => {
    try {
      setDeletePendingLoading(true)
      const {
        data: {success, errors},
      } = await deletePendingInvite(inviteId)
      if (success) {
        toast.success(formatMessage({id: 'Invite Deleted'}))
        getPendingUsers()
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setDeletePendingLoading(false)
      setShowPendingInvites(false)
    }
  }

  const getUserData = async () => {
    try {
      setUserApiSpinner(true)
      const {
        data: {success, data, errors},
      } = await getUser(showUserId)
      if (success) {
        setUserDetails(data)
        setUserApiSpinner(false)
      } else {
        setUserApiSpinner(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setUserApiSpinner(false)
      console.log(err)
    }
  }

  const handleResendInvite = async (values: any) => {
    setLoading(true)
    try {
      const {
        data: {data, success, errors},
      } = await resendInvite(values.email)
      if (success) {
        toast.success(formatMessage({id: data}))
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
      setShowKebabMenu(false)
    } catch (err) {
      console.log(err)
      setShowKebabMenu(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='row d-flex'>
          <div className='col-md-6 col-12'>
            <h4 className='card-label fs-5'>{formatMessage({id: 'My Team'})}</h4>
            <p className='pt-3'>{formatMessage({id: 'Manage your team members'})}</p>
          </div>
          <div className='col-12 d-md-flex flex-md-row justify-content-end col-md-6'>
            <div className='row mt-3'>
              <div className='col-12'>
                <button
                  className='btn btn_bg_color text-white font-size-13'
                  onClick={() => {
                    if (
                      currentUser?.metadata?.members &&
                      parseInt(currentUser?.metadata?.members, 10) !== 1 &&
                      userList.length < parseInt(currentUser?.metadata?.members, 10)
                    ) {
                      setAddModal(true)
                      setFlag(false)
                    } else if (
                      currentUser?.metadata?.members &&
                      parseInt(currentUser?.metadata?.members, 10) !== 1 &&
                      userList.length >= parseInt(currentUser?.metadata?.members, 10)
                    ) {
                      toast.error(
                        formatMessage({
                          id: 'You have reached the max limit of users in your team. If you wish to add more, please contact us.',
                        })
                      )
                    } else if (
                      currentUser?.metadata?.members &&
                      parseInt(currentUser?.metadata?.members, 10) == 1
                    ) {
                      setSelectPlanModal(true)
                    }
                  }}
                >
                  <div className='d-flex'>
                    <img
                      src={toAbsoluteUrl('/media/icons/investor/whiteLock.svg')}
                      alt='lock icon'
                      width={15}
                      className={`me-2 ${
                        currentUser?.metadata?.team === 'true' &&
                        currentUser?.metadata?.members &&
                        parseInt(currentUser?.metadata?.members, 10) !== 1
                          ? 'd-none'
                          : 'd-block'
                      }`}
                      height={15}
                    />
                    {formatMessage({id: 'Add New User'})}
                  </div>
                </button>
                <UserModal
                  title={formatMessage({id: 'Add New User'})}
                  buttonText={formatMessage({id: 'Add User'})}
                  addModal={addModal}
                  setAddModal={setAddModal}
                  flag={flag}
                  getUserList={getUserList}
                  getPendingUsers={getPendingUsers}
                  billingData={billingData}
                />
                <SelectPlanModal
                  selectPlanModal={selectPlanModal}
                  setSelectPlanModal={setSelectPlanModal}
                  setShowBillingModal={setShowBillingModal}
                  setBillingData={setBillingData}
                  setPriceId={setPriceId}
                  runCardDetailsApi={!runCardDetailsApi}
                  runUserDetailsApi={!runUserDetailsApi}
                  modalText={formatMessage({
                    id: `Your current plan (Professional) does not support more than one user in a team. Please upgrade your subscription to access this.`,
                  })}
                ></SelectPlanModal>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='table table-responsive mb-0 custom-table mt-4'>
              <table className='w-100'>
                <thead>
                  <tr className='custom-thead font-size-12 font-weight-400'>
                    <td className='pb-4'>{formatMessage({id: 'Name'})}</td>
                    <td className='pb-4'>{formatMessage({id: 'Role'})}</td>
                    <td className='pb-4'>{formatMessage({id: 'Email Address'})}</td>
                    <td className='pb-4'>
                      <div className='d-flex gap-2'>
                        <p>{formatMessage({id: 'Last Modification Date'})}</p>
                      </div>
                    </td>
                    <td className='text-center pb-4'>{formatMessage({id: 'Action'})}</td>
                  </tr>
                </thead>
                <tbody className='font-size-13 font-weight-400'>
                  {userList &&
                    userList.map((user: any) =>
                      user.role === Roles.OWNER ? (
                        <>
                          <tr key={user.userId}>
                            <td className='min-w-175px'>
                              {user.firstName} {user.lastName}
                            </td>
                            <td className=' min-w-175px'>{RolesName[user.role]}</td>
                            <td className=' min-w-250px'>{user.email}</td>
                            <td className=' min-w-175px'>
                              {(getDateValue(user.updatedAt?.toString())
                                ? getDateValue(user.updatedAt?.toString())
                                : DateTime.fromISO(user.updatedAt).toFormat('dd MMM, yyyy')) +
                                ' ' +
                                (getTimeValue(user.updatedAt?.toString())
                                  ? getTimeValue(user.updatedAt?.toString())
                                  : DateTime.fromISO(user.updatedAt).toFormat('H:m:s'))}
                            </td>
                            {user.userId === currentUser?.userId && (
                              <td
                                className='text-center text-primary cursor-pointer  min-w-175px'
                                onClick={() => setModalShow(!modalShow)}
                              >
                                {formatMessage({id: 'Transfer Ownership'})}
                              </td>
                            )}
                          </tr>
                          <div></div>
                        </>
                      ) : (
                        ''
                      )
                    )}

                  {userList &&
                    userList.map((user: any) =>
                      user.role === Roles.OWNER ? (
                        ''
                      ) : (
                        <>
                          <tr key={user.userId} onMouseLeave={() => setShowKebabMenu(false)}>
                            <td className='min-w-175px'>
                              <div>
                                {user.firstName} {user.lastName}
                              </div>
                            </td>
                            <td className='min-w-175px'>
                              {user.role ? RolesName[user.role] : '-'}
                            </td>
                            <td className='min-w-250px'>{user.email}</td>
                            <td className='min-w-175px'>
                              {(getDateValue(user.updatedAt?.toString())
                                ? getDateValue(user.updatedAt?.toString())
                                : DateTime.fromISO(user.updatedAt).toFormat('dd MMM, yyyy')) +
                                ' ' +
                                (getTimeValue(user.updatedAt?.toString())
                                  ? getTimeValue(user.updatedAt?.toString())
                                  : DateTime.fromISO(user.updatedAt).toFormat('H:m:s'))}
                            </td>
                            {user.userId !== currentUser?.userId && (
                              <td className='text-center p-0 min-w-175px'>
                                <div className='w-100 d-flex justify-content-center position-relative'>
                                  <div id='remove-investor'>
                                    <Dropdown.Toggle
                                      className='bi bi-three-dots-vertical  me-1 p-1 px-3 py-2 bg-light text-dark'
                                      onClick={() => {
                                        setShowKebabMenu(!showKebabMenu)
                                        setShowUserId(user.userId)
                                      }}
                                    ></Dropdown.Toggle>
                                  </div>
                                  {showKebabMenu && user.userId === showUserId && (
                                    <div className='card w-100px h-120px shadow rounded kebab-menu-dropdown cursor-pointer ms-auto'>
                                      <div className='border-bottom border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'>
                                        <div
                                          className='m-0 text-nowrap'
                                          onClick={() => {
                                            setEditUserModal(!editUserModal)
                                            setShowKebabMenu(true)
                                            getUserData()
                                            setFlag(true)
                                          }}
                                        >
                                          {formatMessage({id: 'Change role'})}
                                        </div>
                                      </div>
                                      <div className='border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'>
                                        <div
                                          className='m-0'
                                          onClick={() => {
                                            setShowKebabMenu(!showKebabMenu)
                                            setDeleteShowModal(!showDeleteModal)
                                          }}
                                        >
                                          {formatMessage({id: 'Remove'})}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </td>
                            )}
                          </tr>
                          <UserModal
                            title={formatMessage({id: 'Edit User'})}
                            buttonText={formatMessage({id: 'Edit'})}
                            addModal={editUserModal}
                            setAddModal={setEditUserModal}
                            flag={flag}
                            userDetails={userDetails}
                            getUserList={getUserList}
                            getUserApiSpinner={getUserApiSpinner}
                            billingData={billingData}
                          />
                        </>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {currentUser?.metadata?.team === 'true' &&
        currentUser?.metadata?.members &&
        parseInt(currentUser?.metadata?.members, 10) !== 1 && (
          <div className='card-body'>
            <div className='row d-flex'>
              <div className='col-md-6 col-12'>
                <h4 className='card-label fs-5'>{formatMessage({id: 'Pending Invites'})}</h4>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='table table-responsive mb-0 custom-table mt-4'>
                  <table className='w-100'>
                    <thead>
                      <tr className='custom-thead font-size-12 font-weight-400'>
                        <td className='pb-4'>{formatMessage({id: 'Name'})}</td>
                        <td className='pb-4'>{formatMessage({id: 'Role'})}</td>
                        <td className='pb-4'>{formatMessage({id: 'Email Address'})}</td>
                        <td className='pb-4'>
                          <div className='d-flex gap-2'>
                            <p>{formatMessage({id: 'Invited At'})}</p>
                          </div>
                        </td>
                        <td className='text-center pb-4'>{formatMessage({id: 'Action'})}</td>
                      </tr>
                    </thead>
                    <tbody className='font-size-13 font-weight-400'>
                      {pendingUserList.map((user: any, index: number) => (
                        <>
                          <tr
                            key={user.userId}
                            onMouseLeave={() => {
                              setShowKebabMenu(false)
                            }}
                          >
                            <td className='min-w-175px'>{user.name}</td>
                            <td className='min-w-175px'>{RolesName[user.role]}</td>
                            <td className='min-w-175px'>
                              {user.email} {user.userId}
                            </td>
                            <td className='min-w-175px'>
                              {(getDateValue(user.updatedAt?.toString())
                                ? getDateValue(user.updatedAt?.toString())
                                : DateTime.fromISO(user.updatedAt).toFormat('dd MMM, yyyy')) +
                                ' ' +
                                (getTimeValue(user.updatedAt?.toString())
                                  ? getTimeValue(user.updatedAt?.toString())
                                  : DateTime.fromISO(user.updatedAt).toFormat('H:m:s'))}
                            </td>
                            {/* <td
                          className='text-center text-primary cursor-pointer'
                          onClick={() => {
                            setShowPendingInvites(true)
                            setSelectedInviteId(user.inviteId)
                          }}
                        >
                          {formatMessage({id: 'Delete'})}
                        </td> */}
                            <td className='text-center p-0 min-w-175px'>
                              <div
                                className={`w-100 d-flex justify-content-center position-relative `}
                              >
                                <div id='remove-investor'>
                                  <div
                                    className={`${
                                      showKebabMenu && 'btn btn-primary'
                                    } bi bi-three-dots-vertical  me-1 p-1 px-3 py-2 bg-light text-dark `}
                                    onMouseEnter={() => {
                                      setShowKebabMenu(true)
                                      setShowUserId(user.inviteId)
                                    }}
                                  ></div>
                                </div>
                                {showKebabMenu && user.inviteId === showUserId && (
                                  <div
                                    className={`card h-120px shadow rounded ${
                                      index === 0 ? 'team-kebab-menu' : 'kebab-menu-dropdown'
                                    } cursor-pointer`}
                                  >
                                    <div className='border-bottom border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'>
                                      <div
                                        className={`m-0 ${loading && `disabled-team`}`}
                                        onClick={() => {
                                          if (!loading) handleResendInvite({email: user.email})
                                        }}
                                      >
                                        {loading && <Spinner isInlineLoader />}{' '}
                                        {formatMessage({id: 'Resend invitation'})}
                                        {showKebabMenu} {user.userId}
                                      </div>
                                    </div>
                                    <div className='border-bottom border-gray-300 border-bottom-2 px-4 py-3 dropdown-items'>
                                      <div
                                        className={`m-0 ${loading && `disabled-team`}`}
                                        role='button'
                                        onClick={() => {
                                          if (!loading) {
                                            setShowPendingInvites(true)
                                            setSelectedInviteId(user.inviteId)
                                          }
                                        }}
                                      >
                                        {loading && <Spinner isInlineLoader />}{' '}
                                        {formatMessage({id: 'Revoke invitation'})}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                          <div></div>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

      <div>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton onClick={() => setTransferOwnershipRadio(false)}>
            <h2>{formatMessage({id: 'Transfer Ownership'})}</h2>
          </Modal.Header>
          <Modal.Body>
            <h6 className='mb-4 pb-2'>
              {formatMessage({id: 'Select the user whom you want transfer ownership to'})}
            </h6>
            <table className='table transfer-ownership-table mb-0'>
              <thead>
                <tr className='border border-top-0 border-right-0 border-left-0 border-2'>
                  <td className='p-0 m-0 pt-4'>
                    <p className='ps-15 transfer-ownership-td'>{formatMessage({id: 'Name'})}</p>
                  </td>
                  <td className='p-0 m-0 pt-4'>
                    <p className='transfer-ownership-td'>{formatMessage({id: 'Email Address'})}</p>
                  </td>
                </tr>
              </thead>
              <tbody>
                {userList.map((user: any, index: number) =>
                  user.role === Roles.OWNER ? (
                    ''
                  ) : (
                    <tr className='border border-top-0 border-right-0 border-left-0 border-2'>
                      <td className='p-0 m-0'>
                        <div className='form-check form-check-inline pt-4 m-0'>
                          <input
                            className='form-check-input radio-button'
                            type='radio'
                            name='inlineRadioOptions'
                            id={`inlineRadio${index + 1}`}
                            value='option1'
                            onClick={() => {
                              setUserId(user.userId)
                              setTransferOwnershipRadio(true)
                            }}
                          />
                          <label
                            className='ms-2 cursor-pointer'
                            htmlFor={`inlineRadio${index + 1}`}
                          >
                            {user.firstName} {user.lastName}
                          </label>
                        </div>
                      </td>
                      <td className='p-0 m-0'>
                        <label
                          className='pt-4 m-0 cursor-pointer'
                          htmlFor={`inlineRadio${index + 1}`}
                        >
                          {user.email}
                        </label>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setModalShow(!modalShow)
                setTransferOwnershipRadio(false)
              }}
            >
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-primary'
              disabled={!transferOwnershipRadio}
              onClick={() => {
                setShowModal(!showModal)
                setModalShow(!modalShow)
              }}
            >
              Transfer Ownership
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({id: 'Transfer Ownership'})}</h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              Please note that the transfer of ownership is a permanent action and cannot be undone.
              As a result, you will no longer have the authority to access functionalities or desing
              a new owner . Are you certain you wish to proceed with the transfer?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              onClick={() => {
                setShowModal(!showModal)
                setTransferOwnershipRadio(false)
              }}
            >
              Cancel
            </button>
            <GlobalButton
              buttonText={formatMessage({id: 'Confirm & Transfer'})}
              buttonType={ButtonTypes.TERITARY}
              onButtonClick={() => {
                UpdateTransferOwnership()
              }}
              isLoading={isTransferOwnershipLoading}
              isDisable={isTransferOwnershipLoading}
            />
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setDeleteShowModal(false)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({id: 'Delete User'})}</h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              {formatMessage({
                id: 'Please note that the delete user is a permanent action and cannot be undone. As a result,you will no longer have the user. Are you certain you wish to proceed with this',
              })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <GlobalButton
              buttonText={formatMessage({id: 'Cancel'})}
              buttonType={ButtonTypes.SECONDARY}
              onButtonClick={() => {
                setDeleteShowModal(!showDeleteModal)
              }}
            />
            <GlobalButton
              buttonText={formatMessage({id: 'Confirm Delete'})}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => {
                DeleteUser()
              }}
              isLoading={deleteLoading}
              isDisable={deleteLoading}
            />
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={showDeletePendingInvites}
          onHide={() => setShowPendingInvites(false)}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <h2>{formatMessage({id: 'Delete Pending Invites'})}</h2>
          </Modal.Header>
          <Modal.Body>
            <p>
              {formatMessage({
                id: 'Please note that the delete invite is a permanent action and cannot be undone. As a result, the user will no longer be able to accept the invite. Are you certain you wish to proceed with this?',
              })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <GlobalButton
              buttonText={formatMessage({id: 'Cancel'})}
              buttonType={ButtonTypes.SECONDARY}
              onButtonClick={() => {
                setShowPendingInvites(false)
              }}
            />
            <GlobalButton
              buttonText={formatMessage({id: 'Confirm Delete'})}
              buttonType={ButtonTypes.PRIMARY}
              onButtonClick={() => deleteInvite(selectedInviteId)}
              isLoading={deletePendingLoading}
              isDisable={deletePendingLoading}
            />
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}
