import React from 'react'
import {Pagination} from 'react-bootstrap'
export const CustomPagination = ({
  currentPage,
  totalPages,
  setCurrentPage,
}: {
  currentPage: number
  totalPages: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}) => {
  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }
  const renderPaginationItems = () => {
    const items = []

    const visiblePageCount = 5 // Number of visible page numbers

    // Calculate the range of visible pages based on the current page
    let startPage = Math.max(1, currentPage - Math.floor(visiblePageCount / 2))
    let endPage = Math.min(totalPages, startPage + visiblePageCount - 1)

    // Adjust the range if it exceeds the total number of pages
    if (endPage - startPage + 1 < visiblePageCount) {
      startPage = Math.max(1, endPage - visiblePageCount + 1)
    }

    // Add the First and Prev buttons
    items.push(
      <Pagination.First key='first' disabled={currentPage === 1} onClick={() => handleClick(1)} />
    )
    items.push(
      <Pagination.Prev
        key='prev'
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      />
    )

    // Add the page numbers and Ellipsis as needed
    for (let page = startPage; page <= endPage; page++) {
      items.push(
        <Pagination.Item key={page} active={page === currentPage} onClick={() => handleClick(page)}>
          {page}
        </Pagination.Item>
      )
    }

    if (startPage > 1) {
      items.splice(2, 0, <Pagination.Ellipsis key='ellipsis1' />)
    }
    if (endPage < totalPages) {
      items.splice(items.length, 0, <Pagination.Ellipsis key='ellipsis2' />)
    }

    // Add the Next and Last buttons
    items.push(
      <Pagination.Next
        key='next'
        disabled={currentPage === totalPages}
        onClick={() => handleClick(currentPage + 1)}
      />
    )
    items.push(
      <Pagination.Last
        key='last'
        disabled={currentPage === totalPages}
        onClick={() => handleClick(totalPages)}
      />
    )

    return items
  }

  return <Pagination>{renderPaginationItems()}</Pagination>
}
