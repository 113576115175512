import {useIntl} from 'react-intl'
import React, {useState, useEffect} from 'react'
import '../styles/_template.scss'
import {workflowAction} from '../core/models/_actionsDropdown'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import Offcanvas, {OffcanvasPlacement} from 'react-bootstrap/Offcanvas'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {OverlayTrigger} from 'react-bootstrap'
import {Tooltip} from 'react-bootstrap'
import {useAuth} from '../../auth'
const AddEndNodeDrawer = ({
  isDrawerOpen,
  setIsDrawerOpen,
  workflowId,
  isPositiveProp,
  workflowStepId,
  createWorkflowSteps,
  dropdownOptionsList,
  notAllowedActionIds,
}: {
  isDrawerOpen: boolean
  setIsDrawerOpen: (value: boolean) => void
  workflowId: number
  isPositiveProp: boolean | null
  workflowStepId: number | null
  createWorkflowSteps: (a: number, b: number | null, c: string, d: boolean | null) => void
  dropdownOptionsList: workflowAction[]
  notAllowedActionIds: string[]
}) => {
  const {formatMessage} = useIntl()
  const handleOptionClick = (actionId: string) => {
    createWorkflowSteps(workflowId, workflowStepId, actionId, isPositiveProp)
    setIsDrawerOpen(false)
    setTimeout(() => {
      setIsHovered('')
    }, 500)
  }
  const {setworkflowActionValue} = useAuth()

  const [isHovered, setIsHovered] = useState('')
  const ref = React.useRef<HTMLDivElement>(null)
  const tooltipRef = React.useRef<HTMLDivElement>(null)

  const newOptionsDataArray = dropdownOptionsList.map((item) => ({
    ...item,
    isDisable: notAllowedActionIds?.includes(item.workflowActionId),
  }))
  const [placement, setPlacement] = useState<OffcanvasPlacement>('end')
  const [windowSize, setWindowSize] = useState({width: window.innerWidth})
  const updatePlacement = () => {
    if (window.innerWidth <= 450) {
      setPlacement('bottom')
    } else {
      setPlacement('end')
    }
  }
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({width: window.innerWidth})
    }

    window.addEventListener('resize', handleResize)
    updatePlacement()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowSize])

  return (
    <>
      <div ref={ref}>
        <Offcanvas
          show={isDrawerOpen}
          onHide={() => {
            setIsDrawerOpen(false)
          }}
          placement={placement}
          className='upload-options-drawer-wrapper'
          container={ref.current}
        >
          <Offcanvas.Header className='pt-4 pb-0'>
            <Offcanvas.Title className='text-black'>
              {' '}
              <div className='d-flex justify-content-start align-items-center'>
                <div className='font-size-16 fw-bold text-color'>
                  {formatMessage({id: 'Add Actions'})}
                </div>
                <ToolTipUI
                  tooltipText={formatMessage({
                    id: 'Add actions to the workflow',
                  })}
                />
              </div>
              <div className='font-size-13 fw-normal text-color'>
                {formatMessage({id: 'You can choose any action which are active.'})}
              </div>
            </Offcanvas.Title>
            <div
              onClick={() => {
                setIsDrawerOpen(false)
              }}
              className='position-absolute top-0 end-0 mt-3 me-5 cursor-pointer close-icon-mobile'
            >
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/close.svg')}
                alt='close'
                height='16px'
                width='16px'
                className='mt-1 cursor-pointer'
              />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body className='text-center p-2 pb-1'>
            <div className='offcanvas-body-mobile px-sm-6'>
              {newOptionsDataArray?.map((option: workflowAction) =>
                option.type === 'CONDITION' ? null : option.isDisable ? (
                  <>
                    <div className='add-action-mobile'>
                      <div
                        className='new-option-padding-two p-4 margin-top-bottom'
                        key={option?.workflowActionId}
                      >
                        <div className='option-action mb-0 d-flex align-items-center'>
                          <img
                            src={option.imageOnSteps}
                            width='16px'
                            height='16px'
                            alt='action-icon'
                            className='me-3'
                          />

                          {option.name}
                          <div ref={tooltipRef} className='new-tooltip'>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip id='tooltip'>
                                  <p>
                                    {option.description
                                      ? option.description
                                      : formatMessage({
                                          id: 'The profile will be viewed every 72 hours',
                                        })}
                                  </p>
                                </Tooltip>
                              }
                              container={tooltipRef.current}
                            >
                              <img
                                src={
                                  isHovered === option?.workflowActionId
                                    ? toAbsoluteUrl(
                                        '/media/icons/duotune/general/tooltipOrange.svg'
                                      )
                                    : toAbsoluteUrl('/media/icons/duotune/general/tooltipWhite.svg')
                                }
                                alt='Tooltip'
                                className='text-secondary ms-1'
                                width='18px'
                                height='18px'
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='add-action-mobile'>
                    <div
                      className='new-option-padding p-4 margin-top-bottom'
                      key={option?.workflowActionId}
                      onClick={() => {
                        setworkflowActionValue(option.workflowActionId)
                        handleOptionClick(option?.workflowActionId)
                      }}
                      onMouseEnter={() => setIsHovered(option?.workflowActionId)}
                      onMouseLeave={() => setIsHovered('')}
                      onTouchStart={() => setIsHovered(option?.workflowActionId)}
                      onTouchEnd={() => setIsHovered('')}
                    >
                      <div
                        className={`option-action mb-0 d-flex align-items-center ${
                          isHovered === option?.workflowActionId ? 'orange-hover-color' : ''
                        }`}
                      >
                        <img
                          src={
                            isHovered === option?.workflowActionId
                              ? option.imageOnHover
                              : option.imageOnSteps
                          }
                          width='16px'
                          height='16px'
                          alt='action-icon'
                          className='me-3'
                        />

                        {option.name}
                        <div ref={tooltipRef} className='new-tooltip'>
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip id='tooltip'>
                                <p>
                                  {option.description
                                    ? option.description
                                    : formatMessage({
                                        id: 'The profile will be viewed every 72 hours',
                                      })}
                                </p>
                              </Tooltip>
                            }
                            container={tooltipRef.current}
                          >
                            <img
                              src={
                                isHovered === option?.workflowActionId
                                  ? toAbsoluteUrl('/media/icons/duotune/general/tooltipOrange.svg')
                                  : toAbsoluteUrl('/media/icons/duotune/general/tooltipWhite.svg')
                              }
                              alt='Tooltip'
                              className='text-secondary ms-1'
                              width='18px'
                              height='18px'
                            />
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className='d-flex justify-content-start ps-6 py-2'>
              <div className='font-size-15 text-black text-color fw-bold'>
                {formatMessage({id: 'Conditions:'})}
              </div>
            </div>
            <div className='offcanvas-body-mobile px-sm-6'>
              {newOptionsDataArray?.map((option: workflowAction) =>
                option.type === 'CONDITION' ? (
                  option.isDisable ? (
                    <>
                      <div className='add-action-mobile'>
                        <div
                          className='new-option-padding-two p-4 margin-top-bottom'
                          key={option?.workflowActionId}
                        >
                          <div className='option-action mb-0 d-flex align-items-center'>
                            <img
                              src={option.imageOnSteps}
                              width='16px'
                              height='16px'
                              alt='action-icon'
                              className='me-3'
                            />
                            {option.name}
                            <div ref={tooltipRef} className='new-tooltip'>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    <p>
                                      {option.description
                                        ? option.description
                                        : formatMessage({
                                            id: 'The profile will be viewed every 72 hours',
                                          })}
                                    </p>
                                  </Tooltip>
                                }
                                container={tooltipRef.current}
                              >
                                <img
                                  src={
                                    isHovered === option?.workflowActionId
                                      ? toAbsoluteUrl(
                                          '/media/icons/duotune/general/tooltipOrange.svg'
                                        )
                                      : toAbsoluteUrl(
                                          '/media/icons/duotune/general/tooltipWhite.svg'
                                        )
                                  }
                                  alt='Tooltip'
                                  className='text-secondary ms-1'
                                  width='18px'
                                  height='18px'
                                />
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='add-action-mobile'>
                      <div
                        className='new-option-padding p-4 margin-top-bottom'
                        key={option?.workflowActionId}
                        onClick={() => {
                          setworkflowActionValue(option.workflowActionId)
                          handleOptionClick(option?.workflowActionId)
                          setIsHovered(option?.workflowActionId)
                        }}
                        onMouseEnter={() => setIsHovered(option?.workflowActionId)}
                        onMouseLeave={() => setIsHovered('')}
                        onTouchStart={() => setIsHovered(option?.workflowActionId)}
                        onTouchEnd={() => setIsHovered('')}
                      >
                        <div
                          className={`option-action mb-0 d-flex align-items-center ${
                            isHovered === option?.workflowActionId ? 'orange-hover-color' : ''
                          }`}
                        >
                          <img
                            src={
                              isHovered === option?.workflowActionId
                                ? option.imageOnHover
                                : option.imageOnSteps
                            }
                            width='16px'
                            height='16px'
                            alt='action-icon'
                            className='me-3'
                          />
                          {option.name}
                          <div ref={tooltipRef} className='new-tooltip'>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip id='tooltip'>
                                  <p>
                                    {option.description
                                      ? option.description
                                      : formatMessage({
                                          id: 'The profile will be viewed every 72 hours',
                                        })}
                                  </p>
                                </Tooltip>
                              }
                              container={tooltipRef.current}
                            >
                              <img
                                src={
                                  isHovered === option?.workflowActionId
                                    ? toAbsoluteUrl(
                                        '/media/icons/duotune/general/tooltipOrange.svg'
                                      )
                                    : toAbsoluteUrl('/media/icons/duotune/general/tooltipWhite.svg')
                                }
                                alt='Tooltip'
                                className='text-secondary ms-1'
                                width='18px'
                                height='18px'
                              />
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : null
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  )
}

export default AddEndNodeDrawer
