import {Form, Formik} from 'formik'
import {useIntl} from 'react-intl'
import TextInput from '../../widgets/components/Input/TextInput'
import {Link, useLocation} from 'react-router-dom'
import {resendVerification} from '../core/_requests'
import {toast} from 'react-toastify'
import {useEffect, useState} from 'react'
import {GlobalButton} from '../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../widgets/core/_constants'

export function EmailVerification() {
  const {formatMessage} = useIntl()
  const [isLinkDisabled, setLinkDisabled] = useState(true)
  const location = useLocation()
  const {email} = location.state || ''
  const [domain, setDomain] = useState<string>()
  const [seconds, setSeconds] = useState(30)

  const initialValues = {
    email: '',
  }

  const handleResetVerification = async (values: any) => {
    try {
      setSeconds(30)
      setLinkDisabled(true)
      const {
        data: {data, success, errors},
      } = await resendVerification(values)
      if (success) {
        toast.success(formatMessage({id: data}))
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    } finally {
      setTimeout(() => {
        setLinkDisabled(false)
      }, 30000)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLinkDisabled(false)
    }, 30000)
  }, [])

  useEffect(() => {
    if (email) {
      setDomain(email.split('@')[1])
    }
  }, [email])

  const displayTime = () => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60

    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(remainingSeconds).padStart(2, '0')

    return `You can resend verification in ${formattedMinutes}:${formattedSeconds}`
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prevSeconds) => prevSeconds - 1)
      } else {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [seconds])

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleResetVerification}>
        {(formik) => {
          if (email) {
            formik.initialValues.email = email
          }
          return (
            <>
              <Form className='form w-100 px-sm-3'>
                <div className='text-center mb-10'>
                  <h1 className='text-black fw-bolder mb-29px'>
                    {formatMessage({id: 'Email verification'})}
                  </h1>
                </div>{' '}
                <div className='mb-7 fw-bold font-size-14 text-b lack'>
                  {formatMessage({
                    id: 'To complete your account setup, please click the verification link sent to your email. Check your inbox(including spam folder) for the email.',
                  })}
                </div>
                <TextInput
                  isDisabled={true}
                  fieldName={'email'}
                  formik={formik}
                  fieldType={'text'}
                  placeholder={formatMessage({id: 'Enter Email'})}
                  label={formatMessage({id: 'Email'})}
                  isTooltipNotRequired={true}
                  isStarRequired={true}
                />
                <div className='d-flex justify-content-center my-8'>
                  {domain === 'gmail.com' && (
                    <a
                      href={`https://mail.google.com/mail/u/0/#search/from%3A%40dripfunnel.com`}
                      target='_blank'
                      className='btn d-flex align-items-center justify-content-center custom-button-property btn-with-img gap-2'
                    >
                      {' '}
                      <img src='/media/logos/gmail.svg' height='18px' />
                      <span>{formatMessage({id: 'Open Gmail'})}</span>
                    </a>
                  )}
                  {(domain === 'outlook.com' ||
                    domain === 'live.com' ||
                    domain === 'hotmail.com') && (
                    <a
                      href={`https://outlook.live.com/mail/`}
                      target='_blank'
                      className='btn d-flex align-items-center justify-content-center custom-button-property btn-with-img gap-2'
                    >
                      {' '}
                      <img src='/media/logos/outlook.svg' height='18px' />
                      <span>{formatMessage({id: 'Open Outlook'})}</span>
                    </a>
                  )}
                </div>
                <div className='text-center fw-bold font-size-14 text-blue mb-24px d-flex justify-content-center'>
                  {isLinkDisabled ? (
                    displayTime()
                  ) : (
                    <GlobalButton
                      buttonText={formatMessage({id: 'Resend verification link'})}
                      buttonType={ButtonTypes.BUTTON_LINK}
                      isDisable={isLinkDisabled}
                      onButtonClick={formik.handleSubmit}
                    />
                  )}
                </div>
                <div className='text-center fw-bold font-size-14 text-black'>
                  <Link to='/registration/' className='link-primary'>
                    {formatMessage({id: 'Back to signup'})}
                  </Link>
                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}
