import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import '../../styles/_campaign.scss'
import {AddLeadsModal} from '../view/AddLeadsModal'
import {getCampaign} from '../../core/_requests'
import {leadListObject} from '../../core/_models'
import {toast} from 'react-toastify'
import {useNavigate, useParams} from 'react-router-dom'
import {getLeadList} from '../../../leads/core/_requests'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {LeadList} from '../singleCampaignPage/LeadList'
import {PageHeading} from '../../../template/components/PageHeading'
import {Roles, campaignDetailsBreadCrumbs} from '../../../profile/core/_constants'
import NoDataPage from '../../../widgets/components/NoDataPage'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'

export const AddLeadsPage = ({
  submitStep,
  setCampaignName,
  campaignName,
  teamLength,
  currentUser,
  prevStep,
}: {
  submitStep: any
  setCampaignName: React.Dispatch<React.SetStateAction<string | undefined>>
  campaignName: string | undefined
  teamLength?: number | undefined
  currentUser?: any
  prevStep?: () => void
}) => {
  const {formatMessage} = useIntl()
  const [leadlist, setLeadList] = useState<leadListObject[]>([])
  const [leadListLoader, setleadListLoader] = useState<boolean>(false)
  const [openLeadsModal, setOpenLeadsModal] = useState<boolean>(false)
  const [linkedinUrlFormat, setLinkedinUrlFormat] = useState<boolean>(false)
  const [linkedInURL, setLinkedInURL] = useState<string>('')
  const {campaignId} = useParams()
  const navigate = useNavigate()
  const [loader, setLoader] = useState<boolean>()
  const [leadListCount, setLeadListCount] = useState<number>()
  const [validInput, setValidInput] = useState<boolean>(false)

  const getAllLeadList = async () => {
    try {
      setLoader(true)
      if (campaignId) {
        const campaignIdAsNumber = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await getLeadList(campaignIdAsNumber)
        if (success) {
          setLeadList(data)
          setLeadListCount(data.length)
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
      setLoader(false)
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  const getCampaignName = async () => {
    try {
      setLoader(true)
      if (campaignId) {
        const {
          data: {success, errors, data},
        } = await getCampaign(+campaignId)
        if (success) {
          setCampaignName(data.name)
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
      setLoader(false)
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getAllLeadList()
    getCampaignName()
  }, [])

  return (
    <>
      {loader ? (
        <div className='position-relative spinner w-100'>
          <Spinner />
        </div>
      ) : (
        <>
          {' '}
          <div className='p-8 w-100'>
            <div className='d-flex flex-row w-100'>
              <div className='col-12'>
                <div className='d-flex justify-content-between position-relative'>
                  <PageHeading
                    title={formatMessage({id: 'Add Leads'})}
                    description={formatMessage({
                      id: 'You can start adding leads from your LinkedIn search or sales navigator just by copying and pasting search page URLs.',
                    })}
                    tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.ADD_LEADS'})}
                    breadcrumbs={campaignDetailsBreadCrumbs}
                    breadcrumbTitle={formatMessage({id: 'Add leads'})}
                    campaignName={campaignName}
                  ></PageHeading>
                  <div className='position-absolute top-0 end-0'>
                    <GlobalButton
                      buttonText={formatMessage({id: 'Add leads'})}
                      buttonType={ButtonTypes.PRIMARY}
                      onButtonClick={() => setOpenLeadsModal(true)}
                    />
                  </div>
                </div>
                {leadlist.length !== 0 && (
                  <div className='mt-8'>
                    {leadlist?.map((lead: leadListObject) => (
                      <LeadList
                        key={lead.leadListId}
                        lead={lead}
                        campaignId={campaignId}
                        getListOfList={getAllLeadList}
                        leadListCount={leadListCount}
                      ></LeadList>
                    ))}
                  </div>
                )}
                {leadlist.length === 0 && (
                  <div>
                    <NoDataPage
                      noDataText={formatMessage({
                        id: 'Looks like there are no leads added yet, start adding leads from LinkedIn.',
                      })}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='d-flex flex-stack pt-5'>
              <div className='d-flex gap-6 justify-content-end w-100'>
                {(currentUser?.role === Roles.ADMIN || currentUser?.role === Roles.OWNER) &&
                  teamLength &&
                  teamLength > 1 && (
                    <GlobalButton
                      buttonText={formatMessage({id: 'Back'})}
                      buttonType={ButtonTypes.SECONDARY}
                      onButtonClick={prevStep ? prevStep : () => {}}
                    />
                  )}
                {leadlist.length > 0 && (
                  <GlobalButton
                    buttonText={formatMessage({id: 'Next'})}
                    buttonType={ButtonTypes.PRIMARY}
                    onButtonClick={submitStep}
                    isDisable={leadlist.length <= 0}
                  />
                )}
              </div>
            </div>
          </div>
          <AddLeadsModal
            openLeadsModal={openLeadsModal}
            setOpenLeadsModal={setOpenLeadsModal}
            setleadListLoader={setleadListLoader}
            campaignId={campaignId}
            getAllLeadList={getAllLeadList}
            leadListLoader={leadListLoader}
          />
        </>
      )}
    </>
  )
}
