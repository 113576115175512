import axios from 'axios'
import {serialize} from '../../../core/_util'
import {CsvDataType} from './_models'

const API_URL = process.env.REACT_APP_BASE_API_URL
export const GET_CAMPAIGNS_URL = `${API_URL}/campaign`
export const CREATE_LEAD_LIST_URL = `${API_URL}/campaign/add-leadlist`
export const ADD_LEADS = `${API_URL}/campaign/add-leads`
export const GET_ALL_TIMEZONES = `${API_URL}/timezones`
export const GET_ALL_WORKFLOW = `${API_URL}/workflow`
export const CREATE_WORKINGHOUR = `${API_URL}/campaign/workinghour`
export const ADD_WORKFLOW = `${API_URL}/campaign/add-workflow`
export const ACTIVE_TO_INACTIVE_CAMPAIGN = `${API_URL}/campaign/status/active-to-inactive`
export const INACTIVE_TO_ACTIVE_CAMPAIGN = `${API_URL}/campaign/status/inactive-to-active`
export const DELETE_CAMPAIGN = `${API_URL}/campaign`
export const CHECK_LINKEDIN = `${API_URL}/campaign/check-linkedIn-creds`
export const DISCONNECT_LINKEDIN = `${API_URL}/auth/linkedin/disconnect`
export const CANCEL_ONBOARDING = `${API_URL}/auth/onboarding/cancel`

export const LEADS_COUNT = `${API_URL}/leads/count`
export const CAMPAIGN_DETAILS = `${API_URL}/campaign/details`

export const getAllCampaigns = (status?: string) => {
  const object = {
    status,
  }
  return axios.get(`${GET_CAMPAIGNS_URL}?${serialize(object)}`, {
    validateStatus() {
      return true
    },
  })
}
export const getCampaignAnalytics = () => {
  return axios.get(`${GET_CAMPAIGNS_URL}/analytics`, {
    validateStatus() {
      return true
    },
  })
}

export const getCampaign = (campaignId: number) => {
  return axios.get(`${GET_CAMPAIGNS_URL}/${campaignId}`, {
    validateStatus() {
      return true
    },
  })
}

export const createCampaigns = (name: string) => {
  const payload = {
    name: name,
  }
  return axios.post(`${GET_CAMPAIGNS_URL}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const CreateLeadList = (
  name: string,
  linkedInURL: string,
  expectedLeads: number,
  campaignId: number
) => {
  const payload = {
    name: name,
    linkedInURL: linkedInURL,
    campaignId: campaignId,
    expectedLeads,
  }
  return axios.post(`${CREATE_LEAD_LIST_URL}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const AddLeads = (name: string, profileURLs: CsvDataType[], campaignId: number) => {
  const payload = {
    name: name,
    profileLinkedInURLsData: profileURLs,
    campaignId: campaignId,
  }
  return axios.post(`${ADD_LEADS}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const getAllTimezones = () => {
  return axios.get(`${GET_ALL_TIMEZONES}`, {
    validateStatus() {
      return true
    },
  })
}

export const getAllWorkflow = (type?: string, status?: string) => {
  const object = {
    type,
    status,
  }
  return axios.get(`${GET_ALL_WORKFLOW}?${serialize(object)}`, {
    validateStatus() {
      return true
    },
  })
}

export const createWorkingHours = (
  startTime: string,
  endTime: string,
  includeWeekEnds: boolean,
  timezoneId: number | undefined,
  campaignId: number
) => {
  const payload = {
    startTime: parseInt(startTime),
    endTime: parseInt(endTime),
    includeWeekEnds: includeWeekEnds,
    timezoneId: timezoneId,
    campaignId: campaignId,
  }
  return axios.post(`${CREATE_WORKINGHOUR}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const createWorkFlow = (campaignId: number, workflowId: number) => {
  const payload = {
    campaignId: campaignId,
    workflowId: workflowId,
  }
  return axios.put(`${ADD_WORKFLOW}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const getCampaignDetails = (campaignId: number) => {
  return axios.get(`${GET_CAMPAIGNS_URL}/${campaignId}/workflow`, {
    validateStatus() {
      return true
    },
  })
}

export const updateCampaign = (
  campaignId: number,
  excludeTeamCampaignLead: boolean | undefined,
  excludeOtherCampaignLead: boolean | undefined
) => {
  const payload = {
    excludeTeamCampaignLead: excludeTeamCampaignLead,
    excludeOtherCampaignLead: excludeOtherCampaignLead,
  }
  return axios.put(`${GET_CAMPAIGNS_URL}/status/draft-to-inactive/${campaignId}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const updateStatusActiveToInactive = (campaignId: number) => {
  return axios.put(
    `${ACTIVE_TO_INACTIVE_CAMPAIGN}/${campaignId}`,
    {},
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const updateStatusInactiveToActive = (campaignId: number) => {
  return axios.put(
    `${INACTIVE_TO_ACTIVE_CAMPAIGN}/${campaignId}`,
    {},
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const deleteCampaign = (campaignId: number) => {
  return axios.delete(`${DELETE_CAMPAIGN}/${campaignId}`, {
    validateStatus() {
      return true
    },
  })
}

export const deleteLeadList = (campaignId: number, leadListId: number) => {
  return axios.delete(`${DELETE_CAMPAIGN}/${campaignId}/delete-leadlist/${leadListId}`, {
    validateStatus() {
      return true
    },
  })
}

export const updateCampaignDetails = (
  campaignId: number,
  excludeOtherCampaignLead?: boolean | undefined,
  excludeTeamCampaignLead?: boolean | undefined,
  name?: string | undefined,
  userId?: number
) => {
  const payload = {
    excludeTeamCampaignLead: excludeTeamCampaignLead,
    excludeOtherCampaignLead: excludeOtherCampaignLead,
    name: name,
  }
  const object = {
    userId,
  }
  return axios.put(`${GET_CAMPAIGNS_URL}/update/${campaignId}?${serialize(object)}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const updateCampaignDetail = (campaignId: number, userId?: number) => {
  const object = {
    userId,
  }
  return axios.put(`${GET_CAMPAIGNS_URL}/update/${campaignId}?${serialize(object)}`, {
    validateStatus() {
      return true
    },
  })
}

export const updateWorkingHours = (
  workingHourId: number,
  startTime: number,
  endTime: number,
  includeWeekEnds: boolean | undefined,
  timezoneId: number
) => {
  const payload = {
    startTime: startTime,
    endTime: endTime,
    includeWeekEnds: includeWeekEnds,
    timezoneId: timezoneId,
    workingHourId: workingHourId,
  }
  return axios.put(`${GET_CAMPAIGNS_URL}/update-workinghour/${workingHourId}`, payload, {
    validateStatus() {
      return true
    },
  })
}

export const checkLinkedIn = () => {
  return axios.get(`${CHECK_LINKEDIN}`, {
    validateStatus() {
      return true
    },
  })
}

export const disconnectLinkedInAccount = () => {
  return axios.put(
    `${DISCONNECT_LINKEDIN}`,
    {},
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const fetchLeadCountFromUrl = (searchUrl: string, campaignId: string) => {
  return axios.post(
    `${LEADS_COUNT}/${campaignId}`,
    {
      searchUrl,
    },
    {
      validateStatus() {
        return true
      },
    }
  )
}

export const fetchCampaignDetails = (campaignId: number) => {
  return axios.get(`${CAMPAIGN_DETAILS}/${campaignId}`, {
    validateStatus() {
      return true
    },
  })
}

export const cancelLinkedInOnboarding = () => {
  return axios.post(
    `${CANCEL_ONBOARDING}`,
    {},
    {
      validateStatus() {
        return true
      },
    }
  )
}
