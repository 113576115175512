import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import '../../styles/_campaign.scss'
import {createWorkFlow, getAllWorkflow} from '../../core/_requests'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import Tabs, {Tab} from 'react-best-tabs'
import {toast} from 'react-toastify'
import NoDataPage from '../../../widgets/components/NoDataPage'
import {templateStatus, templateType} from '../../core/_constants'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {PageHeading} from '../../../template/components/PageHeading'
import {campaignDetailsBreadCrumbs} from '../../../profile/core/_constants'
import {GlobalButton} from '../../../widgets/components/UI/GlobalButton'
import {ButtonTypes} from '../../../widgets/core/_constants'
import {WorkflowList} from '../../../template/components/WorkflowList'
import {workflow} from '../../../template/core/models/_workflowCard'
export const SelectWorkflowPage = ({
  setSaveBtnWorkflow,
  saveBtnWorkflow,
  prevStep,
  submitStep,
  campaignName,
}: {
  setSaveBtnWorkflow: Dispatch<SetStateAction<boolean>>
  saveBtnWorkflow: any
  prevStep: any
  submitStep: any
  campaignName: string | undefined
}) => {
  const [systemTemplates, setSystemTemplates] = useState<workflow[]>([])
  const [userTemplates, setUserTemplates] = useState<workflow[]>([])
  const {formatMessage} = useIntl()
  const navigate = useNavigate()
  const {campaignId} = useParams()
  const [key, setKey] = useState(1)
  const [selectedCardId, setSelectedCardId] = useState<number>(0)
  const [loader, setLoader] = useState<boolean>()
  const [dynamicWidth, setDynamicWidth] = useState<string>('252px')
  const ref = React.useRef<HTMLDivElement>(null)
  const selectWorkFlow = (id: number) => {
    setSaveBtnWorkflow(false)
    setSelectedCardId(id)
  }

  const getSystemTemplates = async () => {
    try {
      setLoader(true)
      const {
        data: {data: data, success, errors},
      } = await getAllWorkflow(templateType.SYSTEM, templateStatus.COMPLETE)
      if (success) {
        setSystemTemplates(data)
        setLoader(false)
      } else {
        setLoader(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  const getUserTemplates = async () => {
    try {
      setLoader(true)
      const {
        data: {data: data, success, errors},
      } = await getAllWorkflow(templateType.USER, templateStatus.COMPLETE)
      if (success) {
        setUserTemplates(data)
        setLoader(false)
      } else {
        setLoader(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  const selectWorkflow = async () => {
    try {
      if (campaignId) {
        const campaignIdAsNumber = parseInt(campaignId, 10)
        const {
          data: {data: data, success, errors},
        } = await createWorkFlow(campaignIdAsNumber, selectedCardId)
        if (success) {
          submitStep()
          toast.success(formatMessage({id: 'Workflow Selected successfully'}))
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } else {
        toast.error(formatMessage({id: 'Campaign id not found'}))
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSystemTemplates()
    getUserTemplates()
  }, [])

  return (
    <>
      <div className='gap-2 w-100 p-8'>
        <div className='mb-4'>
          <PageHeading
            title={formatMessage({id: 'Select a Workflow'})}
            description={formatMessage({
              id: 'You can choose from the pre defined workflows provided by us or you can create your own custom workflow',
            })}
            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.SELECT_TEMPLATE'})}
            breadcrumbs={campaignDetailsBreadCrumbs}
            breadcrumbTitle={formatMessage({id: 'Select a workflow'})}
            campaignName={campaignName}
          ></PageHeading>
        </div>
        <div className=''>
          {userTemplates?.length > 0 ? (
            <Tabs
              activeTab={1}
              className='font-size-14'
              ulClassName='text-black  dark-border setting-tabs'
              activityClassName='active-primary initial-width'
              onClick={(event, tab) => {
                setKey(tab)
                if (tab === 1) {
                  getUserTemplates()
                } else if (tab === 2) {
                  getSystemTemplates()
                }
              }}
            >
              <Tab title={formatMessage({id: 'Custom Workflows'})} className={`mr-3`}>
                <div className='position-relative'>
                  <div className='gap-3 mt-4'>
                    <div className='row w-100 g-5 d-flex justify-content-start align-content-stretch flex-wrap'>
                      {loader ? (
                        <div className='spinner'>
                          <Spinner />
                        </div>
                      ) : !userTemplates.length ? (
                        <NoDataPage
                          noDataText={formatMessage({id: 'Oops!! The screen is currently empty.'})}
                        />
                      ) : (
                        userTemplates?.map((item: workflow) => (
                          <div className='col workflow-template-col' key={item.workflowId}>
                            <button
                              className='btn p-0 m-0 w-100'
                              onClick={() => selectWorkFlow(item.workflowId)}
                            >
                              <WorkflowList
                                btnText={item.name}
                                creationDate={item.updatedAt ? item.updatedAt : ''}
                                workflowId={item.workflowId}
                                userfirstName={item?.user?.firstName}
                                userId={item?.user?.userId}
                                teamId={item?.teamId}
                                workflowUpdatedBy={item.workflowUpdatedBy}
                                selectPage={true}
                                selectedCardId={selectedCardId}
                              />
                            </button>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              {
                <Tab title={formatMessage({id: 'System Workflows'})} className={`mr-3`} key={key}>
                  <div className='position-relative'>
                    <div className='gap-3 mt-4'>
                      <div className='row w-100 g-5 d-flex jsutify-content-start align-content-stretch flex-wrap'>
                        {!loader ? (
                          !systemTemplates.length ? (
                            <NoDataPage
                              noDataText={formatMessage({
                                id: 'Oops!! The screen is currently empty.',
                              })}
                            />
                          ) : (
                            systemTemplates?.map((item: workflow) => (
                              <div className='col workflow-template-col' key={item.workflowId}>
                                <button
                                  className='btn p-0 m-0 w-100'
                                  onClick={() => selectWorkFlow(item.workflowId)}
                                >
                                  <WorkflowList
                                    btnText={item.name}
                                    creationDate={item.updatedAt ? item.updatedAt : ''}
                                    workflowId={item.workflowId}
                                    userfirstName={item?.user?.firstName}
                                    userId={item?.user?.userId}
                                    teamId={item?.teamId}
                                    workflowUpdatedBy={item.workflowUpdatedBy}
                                    selectPage={true}
                                    selectedCardId={selectedCardId}
                                  />
                                </button>
                              </div>
                            ))
                          )
                        ) : (
                          <div className='spinner'>
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
              }
            </Tabs>
          ) : (
            <Tabs
              activeTab={1}
              className='font-size-14 text-color'
              ulClassName='dark-border setting-tabs'
              activityClassName='active-primary initial-width'
              onClick={(event, tab) => {
                setKey(tab)
                if (tab === 1) {
                  getSystemTemplates()
                } else if (tab === 2) {
                  getUserTemplates()
                }
              }}
            >
              {
                <Tab title={formatMessage({id: 'System Workflows'})} className={`mr-3`} key={key}>
                  <div className='position-relative'>
                    <div className='gap-3 mt-4'>
                      <div className='row w-100 g-5 d-flex jsutify-content-start align-content-stretch flex-wrap'>
                        {!loader ? (
                          !systemTemplates.length ? (
                            <NoDataPage
                              noDataText={formatMessage({
                                id: 'Oops!! The screen is currently empty.',
                              })}
                            />
                          ) : (
                            systemTemplates?.map((item: workflow) => (
                              <div className='col workflow-template-col' key={item.workflowId}>
                                <button
                                  className='btn p-0 m-0 w-100'
                                  onClick={() => selectWorkFlow(item.workflowId)}
                                >
                                  <WorkflowList
                                    btnText={item.name}
                                    creationDate={item.updatedAt ? item.updatedAt : ''}
                                    workflowId={item.workflowId}
                                    userfirstName={item?.user?.firstName}
                                    userId={item?.user?.userId}
                                    teamId={item?.teamId}
                                    workflowUpdatedBy={item.workflowUpdatedBy}
                                    selectPage={true}
                                    selectedCardId={selectedCardId}
                                  />
                                </button>
                              </div>
                            ))
                          )
                        ) : (
                          <div className='spinner'>
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Tab>
              }
              <Tab title={formatMessage({id: 'Custom Workflows'})} className={`mr-3`}>
                <div className='position-relative'>
                  <div className='gap-3 mt-4'>
                    <div className='row w-100 g-5 d-flex justify-content-start align-content-stretch flex-wrap'>
                      {loader ? (
                        <div className='spinner'>
                          <Spinner />
                        </div>
                      ) : !userTemplates.length ? (
                        <NoDataPage
                          noDataText={formatMessage({id: 'Oops!! The screen is currently empty.'})}
                        />
                      ) : (
                        userTemplates?.map((item: workflow) => (
                          <div className='col workflow-template-col' key={item.workflowId}>
                            <button
                              className='btn p-0 m-0 w-100'
                              onClick={() => selectWorkFlow(item.workflowId)}
                            >
                              <WorkflowList
                                btnText={item.name}
                                creationDate={item.updatedAt ? item.updatedAt : ''}
                                workflowId={item.workflowId}
                                userfirstName={item?.user?.firstName}
                                userId={item?.user?.userId}
                                teamId={item?.teamId}
                                workflowUpdatedBy={item.workflowUpdatedBy}
                                selectPage={true}
                                selectedCardId={selectedCardId}
                              />
                            </button>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
          )}

          <div className='d-flex flex-stack pt-10'>
            <div className='d-flex gap-6 justify-content-end w-100'>
              <GlobalButton
                buttonText={formatMessage({id: 'Back'})}
                buttonType={ButtonTypes.SECONDARY}
                onButtonClick={prevStep}
              />
              <GlobalButton
                buttonText={formatMessage({id: 'Select Workflow'})}
                buttonType={ButtonTypes.PRIMARY}
                onButtonClick={selectWorkflow}
                isDisable={saveBtnWorkflow}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
