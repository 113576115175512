import {useState, useEffect} from 'react'
import clsx from 'clsx'
import {ToolTipUI} from '../../widgets/components/UI/ToolTipUI'
import {useLayout} from '../../../../_metronic/layout/core'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {PageLink} from '../core/models/_heading'

const PageHeading = ({
  title,
  description,
  tooltipText,
  breadcrumbs,
  breadcrumbTitle,
  workflowName,
  showDescription,
  campaignName,
}: {
  title: string
  description: string
  tooltipText?: string
  breadcrumbs: Array<PageLink>
  breadcrumbTitle?: string
  workflowName?: string
  showDescription?: boolean
  campaignName?: string
}) => {
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  useEffect(() => {
    setPageBreadcrumbs(breadcrumbs)
  }, [breadcrumbs])
  const {formatMessage} = useIntl()

  return (
    <div className='p-0 text-color'>
      {title && (
        <div className='font-size-20 fw-bold mt-3 '>
          {title}
          {tooltipText ? (
            <span className='ms-1'>
              <ToolTipUI tooltipText={tooltipText} />
            </span>
          ) : (
            ''
          )}
          {workflowName ? (
            <>
              :&nbsp;<span className='blue-color'>{workflowName}</span>
            </>
          ) : (
            ''
          )}
          {!showDescription && description && (
            <div className='font-size-14 fw-normal'>{description}</div>
          )}
        </div>
      )}
      {/* end::Title */}

      <ul
        className={`breadcrumb breadcrumb-separatorless fw-normal font-size-12 mt-6 ${
          showDescription ? 'd-none' : ''
        }`}
      >
        {Array.from(pageBreadcrumbs).map((item, index) => (
          <li
            className={clsx('breadcrumb-item', {
              'text-dark': !item.isSeparator && item.isActive,
              'text-muted': !item.isSeparator && !item.isActive,
            })}
            key={`${item.path}${index}`}
          >
            {!item.isSeparator ? (
              <Link className='text-muted' to={item.path}>
                {item.title}
              </Link>
            ) : (
              <span className='h-1px bullet '></span>
            )}
          </li>
        ))}
        {campaignName ? (
          <>
            <li className='breadcrumb-item text-muted'>{campaignName}</li>
            <span className='h-1px bullet me-2'></span>
          </>
        ) : (
          ''
        )}
        {breadcrumbTitle !== 'undefined' ? (
          <li className={`breadcrumb-item text-color${campaignName ? 'fw-semibold' : ''}`}>
            {breadcrumbTitle}
          </li>
        ) : (
          ''
        )}
      </ul>
    </div>
  )
}
export {PageHeading}
