import '../styles/_template.scss'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import OutsideClickHandler from 'react-outside-click-handler'
import {TreeNodeDropdown} from './TreeNodeDropdown'
import {AddEndNodeDropdown} from './AddEndNodeDropdown'
import {useIntl} from 'react-intl'
import {
  createWorkflowStep,
  getSingleWorkflow,
  getWorkflowActions,
  getAllowedWorkflowActions,
} from '../core/_requests'
import {toast} from 'react-toastify'
import {useLocation} from 'react-router-dom'
import {singleWorkflow, workflowSteps} from '../core/models/_treeStructure'
import {workflowActionFlowType} from '../core/models/_workflowActionFlow'
import {workflowAction} from '../core/models/_actionsDropdown'
import {usePopper} from 'react-popper'
import {workflowBreadCrumbs} from '../../profile/core/_constants'
import {PageHeading} from './PageHeading'
import {FullScreen, useFullScreenHandle} from 'react-full-screen'
import {createUseGesture, dragAction, pinchAction, wheelAction, useDrag} from '@use-gesture/react'
import {useSpring, animated} from '@react-spring/web'
import {actionType, workflowActionIdEnum} from '../core/_contants'
import {AddWaitTimeModal} from './AddWaitTimeModal'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import React from 'react'
import AddEndNodeDrawer from './AddEndNodeDrawer'
import {updateWorkflowStep, deleteWorkflowStep} from '../core/_requests'
import {CustomiseInputBoxesModal} from './CustomiseInputBoxesModal'
import {DeleteConfirmationModal} from '../../widgets/components/DeleteConfirmationModal'
import {getEnableDisableList} from '../core/_workflowUtil'
import {useAuth} from '../../auth'
import _debounce from 'lodash/debounce'
import {Spinner} from '../../widgets/components/General/Spinner'
const TreeStructure = ({
  node,
  workflowId,
  getSingleWorkflowData,
  userId,
  parentStepId,
  createWorkflowSteps,
  isPositive,
  isBinary,
  dropdownOptionsList,
  endSequenceAction,
  openAddActionBtn,
  setOpenAddActionBtn,
  openAddBtnBool,
  setOpenAddBtnBool,
  bothChildNull,
  showDescription,
  isDrawerOpen,
  setIsDrawerOpen,
  setIsPositiveDrawer,
  setParentStepIdDrawer,
  setOpenTimeModal,
  setPrimaryValue,
  setSecondaryValue,
  setWorkflowStep,
  setOpenInputBoxModal,
  setShowDeleteStepModal,
  workflowActionFlow,
  entireTreeData,
  notAllowedActionIds,
  setNotAllowedActionIds,
  actionIDs,
  actionLoader,
  workflowActionType,
  setWorkflowActionType,
}: {
  node: workflowSteps
  workflowId: number
  getSingleWorkflowData: (value: number) => void
  userId: number
  parentStepId: number | null
  createWorkflowSteps: (
    workflowId: number,
    workflowStepId: number | null,
    workflowActionId: string,
    isPositive: boolean | null
  ) => void
  isPositive: boolean | null
  isBinary: boolean | undefined
  dropdownOptionsList: workflowAction[]
  endSequenceAction: string
  openAddActionBtn: number | null
  setOpenAddActionBtn: (value: number | null) => void
  openAddBtnBool: boolean | null
  setOpenAddBtnBool: (value: boolean | null) => void
  bothChildNull: boolean
  showDescription?: boolean
  isDrawerOpen: boolean
  setIsDrawerOpen: (value: boolean) => void
  setIsPositiveDrawer: (value: boolean | null) => void
  setParentStepIdDrawer: (value: number | null) => void
  setOpenTimeModal: (value: boolean) => void
  setPrimaryValue: (value: string) => void
  setSecondaryValue: (value: string) => void
  setWorkflowStep: (value: number | null) => void
  setOpenInputBoxModal: (value: boolean) => void
  setShowDeleteStepModal: (value: boolean) => void
  workflowActionFlow: workflowActionFlowType | null
  entireTreeData: singleWorkflow | null
  notAllowedActionIds: string[]
  setNotAllowedActionIds: (value: string[]) => void
  actionIDs: string[]
  actionLoader: number | null
  workflowActionType: string
  setWorkflowActionType: Dispatch<SetStateAction<string>>
}) => {
  //useStates
  const [isOpenTreeNode, setIsOpenTreeNode] = useState<boolean>(false)
  const {formatMessage} = useIntl()

  const [referenceElement, setReferenceElement] = useState<any>(null)
  const [popperElement, setPopperElement] = useState<any>(null)
  const [arrowElement, setArrowElement] = useState<any>(null)
  const [buttonClick, setButtonClick] = useState<boolean>(false)
  const [inmailHasStepValue, setInmailHasStepValue] = useState<boolean>(false)
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [
      {
        name: 'arrow',
        options: {element: arrowElement},
      },
      {name: 'preventOverflow', options: {mainAxis: true}},
      {name: 'flip', options: {fallbackPlacements: ['left', 'top', 'bottom']}},
    ],
  })
  const popperStyles = {
    ...styles.popper,
    zIndex: 999,
  }

  const handleButtonClick = () => {
    if (openAddActionBtn === parentStepId && openAddBtnBool === isPositive) {
      setOpenAddActionBtn(0)
      setOpenAddBtnBool(null)
    } else {
      setOpenAddActionBtn(parentStepId)
      setOpenAddBtnBool(isPositive)
    }
  }
  const handleOustideClickForAdd = () => {
    if (openAddActionBtn === parentStepId && openAddBtnBool === isPositive) {
      setOpenAddActionBtn(0)
      setOpenAddBtnBool(null)
    }
  }

  function filterFalseActions(boolList: boolean[], actionIDs: string[]) {
    const falseActionIDs = []

    for (let i = 0; i < boolList.length; i++) {
      if (!boolList[i]) {
        falseActionIDs.push(actionIDs[i])
      }
    }

    return falseActionIDs
  }

  function flattenSteps(data: any) {
    const result: any = []

    function flatten(step: any) {
      const flatStep = {
        workflowStepId: step.workflowStepId,
        workflowAction: step.workflowAction,
        primaryValue: step.primaryValue,
        secondaryValue: step.secondaryValue,
        positiveStepId: step.positiveStep ? step.positiveStep.workflowStepId : null,
        negativeStepId: step.negativeStep ? step.negativeStep.workflowStepId : null,
      }

      result.push(flatStep)

      if (step.positiveStep) {
        flatten(step.positiveStep)
      }

      if (step.negativeStep) {
        flatten(step.negativeStep)
      }
    }

    flatten(data.workflowSteps)

    return result
  }
  const handleDrawerOpen = () => {
    const arrOfSteps = flattenSteps(entireTreeData)
    if (workflowActionFlow && entireTreeData) {
      const boolList = getEnableDisableList(parentStepId, workflowActionFlow, arrOfSteps, actionIDs)
      const allowedBoolList = boolList.filter((value) => value !== undefined)
      const result = filterFalseActions(allowedBoolList, actionIDs)
      setNotAllowedActionIds(result)
    }
    setIsDrawerOpen(!isDrawerOpen)
    setIsPositiveDrawer(isPositive)
    setParentStepIdDrawer(parentStepId)
    setButtonClick(true)
  }

  useEffect(() => {
    if (!isDrawerOpen) setButtonClick(false)
  }, [isDrawerOpen])
  if (!node) {
    return (
      <div className='node-temp'>
        <div className={`node-end-add-temp d-flex justify-content-center`}>
          <div className='card-option-wrapper'>
            <button
              className={`add-end-span-new cursor-pointer px-2 ${
                buttonClick ? 'orange-button' : ''
              }`}
              type='button'
              ref={setReferenceElement}
              onClick={handleDrawerOpen}
            >
              {actionLoader === parentStepId ? (
                <span className='indicator-label font-size-14 p-0 orange-color'>
                  {formatMessage({id: 'Adding...'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              ) : (
                <>
                  <img
                    src={
                      buttonClick
                        ? toAbsoluteUrl('/media/icons/duotune/general/plusOrange.svg')
                        : toAbsoluteUrl('/media/icons/duotune/general/plus-blue.svg')
                    }
                    alt='plus'
                    height='16px'
                    width='16px'
                    className='me-2'
                  />
                  {formatMessage({id: 'Add action'})}
                </>
              )}
            </button>
          </div>
          <button
            className='remove-add-span-temp cursor-pointer'
            onClick={() =>
              createWorkflowSteps(workflowId, parentStepId, endSequenceAction, isPositive)
            }
          >
            {formatMessage({id: 'End'})}
          </button>
        </div>
      </div>
    )
  }

  const handleAddWaitTime = (actionType: string | undefined) => {
    if (actionType === 'WAIT') {
      setOpenTimeModal(true)
      setPrimaryValue(node?.primaryValue)
      setSecondaryValue(node?.secondaryValue)
      setWorkflowStep(node?.workflowStepId)
    }
  }
  const countBinaryChildrenForNode = (node: workflowSteps): {left: number; right: number} => {
    if (!node) {
      return {left: 0, right: 1}
    }
    if (node?.workflowAction?.type === actionType.END) {
      return {left: 0, right: 1}
    }
    if (node?.workflowAction?.type === actionType.WAIT) {
      return countBinaryChildrenForNode(node.positiveStep)
    }
    let binaryCount = {left: 0, right: 0}

    if (node.negativeStep) {
      const leftCounts = countBinaryChildrenForNode(node.negativeStep)
      binaryCount.left = leftCounts.left + leftCounts.right
    }
    if (node.positiveStep) {
      const rightCounts = countBinaryChildrenForNode(node.positiveStep)
      binaryCount.right = rightCounts.left + rightCounts.right
    }
    return binaryCount
  }
  const binaryCounts = countBinaryChildrenForNode(node)
  const leftColumnWidth = `${binaryCounts.left * 250 + 15}px`
  const rightColumnWidth = `${binaryCounts.right * 250 + 15}px`
  let nodePrimaryValueData
  let isInMailMsgEmpty
  if (node?.workflowAction?.workflowActionId === 'INMAIL') {
    nodePrimaryValueData = JSON.parse(node?.primaryValue)
    isInMailMsgEmpty = !nodePrimaryValueData?.message
  }
  return (
    <>
      <div
        className={`${
          node?.workflowAction?.isBinary
            ? 'binary-node'
            : node?.workflowAction?.type === actionType.END
            ? 'end-node'
            : 'action-node'
        } node-temp`}
      >
        <div
          className={`${
            node?.workflowAction?.type === actionType.WAIT ? 'cursor-pointer' : ''
          } d-flex first-row`}
          onClick={() => handleAddWaitTime(node?.workflowAction?.type)}
        >
          {node?.workflowAction?.isBinary ? (
            <img
              src={toAbsoluteUrl('/media/icons/duotune/general/rejected.svg')}
              alt='rejected'
              height='18px'
              width='18px'
              className='rejected'
            />
          ) : (
            ''
          )}
          <div
            className={`${
              node?.workflowAction?.type === actionType.WAIT
                ? 'wait-item'
                : node?.workflowAction?.type === actionType.END
                ? 'end-item'
                : 'action-item'
            } 
            ${
              !node?.primaryValue &&
              node?.workflowAction?.hasMessage &&
              node?.workflowAction?.workflowActionId !== 'SEND_AN_INVITE' &&
              node?.workflowAction?.workflowActionId !== 'INMAIL'
                ? 'highlight-empty-msg'
                : ''
            }
            ${
              isInMailMsgEmpty &&
              node?.workflowAction?.hasMessage &&
              node?.workflowAction?.workflowActionId === 'INMAIL'
                ? 'highlight-empty-msg'
                : ''
            }
           
            value-item font-size-14 fw-bold px-2`}
          >
            <div
              className={`${node?.workflowAction?.hasMessage ? 'cursor-pointer' : ''}`}
              onClick={() => {
                if (
                  node?.workflowAction?.hasMessage &&
                  node?.workflowAction?.type !== actionType.WAIT
                ) {
                  setOpenInputBoxModal(true)
                  setWorkflowActionType(node?.workflowAction?.workflowActionId)
                  setWorkflowStep(node?.workflowStepId)
                  setPrimaryValue(node?.primaryValue)
                  setSecondaryValue(node?.secondaryValue)
                }
              }}
            >
              {!node?.primaryValue &&
              node?.workflowAction?.hasMessage &&
              node?.workflowAction?.workflowActionId !== 'SEND_AN_INVITE' ? (
                <img
                  src={node?.workflowAction?.imageOnHover}
                  height='16px'
                  width='16px'
                  alt='icon'
                  className='ms-1 me-2'
                  style={{fill: 'white'}}
                />
              ) : (
                <img
                  src={node?.workflowAction?.imageOnSteps}
                  height='16px'
                  width='16px'
                  alt='icon'
                  className='ms-1 me-2'
                  style={{fill: 'white'}}
                />
              )}
              {node?.workflowAction?.type === actionType.WAIT
                ? parseInt(node?.primaryValue) < 1
                  ? formatMessage({id: 'No delay'})
                  : parseInt(node?.primaryValue) === 1
                  ? node?.primaryValue +
                    ' ' +
                    (node?.secondaryValue === 'days'
                      ? formatMessage({id: 'day'})
                      : formatMessage({id: 'hour'}))
                  : node?.primaryValue + ' ' + node?.secondaryValue
                : node?.workflowAction?.name}
            </div>
            {(node?.workflowAction?.type === actionType.END ||
              node?.workflowAction?.type === actionType.ACTION ||
              node?.workflowAction?.type === actionType.CONDITION) && (
              <div className='card-option-wrapper mb-1 ms-1'>
                <OutsideClickHandler onOutsideClick={() => setIsOpenTreeNode(false)}>
                  <div
                    onClick={() => {
                      setIsOpenTreeNode(!isOpenTreeNode)
                      setWorkflowStep(node?.workflowStepId)
                      setPrimaryValue(node?.primaryValue)
                      setSecondaryValue(node?.secondaryValue)
                      if (node?.workflowAction?.workflowActionId) {
                        setWorkflowActionType(node?.workflowAction?.workflowActionId)
                      }
                    }}
                  >
                    {' '}
                    {userId &&
                      ((showDescription && node?.workflowAction?.hasMessage) ||
                        !showDescription) && (
                        <img
                          className='cursor-pointer'
                          height='18px'
                          width='18px'
                          src={toAbsoluteUrl('/media/icons/duotune/general/kebab-menu.svg')}
                          alt='kebab-menu'
                        ></img>
                      )}
                  </div>{' '}
                  <TreeNodeDropdown
                    isOpenTreeNode={isOpenTreeNode}
                    hasMessage={node?.workflowAction?.hasMessage}
                    showDescription={showDescription}
                    setOpenInputBoxModal={setOpenInputBoxModal}
                    setShowDeleteStepModal={setShowDeleteStepModal}
                  />
                </OutsideClickHandler>
              </div>
            )}
          </div>
          {node?.workflowAction?.isBinary ? (
            <img
              src={toAbsoluteUrl('/media/icons/duotune/general/accepted.svg')}
              alt='accepted'
              height='18px'
              width='18px'
              className='accepted'
            />
          ) : (
            ''
          )}
        </div>

        {node?.workflowAction?.type !== actionType.END &&
          (node?.workflowAction?.isBinary ? (
            <>
              <div
                className='node-binary-children-temp'
                style={{gridTemplateColumns: `${leftColumnWidth} ${rightColumnWidth}`}}
              >
                <div className='left-branch' style={{width: `${leftColumnWidth}`}}>
                  <TreeStructure
                    node={node.negativeStep}
                    workflowId={workflowId}
                    getSingleWorkflowData={getSingleWorkflowData}
                    userId={userId}
                    parentStepId={node?.workflowStepId}
                    createWorkflowSteps={createWorkflowSteps}
                    isPositive={false}
                    isBinary={node?.workflowAction?.isBinary}
                    dropdownOptionsList={dropdownOptionsList}
                    endSequenceAction={endSequenceAction}
                    openAddActionBtn={openAddActionBtn}
                    setOpenAddActionBtn={setOpenAddActionBtn}
                    openAddBtnBool={openAddBtnBool}
                    setOpenAddBtnBool={setOpenAddBtnBool}
                    bothChildNull={!node.positiveStep && !node.negativeStep}
                    showDescription={showDescription}
                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={setIsDrawerOpen}
                    setIsPositiveDrawer={setIsPositiveDrawer}
                    setParentStepIdDrawer={setParentStepIdDrawer}
                    setOpenTimeModal={setOpenTimeModal}
                    setPrimaryValue={setPrimaryValue}
                    setSecondaryValue={setSecondaryValue}
                    setWorkflowStep={setWorkflowStep}
                    setOpenInputBoxModal={setOpenInputBoxModal}
                    setShowDeleteStepModal={setShowDeleteStepModal}
                    workflowActionFlow={workflowActionFlow}
                    entireTreeData={entireTreeData}
                    notAllowedActionIds={notAllowedActionIds}
                    setNotAllowedActionIds={setNotAllowedActionIds}
                    actionIDs={actionIDs}
                    actionLoader={actionLoader}
                    workflowActionType={workflowActionType}
                    setWorkflowActionType={setWorkflowActionType}
                  />
                </div>
                <div className='right-branch' style={{width: `${rightColumnWidth}`}}>
                  <TreeStructure
                    node={node.positiveStep}
                    workflowId={workflowId}
                    getSingleWorkflowData={getSingleWorkflowData}
                    userId={userId}
                    parentStepId={node?.workflowStepId}
                    createWorkflowSteps={createWorkflowSteps}
                    isPositive={true}
                    isBinary={node?.workflowAction?.isBinary}
                    dropdownOptionsList={dropdownOptionsList}
                    endSequenceAction={endSequenceAction}
                    openAddActionBtn={openAddActionBtn}
                    setOpenAddActionBtn={setOpenAddActionBtn}
                    openAddBtnBool={openAddBtnBool}
                    setOpenAddBtnBool={setOpenAddBtnBool}
                    bothChildNull={!node.positiveStep && !node.negativeStep}
                    showDescription={showDescription}
                    isDrawerOpen={isDrawerOpen}
                    setIsDrawerOpen={setIsDrawerOpen}
                    setIsPositiveDrawer={setIsPositiveDrawer}
                    setParentStepIdDrawer={setParentStepIdDrawer}
                    setOpenTimeModal={setOpenTimeModal}
                    setPrimaryValue={setPrimaryValue}
                    setSecondaryValue={setSecondaryValue}
                    setWorkflowStep={setWorkflowStep}
                    setOpenInputBoxModal={setOpenInputBoxModal}
                    setShowDeleteStepModal={setShowDeleteStepModal}
                    workflowActionFlow={workflowActionFlow}
                    entireTreeData={entireTreeData}
                    notAllowedActionIds={notAllowedActionIds}
                    setNotAllowedActionIds={setNotAllowedActionIds}
                    actionIDs={actionIDs}
                    actionLoader={actionLoader}
                    workflowActionType={workflowActionType}
                    setWorkflowActionType={setWorkflowActionType}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='node-children-temp'>
                <TreeStructure
                  node={node.positiveStep}
                  workflowId={workflowId}
                  getSingleWorkflowData={getSingleWorkflowData}
                  userId={userId}
                  parentStepId={node?.workflowStepId}
                  createWorkflowSteps={createWorkflowSteps}
                  isPositive={true}
                  isBinary={node?.workflowAction?.isBinary}
                  dropdownOptionsList={dropdownOptionsList}
                  endSequenceAction={endSequenceAction}
                  openAddActionBtn={openAddActionBtn}
                  setOpenAddActionBtn={setOpenAddActionBtn}
                  openAddBtnBool={openAddBtnBool}
                  setOpenAddBtnBool={setOpenAddBtnBool}
                  bothChildNull={!node.positiveStep && !node.negativeStep}
                  showDescription={showDescription}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                  setIsPositiveDrawer={setIsPositiveDrawer}
                  setParentStepIdDrawer={setParentStepIdDrawer}
                  setOpenTimeModal={setOpenTimeModal}
                  setPrimaryValue={setPrimaryValue}
                  setSecondaryValue={setSecondaryValue}
                  setWorkflowStep={setWorkflowStep}
                  setOpenInputBoxModal={setOpenInputBoxModal}
                  setShowDeleteStepModal={setShowDeleteStepModal}
                  workflowActionFlow={workflowActionFlow}
                  entireTreeData={entireTreeData}
                  notAllowedActionIds={notAllowedActionIds}
                  setNotAllowedActionIds={setNotAllowedActionIds}
                  actionIDs={actionIDs}
                  actionLoader={actionLoader}
                  workflowActionType={workflowActionType}
                  setWorkflowActionType={setWorkflowActionType}
                />
              </div>
            </>
          ))}
      </div>
    </>
  )
}

const BinaryTree = ({
  tree,
  workflowId,
  getSingleWorkflowData,
  userId,
  createWorkflowSteps,
  dropdownOptionsList,
  endSequenceAction,
  openAddActionBtn,
  setOpenAddActionBtn,
  openAddBtnBool,
  setOpenAddBtnBool,
  workflowName,
  showDescription,
  workflowActionFlow,
  entireTreeData,
  actionIDs,
  openInputBoxModal,
  setOpenInputBoxModal,
  workflowStep,
  setWorkflowStep,
  primaryValue,
  setPrimaryValue,
  secondaryValue,
  setSecondaryValue,
  actionLoader,
  parentStepIdDrawer,
  setParentStepIdDrawer,
  workflowStatus,
  workflowActionType,
  setWorkflowActionType,
}: {
  tree: workflowSteps
  workflowId: number
  getSingleWorkflowData: (value: number) => void
  userId: number
  createWorkflowSteps: (
    workflowId: number,
    workflowStepId: number | null,
    workflowActionId: string,
    isPositive: boolean | null
  ) => void
  dropdownOptionsList: workflowAction[]
  endSequenceAction: string
  openAddActionBtn: number | null
  setOpenAddActionBtn: (value: number | null) => void
  openAddBtnBool: boolean | null
  setOpenAddBtnBool: (value: boolean | null) => void
  workflowName: string | undefined
  showDescription?: boolean
  workflowActionFlow: workflowActionFlowType | null
  entireTreeData: singleWorkflow | null
  actionIDs: string[]
  openInputBoxModal: boolean
  setOpenInputBoxModal: Dispatch<SetStateAction<boolean>>
  workflowStep: number | null
  setWorkflowStep: Dispatch<SetStateAction<number | null>>
  primaryValue: string
  setPrimaryValue: Dispatch<SetStateAction<string>>
  secondaryValue: string
  setSecondaryValue: Dispatch<SetStateAction<string>>
  actionLoader: number | null
  parentStepIdDrawer: number | null
  setParentStepIdDrawer: (value: number | null) => void
  workflowStatus: string | undefined
  workflowActionType: string
  setWorkflowActionType: Dispatch<SetStateAction<string>>
}) => {
  const {formatMessage} = useIntl()
  const [zoomLevel, setZoomLevel] = useState(1)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isPositiveDrawer, setIsPositiveDrawer] = useState<boolean | null>(false)
  const [openTimeModal, setOpenTimeModal] = useState<boolean>(false)
  const [delayTimeLoader, setdelayTimeLoader] = useState<boolean>(false)
  const [saveMessageLoading, setSaveMessageLoading] = useState<boolean>(false)
  const [msgErr, setMsgErr] = useState<boolean>(false)
  const [showDeleteStepModal, setShowDeleteStepModal] = useState<boolean>(false)
  const [deletingStep, setDeletingStep] = useState<boolean>(false)
  const [notAllowedActionIds, setNotAllowedActionIds] = useState<string[]>([])
  const [secondaryMsgErr, setSecondaryMsgErr] = useState<boolean>(false)

  const handle = useFullScreenHandle()
  const handleToggleFullScreen = () => {
    if (handle.active) {
      handle.exit()
    } else {
      handle.enter()
    }
  }

  const useGesture = createUseGesture([dragAction, pinchAction, wheelAction])
  useEffect(() => {
    const handler = (e: Event) => e.preventDefault()
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])
  const [style, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
  }))
  const ref = React.useRef<HTMLDivElement>(null)
  useGesture(
    {
      onDrag: ({pinching, cancel, offset: [x, y], ...rest}) => {
        if (pinching) return cancel()
        api.start({x, y})
      },
      onPinch: ({origin: [ox, oy], first, movement: [ms], offset: [s, a], memo}) => {
        setZoomLevel(s)
        api.start({scale: s})
        return memo
      },

      onWheel: ({event, memo = 0, offset: [_, y]}) => {
        const isMouseOrTouchpad = event && event.deltaY !== undefined

        if (!isMouseOrTouchpad) {
          const scaleDiff = y > 0 ? 0.1 : -0.1
          const newScale = style.scale.get() + scaleDiff
          const minScale = 0.2
          const maxScale = 2
          const clampedScale = Math.min(maxScale, Math.max(minScale, newScale))
          api.start({scale: clampedScale})
          setZoomLevel(clampedScale)
        }

        return memo
      },
    },
    {
      target: ref,
      drag: {from: () => [style.x.get(), style.y.get()]},
      pinch: {
        from: () => [style.scale.get(), 0],
        scaleBounds: {min: 0.2, max: 2},
        rubberband: true,
      },
    }
  )

  const checkVariable = (text: string) => {
    const variablesRegex = /%\w+%/g
    const matches = text?.match(variablesRegex)
    const allowedVariables = ['%first_name%', '%last_name%', '%country%']

    const intersection = matches?.some((item) => !allowedVariables.includes(item))

    return intersection ? true : false
  }
  const handleUpdateWorkflowStepValue = async () => {
    if (checkVariable(secondaryValue)) {
      setSecondaryMsgErr(true)
      return
    }
    if (!primaryValue && workflowActionType === 'MESSAGE') {
      setMsgErr(true)
      return
    } else if (!primaryValue && workflowActionType === 'SEND_AN_INVITE') {
      setOpenInputBoxModal(false)
      return
    } else if (workflowActionType === 'INMAIL') {
      const primaryValueData = JSON.parse(primaryValue)
      if (!primaryValueData.message) {
        setMsgErr(true)
        return
      }
    }

    try {
      setSaveMessageLoading(true)
      const {
        data: {success, errors},
      } = await updateWorkflowStep(primaryValue, secondaryValue, workflowStep)
      if (success) {
        setOpenInputBoxModal(false)
        toast.success(formatMessage({id: 'Message saved successfully'}))
        setSaveMessageLoading(false)
        getSingleWorkflowData(workflowId)
      } else {
        setOpenInputBoxModal(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setSaveMessageLoading(false)
      }
    } catch (err) {
      setOpenInputBoxModal(false)
      setSaveMessageLoading(false)
      console.log(err)
    }
  }
  const handleDeleteWorkflowStep = async () => {
    try {
      setDeletingStep(true)
      const {
        data: {success, errors},
      } = await deleteWorkflowStep(workflowStep)
      if (success) {
        setDeletingStep(false)
        setShowDeleteStepModal(false)
        getSingleWorkflowData(workflowId)
        toast.success(formatMessage({id: 'Workflow Step Deleted successfully'}))
      } else {
        setDeletingStep(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      setDeletingStep(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (!checkVariable(secondaryValue)) {
      setSecondaryMsgErr(false)
    }
  }, [secondaryValue])

  const treeContainerRef = React.useRef<HTMLDivElement>(null)
  const binaryTreeRef = React.useRef<HTMLDivElement>(null)
  const handleResize = _debounce(() => {
    const treeContainer = treeContainerRef.current
    const binaryTree = binaryTreeRef.current
    if (treeContainer && binaryTree) {
      const widthScale = treeContainer.clientWidth / binaryTree.clientWidth
      const heightScale = treeContainer.clientHeight / binaryTree.clientHeight
      const s = Math.min(widthScale, heightScale) - 0.07
      if (s < 1) {
        if (s > 0.2) {
          setZoomLevel(s)
          api.start({scale: s})
        } else {
          setZoomLevel(0.2)
          api.start({scale: 0.2})
        }
      } else {
        setZoomLevel(1)
        api.start({scale: 1})
      }
    }
  }, 200)
  useEffect(() => {
    const binaryTree = binaryTreeRef.current
    if (binaryTree) {
      const resizeObserver = new ResizeObserver(handleResize)
      resizeObserver.observe(binaryTree)
      handleResize()
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [binaryTreeRef])
  const handleResetSize = () => {
    const treeContainer = treeContainerRef.current
    const binaryTree = binaryTreeRef.current
    if (treeContainer && binaryTree) {
      const widthScale = treeContainer.clientWidth / binaryTree.clientWidth
      const heightScale = treeContainer.clientHeight / binaryTree.clientHeight
      const s = Math.min(widthScale, heightScale) - 0.07
      if (s < 1) {
        if (s > 0.2) {
          setZoomLevel(s)
          api.start({scale: s, x: 0, y: 0})
        } else {
          setZoomLevel(0.2)
          api.start({scale: 0.2, x: 0, y: 0})
        }
      } else {
        setZoomLevel(1)
        api.start({scale: 1, x: 0, y: 0})
      }
    }
  }
  const [formattedStatus, setFormattedStatus] = useState('')
  useEffect(() => {
    if (workflowStatus) {
      const formattedStatus =
        workflowStatus.charAt(0).toUpperCase() + workflowStatus.slice(1).toLowerCase()
      setFormattedStatus(formattedStatus)
    }
  }, [workflowStatus])
  return (
    <div className='card p-8 add-step-container card-option-wrapper'>
      <div>
        <PageHeading
          title={formatMessage({id: 'Workflow'})}
          description={formatMessage({
            id: 'A workflow is like a carefully planned set of steps that decide how and when you connect with potential customers in a campaign.',
          })}
          breadcrumbs={workflowBreadCrumbs}
          breadcrumbTitle={workflowName}
          workflowName={workflowName}
          showDescription={showDescription}
        ></PageHeading>
      </div>
      <FullScreen handle={handle} className='mt-8'>
        <div className='add-step-content' style={{overflow: 'clip'}} ref={treeContainerRef}>
          <div className='zoom text-center'>
            <button
              className='btn border border-primary zoom-in blue-color'
              onClick={() => {
                setZoomLevel(style.scale.get() + 0.1)
                api.start({scale: style.scale.get() + 0.1})
              }}
              disabled={zoomLevel >= 2}
            >
              &#43;
            </button>
            <div className='blue-color py-2 cursor-pointer' onClick={handleResetSize}>
              {formatMessage({id: 'Reset position'})}
            </div>
            <button
              className='btn border border-primary zoom-out blue-color'
              onClick={() => {
                setZoomLevel(style.scale.get() - 0.1)
                api.start({scale: style.scale.get() - 0.1})
              }}
              disabled={zoomLevel <= 0.2}
            >
              &minus;
            </button>
          </div>
          <div className='screen-fit d-flex'>
            {workflowStatus && (
              <div
                className={`${
                  workflowStatus === 'COMPLETE' ? 'complete' : 'incomplete'
                } rounded-pill px-4 py-1 me-6`}
              >
                <div className='font-size-10 font-weight-500 m-0'>{formattedStatus}</div>
              </div>
            )}
            <div className='cursor-pointer' onClick={handleToggleFullScreen}>
              <img
                src={toAbsoluteUrl('/media/icons/duotune/general/screenFit.svg')}
                alt='screenFit'
                height='24px'
                width='24px'
              />
            </div>
          </div>
          <animated.div ref={ref} style={style} className='drag-action'>
            <div className='main-screen d-flex justify-content-center align-items-center'>
              <div className='binary-tree' ref={binaryTreeRef}>
                <TreeStructure
                  node={tree}
                  workflowId={workflowId}
                  getSingleWorkflowData={getSingleWorkflowData}
                  userId={userId}
                  parentStepId={tree?.workflowStepId}
                  createWorkflowSteps={createWorkflowSteps}
                  isPositive={null}
                  isBinary={tree?.workflowAction?.isBinary}
                  dropdownOptionsList={dropdownOptionsList}
                  endSequenceAction={endSequenceAction}
                  openAddActionBtn={openAddActionBtn}
                  setOpenAddActionBtn={setOpenAddActionBtn}
                  openAddBtnBool={openAddBtnBool}
                  setOpenAddBtnBool={setOpenAddBtnBool}
                  bothChildNull={!tree.positiveStep && !tree.positiveStep}
                  showDescription={showDescription}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                  setIsPositiveDrawer={setIsPositiveDrawer}
                  setParentStepIdDrawer={setParentStepIdDrawer}
                  setOpenTimeModal={setOpenTimeModal}
                  setPrimaryValue={setPrimaryValue}
                  setSecondaryValue={setSecondaryValue}
                  setWorkflowStep={setWorkflowStep}
                  setOpenInputBoxModal={setOpenInputBoxModal}
                  setShowDeleteStepModal={setShowDeleteStepModal}
                  workflowActionFlow={workflowActionFlow}
                  entireTreeData={entireTreeData}
                  notAllowedActionIds={notAllowedActionIds}
                  setNotAllowedActionIds={setNotAllowedActionIds}
                  actionIDs={actionIDs}
                  actionLoader={actionLoader}
                  workflowActionType={workflowActionType}
                  setWorkflowActionType={setWorkflowActionType}
                />
              </div>
            </div>
          </animated.div>
        </div>
        <AddEndNodeDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          workflowId={workflowId}
          isPositiveProp={isPositiveDrawer}
          workflowStepId={parentStepIdDrawer}
          createWorkflowSteps={createWorkflowSteps}
          dropdownOptionsList={dropdownOptionsList}
          notAllowedActionIds={notAllowedActionIds}
        />
        <AddWaitTimeModal
          openTimeModal={openTimeModal}
          setOpenTimeModal={setOpenTimeModal}
          delayTimeLoader={delayTimeLoader}
          setdelayTimeLoader={setdelayTimeLoader}
          workflowStepId={workflowStep}
          primaryValue={primaryValue}
          secondaryValue={secondaryValue}
          getSingleWorkflowData={getSingleWorkflowData}
          workflowId={workflowId}
        />
        <CustomiseInputBoxesModal
          openInputBoxModal={openInputBoxModal}
          setOpenInputBoxModal={setOpenInputBoxModal}
          saveMessageLoading={saveMessageLoading}
          primaryMsg={primaryValue}
          setPrimaryMsg={setPrimaryValue}
          secondaryMsg={secondaryValue}
          setSecondaryMsg={setSecondaryValue}
          handleUpdateWorkflowStepValue={handleUpdateWorkflowStepValue}
          msgErr={msgErr}
          setMsgErr={setMsgErr}
          secondaryMsgErr={secondaryMsgErr}
          setSecondaryMsgErr={setSecondaryMsgErr}
          checkVariable={checkVariable}
          workflowActionType={workflowActionType}
        />
        <DeleteConfirmationModal
          showDeleteModal={showDeleteStepModal}
          setDeleteShowModal={setShowDeleteStepModal}
          headingMessage={formatMessage({id: 'Delete Workflow Step'})}
          description={formatMessage({
            id: 'Are you sure, you want to delete this step? ',
          })}
          handleDelete={handleDeleteWorkflowStep}
          deleteLoading={deletingStep}
        />
      </FullScreen>
      <div className='font-size-12 position-absolute instructionLine text-color'>
        <span>{formatMessage({id: 'Use'})}</span>&nbsp;
        <span className='text-danger'>{formatMessage({id: 'ctrl+mousewheel'})}</span>&nbsp;
        <span>{formatMessage({id: 'to zoom in / out of sequence tree'})}</span>
      </div>
    </div>
  )
}

const TreeDiagram = ({
  workflowSteps,
  userIdValue,
  isCampaign,
  showDescription,
  workflowName,
  workflowIdCamp,
}: {
  workflowSteps?: workflowSteps | undefined
  userIdValue?: number
  isCampaign?: boolean
  showDescription?: boolean
  workflowName?: string
  workflowIdCamp?: number
}) => {
  // useStates
  const [singleWorkflowData, setSingleWorkflowData] = useState<singleWorkflow>()
  const [dropdownOptionsList, setDropdownOptionsList] = useState<workflowAction[]>([])
  const [endSequenceAction, setEndSequenceAction] = useState<workflowAction[]>([])
  const [openAddActionBtn, setOpenAddActionBtn] = useState<number | null>(0)
  const [openAddBtnBool, setOpenAddBtnBool] = useState<boolean | null>(false)
  const [openFirstAddBtnBool, setOpenFirstAddBtnBool] = useState<boolean>(false)
  const [referenceElementFirst, setReferenceElementFirst] = useState<any>(null)
  const [popperElementFirst, setPopperElementFirst] = useState<any>(null)
  const [arrowElementFirst, setArrowElementFirst] = useState<any>(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [workflowActionFlow, setWorkflowActionFlow] = useState<workflowActionFlowType>()
  const [notAllowedFirstActionIds, setNotAllowedFirstActionIds] = useState<string[]>([])
  const [actionIDs, setActionIDs] = useState<string[]>([])
  const [openInputBoxModal, setOpenInputBoxModal] = useState<boolean>(false)
  const [workflowActionType, setWorkflowActionType] = useState('')
  const [workflowStep, setWorkflowStep] = useState<number | null>(0)
  const [primaryValue, setPrimaryValue] = useState<string>('')
  const [secondaryValue, setSecondaryValue] = useState<string>('')
  const [actionLoader, setActionLoader] = useState<number | null>(0)
  const [parentStepIdDrawer, setParentStepIdDrawer] = useState<number | null>(0)
  const [campaignWorkflowSteps, setCampaignWorkflowSteps] = useState<workflowSteps>()
  const [isWorkflowDataLoading, setIsWorkflowDataLoading] = useState<boolean>(false)
  useEffect(() => {
    if (workflowSteps) {
      setCampaignWorkflowSteps(workflowSteps)
    }
  }, [workflowSteps])

  const {styles, attributes} = usePopper(referenceElementFirst, popperElementFirst, {
    placement: 'right',
    modifiers: [
      {
        name: 'arrow',
        options: {element: arrowElementFirst},
      },
      {name: 'preventOverflow', options: {mainAxis: true}},
      {name: 'flip', options: {fallbackPlacements: ['left', 'top', 'bottom']}},
    ],
  })

  const {formatMessage} = useIntl()
  const location = useLocation()
  const pathname = location.pathname
  const parts = pathname.split('/')
  const workflowId = parts[parts.length - 1]
  const getSingleWorkflowData = async (workflowId: number) => {
    try {
      setIsWorkflowDataLoading(true)
      const {
        data: {data, success, errors},
      } = await getSingleWorkflow(workflowId)
      if (success) {
        if (isCampaign) {
          setCampaignWorkflowSteps(data.workflowSteps)
        } else {
          setSingleWorkflowData(data)
        }
        setIsWorkflowDataLoading(false)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setIsWorkflowDataLoading(false)
      }
    } catch (err) {
      console.log(err)
      setIsWorkflowDataLoading(false)
    }
  }

  const getWorkflowActionRestrictions = async () => {
    try {
      const {
        data: {data, success, errors},
      } = await getAllowedWorkflowActions()
      if (success) {
        setWorkflowActionFlow(data)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (isCampaign) {
      null
    } else {
      getSingleWorkflowData(parseInt(workflowId))
      getWorkflowActionRestrictions()
    }
  }, [])
  const createWorkflowSteps = async (
    workflowId: number,
    workflowStepId: number | null,
    workflowActionId: string,
    isPositive: boolean | null
  ) => {
    try {
      setOpenAddActionBtn(null)
      setActionLoader(parentStepIdDrawer)
      if (workflowId && workflowActionId) {
        const {
          data: {data, success, errors},
        } = await createWorkflowStep(workflowId, workflowStepId, workflowActionId, isPositive)
        if (success) {
          setActionLoader(0)
          toast.success(formatMessage({id: 'Action added successfully'}))
          getSingleWorkflowData!(workflowId)
          if (
            workflowActionId === workflowActionIdEnum.MESSAGE ||
            workflowActionId === workflowActionIdEnum.INMAIL ||
            workflowActionId === workflowActionIdEnum.SEND_AN_INVITE
          ) {
            setOpenInputBoxModal(true)
            setWorkflowActionType(workflowActionId)
            if (workflowStepId) {
              setWorkflowStep(data?.step_added?.workflowStepId)
              setPrimaryValue(data?.step_added?.primaryValue)
              setSecondaryValue(data?.step_added?.secondaryValue)
            } else {
              setWorkflowStep(data?.workflowStepId)
              setPrimaryValue(data?.primaryValue)
              setSecondaryValue(data?.secondaryValue)
            }
          }
        } else {
          setActionLoader(0)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
    } catch (err) {
      setActionLoader(0)
      console.log(err)
    }
  }
  const getAllWorkflowActions = async () => {
    try {
      const {
        data: {data, success, errors},
      } = await getWorkflowActions()

      if (success) {
        setDropdownOptionsList(
          data.filter(
            (obj: {type: string}) =>
              obj.type === actionType.ACTION || obj.type === actionType.CONDITION
          )
        )
        const actionConditionData = data.filter(
          (obj: {type: string}) =>
            obj.type === actionType.ACTION || obj.type === actionType.CONDITION
        )
        const workflowActionIds = actionConditionData.map(
          (item: {workflowActionId: string}) => item.workflowActionId
        )
        setActionIDs(workflowActionIds)
        const endAction = data.filter((item: {type: string}) => item.type === actionType.END)!
        setEndSequenceAction(endAction)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllWorkflowActions()
  }, [])
  function filterFalseActions(boolList: boolean[], actionIDs: string[]) {
    const falseActionIDs = []

    for (let i = 0; i < boolList.length; i++) {
      if (!boolList[i]) {
        falseActionIDs.push(actionIDs[i])
      }
    }

    return falseActionIDs
  }
  const handle = useFullScreenHandle()
  const handleToggleFullScreen = () => {
    if (handle.active) {
      handle.exit()
    } else {
      handle.enter()
    }
  }

  const handleDrawerOpen = () => {
    if (workflowActionFlow && singleWorkflowData) {
      const boolList = getEnableDisableList(0, workflowActionFlow, null, actionIDs)
      const allowedBoolList = boolList.filter((value) => value !== undefined)
      const result = filterFalseActions(allowedBoolList, actionIDs)
      setNotAllowedFirstActionIds(result)
    }
    setIsDrawerOpen(!isDrawerOpen)
  }

  return (
    <>
      {isWorkflowDataLoading ? (
        <div className='d-flex justify-content-center'>
          <Spinner />
        </div>
      ) : singleWorkflowData?.workflowSteps && workflowActionFlow ? (
        <BinaryTree
          tree={singleWorkflowData?.workflowSteps}
          workflowId={parseInt(workflowId)}
          getSingleWorkflowData={getSingleWorkflowData}
          userId={singleWorkflowData?.userId}
          createWorkflowSteps={createWorkflowSteps}
          dropdownOptionsList={dropdownOptionsList}
          endSequenceAction={endSequenceAction[0]?.workflowActionId}
          openAddActionBtn={openAddActionBtn}
          setOpenAddActionBtn={setOpenAddActionBtn}
          openAddBtnBool={openAddBtnBool}
          setOpenAddBtnBool={setOpenAddBtnBool}
          workflowName={singleWorkflowData?.name}
          workflowActionFlow={workflowActionFlow}
          entireTreeData={singleWorkflowData}
          actionIDs={actionIDs}
          openInputBoxModal={openInputBoxModal}
          setOpenInputBoxModal={setOpenInputBoxModal}
          workflowStep={workflowStep}
          setWorkflowStep={setWorkflowStep}
          primaryValue={primaryValue}
          setPrimaryValue={setPrimaryValue}
          secondaryValue={secondaryValue}
          setSecondaryValue={setSecondaryValue}
          actionLoader={actionLoader}
          parentStepIdDrawer={parentStepIdDrawer}
          setParentStepIdDrawer={setParentStepIdDrawer}
          workflowStatus={singleWorkflowData?.status}
          workflowActionType={workflowActionType}
          setWorkflowActionType={setWorkflowActionType}
        />
      ) : campaignWorkflowSteps ? (
        <BinaryTree
          tree={campaignWorkflowSteps}
          workflowId={workflowIdCamp ? workflowIdCamp : 0}
          getSingleWorkflowData={getSingleWorkflowData}
          userId={userIdValue ?? 0}
          createWorkflowSteps={createWorkflowSteps}
          dropdownOptionsList={dropdownOptionsList}
          endSequenceAction={endSequenceAction[0]?.workflowActionId}
          openAddActionBtn={openAddActionBtn}
          setOpenAddActionBtn={setOpenAddActionBtn}
          openAddBtnBool={openAddBtnBool}
          setOpenAddBtnBool={setOpenAddBtnBool}
          workflowName={workflowName}
          showDescription={showDescription}
          workflowActionFlow={null}
          entireTreeData={null}
          actionIDs={actionIDs}
          openInputBoxModal={openInputBoxModal}
          setOpenInputBoxModal={setOpenInputBoxModal}
          workflowStep={workflowStep}
          setWorkflowStep={setWorkflowStep}
          primaryValue={primaryValue}
          setPrimaryValue={setPrimaryValue}
          secondaryValue={secondaryValue}
          setSecondaryValue={setSecondaryValue}
          actionLoader={actionLoader}
          parentStepIdDrawer={parentStepIdDrawer}
          setParentStepIdDrawer={setParentStepIdDrawer}
          workflowStatus={singleWorkflowData?.status}
          workflowActionType={workflowActionType}
          setWorkflowActionType={setWorkflowActionType}
        />
      ) : (
        <div className='card p-8 add-step-container card-option-wrapper'>
          <div>
            <PageHeading
              title={formatMessage({id: 'Workflow'})}
              description={formatMessage({
                id: 'A workflow is like a carefully planned set of steps that decide how and when you connect with potential customers in a campaign.',
              })}
              breadcrumbs={workflowBreadCrumbs}
              breadcrumbTitle={singleWorkflowData?.name}
              workflowName={singleWorkflowData?.name}
            ></PageHeading>
          </div>
          <FullScreen handle={handle} className='fullscreen mt-8'>
            <div className={`add-step-content`}>
              <div className='zoom text-center'>
                <button
                  className='btn border border-primary zoom-in py-1 px-3 blue-color'
                  disabled={true}
                >
                  &#43;
                </button>
                <div className='blue-color py-2 cursor-pointer'>
                  {formatMessage({id: 'Reset position'})}
                </div>
                <button
                  className='btn border border-primary zoom-out py-1 px-3 blue-color'
                  disabled={true}
                >
                  &minus;
                </button>
              </div>
              <div className='screen-fit d-flex'>
                <div className='incomplete rounded-pill px-4 py-1 me-6'>
                  <div className='font-size-10 font-weight-500 m-0'>
                    {formatMessage({id: 'Incomplete'})}
                  </div>
                </div>

                <div className='cursor-pointer' onClick={handleToggleFullScreen}>
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/general/screenFit.svg')}
                    alt='screenFit'
                    height='24px'
                    width='24px'
                  />
                </div>
              </div>
              <div className='add-action text-center'>
                <div className='fw-bold my-2'>{formatMessage({id: 'Add new Actions'})}</div>
                <button
                  ref={setReferenceElementFirst}
                  className='btn add-step-button fw-bold'
                  onClick={handleDrawerOpen}
                >
                  <img
                    src={toAbsoluteUrl('/media/icons/duotune/general/plusCircle.svg')}
                    alt='plus'
                    height='16px'
                    width='16px'
                    className='me-2'
                  />
                  {formatMessage({id: 'Add Action'})}
                </button>
              </div>
            </div>
            <AddEndNodeDrawer
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              workflowId={parseInt(workflowId)}
              isPositiveProp={true}
              workflowStepId={null}
              createWorkflowSteps={createWorkflowSteps}
              dropdownOptionsList={dropdownOptionsList}
              notAllowedActionIds={notAllowedFirstActionIds}
            />
          </FullScreen>
          <div className='font-size-12 position-absolute instructionLine text-color'>
            <span>{formatMessage({id: 'Use'})}</span>&nbsp;
            <span className='text-danger'>{formatMessage({id: 'cntrl+mousewheel'})}</span>&nbsp;
            <span>{formatMessage({id: 'to zoom in / out of sequence tree'})}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default TreeDiagram
