import clsx from 'clsx'
import {PageTitle, ToolbarType, useLayout} from '../../core'
import {Toolbar} from './Toolbar'
import {PageTitleWrapper} from './page-title'
import {useLocation, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Tabs, {Tab} from 'react-best-tabs'
import 'react-best-tabs/dist/index.css'
import {User} from '../../../../app/modules/profile/components/User'
import {Billing} from '../../../../app/modules/profile/components/Billing'
import {Locale} from '../../../../app/modules/profile/components/Locale'
import {Referral} from '../../../../app/modules/profile/components/Referral'
import {getCompanyMetaIdType} from '../../../../app/modules/profile/core/_models'
import {
  profileBreadCrumbs,
  userInitialValues,
  localeInitialValues,
  Roles,
} from '../../../../app/modules/profile/core/_constants'
import {useEffect, useMemo, useState} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {
  getCountry,
  getLocale,
  profileData,
  getReferralData,
  getTotalReferralEarning,
  getUserList,
  getPendingUsers,
  getTransactionData,
} from '../../../../app/modules/profile/core/_requests'
import {toast} from 'react-toastify'
import {CountryModel} from '../../../../app/modules/profile/core/_models'
import useGetBillingData from '../../../../app/hooks/useGetBillingData'
import {Team} from '../../../../app/modules/profile/components/Team'
import {toAbsoluteUrl} from '../../../helpers'
import {Transaction} from '../../../../app/modules/profile/components/Transaction'
import {PageHeading} from '../../../../app/modules/template/components/PageHeading'
import {SelectPlanModal} from '../../../../app/modules/profile/components/SelectPlanModal'
import NoDataPage from '../../../../app/modules/widgets/components/NoDataPage'

const ToolbarWrapper = () => {
  const location = useLocation()
  const {formatMessage} = useIntl()
  const {config, classes} = useLayout()
  const [key, setKey] = useState(1)
  const {
    currentUser,
    setCurrentUser,
    setCurrentState,
    setSelected,
    setCurrencyBill,
    billingData,
    selected,
    setBillingData,
    setShowBillingModal,
    priceId,
    setPriceId,
  } = useAuth()
  const [getApiLoading, setApiLoading] = useState(false)
  const [imgName, setImgName] = useState<string>()
  const [countryOptions, setcountryOptions] = useState<any[]>([])
  const [getLocaleApiLoading, setGetLocaleApiLoading] = useState(false)
  const {onAccessBillingPortal} = useGetBillingData()
  const [referral, setReferral] = useState<string>()
  const [paypalId, setPaypalId] = useState<string>()
  const [totalEarning, setTotalEarning] = useState<string>()
  const [totalReferred, setTotalReferred] = useState<string>()
  const [totalPaidUsers, setTotalPaidUsers] = useState<string>()
  const [totalTrialUsers, setTotalTrialUsers] = useState<string>()
  const [userList, setUserList] = useState<any[]>([])
  const [pendingUsersList, setPendingUsersList] = useState<any[]>([])
  const [linkedinEmail, setLinkedinEmail] = useState<string>('')
  const [transactionData, setTransactionData] = useState<any[] | string | undefined>([])
  const [searchParams] = useSearchParams()
  const tabKey = useMemo(() => searchParams.get('tabKey'), [])
  const [runTimezoneApi, setRunTimezoneApi] = useState<boolean>(false)
  const [runUserDetailsApi, setRunUserDetailsApi] = useState<boolean>(true)
  const [runCardDetailsApi, setRunCardDetailsApi] = useState<boolean>(false)
  const [serverProvisioned, setServerProvisioned] = useState<boolean>(false)
  const [transactionDetails, setTransactionDetails] = useState<boolean>(false)
  const [referralTabData, setReferralTabData] = useState<boolean>(false)
  const [teamTabData, setTeamTabData] = useState<boolean>(false)
  const [linkedinName, setLinkedinName] = useState<string>('')
  const [linkedinUrl, setLinkedinUrl] = useState<string>('')
  const [userUpdatedAt, setuserUpdatedAt] = useState<string>('')
  const [selectPlanModal, setSelectPlanModal] = useState<boolean>(false)
  const [transactionLoading, setTransactionLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('Profile')
  // api calling starts
  //Billing API Call

  useEffect(() => {
    if (billingData && Object.keys(billingData).length !== 0) {
      setSelected(billingData?.productName)
      setCurrencyBill(billingData?.plan?.currency.toUpperCase())
      setCurrentState(billingData?.plan?.interval)
    }
  }, [billingData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getProfileInfo = async () => {
    try {
      setApiLoading(true)
      const {
        data: {success, data, errors},
      } = await profileData()
      if (success) {
        setApiLoading(false)
        setImgName(data.profileImage)
        setCurrentUser({
          ...currentUser,
          firstName: data.firstName,
          profileImg: data.profileImage,
          lastName: data.lastName,
        })
        userInitialValues.firstName = data.firstName
        userInitialValues.lastName = data.lastName
        userInitialValues.email = data.email
        userInitialValues.contact = data.contact!
        userInitialValues.country = data.countryId!
        userInitialValues.profileImageId = data.profileImageId!
        setServerProvisioned(data.serverProvisioned)
        setLinkedinEmail(data.linkedInEmail)
        setLinkedinName(data.linkedInName)
        setLinkedinUrl(data.linkedInUrl)
        setRunUserDetailsApi(false)
        setuserUpdatedAt(data.updatedAt)
      } else if (errors) {
        setApiLoading(false)
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setRunUserDetailsApi(false)
      }
    } catch (err) {
      setApiLoading(false)
      console.log(err)
      setRunUserDetailsApi(false)
    }
  }

  useEffect(() => {
    const fetchCountry = async () => {
      const {
        data: {data: countries},
      } = await getCountry()
      const countriesData = countries.map((country: CountryModel) => {
        return {
          id: country.countryId,
          name: country.country_name,
          value: country.countryId,
        }
      })
      setcountryOptions([...countriesData])
    }
    if (runUserDetailsApi) {
      fetchCountry()
    }
  }, [runUserDetailsApi])
  // api calling ends

  useEffect(() => {
    const getCompanyMeta = async () => {
      try {
        setGetLocaleApiLoading(true)
        const {
          data: {success, data, errors},
        } = await getLocale()
        if (success) {
          setGetLocaleApiLoading(false)
          data.map(({key, value}: getCompanyMetaIdType) => {
            return (localeInitialValues[key] = value)
          })
          return null
        } else {
          setGetLocaleApiLoading(false)
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      } catch (err) {
        setGetLocaleApiLoading(false)
        console.log(err)
      }
    }
    if (runTimezoneApi) {
      getCompanyMeta()
    }
  }, [runTimezoneApi]) // eslint-disable-line react-hooks/exhaustive-deps

  const getReferral = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getReferralData()
      if (success) {
        setReferral(data.referCode)
        setPaypalId(data.paypalEmail)
      } else {
        errors?.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const totalReferralEarning = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getTotalReferralEarning()
      if (success) {
        setTotalEarning(data.totalEarnings)
        setTotalReferred(data.totalReferred)
        setTotalPaidUsers(data.user_subscribed_referred)
        setTotalTrialUsers(data.user_trail_referred)
        setReferralTabData(false)
      } else {
        errors?.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setReferralTabData(false)
      }
    } catch (err) {
      console.log(err)
      setReferralTabData(false)
    }
  }
  useEffect(() => {
    if (referralTabData) {
      getReferral()
      totalReferralEarning()
    }
  }, [referralTabData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPendingUserList = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getPendingUsers()
      if (success) {
        setPendingUsersList(data)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  const getUsersList = async () => {
    try {
      const {
        data: {success, data, errors},
      } = await getUserList()
      if (success) {
        setUserList(data)
        setTeamTabData(false)
      } else {
        errors.forEach((error: string) => {
          toast.error(formatMessage({id: error}))
        })
        setTeamTabData(false)
      }
    } catch (err) {
      console.log(err)
      setTeamTabData(false)
    }
  }
  useEffect(() => {
    if (teamTabData) {
      getUsersList()
      getPendingUserList()
    }
  }, [teamTabData]) // eslint-disable-line react-hooks/exhaustive-deps
  if (!config.app?.toolbar?.display) {
    return null
  }

  async function getTransactions() {
    try {
      setTransactionLoading(true)
      const {
        data: {data},
      } = await getTransactionData()
      {
        setTransactionData(data)
        setTransactionDetails(false)
      }
    } catch (error) {
      console.log(error)
      setTransactionDetails(false)
    } finally {
      setTransactionLoading(false)
    }
  }

  useEffect(() => {
    if (transactionDetails) {
      getTransactions()
    }
  }, [transactionDetails])

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )

  const handleTabClick = (tab: any) => {
    setActiveTab(tab)
  }
  useEffect(() => {
    const tabsContainer = document.getElementsByClassName('setting-tabs-list')[0]
    if (tabsContainer) {
      const tabElements = tabsContainer.getElementsByClassName('rb-tabs-item')
      const selectedTab = Array.from(tabElements).find(
        (element) => element.textContent === activeTab
      )
      if (selectedTab) {
        const rect = selectedTab.getBoundingClientRect()
        tabsContainer.scrollTo({
          left: rect.left - tabsContainer.getBoundingClientRect().left,
          behavior: 'smooth',
        })
      }
    }
  }, [activeTab])
  return (
    <div id='kt_app_toolbar' className={clsx('app-toolbar')}>
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container d-md-flex flex-md-column align-items-start flex-column overflow-hidden',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        {location.pathname.includes('settings') && (
          <>
            <div className='card p-md-8 w-100 p-4'>
              <PageHeading
                title={formatMessage({id: 'Settings'})}
                description={formatMessage({
                  id: 'You can customize the profile, billing, transaction, and many more options here.',
                })}
                tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.SETTINGS.TITLE'})}
                breadcrumbs={profileBreadCrumbs}
                breadcrumbTitle={formatMessage({id: 'Settings'})}
              ></PageHeading>
            </div>
            <Tabs
              activeTab={parseInt(`${tabKey ? tabKey : 1}`)}
              className='font-size-13 pt-4'
              ulClassName='text-muted dark-border setting-tabs-list'
              activityClassName='active-tab'
              onClick={(event, tab) => {
                setKey(tab)
                if (tab === 1) {
                  setRunUserDetailsApi(true)
                  handleTabClick('Profile')
                } else if (tab === 2) {
                  setRunCardDetailsApi(true)
                  handleTabClick('Billing')
                } else if (tab === 3) {
                  setTransactionDetails(true)
                  handleTabClick('Transactions')
                } else if (tab === 4 && currentUser?.metadata?.team === 'false') {
                  setSelectPlanModal(true)
                  handleTabClick('Team')
                } else if (tab === 4 && currentUser?.metadata?.team === 'true') {
                  setTeamTabData(true)
                  handleTabClick('Team')
                } else if (tab === 5) {
                  setRunTimezoneApi(true)
                  handleTabClick('Locale')
                } else if (tab === 6) {
                  setReferralTabData(true)
                  handleTabClick('Referral')
                }
              }}
            >
              <Tab
                title={formatMessage({id: 'Profile'})}
                className='mr-3 mt-2 fw-bolder text-color'
              >
                <div className='mt-4'>
                  <User
                    getApiLoading={getApiLoading}
                    setImgName={setImgName}
                    imgName={imgName}
                    countryOptions={countryOptions}
                    linkedinEmail={linkedinEmail}
                    runUserDetailsApi={runUserDetailsApi}
                    serverProvisioned={serverProvisioned}
                    getProfileInfo={getProfileInfo}
                    linkedinName={linkedinName}
                    linkedinUrl={linkedinUrl}
                    userUpdatedAt={userUpdatedAt}
                  />
                </div>
              </Tab>

              <Tab
                title={formatMessage({id: 'Billing'})}
                className={`mr-3 mt-2  fw-bolder text-color ${
                  currentUser?.role === Roles.OWNER ? '' : 'd-none'
                }`}
              >
                <div className='mt-4'>
                  <Billing
                    billingData={billingData}
                    selected={selected}
                    onAccessBillingPortal={onAccessBillingPortal}
                    runCardDetailsApi={runCardDetailsApi}
                    setRunCardDetailsApi={setRunCardDetailsApi}
                  />
                </div>
              </Tab>

              <Tab
                title={formatMessage({id: 'Transactions'})}
                className={`mr-3 mt-2 fw-bolder text-color ${
                  currentUser?.role === Roles.OWNER ? '' : 'd-none'
                }`}
              >
                <div className='mt-4'>
                  <Transaction
                    key={key}
                    transactionData={transactionData}
                    transactionLoading={transactionLoading}
                  />
                </div>
              </Tab>

              <Tab
                title={
                  (
                    <div
                      className={`d-flex flex-row ${
                        currentUser?.metadata?.team === 'true' ? '' : 'text-color'
                      }`}
                    >
                      <div>
                        <img
                          src={toAbsoluteUrl('/media/icons/investor/darkLock.svg')}
                          alt='lock icon'
                          width={15}
                          className={`me-2  ${
                            currentUser?.metadata?.team === 'true' ? 'd-none' : ''
                          }`}
                          height={15}
                        />
                      </div>
                      {formatMessage({id: 'Team'})}
                    </div>
                  ) as any
                }
                className={`mr-3 mt-2 fw-bolder text-color ${
                  currentUser?.role === 'member' ? 'd-none' : ''
                }`}
              >
                {currentUser?.metadata?.team === 'true' && (
                  <div className='mt-4'>
                    <Team
                      userList={userList}
                      pendingUserList={pendingUsersList}
                      getUserList={getUsersList}
                      getPendingUsers={getPendingUserList}
                      billingData={billingData}
                      runCardDetailsApi={runCardDetailsApi}
                      runUserDetailsApi={runUserDetailsApi}
                      setRunUserDetailsApi={setRunUserDetailsApi}
                    />
                  </div>
                )}
              </Tab>

              <Tab title={formatMessage({id: 'Locale'})} className='mr-3 mt-2 fw-bolder text-color'>
                <div className='mt-4'>
                  <Locale
                    key={key}
                    getLocaleApiLoading={getLocaleApiLoading}
                    setRunTimezoneApi={setRunTimezoneApi}
                    runTimezoneApi={runTimezoneApi}
                  />
                </div>
              </Tab>

              <Tab
                title={formatMessage({id: 'Referral'})}
                className='mr-3 mt-2 fw-bolder text-color d-none'
              >
                <div className='mt-4'>
                  <Referral
                    key={key}
                    paypalId={paypalId}
                    referral={referral}
                    totalEarning={totalEarning}
                    totalReferred={totalReferred}
                    setPaypalId={setPaypalId}
                    totalPaidUsers={totalPaidUsers}
                    totalTrialUsers={totalTrialUsers}
                  />
                </div>
              </Tab>
            </Tabs>
          </>
        )}
      </div>
      <SelectPlanModal
        selectPlanModal={selectPlanModal}
        setSelectPlanModal={setSelectPlanModal}
        setShowBillingModal={setShowBillingModal}
        setBillingData={setBillingData}
        setPriceId={setPriceId}
        runCardDetailsApi={runCardDetailsApi}
        runUserDetailsApi={runUserDetailsApi}
        modalText={formatMessage({
          id: 'Your current plan (Starter) does not support the Team feature. Please upgrade your subscription to access this.',
        })}
      ></SelectPlanModal>
    </div>
  )
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export {ToolbarWrapper}
