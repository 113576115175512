import React, {useEffect, useState} from 'react'
import '../../styles/_campaign.scss'
import {useIntl} from 'react-intl'
import {FilterForLeads} from './FilterForLeads'
import {useNavigate, useParams} from 'react-router-dom'
import {getCampaignDetails, updateCampaign, updateCampaignDetails} from '../../core/_requests'
import {CampaignObject} from '../../core/_models'
import {useTimeUnit} from '../../../../hooks/useTimeUnit'
import {Spinner} from '../../../widgets/components/General/Spinner'
import {toast} from 'react-toastify'
import {CampaignSettings} from './CampaignSetting'
import {PageHeading} from '../../../template/components/PageHeading'
import {campaignDetailsBreadCrumbs, profileBreadCrumbs} from '../../../profile/core/_constants'

export const CampaignSettingsPage = ({
  prevStep,
  submitStep,
  isApiCalled,
  setIsApiCalled,
  campaignName,
}: {
  prevStep: any
  submitStep: any
  isApiCalled: boolean
  setIsApiCalled: any
  campaignName: string | undefined
}) => {
  const {formatMessage} = useIntl()
  const {campaignId} = useParams()
  const [campaignDataList, setCampaignDataList] = useState<CampaignObject>()
  const [loader, setLoader] = useState<boolean>(true)

  const getCampaignData = async () => {
    try {
      setLoader(true)
      if (campaignId && isApiCalled) {
        const campaignValue = parseInt(campaignId, 10)
        const {
          data: {success, errors, data},
        } = await getCampaignDetails(campaignValue)
        if (success) {
          setCampaignDataList(data)
          setIsApiCalled(false)
        } else {
          errors.forEach((error: string) => {
            toast.error(formatMessage({id: error}))
          })
        }
      }
      setLoader(false)
    } catch (error: any) {
      setLoader(false)
      console.log(error)
    }
  }

  useEffect(() => {
    if (isApiCalled && campaignId) {
      getCampaignData()
    }
  }, [campaignDataList, isApiCalled])

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <div className='w-100 p-8'>
          <PageHeading
            title={formatMessage({id: 'Settings'})}
            description={formatMessage({
              id: 'Enhance your campaign by utilizing the available options on the settings page. Additionally, review the details of your previous selections in the preceding steps',
            })}
            tooltipText={formatMessage({id: 'GLOBAL.TOOLTIP.CAMPAIGNS.SETTING'})}
            breadcrumbs={campaignDetailsBreadCrumbs}
            breadcrumbTitle={formatMessage({id: 'Settings'})}
            campaignName={campaignName}
          ></PageHeading>
          <div className='pt-8'>
            <CampaignSettings
              campaignName={campaignDataList?.name}
              totalLeadsCount={campaignDataList?.leadLists.length}
              excludeOtherCampaignLead={campaignDataList?.excludeOtherCampaignLead}
              excludeTeamCampaignLead={campaignDataList?.excludeTeamCampaignLead}
              workingHour={campaignDataList?.workingHour}
              workflowName={campaignDataList?.workflow.name}
              isCampaignDetails={false}
              name={campaignDataList?.name}
              getCampaignData={getCampaignData}
              prevStep={prevStep}
              submitStep={submitStep}
              leadsCount={campaignDataList?.totalLeadsCount}
              totalExpectedLeads={campaignDataList?.totalExpectedLeads}
            />
          </div>
        </div>
      )}
    </>
  )
}
