import React from 'react'
import {useIntl} from 'react-intl'
import '../styles/_template.scss'

export const VariablesMoreOption = ({
  isOpenVariables,
  appendVariableToPrimaryMsg,
  primaryCharacterCount,
  getCharacterLimit,
}: {
  isOpenVariables: boolean
  appendVariableToPrimaryMsg: (value: string) => void
  primaryCharacterCount: number
  getCharacterLimit: number
}) => {
  const {formatMessage} = useIntl()
  console.log(primaryCharacterCount + ' %%number_of_connections%%  '.length <= getCharacterLimit)
  return (
    <>
      {isOpenVariables ? (
        <div className='menu menu-sub menu-sub-dropdown data-room-option-dropdown show more-variable-dropdown'>
          <div className='separator border-gray-200'></div>
          <div
            className='option-padding'
            onClick={() => {
              if (
                primaryCharacterCount + ' %%number_of_connections%%  '.length <=
                getCharacterLimit
              ) {
                appendVariableToPrimaryMsg(' %%number_of_connections%% ')
              }
            }}
          >
            <p className='mb-0 text-dark'>{formatMessage({id: 'Number of connections'})}</p>
          </div>
          <div className='separator border-gray-200'></div>
          <div
            className='option-padding'
            onClick={() => {
              if (primaryCharacterCount + ' %%top_skill%%  '.length <= getCharacterLimit) {
                appendVariableToPrimaryMsg(' %%top_skill%% ')
              }
            }}
          >
            <p className='mb-0 text-dark'>{formatMessage({id: 'Top skill'})}</p>
          </div>
          <div className='separator border-gray-200'></div>
          <div
            className='option-padding'
            onClick={() => {
              if (primaryCharacterCount + ' %%country%%'.length <= getCharacterLimit) {
                appendVariableToPrimaryMsg(' %%country%% ')
              }
            }}
          >
            <p className='mb-0 text-dark'>{formatMessage({id: 'Country'})}</p>
          </div>
          <div className='separator border-gray-200'></div>
          <div className='option-padding'>
            <p
              className='mb-0 text-dark'
              onClick={() => {
                if (primaryCharacterCount + ' %%university_name%%'.length <= getCharacterLimit) {
                  appendVariableToPrimaryMsg(' %%university_name%% ')
                }
              }}
            >
              {formatMessage({id: 'University name'})}
            </p>
          </div>
          <div className='separator border-gray-200'></div>
          <div
            className='option-padding'
            onClick={() => {
              if (primaryCharacterCount + ' %%profile_type%% '.length <= getCharacterLimit) {
                appendVariableToPrimaryMsg(' %%profile_type%% ')
              }
            }}
          >
            <p className='mb-0 text-dark'>{formatMessage({id: 'Profile type(Free/Premium)'})}</p>
          </div>
          <div className='separator border-gray-200'></div>
          <div
            className='option-padding'
            onClick={() => {
              if (
                primaryCharacterCount + ' %%time_in_current_role%% '.length <=
                getCharacterLimit
              ) {
                appendVariableToPrimaryMsg(' %%time_in_current_role%% ')
              }
            }}
          >
            <p className='mb-0 text-dark'>{formatMessage({id: 'Time in current role'})}</p>
          </div>
        </div>
      ) : null}
    </>
  )
}
