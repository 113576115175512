export const roundTypeOptions = [
  {
    id: 1,
    name: 'Equity - Priced Round',
    value: 'Equity - Priced Round',
  },
  {
    id: 2,
    name: 'Convertible Note',
    value: 'Convertible Note',
  },
  {
    id: 3,
    name: 'Loan (Debt)',
    value: 'Loan (Debt)',
  },
  {
    id: 4,
    name: 'SAFE',
    value: 'SAFE',
  },
  {
    id: 5,
    name: 'SAFT',
    value: 'SAFT',
  },
]

export const RolesConst = [
  {id: 6, name: 'ADMIN', value: 'admin'},
  {id: 7, name: 'BILLING', value: 'billing'},
  {id: 8, name: 'IRM', value: 'irm'},
  {id: 9, name: 'FRM', value: 'frm'},
  {id: 10, name: 'CURATOR', value: 'curator'},
  {id: 11, name: 'OWNER', value: 'owner'},
]

export const plans = [
  {
    title: 'Basic',
    curreny: 'INR',
    priceMonthINR: '0.00',
    priceAnnualINR: '0.00',
    priceMonthUSD: '0.00',
    priceAnnualUSD: '0.00',
    custom: false,
    features: [
      {
        title: 'Add Upto 25 investors to CRM funnel',
        supported: true,
      },
      {
        title: 'Create upto 5 investor updates',
        supported: true,
      },
      {
        title: 'Send upto 5 Email communication',
        supported: true,
      },
      {
        title: 'View upto 50 investors profile',
        supported: true,
      },
      {
        title: 'Create your company page',
        supported: true,
      },
      {
        title: 'Add up to 2 team members',
        supported: true,
      },
      {
        title: 'Data import',
        supported: false,
      },
      {
        title: 'Data Room',
        supported: false,
      },
      {
        title: 'Data Export',
        supported: false,
      },
      {
        title: 'BCC Tracking',
        supported: false,
      },
    ],
  },
  {
    title: 'Silver',
    curreny: 'INR',
    priceMonthINR: '3,900.00',
    priceAnnualINR: '39,000.00',
    priceMonthUSD: '49.00',
    priceAnnualUSD: '490.00',
    custom: false,
    label: 'Recommended',
    features: [
      {
        title: 'Add Upto 100 investors to CRM funnel',
        supported: true,
      },
      {
        title: 'Create upto 12 investor updates',
        supported: true,
      },
      {
        title: 'Send upto 30 Email Communication',
        supported: true,
      },
      {
        title: 'View upto 250 investors profile',
        supported: true,
      },
      {
        title: 'Create your company page',
        supported: true,
      },
      {
        title: 'Add up to 5 team members',
        supported: true,
      },
      {
        title: 'Data import',
        supported: true,
      },
      {
        title: 'Data room',
        supported: true,
      },
      {
        title: 'Data Export',
        supported: false,
      },
      {
        title: 'BCC Tracking',
        supported: false,
      },
    ],
  },
  {
    title: 'Gold',
    curreny: 'INR',
    priceMonthINR: '6,400.00',
    priceAnnualINR: '64,000.00',
    priceMonthUSD: '79.00',
    priceAnnualUSD: '790.00',
    custom: false,
    label: 'Popular',
    features: [
      {
        title: 'Add Upto 500 investors to CRM funnel',
        supported: true,
      },
      {
        title: 'Create unlimited investor updates',
        supported: true,
      },
      {
        title: 'Send unlimited Email Communication',
        supported: true,
      },
      {
        title: 'View unlimited investors profile',
        supported: true,
      },
      {
        title: 'Create your company page',
        supported: true,
      },
      {
        title: 'Add up to 30 team members',
        supported: true,
      },
      {
        title: 'Data import',
        supported: true,
      },
      {
        title: 'Data room',
        supported: true,
      },
      {
        title: 'Data Export',
        supported: true,
      },
      {
        title: 'BCC tracking',
        supported: true,
      },
    ],
  },
]

export enum PlanCycle {
  MONTHLY = 'month',
  QUARTERLY = 'quarter',
  YEARLY = 'year',
}
