/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/campaigns'
        icon='/media/icons/sidebar/campaignWhite.svg'
        activeIcon='/media/icons/sidebar/campaignBlue.svg'
        hoverIcon='/media/icons/sidebar/campaign.svg'
        title={intl.formatMessage({id: 'Campaigns'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/workflow'
        icon='/media/icons/sidebar/workflowWhite.svg'
        activeIcon='/media/icons/sidebar/workflowBlue.svg'
        hoverIcon='/media/icons/sidebar/workflow.svg'
        title={intl.formatMessage({id: 'Workflows'})}
        fontIcon='bi-layers'
      />

      <SidebarMenuItem
        to='/leads'
        icon='/media/icons/sidebar/leadsWhite.svg'
        activeIcon='/media/icons/sidebar/leadsBlue.svg'
        hoverIcon='/media/icons/sidebar/leads.svg'
        title={intl.formatMessage({id: 'Leads'})}
        fontIcon='bi-layers'
      />
      {process.env.REACT_APP_DEV_ENV === 'dev' && (
        <SidebarMenuItem
          to='/inbox'
          icon='/media/icons/sidebar/inbox.svg'
          activeIcon='/media/icons/sidebar/inboxBlue.svg'
          hoverIcon='/media/icons/sidebar/inboxOrange.svg'
          title={intl.formatMessage({id: 'Inbox'})}
          fontIcon='bi-layers'
        />
      )}
      <SidebarMenuItem
        to='/activity-log'
        icon='/media/icons/sidebar/logWhite.svg'
        activeIcon='/media/icons/sidebar/logBlue.svg'
        hoverIcon='/media/icons/sidebar/log.svg'
        title={intl.formatMessage({id: 'Activity Log'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/activity-control'
        icon='/media/icons/sidebar/controlWhite.svg'
        activeIcon='/media/icons/sidebar/controlBlue.svg'
        hoverIcon='/media/icons/sidebar/control.svg'
        title={intl.formatMessage({id: 'Activity Control'})}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/settings'
        icon='/media/icons/sidebar/settingsWhite.svg'
        activeIcon='/media/icons/sidebar/settingsBlue.svg'
        hoverIcon='/media/icons/sidebar/settings.svg'
        title={intl.formatMessage({id: 'Settings'})}
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
